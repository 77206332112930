/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, Suspense } from 'react';

import {
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Brightness1 } from '@material-ui/icons';
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { isArray } from 'shineout/lib/utils/is';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import RadioGroup from 'components/RadioGroup/radioGroup';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import * as MenuActions from 'store/actions/menu';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import CmvTable from './CmvTable';

const environments = Environment(process.env.REACT_APP_ENV);

const DiscardPercentageAndValue = React.lazy(() =>
  import('components/Dialogs/DiscardPercentageAndValue')
);

const DifferenceInCount = React.lazy(() =>
  import('components/Dialogs/DifferenceInCount')
);

const TheoryStockDialog = React.lazy(() =>
  import('components/Dialogs/ExtractsDialog')
);

const DetailsDiscardByProductDialog = React.lazy(() =>
  import('components/Dialogs/DetailsDiscardByType')
);

function Cmv({ userInfo }) {
  const { id: paramsId } = useParams();
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'discardPercentageAndValue',
      open: false,
      fullScreen: null,
      maxWidth: null
    },
    {
      id: 2,
      name: 'extracts',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      data: [],
      descriptionSku: null
    },
    {
      id: 3,
      name: 'differenceInCount',
      currentRestaurant: '',
      open: false,
      fullScreen: null,
      maxWidth: null
    },
    {
      id: 4,
      name: 'detailsDiscard',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentRestaurant: ''
    }
  ]);
  const restaurantGroupId = userInfo.companies?.groups[0].id;
  const enabledCompanies = userInfo.companies?.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat();
  const [companies, setCompanies] = useState([]);

  const [companyOptions, setCompanyOptions] = useState(
    companies?.map((company) => ({
      id: company?.id,
      title: company?.name,
      value: company?.id
    }))
  );
  const [checkConsume, setCheckConsume] = useState(false);
  const [companyOptionsDefaultValue, setCompanyOptionDefaultValue] = useState({
    id: companies?.map((el) => el.id),
    title: 'Todos',
    value: companies?.map((el) => el.id)
  });

  const [formattedCompanyOptions, setFormattedCompanyOptions] = useState(
    [companyOptionsDefaultValue, companyOptions].flat()
  );

  const actualDate = new Date();
  const dateLessSeven = new Date().setDate(actualDate.getDate() - 7);
  const dateLessOne = new Date().setDate(new Date().getDate() - 1);
  const [productModal, setProductModal] = useState({});
  const [detailsDiscard, setDetailsDiscard] = useState({})
  const [parameters, setParameters] = useState('');

  const [loading, setLoading] = useState(false);
  const [mainContent, setMainContent] = useState([]);
  const [option, setOption] = useState('percent');
  const [filters, setFilters] = useState([
    {
      param: 'startDate',
      value: format(dateLessSeven, 'yyyy-MM-dd'),
      urlParam: `startDate=${format(dateLessSeven, 'yyyy-MM-dd')}`
    },
    {
      param: 'endDate',
      value: format(dateLessOne, 'yyyy-MM-dd'),
      urlParam: `endDate=${format(dateLessOne, 'yyyy-MM-dd')}`
    }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'RESTAURANTES',
        filterName: 'restaurantIds',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        optionReference: 'name',
        defaultValue: [companyOptionsDefaultValue],
        updateDefaultValue: [companyOptionsDefaultValue],
        options: formattedCompanyOptions,
        multiple: true,
        size: 'large_plus'
      },
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'startDate',
        filterNameSecond: 'endDate',
        initialDate: dateLessSeven,
        endDate: dateLessOne
        // minDate: true,
        // maxDate: true
      }
    ]
  ]);

  const getMainContent = () => {
    setLoading(true);

    if (!filtersChanged.find((item) => item.param === 'startDate')) {
      filtersChanged.push(...filtersChanged, {
        param: 'startDate',
        value: format(new Date(dateLessSeven), 'yyyy-MM-dd'),
        urlParam: `startDate=${format(new Date(dateLessSeven), 'yyyy-MM-dd')}`
      });
    }

    if (!filtersChanged.find((item) => item.param === 'endDate')) {
      filtersChanged.push({
        param: 'endDate',
        value: format(new Date(dateLessOne), 'yyyy-MM-dd'),
        urlParam: `endDate=${format(new Date(dateLessOne), 'yyyy-MM-dd')}`
      });
    }

    if (!filtersChanged.find((item) => item.param === 'restaurantIds')) {
      filtersChanged.push({
        param: 'restaurantIds',
        value: companies?.map((company) => company?.id || company?.storeId),
        urlParam: `restaurantIds=${companies?.map(
          (company) => company?.id || company?.storeId
        )}`
      });
    }

    const params = filtersChanged.map((filter) => filter.urlParam).join('&');
    axios
      .get(`${environments.dashBoard}/cmvView?originId=${paramsId}&${params}`)
      .then((response) => {
        setMainContent([response?.data || []]);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMainContent([]);
      });
  };

  useEffect(() => {
    if (companies.length) {
      getMainContent();
    }
  }, [paramsId, filtersChanged, companies]);

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const handleFilter = (param, value) => {
    let validValue = value;
    const hasInitialValue = isArray(value[0]) && value.length > 1;

    if (param === 'restaurantIds' && hasInitialValue) {
      validValue = value[1];
    }
    handleFilterOptions(param, validValue, filtersChanged, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      paramsId,
      filtersChanged
    );
  };

  const getRestaurantsOptions = () => {
    axios
      .get(`${environments.restaurants}/groups?size=100`)
      .then((response) => {
        const allCompanies = response?.data?.content
          .find((group) => group._id === restaurantGroupId)
          .subgroups.reduce((acc, subGroup) => [...acc, subGroup.stores], [])
          .flat();

        const allCompanyOptions = allCompanies?.map((company) => ({
          id: company?.storeId,
          title: company?.description,
          value: company?.storeId
        }));

        const allCompanyOptionsDefaultValue = {
          id: allCompanies?.map((el) => el.storeId),
          title: 'Todos',
          value: allCompanies?.map((el) => el.storeId)
        };

        setCompanies(allCompanies);

        setCompanyOptions(allCompanyOptions);

        setCompanyOptionDefaultValue(allCompanyOptionsDefaultValue);

        setFormattedCompanyOptions(
          [allCompanyOptionsDefaultValue, allCompanyOptions].flat()
        );

        const newArr = [...filterFields];
        newArr[0][0].options = [
          allCompanyOptionsDefaultValue,
          allCompanyOptions
        ].flat();
        newArr[0][0].defaultValue = [allCompanyOptionsDefaultValue];
        newArr[0][0].updateDefaultValue = [allCompanyOptionsDefaultValue];

        setFilterFields(newArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      id: 'restaurantHeader',
      Header: '',
      className: 'defaultHeader withoutLeftBorder borderRightBlack',
      columns: [
        {
          Header: 'GRUPO, SUBGRUPO, RESTAURANTE',
          accessor: 'restaurants',
          id: 'restaurants',
          className: 'defaultSubtitle textAlignLeft borderRightBlack',
          Cell: ({ row }) => {
            return (
              <Grid container spacing={1} alignItems="center">
                <Grid container item xs={12} justify="flex-start">
                  <span
                    {...row.getToggleRowExpandedProps({
                      style: {
                        paddingLeft: `${row.depth * 0.6}rem`
                      }
                    })}
                    {...row.toggleRowExpanded(true)}
                  >
                    <Brightness1
                      style={{ color: '#313347', fontSize: '5px' }}
                    />
                  </span>

                  <Typography
                    className="m0 textAlignCenter dFlex alignCenter"
                    style={{
                      fontSize: '11px',
                      padding: '3px 0px'
                    }}
                  >
                    {row?.depth === 2 && (
                      <p className="mb0 mr3">{row.index + 1} - </p>
                    )}
                    {ValidationLength(row.original.name, 25)}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        }
      ]
    },
    {
      Header: 'CARDÁPIO',
      accessor: 'menu',
      id: 'menuHeader',
      className: 'defaultHeader menu withoutLeftBorder borderRightBlack',
      columns: [
        {
          Header: 'VENDAS',
          accessor: 'salesCmv',
          id: 'salesCmv',
          className: 'defaultSubtitle column-item withLeftDefaultBorder',
          Cell: ({ row }) => (
            <div className="dFlex alignCenter justifyEnd">
              <Link
                className={`m0 textAlignRight black ${
                  (row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)) &&
                  'disabledLink'
                }`}
                target="_blank"
                to={{
                  pathname: `/cardapio/${row?.original?.id}?startAt=${
                    filtersChanged.find((el) => el.param === 'startDate')
                      ?.value || dateLessSeven
                  }&endAt=${
                    filtersChanged.find((el) => el.param === 'endDate')
                      ?.value || dateLessOne
                  }`
                }}
              >
                <p className="textAlignRight m0 dFlex alignCenter justifyEnd">
                  R$
                  {row.original.sales?.total?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || '0,00'}
                </p>
              </Link>
            </div>
          )
        },
        {
          Header: 'CMV TEÓRICO',
          accessor: 'theoreticalCmv',
          id: 'theoreticalCmv',
          className: 'defaultSubtitle column-item withLeftDefaultBorder',
          Cell: ({ row }) => (
            <div className="dFlex alignCenter justifyEnd">
              {row.original?.someItemHasNotCategory && !row?.subRows?.length && (
                <Tooltip
                  placement="right"
                  title={
                    <span className="dFlex" style={{ padding: '3px 7px' }}>
                      Este restaurante possui alguns itens de cardápio que ainda
                      não foram categorizados
                    </span>
                  }
                >
                  <span className="error bold mr5 mt5" style={{ fontSize: 15 }}>
                    *
                  </span>
                </Tooltip>
              )}

              <Link
                className={`m0 textAlignRight black ${
                  (row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)) &&
                  'disabledLink'
                }`}
                target="_blank"
                to={{
                  pathname: `/cardapio/${row?.original?.id}?startAt=${
                    filtersChanged.find((el) => el.param === 'startDate')
                      ?.value || dateLessSeven
                  }&endAt=${
                    filtersChanged.find((el) => el.param === 'endDate')
                      ?.value || dateLessOne
                  }`
                }}
              >
                <p className="textAlignRight m0 dFlex alignCenter justifyEnd">
                  {option !== 'percent' && 'R$'}
                  {(option === 'percent'
                    ? row.original.menu?.cmv
                    : row.original.menu?.cmvMonetary
                  )?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || '0,00'}
                  {option === 'percent' && '%'}
                </p>
              </Link>
            </div>
          )
        },
        {
          Header: 'DESCONTOS',
          accessor: 'discounts',
          id: 'discounts',
          className:
            'defaultSubtitle column-item withoutLeftBorder borderRightBlack',
          Cell: ({ row }) => (
            <Link
              className={`m0 w100 textAlignRight black ${
                (row?.subRows?.length ||
                  !enabledCompanies
                    ?.map((el) => el?.id)
                    ?.includes(row?.original?.id)) &&
                'disabledLink'
              }`}
              target="_blank"
              to={{
                pathname: `/cardapio/${row?.original?.id}?startAt=${
                  filtersChanged.find((el) => el.param === 'startDate')
                    ?.value || dateLessSeven
                }&endAt=${
                  filtersChanged.find((el) => el.param === 'endDate')?.value ||
                  dateLessOne
                }`
              }}
            >
              <p className="textAlignRight m0">
                {option !== 'percent' && 'R$'}
                {(option === 'percent'
                  ? row.original.menu?.discount
                  : row.original.menu?.discountMonetary
                )?.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) || '0,00'}
                {option === 'percent' && '%'}
              </p>
            </Link>
          )
        }
      ]
    },
    {
      Header: 'RECEITAS',
      className: 'recipes defaultHeader withLeftDefaultBorder borderRightBlack',
      id: 'recipes',
      columns: [
        {
          Header: 'SOBRA',
          accessor: 'recipesLeftovers',
          id: 'recipesLeftovers',
          className: 'defaultSubtitle column-item withLeftDefaultBorder',
          Cell: ({ row }) => {
            const currentFilterValue =
              row?.original?.manufacture?.recipe?.leftovers > 0
                ? 'leftover'
                : 'noDiff';

            return (
              <Link
                className={`m0 w100 textAlignRight black ${
                  (row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)) &&
                  'disabledLink'
                }`}
                target="_blank"
                to={{
                  pathname: `/producao/${
                    row?.original?.id
                  }?difference=${currentFilterValue}&type=recipe&startAt=${
                    filtersChanged.find((el) => el.param === 'startDate')
                      ?.value || dateLessSeven
                  }&endAt=${
                    filtersChanged.find((el) => el.param === 'endDate')
                      ?.value || dateLessOne
                  }`
                }}
              >
                <p className="textAlignRight m0">
                  {option !== 'percent' && 'R$'}
                  {(option === 'percent'
                    ? row.original.manufacture?.recipe?.leftovers
                    : row.original.manufacture?.recipe?.leftoversMonetary
                  )?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || '0,00'}
                  {option === 'percent' && '%'}
                </p>
              </Link>
            );
          }
        },
        {
          Header: 'FALTA',
          accessor: 'recipesLack',
          id: 'recipesLack',
          className: 'defaultSubtitle column-item borderRightBlack',
          Cell: ({ row }) => {
            const currentFilterValue =
              row?.original?.manufacture?.recipe?.lack > 0 ? 'lack' : 'noDiff';

            return (
              <Link
                className={`m0 w100 textAlignRight black ${
                  (row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)) &&
                  'disabledLink'
                }`}
                target="_blank"
                to={{
                  pathname: `/producao/${
                    row?.original?.id
                  }?difference=${currentFilterValue}&type=recipe&startAt=${
                    filtersChanged.find((el) => el.param === 'startDate')
                      ?.value || dateLessSeven
                  }&endAt=${
                    filtersChanged.find((el) => el.param === 'endDate')
                      ?.value || dateLessOne
                  }`
                }}
              >
                <p className="textAlignRight m0">
                  {option !== 'percent' && 'R$'}
                  {(option === 'percent'
                    ? row.original.manufacture?.recipe?.lack
                    : row.original.manufacture?.recipe?.lackMonetary
                  )?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || '0,00'}
                  {option === 'percent' && '%'}
                </p>
              </Link>
            );
          }
        }
      ]
    },
    {
      Header: 'TRANSFORMAÇÕES',
      className:
        'transformation defaultHeader withLeftDefaultBorder borderRightBlack',
      id: 'transformations',
      columns: [
        {
          Header: 'SOBRA',
          accessor: 'transformationsLeftovers',
          id: 'transformationsLeftovers',
          className: 'defaultSubtitle column-item withLeftDefaultBorder',
          Cell: ({ row }) => {
            const currentFilterValue =
              row?.original?.manufacture?.transformation?.leftovers > 0
                ? 'leftover'
                : 'noDiff';

            return (
              <Link
                className={`m0 w100 textAlignRight black ${
                  (row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)) &&
                  'disabledLink'
                }`}
                target="_blank"
                to={{
                  pathname: `/producao/${
                    row?.original?.id
                  }?difference=${currentFilterValue}&type=transformation&startAt=${
                    filtersChanged.find((el) => el.param === 'startDate')
                      ?.value || dateLessSeven
                  }&endAt=${
                    filtersChanged.find((el) => el.param === 'endDate')
                      ?.value || dateLessOne
                  }`
                }}
              >
                <p className="textAlignRight m0">
                  {option !== 'percent' && 'R$'}
                  {(option === 'percent'
                    ? row.original.manufacture?.transformation?.leftovers
                    : row.original.manufacture?.transformation
                        ?.leftoversMonetary
                  )?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || '0,00'}
                  {option === 'percent' && '%'}
                </p>
              </Link>
            );
          }
        },
        {
          Header: 'FALTA',
          accessor: 'transformationsLack',
          id: 'transformationsLack',
          className: 'defaultSubtitle column-item borderRightBlack',
          Cell: ({ row }) => {
            const currentFilterValue =
              row?.original?.manufacture?.transformation?.lack > 0
                ? 'lack'
                : 'noDiff';

            return (
              <Link
                className={`m0 w100 textAlignRight black ${
                  (row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)) &&
                  'disabledLink'
                }`}
                target="_blank"
                to={{
                  pathname: `/producao/${
                    row?.original?.id
                  }?difference=${currentFilterValue}&type=transformation&startAt=${
                    filtersChanged.find((el) => el.param === 'startDate')
                      ?.value || dateLessSeven
                  }&endAt=${
                    filtersChanged.find((el) => el.param === 'endDate')
                      ?.value || dateLessOne
                  }`
                }}
              >
                <p className="textAlignRight m0">
                  {option !== 'percent' && 'R$'}
                  {(option === 'percent'
                    ? row.original.manufacture?.transformation?.lack
                    : row.original.manufacture?.transformation?.lackMonetary
                  )?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || '0,00'}
                  {option === 'percent' && '%'}
                </p>
              </Link>
            );
          }
        }
      ]
    },
    {
      Header: 'PRODUTO FINAL',
      className:
        'finalProduct defaultHeader withLeftDefaultBorder borderRightBlack',
      id: 'finalProduct',
      columns: [
        {
          Header: 'SOBRA',
          accessor: 'finalProductLeftovers',
          id: 'finalProductLeftovers',
          className: 'defaultSubtitle column-item withLeftDefaultBorder',
          Cell: ({ row }) => {
            const currentFilterValue =
              row?.original?.count?.leftovers > 0 ? 'leftover' : 'noDiff';

            return (
              <span
                className={`m0 w100 textAlignRight black cursorPointer justifyEnd ${
                  (row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)) &&
                  'disabledLink'
                }`}
                onClick={() => {
                  if (
                    row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)
                  )
                    return;
                  setModalSettings(
                    modalSettings.map((modal) =>
                      modal.name === 'differenceInCount'
                        ? {
                            ...modal,
                            open: true,
                            currentRestaurant: row.original.name,
                            currentStartDate: filtersChanged?.find(
                              (el) => el.param === 'startDate'
                            ).value,
                            currentEndDate: filtersChanged?.find(
                              (el) => el.param === 'endDate'
                            ).value,
                            currentDiff: 'leftover'
                          }
                        : modal
                    )
                  );
                }}
                // target="_blank"
                // to={{
                //   pathname: `/inventario/contagens/${
                //     row?.original?.id
                //   }?difference=${currentFilterValue}&startAt=${
                //     filtersChanged.find((el) => el.param === 'startDate')
                //       ?.value || dateLessSeven
                //   }&endAt=${
                //     filtersChanged.find((el) => el.param === 'endDate')
                //       ?.value || dateLessOne
                //   }`
                // }}
              >
                <p className="textAlignRight m0">
                  {option !== 'percent' && 'R$'}
                  {(option === 'percent'
                    ? row.original.count?.leftovers
                    : row.original.count?.leftoversMonetary
                  )?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || '0,00'}
                  {option === 'percent' && '%'}
                </p>
              </span>
            );
          }
        },
        {
          Header: 'FALTA',
          accessor: 'finalProductLack',
          id: 'finalProductLack',
          className: 'defaultSubtitle column-item borderRightBlack',
          Cell: ({ row }) => {
            const currentFilterValue =
              row?.original?.count?.lack > 0 ? 'lack' : 'noDiff';

            return (
              <span
                className={`m0 w100 textAlignRight black cursorPointer justifyEnd ${
                  (row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)) &&
                  'disabledLink'
                }`}
                onClick={() => {
                  if (
                    row?.subRows?.length ||
                    !enabledCompanies
                      ?.map((el) => el?.id)
                      ?.includes(row?.original?.id)
                  )
                    return;
                  setModalSettings(
                    modalSettings.map((modal) =>
                      modal.name === 'differenceInCount'
                        ? {
                            ...modal,
                            open: true,
                            currentRestaurant: row.original.name,
                            currentStartDate: filtersChanged?.find(
                              (el) => el.param === 'startDate'
                            ).value,
                            currentEndDate: filtersChanged?.find(
                              (el) => el.param === 'endDate'
                            ).value,
                            currentDiff: 'lack'
                          }
                        : modal
                    )
                  );
                }}
                // target="_blank"
                // to={{
                //   pathname: `/inventario/contagens/${
                //     row?.original?.id
                //   }?difference=${currentFilterValue}&startAt=${
                //     filtersChanged.find((el) => el.param === 'startDate')
                //       ?.value || dateLessSeven
                //   }&endAt=${
                //     filtersChanged.find((el) => el.param === 'endDate')
                //       ?.value || dateLessOne
                //   }`
                // }}
              >
                <p className="textAlignRight m0">
                  {option !== 'percent' && 'R$'}
                  {(option === 'percent'
                    ? row.original.count?.lack
                    : row.original.count?.lackMonetary
                  )?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || '0,00'}
                  {option === 'percent' && '%'}
                </p>
              </span>
            );
          }
        }
      ]
    },
    {
      Header: () => (
        <FormControlLabel
          className="toggleDefault m0 dFlex"
          control={
            <Switch
              checked={option === 'percent'}
              onChange={() => {
                setOption(option === 'percent' ? 'real' : 'percent');
              }}
              name="View Mode"
            />
          }
          label={option === 'percent' ? '%' : 'R$'}
        />
      ),
      className: 'cmv defaultHeader cmvSwitchRealPercent withoutRightBorder',
      id: 'cmvHeader',
      columns: [
        {
          Header: 'DESCARTES',
          accessor: 'discards',
          id: 'discards',
          className:
            'defaultSubtitle column-item withLeftDefaultBorder borderRightBlack',
          Cell: ({ row }) => (
            <p
              className={`textAlignRight m0 ${
                !row?.subRows?.length && 'cursorPointer'
              }`}
              onClick={() => {
                if (
                  row?.subRows?.length ||
                  !enabledCompanies
                    ?.map((el) => el?.id)
                    ?.includes(row?.original?.id)
                )
                  return;
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'discardPercentageAndValue'
                      ? {
                          ...modal,
                          open: true,
                          currentRestaurant: row.original.name,
                          currentStartDate: filtersChanged?.find(
                            (el) => el.param === 'startDate'
                          ).value,
                          currentEndDate: filtersChanged?.find(
                            (el) => el.param === 'endDate'
                          ).value
                        }
                      : modal
                  )
                );
              }}
            >
              {option !== 'percent' && 'R$'}
              {(option === 'percent'
                ? row.original.discard
                : row.original.discardMonetary
              )?.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) || '0,00'}
              {option === 'percent' && '%'}
            </p>
          )
        },
        {
          Header: 'CMV',
          accessor: 'cmv',
          id: 'cmv',
          className:
            'defaultSubtitle column-cmv-final withoutLeftBorder withoutRightBorder',
          Cell: ({ row }) => (
            <p className="textAlignRight m0 bold black">
              {option !== 'percent' && 'R$'}
              {(option === 'percent'
                ? row.original.cmv
                : row.original.cmvMonetary
              )?.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) || '0,00'}
              {option === 'percent' && '%'}
            </p>
          )
        }
      ]
    }
  ];

  useEffect(() => {
    if (!companies.length) {
      getRestaurantsOptions();
    }

    if (
      modalSettings.find((modal) => modal.name === 'discardPercentageAndValue')
        .open &&
      !parameters?.length
    ) {
      (async () => {
        const response = await fetch(
          `${environments.restaurants}/companies/${paramsId}/parameters`
        );
        const params = await response.json();
        setParameters(params?.product?.type);
      })();
    }
  }, [
    paramsId,
    modalSettings.find((modal) => modal.name === 'discardPercentageAndValue')
      .open
  ]);

  return (
    <Layout>
      <TitleHead title="CMV" />

      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="CMV"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          handleMultipleFilters={handleMultipleFiltersFunc}
          getFilterhandle={getFilterhandle}
        />

        <div className="pRelative">
          <CmvTable
            columns={columns}
            loading={loading}
            data={mainContent}
            className="desktop menuListTableCmv cmvTable"
          />
        </div>
      </Container>

      {modalSettings.find((modal) => modal.name === 'discardPercentageAndValue')
        .open && (
        <Suspense fallback={<span>Carregando...</span>}>
          <DiscardPercentageAndValue
            open={
              modalSettings.find(
                (modal) => modal.name === 'discardPercentageAndValue'
              ).open
            }
            handleClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'discardPercentageAndValue'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            setProductModal={setProductModal}
            setDetailsDiscard={setDetailsDiscard}
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
          />
        </Suspense>
      )}

      {modalSettings.find((modal) => modal.name === 'detailsDiscard')
        .open && (
          <Suspense fallback={<span>Carregando...</span>}>
            <DetailsDiscardByProductDialog
              open={
                modalSettings.find((modal) => modal.name === 'detailsDiscard').open
              }
              handleClose={() => {
                setModalSettings(
                  modalSettings.map((modal) => modal.name === 'detailsDiscard' ? { ...modal, open: false } : modal
                  )
                );
              }}
              fullscreen={
                modalSettings.find((modal) => modal.name === 'detailsDiscard').fullScreen
              }
              maxWidth={
                modalSettings.find((modal) => modal.name === 'detailsDiscard').maxWidth
              }
              product={detailsDiscard}
              modalSettings={modalSettings}
              setModalSettings={setModalSettings}
              setProductModal={setProductModal}
              startDate={filtersChanged?.find((el) => el.param === 'startDate').value}
              endDate={filtersChanged?.find((el) => el.param === 'endDate').value}
            />
          </Suspense>
        )}

      {modalSettings.find((modal) => modal.name === 'differenceInCount')
        .open && (
        <Suspense fallback={<span>Carregando...</span>}>
          <DifferenceInCount
            open={
              modalSettings.find((modal) => modal.name === 'differenceInCount')
                .open
            }
            handleClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'differenceInCount'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
          />
        </Suspense>
      )}

      {modalSettings.find((modal) => modal.name === 'extracts').open && (
        <Suspense fallback={<span />}>
          <TheoryStockDialog
            getId={
              modalSettings.find((modal) => modal.name === 'extracts')
                .currentRestaurant
            }
            openModal={{
              modalProduct: modalSettings.find(
                (modal) => modal.name === 'extracts'
              ).open,
              modalDiscard: modalSettings.find(
                (modal) => modal.name === 'discardPercentageAndValue'
              ).open
            }}
            handleClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'extracts' ? { ...modal, open: false } : modal
                )
              );
            }}
            checkConsume={checkConsume}
            setCheckConsume={setCheckConsume}
            productModal={productModal}
            setProductModal={setProductModal}
            parameters={parameters}
            originPage="stocksPage"
            defaultStartDate={
              new Date(
                moment(
                  modalSettings.find((modal) => modal.name === 'discardPercentageAndValue').currentStartDate
                )
              ) || 
              new Date(
                moment(
                  modalSettings.find((modal) => modal.name === 'extracts').startDate
                )
              )
            }
            countDate={
              modalSettings.find((modal) => modal.name === 'extracts').endDate
            }
            defaultExtractType={
              modalSettings.find((modal) => modal.name === 'extracts').extractType
            }
            filterDiscardId={
              modalSettings.find((modal) => modal.name === 'extracts').discardId
            }
          />
        </Suspense>
      )}
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  cardsInfo: state.cards
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(MenuActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cmv);
