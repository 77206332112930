/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { Tooltip } from '@material-ui/core';
import ReactGA from 'react-ga4';

import RotateLeftIcon from 'images/icons/arrows/RotateLeftIcon.svg';

import AutoCompleteField from './AutoCompleteField/AutoCompleteField';
import DateInputField from './DateInputField/DateInputField';
import DateRangeField from './DateRangeField/DateRangeField';
import DefaultInputFieldSearch from './DefaultInputFieldSearch/DefaultInputFieldSearch';
import {
  PageTitleAndFilterMainContainer,
  PageTitleAndFilterContainer,
  PageTitle,
  IconFilter,
  Titlecard,
  FilterCase,
  FilterContainer,
  FilterWrap,
  TitlecardButtons,
  TreeSelectorWrapper,
  FilterLabel,
  PageAnTitleFilterWrapper,
  BackToDefaultIcon
} from './index.style';
import InputFieldSearch from './InputFieldSearch/InputFiledSearch';
import PageAndTitleFilterMobile from './Mobile';
import MultipleCheckbox from './MultipleCheckbox/MultipleCheckbox';
import MultipleRadiobox from './MultipleRadiobox/MultipleRadiobox';
import SwitchField from './SwitchField/SwitchField';
import NewTreeSelector from './TreeSelectorField';
import { icons, inputSizes } from './utils';

import TreeSelector from '../NewTreeSelector';

function PageTitleAndFilter({
  title,
  subTitle,
  titleInsideFilterContainer,
  activeFilterFilds,
  handleFilter,
  handleMultipleFilters,
  additionalbutton,
  extrabutton,
  supplementarybutton,
  complementarybutton,
  afterTitleButton,
  midTitleCourt,
  dynamicJustifyOff,
  onChange,
  getFilterhandle,
  iconFilterOff,
  toggleFilter,
  externalTo,
  settings = false,
  backButton = null,
  filterOff = false,
  settingsMobile = [],
  closeModalSettings,
  handleClear,
  onClear,
  classOnClear,
  status,
  setFilterOpenedMainPageControl,
  changeRest,
  filterDefaultStatus = true,
  customClassName,
  extraButtonMobile,
  showApplyFiltersMobileButton
}) {
  const [filterOpen, setFilterOpen] = useState(filterDefaultStatus);
  const [clearFiltersCount, setClearFiltersCount] = useState(0);

  const handleFilterOpen = () => {
    ReactGA.event({
      category: 'AbrirFiltro',
      action: 'CliqueBotao',
      label: 'IconeFiltro',
      value: !filterOpen ? 1 : 0
    });
    setFilterOpen(!filterOpen);
    setFilterOpenedMainPageControl && setFilterOpenedMainPageControl(!filterOpen);
  };

  const topAdjustment = (activeFilterIndex, label) => {
    if (activeFilterIndex === 1 && label === '') {
      return true;
    }
    return false;
  };

  const onClearFilters = () => {
    onClear && onClear();
    setClearFiltersCount((count) => count + 1);
  };

  useEffect(() => {
    if (changeRest) {
      onClearFilters()
    }
  }, [changeRest])

  return (
    <PageTitleAndFilterMainContainer className={`pageTitleAndFilterMainContainer ${customClassName}`}>
      <PageAndTitleFilterMobile
        title={title}
        status={status}
        subTitle={subTitle}
        activeFilterFilds={activeFilterFilds}
        settings={settings}
        inputSizes={inputSizes}
        handleFilter={handleFilter}
        getFilterhandle={getFilterhandle}
        handleMultipleFilters={handleMultipleFilters}
        topAdjustment={topAdjustment}
        onChange={onChange}
        backButton={backButton}
        filterOff={filterOff}
        settingsMobile={settingsMobile}
        closeSettings={closeModalSettings}
        extraButtonMobile={extraButtonMobile}
        showApplyFiltersMobileButton={showApplyFiltersMobileButton}
      />

      <PageAnTitleFilterWrapper className="pageTitleAndFilterSection">
        <PageTitleAndFilterContainer className="input-search-sku-site">
          <Titlecard className="pageTitleAndFilterTitleCard">
            <PageTitle>{title}</PageTitle>
            {status && <span style={{ marginLeft: '10px' }}>{status}</span>}
            {afterTitleButton && afterTitleButton()}
          </Titlecard>

          {midTitleCourt && midTitleCourt()}
          
          <TitlecardButtons>
            {complementarybutton && complementarybutton()}
            {supplementarybutton && supplementarybutton()}
            {extrabutton && extrabutton()}
            {additionalbutton && additionalbutton()}
            {activeFilterFilds && !iconFilterOff && (
              <IconFilter 
                src={filterOpen ? icons.filterIcon : icons.filterIconDisabled} 
                onClick={handleFilterOpen} 
              />
            )}
          </TitlecardButtons>
        </PageTitleAndFilterContainer>

        {activeFilterFilds && (
          <FilterWrap
            className={
              (externalTo ? toggleFilter : filterOpen)
                ? 'filterOpened'
                : 'filterClosed'
            }
          >
            <FilterCase>
              {titleInsideFilterContainer && titleInsideFilterContainer}

              {activeFilterFilds.map((filterLine, activeFilterIndex) => {
                const filterLineSize = filterLine.length;

                return (
                  <FilterContainer
                    className={activeFilterIndex && 'mt16'}
                    filterOpen={filterOpen}
                    justifyContent={
                      dynamicJustifyOff ? false : filterLineSize === 4
                    }
                  >
                    {filterLine.map(
                      ({
                        urlPath,
                        label,
                        key,
                        optionReference,
                        placeHolder,
                        type,
                        multiple,
                        options,
                        filterName,
                        filterNameFirst,
                        filterNameSecond,
                        checkBoxInputs,
                        size,
                        internalCheckBox,
                        initialDate,
                        endDate,
                        minDate,
                        maxDate,
                        customMinStartDate,
                        isExtract,
                        disabled,
                        disabledDays,
                        tooltipText,
                        storedValue,
                        defaultValue,
                        actionButtons,
                        views,
                        dateFormat,
                        updateDefaultValue,
                        inputOptions,
                        resetDateWithDelete,
                        focus,
                        searchByTermMode,
                        clearable,
                        checkedAsDefault,
                        isBloom = false,
                        disableGetOptionsOnFocus,
                        allowClear,
                        isSelect,
                        marginRight,
                        disableFilterNameSecond,
                        disabledParamCondition,
                        disabledParamArr,
                        ignoreFilterContent,
                        customClassName,
                        useTitle,
                        customSize,
                        shouldDisableStartDate,
                        shouldDisableEndDate,
                        onMonthStartDateChange,
                        onMonthEndDateChange,
                        customHandleStartDate
                      }) => (
                        <>
                          {type === 'autoComplete' && (
                            <AutoCompleteField
                              urlPath={urlPath}
                              label={label}
                              keyField={key}
                              multiple={multiple}
                              optionReference={optionReference}
                              placeHolder={placeHolder}
                              options={options}
                              filterName={filterName}
                              filterNameSecond={filterNameSecond}
                              handleFilter={handleFilter}
                              handleClear={handleClear}
                              size={size}
                              customSize={customSize}
                              inputSizes={inputSizes}
                              internalCheckBox={internalCheckBox}
                              getFilterhandle={getFilterhandle}
                              defaultValue={defaultValue}
                              updateDefaultValue={updateDefaultValue}
                              actionButtons={actionButtons}
                              searchByTermMode={searchByTermMode}
                              isBloom={isBloom}
                              disableGetOptionsOnFocus={
                                disableGetOptionsOnFocus
                              }
                              disable={disabled}
                              storedValue={storedValue}
                              allowClear={allowClear}
                              isSelect={isSelect}
                              shouldCleanFilter={clearFiltersCount}
                              marginRight={marginRight}
                              disableFilterNameSecond={disableFilterNameSecond}
                              disabledParamCondition={disabledParamCondition}
                              disabledParamArr={disabledParamArr}
                              ignoreFilterContent={ignoreFilterContent}
                              changeRest={changeRest}
                            />
                          )}
                          {type === 'fieldSearch' && (
                            <InputFieldSearch
                              label={label}
                              placeHolder={placeHolder}
                              filterName={filterName}
                              handleFilter={handleFilter}
                              size={size}
                              inputSizes={inputSizes}
                              clearable={clearable}
                              defaultValue={defaultValue}
                              storedValue={storedValue}
                              shouldCleanFilter={clearFiltersCount}
                              marginRight={marginRight}
                            />
                          )}
                          {type === 'defaultInputFieldSearch' && (
                            <DefaultInputFieldSearch
                              label={label}
                              placeHolder={placeHolder}
                              filterName={filterName}
                              handleFilter={handleFilter}
                              size={size}
                              inputSizes={inputSizes}
                              focus={focus}
                              shouldCleanFilter={clearFiltersCount}
                            />
                          )}
                          {type === 'multipleCheckbox' && (
                            <MultipleCheckbox
                              storedValue={storedValue}
                              title={label}
                              checkBoxInputs={checkBoxInputs}
                              handleFilter={handleFilter}
                              defaultValue={defaultValue}
                              searchByTermMode={searchByTermMode}
                              shouldCleanFilter={clearFiltersCount}
                              topAdjustment={topAdjustment(
                                activeFilterIndex,
                                label
                              )}
                            />
                          )}
                          {type === 'multipleRadiobox' && (
                            <MultipleRadiobox
                              title={label}
                              defaultValue={defaultValue}
                              checkBoxInputs={checkBoxInputs}
                              handleFilter={handleFilter}
                              topAdjustment={topAdjustment(
                                activeFilterIndex,
                                label
                              )}
                            />
                          )}
                          {type === 'dateInputField' && (
                            <DateInputField
                              size={size}
                              handleFilter={handleFilter}
                              inputSizes={inputSizes}
                              label={label}
                              filterName={filterName}
                              initialDate={initialDate}
                              minDate={minDate && minDate}
                              maxDate={maxDate && maxDate}
                              isExtract={isExtract}
                              disabled={disabled}
                              disabledDays={disabledDays}
                              tooltipText={tooltipText}
                              resetDateWithDelete={resetDateWithDelete}
                            />
                          )}
                          {type === 'range' && (
                            <div className="dFlex flexColumn rangeFilter">
                              <label className="fakeLabel">{label}</label>

                              <div className="dFlex alignEnd inputBox">
                                <InputFieldSearch
                                  placeHolder={placeHolder}
                                  filterName={filterNameFirst}
                                  handleFilter={handleFilter}
                                  inputSizes={inputSizes}
                                  withoutIcon
                                />

                                <span>até</span>

                                <InputFieldSearch
                                  placeHolder={placeHolder}
                                  filterName={filterNameSecond}
                                  handleFilter={handleFilter}
                                  inputSizes={inputSizes}
                                  withoutIcon
                                />
                              </div>
                            </div>
                          )}
                          {type === 'dateRangeField' && (
                            <DateRangeField
                              size={size}
                              handleFilter={handleMultipleFilters}
                              inputSizes={inputSizes}
                              filterNameStart={filterNameFirst}
                              filterNameFinal={filterNameSecond}
                              initialDate={initialDate}
                              endDate={endDate}
                              minDate={minDate && minDate}
                              customMinStartDate={customMinStartDate}
                              maxDate={maxDate && maxDate}
                              views={views}
                              dateFormat={dateFormat}
                              shouldCleanFilter={clearFiltersCount}
                              marginRight={marginRight}
                              shouldDisableStartDate={shouldDisableStartDate}
                              shouldDisableEndDate={shouldDisableEndDate}
                              onMonthStartDateChange={onMonthStartDateChange}
                              onMonthEndDateChange={onMonthEndDateChange}
                              customHandleStartDate={customHandleStartDate}
                            />
                          )}
                          {type === 'switchField' && (
                            <SwitchField
                              onChange={onChange}
                              title={label}
                              handleFilter={handleFilter}
                              customClassName={customClassName}
                              topAdjustment={topAdjustment(
                                activeFilterIndex,
                                label
                              )}
                              filterName={filterName}
                              checkedAsDefault={checkedAsDefault}
                              useTitle={useTitle}
                            />
                          )}
                          {type === 'treeSelector' && (
                            <TreeSelectorWrapper id={`${filterName}Container`}>
                              <FilterLabel>{label}</FilterLabel>
                              <TreeSelector
                                label=""
                                inputOptions={{ ...inputOptions }}
                                setCountCategoryDescription={() => { }}
                                StylePageTitleAnFilter
                                handleFilter={handleFilter}
                                filterName={filterName}
                                placeHolder={placeHolder}
                                size={size}
                                inputSizes={inputSizes}
                              />
                            </TreeSelectorWrapper>
                          )}
                          {type === 'newTreeSelector' && (
                            <NewTreeSelector
                              urlPath={urlPath}
                              label={label}
                              multiple={multiple}
                              options={options}
                              filterName={filterName}
                              handleFilter={handleFilter}
                              size={size}
                              inputSizes={inputSizes}
                              getFilterhandle={getFilterhandle}
                              defaultValue={defaultValue}
                              storedValue={storedValue}
                              marginRight={marginRight}
                            />
                          )}
                        </>
                      )
                    )}

                    {activeFilterIndex === 0 && !!onClear && (
                      <div className={classOnClear}>
                        <Tooltip
                          title="Limpar todos os filtros"
                          aria-label="clearFilters"
                        >
                          <BackToDefaultIcon
                            src={RotateLeftIcon}
                            alt="Back to default"
                            onClick={onClearFilters}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </FilterContainer>
                );
              })}
            </FilterCase>
          </FilterWrap>
        )}
      </PageAnTitleFilterWrapper>
    </PageTitleAndFilterMainContainer>
  );
}

export default PageTitleAndFilter;
