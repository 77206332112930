/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo, useState } from 'react';

import { 
  Checkbox,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography, 
  Tooltip,
  useMediaQuery
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

import TreeSelector from 'components/NewTreeSelector';
import ArrowRightYellowBg from 'images/icons/arrows/arrowRightWhiteBgYellowCircle.svg';
import CheckBoxIcon from 'images/icons/signals/checks/checkWhiteNavyBlueBgCircle.svg';
import TrashIcon from 'images/icons/signals/removesAndDeletes/trashGrayOutline.svg';
import { BorderLinearProgress } from 'pages/inventory/Counts/styles';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

import {
  filterProducts,
  handleChangeRadio,
  handleFilterOnlyNotCounted,
} from '../MainFunctions';
import {
  HeaderContainer,
  StyledGrid,
  TotalDiscard
} from '../styles';

const environments = Environment(process.env.REACT_APP_ENV);

function CountHeader({
  differenceBetweenCountAndMenuAlert,
  filterOnlyNotCounted,
  getId,
  products,
  countDate,
  periodicity,
  isCmvCount,
  filterSelectedProduct,
  countGroupInfos,
  smallScreen,
  setProducts,
  setFilterOnlyNotCounted,
  salesInfos,
  lastExtractSale,
  hasProcessingInProgress,
  setModalStates,
  modalStates,
  valuesDiscard,
}) {
  const isMobile = useMediaQuery('(max-width: 821px)')
  const [productOptions, setProductOptions] = useState([]);
  const [productFilterValue, setProductFilterValue] = useState(
    filterSelectedProduct
  );
  const filteredCountedProducts = products?.filter(el => el?.realStock !== null)?.length

  const closureTotal = useMemo(() => {
    if (salesInfos.length) {
      const total = salesInfos.find(el => el._id === parseFloat(getId))?.total || 0

      return (
        total.toLocaleString('pt-br', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }) || '0,00'
      );
    }

    return '0,00';
  }, [salesInfos]);

  const validateQueue = () => {
    if (
      (hasProcessingInProgress?.existQueue === 'reprocessing' &&
        hasProcessingInProgress?.isToday) ||
      hasProcessingInProgress?.existQueue === 'processing'
    ) {
      return 'Em andamento';
    }
    return 'Concluído';
  };

  const frequencyTranslated = () => {
    const frequencyType = countGroupInfos?.countGroup?.counts?.length && (countGroupInfos?.countGroup?.periodicity?.type || periodicity?.type)
    let type = '';

    switch (frequencyType) {
      case 'DIARY':
        type = 'Diário';
        break;
      case 'MONTHLY':
        type = 'Mensal';
        break;
      case 'BIMONTHLY':
        type = 'Bimensal';
        break;
      case 'WEEKLY':
        type = 'Semanal';
        break;
      case 'BIWEEKLY':
        type = 'Bissemanal';
        break;
      case 'FREE_WEEKLY':
        type = 'Semenal Livre';
        break;
      case 'FREE_MONTHLY':
        type = 'Mensal Livre';
        break;
      default:
        break;
    }
    
    return type
  }

  return (
    <HeaderContainer showAlert={differenceBetweenCountAndMenuAlert}>
      <StyledGrid
        className="alignCenter mb0 pSticky gridTableCount alignStart"
        container
      >
        <Grid container item className="countMainInfosBox p0">
          <section className="countInfos">
            <div className="countDateInfo bold">
              {countDate ? moment(countDate)?.add('3', 'hour')?.format(isMobile ? 'DD/MM' : 'DD/MM/YYYY') : '-'}
            </div>

            <div className="countOtherInfos">
              <div className="lineInfo dFlex">
                <span className="">
                  {countGroupInfos?.countGroup?.counts?.length &&
                    countGroupInfos.isCountGroup
                      ? ValidationLength(
                          periodicity?.description
                            ? ` ${periodicity?.description}`
                            : '',
                          smallScreen ? 20 : 40
                        )
                      : periodicity?.description
                      ? ` ${periodicity?.description}`
                      : ''
                  }
                </span>

                {isCmvCount ? 
                  <div
                    style={{
                      width: "38.89px",
                      height: "20px",
                      backgroundColor: "#5062F0",
                      borderRadius: "10px",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "8px"
                    }}
                  >
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Lato",
                        fontWeight: "700",
                        lineHeight: "15px",
                        fontSize: "10px"
                      }}
                    >
                      CMV
                    </span>
                  </div>
                : null}
              </div>

              <div className="lineInfo dFlex">
                <span className="uppercase">
                  {countGroupInfos?.countGroup?.counts?.length &&
                    countGroupInfos.isCountGroup
                      ? ValidationLength(
                          frequencyTranslated()
                            ? ` ${frequencyTranslated()}`
                            : '',
                          smallScreen ? 30 : 40
                        )
                      : frequencyTranslated()
                      ? ` ${frequencyTranslated()}`
                      : ''
                  }
                </span>
              </div>
            </div>
          </section>
        </Grid>

        <Grid className="categoryFilter dFlex flexColumn">
          <div className="dFlex flexColumn">
            {/* {!isMobile ?
              <div className="dFlex alignCenter">
                <TreeSelector
                  label="Categoria"
                  inputOptions={{
                    endpoint: 'categories',
                    originId: getId,
                    type: 'Shopping'
                  }}
                  placeHolder="Selecione..."
                  countCategoryDescription={countCategoryDescription}
                  boxSize="50%"
                  customInputSize="66%"
                  justifyBoxContent="flex-start"
                  setCountCategoryDescription={setCountCategoryDescription}
                />

                <div className="dFlex alignCenter diaryCountsProductAutocomplete">
                  <label
                    className="bold fontSizeDefault mr10 mb0"
                    style={{ marginTop: '-8px' }}
                  >
                    Produto
                  </label>

                  <Autocomplete
                    key="productFilter"
                    tabIndex={-1}
                    options={productOptions || []}
                    disablePortal
                    freeSolo
                    onKeyDown={(event) => {
                      if (event.keyCode === 8 || event.keyCode === 13) {
                        setProductFilterValue(event.target.value);
                        setFilterSelectedProduct(event.target.value);
                      }
                    }}
                    onFocus={() => {
                      if (products.length && productOptions.length) return;

                      getOptions(
                        `${environments.catalog}/products`,
                        'productOptions',
                        productOptions,
                        setProductOptions,
                        getId,
                        { autoComplete: true },
                        null,
                        2000
                      );
                    }}
                    getOptionLabel={(option) => option?.description || option}
                    className="autocompleteSmall"
                    defaultValue={
                      typeof productFilterValue === 'string'
                        ? productFilterValue
                        : productFilterValue?.description || ''
                    }
                    inputValue={
                      typeof productFilterValue === 'string'
                        ? productFilterValue
                        : productFilterValue?.description || ''
                    }
                    onInputChange={(_, value) => {
                      setProductFilterValue(value);
                    }}
                    size="small"
                    onChange={(_, value) => {
                      setProductFilterValue(value || '');
                      setFilterSelectedProduct(value || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        tabIndex={-1}
                        id={params.id}
                        type="text"
                        name="Products"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </div>
              </div>
            : null} */}

            <div className="dFlex alignCenter diaryCountProgressBar">
              <BorderLinearProgress
                variant="determinate"
                color="secondary"
                className='withoutError'
                value={(filteredCountedProducts * 100 / products?.length)?.toFixed(0)}
              />

              <div className="dFlex">
                <span className="bold lightBlue">
                  {products?.length 
                    ? `${filteredCountedProducts}/${products?.length}`
                    : '0/0'
                  }
                </span>

                <span className="bold lightBlue ml5">
                  {products?.length 
                    ? `(${(filteredCountedProducts * 100 / products?.length)?.toFixed(0)}%)`
                    : '(0%)'
                  }
                </span>
              </div>
            </div>

            <div className="dFlex w100 mt10 justifyCenter" style={{ marginLeft: '-2px', marginBottom: 3 }}>
              <div className="dFlex alignCenter">
                <Checkbox
                  checked={filterOnlyNotCounted}
                  size="small"
                  onChange={(_, checked) =>
                    handleFilterOnlyNotCounted(
                      checked,
                      setFilterOnlyNotCounted,
                      setProducts,
                      products
                    )
                  }
                />

                <p
                  className="mb0 fontSizeDefault cursorPointer"
                  onClick={() =>
                    handleFilterOnlyNotCounted(
                      !filterOnlyNotCounted,
                      setFilterOnlyNotCounted,
                      setProducts,
                      products
                    )
                  }
                >
                  {isMobile ? 'sem contagem' : 'Visualizar produtos sem contagem'}
                </p>
              </div>

              {/* <div className="dFlex alignCenter" style={{ marginLeft: 20 }}>
                <Checkbox
                  checked={orderByQuantityDesc}
                  size="small"
                  onChange={(_, checked) =>
                    handleOrderByQuantity(
                      checked,
                      products,
                      isSelectedRealDiff,
                      setOrderByQuantityDesc,
                      setProducts
                    )
                  }
                />

                <p
                  className="mb0 fontSizeDefault cursorPointer"
                  onClick={() =>
                    handleOrderByQuantity(
                      !filterOnlyNotCounted,
                      products,
                      isSelectedRealDiff,
                      setOrderByQuantityDesc,
                      setProducts
                    )
                  }
                >
                  Ordenar pelas diferenças (maiores para menores)
                </p>
              </div> */}
            </div>

            {/* {!isMobile ?
              <div className="dFlex w100 ordinationAndUserFilter">
                <Typography
                  className="dFlex alignCenter bold fontSizeDefault"
                  style={{
                    marginRight: smallScreen ? '15px' : '17px'
                  }}
                >
                  {smallScreen ? "Ord." : "Ordenação"}
                </Typography>

                <RadioGroup
                  className="radio-button"
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '0 3px'
                  }}
                  aria-label="order"
                  name="order"
                  value={radioValue}
                  onChange={(e) => {
                    handleChangeRadio(
                      e,
                      date,
                      countId,
                      getId,
                      assortmentDate,
                      setLoading,
                      setProducts
                    );
                    setRadioValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    className="orderButton"
                    value="ALPHABETICAL"
                    control={<Radio />}
                    label="Alfabética"
                  />

                  <FormControlLabel
                    className="orderButton"
                    value="PHYSICAL"
                    control={<Radio />}
                    label="Últ. Contagens"
                  />
                </RadioGroup>

                <Autocomplete
                  fullWidth
                  size="small"
                  getOptionSelected={(option, value) =>
                    option.date === value.date
                  }
                  getOptionLabel={(option) =>
                    `${moment(option.date).format('DD/MM/YYYY')}    ${option.user
                    }`
                  }
                  options={assortmentsOptions}
                  value={assortmentDate}
                  onChange={(e, value) => {
                    setAssortmentDate(value);
                    setRadioValue('PHYSICAL');
                  }}
                  disablePortal
                  className="autocompleteSmall userAutocomplete"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id={params.id}
                      name="typeFilter"
                      required
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <div className="dFlex alignCenter w100">
                      <div style={{ width: "31%" }}>
                        <Typography className="fontSizeDefault bold w100">
                          {moment(params.date).format('DD/MM/YYYY')}
                        </Typography>
                      </div>

                      <div style={{ width: "69%" }}>
                        <Typography
                          className="fontSizeDefault w100"
                          style={{
                            fontStyle: 'italic',
                            color: '#666770'
                          }}
                        >
                          {params.user}
                        </Typography>
                      </div>
                    </div>
                  )}
                />
              </div>
            : null} */}
          </div>
        </Grid>

        <Grid className="dFlex salesAndDiffFilterBox flexColumn justifyBetween pt5 pb3">
          <div className="dFlex justifyEnd salesBox">
            <div className="dFlex alignStart mr15">
              <p className="mb0 salesTitle bold">
                VENDAS
              </p>

              <p className="mb0 salesTitle">
                $
              </p>
            </div>

            <div className="dFlex alignEnd salesInfos">
              <p className="mb0 bold">
                R$ {" "}
                {(salesInfos?.sales?.totalWithDiscount || 0).toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </p>

              <Tooltip
                placement="right"
                title={
                  <p style={{ padding: '4px 6px' }}>
                    Processamento das baixas: {validateQueue()}
                  </p>
                }
              >
                <img
                  style={{ marginLeft: 5, marginRight: 2, marginBottom: 2, width: 14, borderRadius: '50px' }}
                  src={validateQueue() === 'Em andamento' ? ArrowRightYellowBg : CheckBoxIcon}
                  alt={validateQueue() === 'Em andamento' ? ArrowRightYellowBg : CheckBoxIcon}
                />
              </Tooltip>

              {parseFloat(closureTotal?.replace(',', '.')) > 0 && 
              lastExtractSale?.updated_at && !isMobile ? (
                <span
                  className="textAlignRight mb2 mlAuto white"
                  style={{ fontSize: 8, lineHeight: 1, height: 10 }}
                >
                  últ. atualização:{' '}
                  {moment(lastExtractSale?.updated_at).format('LT')}hs
                </span>
              ) : null}
            </div>
          </div>

          <TotalDiscard
            onClick={() =>
              setModalStates({
                ...modalStates,
                discardPercentageAndValue: true
              })
            }
          >
            <div className="iconTotal">
              <p>DESCARTES:</p>
              
              <img
                style={{ marginLeft: 9, marginTop: 2, marginRight: 11.5, width: 14 }}
                src={TrashIcon}
                alt={TrashIcon}
              />
            </div>

            <div className="valuesDiscard">
              <p>
                R${' '}
                {valuesDiscard?.totalValue
                  ? valuesDiscard.totalValue.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  : '0,00'}
              </p>

              <span className="fontSizeDefault">
                ({valuesDiscard?.products ? valuesDiscard.products : 0}){' '}
                {/* {valuesDiscard?.products > 1 ? 'produtos' : 'produto'} */}
              </span>
            </div>
          </TotalDiscard>
        </Grid>
      </StyledGrid>
    </HeaderContainer>
  );
}

export default CountHeader;
