/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Axios from 'axios';
import { ptBR } from 'date-fns/locale';
import moment from 'moment/moment';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import MenuCardDialog from 'components/Register';
import periodOptions from 'data/periodicities.json';
import AlertBlueIcon from 'images/icons/signals/alerts/alertWhiteLightBlueBgCircle.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import * as CardsActions from 'store/actions/cards';
import Environment from 'utils/environments';

import InternalProductRelated from './Dialogs/InternalProductRelated';
import ProductRelatedMenus from './Dialogs/ProductRelatedMenus';
import { Container, ConfiguredProducts, CmvAndCountSetupCard, TableCurrentSetups } from './styles';
import Table from './Table';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

const environments = Environment(process.env.REACT_APP_ENV);

const arrayDays = [
  {
    "description": "Todos"
  },
  {
    "id": 0,
    "description": "Domingo"
  },
  {
    "id": 1,
    "description": "Segunda"
  },
  {
    "id": 2,
    "description": "Terça"
  },
  {
    "id": 3,
    "description": "Quarta"
  },
  {
    "id": 4,
    "description": "Quinta"
  },
  {
    "id": 5,
    "description": "Sexta"
  },
  {
    "id": 6,
    "description": "Sábado"
  }
]

const freeMonthDayOptions = Array.from({ length: 31 }, (_, i) =>
  (i + 1).toString()
);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

function CmvAndCountSetup({
  open,
  setModalSettings,
  modalSettings,
  updateStorageLocations,
  setMessage,
  userInfo,
  defaultDate,
  setEditCards
}) {
  const { id: paramsID } = useParams();
  const actualDate = moment();
  const actualDateLessOne = moment(actualDate)?.format('YYYY-MM-DD');
  const actualDateLessSeven = moment(actualDate)?.subtract(7, 'day')?.format('YYYY-MM-DD');
  const [mainList, setMainList] = useState([]);
  const [periodicitiesOptions, setPeriodicitiesOptions] = useState([])
  const [storageLocationOptions, setStorageLocationOptions] = useState([])
  const [currentPeriodicity, setCurrentPeriodicity] = useState()
  
  const [countDate, setCountDate] = useState(defaultDate)
  const [period, setPeriod] = useState()
  const [selectedProduct, setSelectedProduct] = useState();
  const [pendentItems, setPendentItems] = useState(false);
  const [enableToLoadPeriodicities, setEnableToLoadPeriodicities] = useState(false)
  const [enableToLoadStorageLocations, setEnableToLoadStorageLocations] = useState(false)
  const [loading, setLoading] = useState(false)
  const [internalProductRelated, setInternalProductRelated] = useState({
    loading: false,
    content: ''
  });
  const [productRelatedMenus, setProductRelatedMenus] = useState({
    loading: false,
    content: ''
  });

  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    mainLoading: false,
    size: 100,
    filters: [],
  });
  const [menuId, setMenuId] = useState();
  const [hasMenuModalChange, setHasMenuModalChange] = useState(false);
  const [salesAverageDiscount, setSalesAverageDiscount] = useState();
  const [menuLowAutomaticParams, setMenuLowAutomaticParams] = useState();
  const [freeWeeklyDays, setFreeWeeklyDays] = useState([]);
  const [freeMonthlyDays, setFreeMonthlyDays] = useState([]);
  const [firstDayWeek, setFirstDayWeek] = useState('');
  const [secondDayWeek, setSecondDayWeek] = useState('');
  const [firstDayMonth, setFirstDayMonth] = useState('');
  const [secondDayMonth, setSecondDayMonth] = useState('');
  const [updatedItems, setUpdatedItems] = useState([]);
  const [hasUpdates, setHasUpdates] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [disabledDates, setDisabledDates] = useState(false);
  const [hasProductUpdates, setHasProductUpdates] = useState(false);
  const [internalDialogSettings, setInternalDialogSettings] = useState([
    { id: 1, name: 'internalProductRelated', open: false, fullScreen: false },
    { id: 2, name: 'productRelatedMenus', open: false, fullScreen: false },
    { id: 3, name: 'confirmCloseDialog', open: false, fullScreen: false },
    { id: 4, name: 'cardMenuDialog', open: false, fullScreen: false },
    { id: 5, name: 'confirmCloseEditCardMenu', open: false, fullScreen: false },
    { id: 6, name: 'technicalSheet', open: false, fullScreen: false },
  ]);

  const { getId, mainLoading, page, size } = callSettings;

  const [filtersChanged, setFiltersChanged] = useState(callSettings.filters);
  const [validFields, setValidFields] = useState({
    description: false,
    periodDescription: false,
    monthDays: false,
    weekDays: false,
    dayOneMonth: false,
    dayTwoMonth: false,
    dayOneWeek: false,
    dayTwoWeek: false
  });

  const handleModalMenuToggle = (confirmClose = false) => {
    const cardMenuDialogIsOpen = internalDialogSettings?.find((dialog) => dialog.name === 'cardMenuDialog')?.open 
    if (hasMenuModalChange && cardMenuDialogIsOpen && !confirmClose) {
      setInternalDialogSettings(
        internalDialogSettings.map((modal) =>
          modal.name === 'confirmCloseEditCardMenu' ? { ...modal, open: true } : modal
        )
      );
    } else {
      setInternalDialogSettings(
        internalDialogSettings.map((modal) =>
          modal.name === 'confirmCloseEditCardMenu' ? { ...modal, open: false } : modal
        )
      );
      setHasMenuModalChange(false);
      setInternalDialogSettings(
        internalDialogSettings.map((modal) =>
          modal.name === 'cardMenuDialog' 
            ? { 
                ...modal, 
                open: !cardMenuDialogIsOpen
              } 
            : modal
        )
      );
    }

    if (cardMenuDialogIsOpen) {
      const redirectUrl = `${window.location.href}`
        .split(`/${paramsID}/`)[0]
        .concat(`/${paramsID}`);

      window?.history?.replaceState(null, '', redirectUrl);
    }
  };

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product?.products?.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product?.products?.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product?.products?.approximateCost
                  ? product?.products?.approximateCost?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 3
                      }
                    )
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product?.products?.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product?.products?.approximateCost
                  ? product?.products?.approximateCost?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 9
                      }
                    )
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product?.products?.approximateCost
                  ? product?.products?.approximateCost?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 3
                      }
                    )
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product?.products?.approximateCost
                  ? product?.products?.approximateCost?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 9
                      }
                    )
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setInternalDialogSettings(
      internalDialogSettings.map((modal) =>
        modal.name === 'technicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  const handleToCardEditPageClick = (e, card) => {
    if (card?.type === 'MENU') {
      e.preventDefault();

      const dateLessOne = moment().subtract(1, 'day').format('YYYY-MM-DD')
      const dateParams = card?.lowAutomatic ? `?startDate=${dateLessOne}&endDate=${dateLessOne}` : ''

      const redirectUrl = `${window.location.href}/menu/${card?.id}${dateParams}`;

      if (e.ctrlKey) {
        window?.open(`${redirectUrl}`);
      } else {
        window?.history?.replaceState(null, '', redirectUrl);
        setMenuId(card?.id || null);
        setMenuLowAutomaticParams({
          startDate: dateLessOne,
          endDate: dateLessOne,
          lowAutomatic: card?.lowAutomatic
        })
        handleModalMenuToggle();
      }
    }

    setSalesAverageDiscount(card?.salesAverageDiscount || 0);

    card.notHasSkus = false;
  };

  const BodyModal = (
    <MenuCardDialog
      userInfo={userInfo}
      menuInfo={{}}
      menuId={menuId}
      handleModalToggle={handleModalMenuToggle}
      replaceCategoryOf
      updateList={() => {
        getProductRelatedMenus(selectedProduct)
      }}
      salesAverageDiscount={salesAverageDiscount}
      setSalesAverageDiscount={setSalesAverageDiscount}
      openModalMenu={internalDialogSettings?.find((dialog) => dialog.name === 'cardMenuDialog')?.open}
      setHasMenuModalChange={setHasMenuModalChange}
      hasMenuModalChange={hasMenuModalChange}
      showInactiveItems={
        filtersChanged.find((el) => el.param === 'status')?.value
      }
      menuLowAutomaticParams={menuLowAutomaticParams}
      setMenuLowAutomaticParams={setMenuLowAutomaticParams}
    />
  );

  const onDateFilterMonthChange = (startDate, endDate) => {
    const currentStartDate = startDate ? moment(startDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').startOf('month')?.format('YYYY-MM-DD')
    const currentEndDate = endDate ? moment(endDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').endOf('month')?.format('YYYY-MM-DD')

    Axios
      .get(
        `${environments.dashBoard}/cmvCount/dates?restaurantId=${getId}&startDate=${currentStartDate}&endDate=${currentEndDate}`
      )
      .then((response) => {
        const defaultSevenDays = [...Array(7).keys()]
        const hasDates = response?.data?.dates?.length
        const hasPeriodicity = response?.data?.periodicity
        const disabledDays = hasDates 
          ? response?.data?.dates?.sort((a, b) => (a > b ? 1 : -1)) 
          : hasPeriodicity
            ? defaultSevenDays?.map(el => moment()?.subtract(el + 1, 'day')?.format('YYYY-MM-DD'))
            : ''

        setDisabledDates(disabledDays !== '' ? disabledDays?.map(el => moment(el)?.format('D')) : disabledDays)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const validateRequiredFields = () => {
    const currentLabel = period?.label
    const validMainDescriptions = currentPeriodicity?.description && period?.description

    if (currentLabel === 'DIARY' || !currentLabel) {
      setValidFields({
        ...validFields,
        description: !currentPeriodicity?.description,
        periodDescription: !period?.description
      })

      return validMainDescriptions
    }

    if (currentLabel === 'BIMONTHLY') {
      setValidFields({
        ...validFields,
        dayOneMonth: !firstDayMonth,
        dayTwoMonth: !secondDayMonth,
        description: !currentPeriodicity?.description,
        periodDescription: !period?.description
      })

      return firstDayMonth && secondDayMonth && validMainDescriptions
    }

    if (currentLabel === 'BIWEEKLY') {
      setValidFields({
        ...validFields,
        dayOneWeek: !firstDayWeek?.description,
        dayTwoWeek: !secondDayWeek?.description,
        description: !currentPeriodicity?.description,
        periodDescription: !period?.description
      })

      return firstDayWeek?.description && secondDayWeek?.description && validMainDescriptions
    }

    if (currentLabel === 'MONTHLY') {
      setValidFields({
        ...validFields,
        dayOneMonth: !firstDayMonth,
        description: !currentPeriodicity?.description,
        periodDescription: !period?.description
      })

      return firstDayMonth && validMainDescriptions
    }

    if (currentLabel === 'WEEKLY') {
      setValidFields({
        ...validFields,
        dayOneWeek: !firstDayWeek?.description,
        description: !currentPeriodicity?.description,
        periodDescription: !period?.description
      })

      return firstDayWeek?.description && validMainDescriptions
    }

    if (currentLabel === 'FREE_WEEKLY') {
      setValidFields({
        ...validFields,
        weekDays: !freeWeeklyDays?.length,
        description: !currentPeriodicity?.description,
        periodDescription: !period?.description
      })

      return freeWeeklyDays?.length && validMainDescriptions
    }

    if (currentLabel === 'FREE_MONTHLY') {
      setValidFields({
        ...validFields,
        monthDays: !freeMonthlyDays?.length,
        description: !currentPeriodicity?.description,
        periodDescription: !period?.description
      })

      return freeMonthlyDays?.length && validMainDescriptions
    }
  }

  const configProductsStyle = () => {
    if (mainList?.nConfigProducts === 0) {
      return '#F53D4C'
    }

    if (mainList?.nConfigProducts !== mainList?.nTotalProducts) {
      return '#AA8208'
    }

    return '#006608'
  }

  const getInternalProductRelated = (product) => {
    setInternalProductRelated({
      ...internalProductRelated,
      loading: true,
    });

    Axios
      .get(
        `${environments.dashBoard}/cmvcount/${product?.id}/cards?restaurantId=${getId}`
      )
      .then((response) => {
        setInternalProductRelated({
          loading: false,
          content: response?.data || ''
        });

        setInternalDialogSettings(
          internalDialogSettings.map((modal) =>
            modal.name === 'internalProductRelated' ? { ...modal, open: true } : modal
          )
        );
      })
      .catch((error) => {
        console.log(error);
        setInternalProductRelated({
          loading: false,
          content: ''
        });
      });
  }

  const getProductRelatedMenus = (product) => {
    setProductRelatedMenus({
      ...productRelatedMenus,
      loading: true,
    });

    setSelectedProduct(product)

    Axios
      .get(
        `${environments.dashBoard}/cmvcount/${product?.id}/menus?restaurantId=${getId}`
      )
      .then((response) => {
        setProductRelatedMenus({
          loading: false,
          content: response?.data || ''
        });

        setInternalDialogSettings(
          internalDialogSettings.map((modal) =>
            modal.name === 'productRelatedMenus' ? { ...modal, open: true } : modal
          )
        );
      })
      .catch((error) => {
        console.log(error);
        setProductRelatedMenus({
          loading: false,
          content: ''
        });
      });
  }

  const getMainContent = useCallback(
    () => {
      (async () => {
        setCallSettings((prevState) => ({ ...prevState, mainLoading: true }));

        const formattedDate = countDate 
          ? moment(countDate).format('YYYY-MM-DD') 
          : ''
        const countDateFilter = formattedDate ? `&countDate=${formattedDate}` : ''

        Axios.get(`${environments.dashBoard}/cmvCount/products?restaurantId=${getId}${countDateFilter}`)
          .then((response) => {
          const periodicityResponse = response?.data?.periodicity
          setMainList(response?.data
            ?
              {
                ...response?.data,
                periodicity: {
                  ...periodicityResponse,
                  counts: periodicityResponse?.counts?.sort((a, b) => a?.setup?.storageLocationDescription >= b?.setup?.storageLocationDescription ? 1 : -1)
                }
              }
            : []
          );

          const pendingAndConfigItems = response?.data?.products?.filter(el => el?.pending && el?.storageLocations?.length)

          setUpdatedItems(pendingAndConfigItems?.length
            ? pendingAndConfigItems?.map(el => (
              {
                id: el?.id,
                storageLocations: el?.storageLocations?.map(item => item?.id)
              }
            ))
            : []
          )
          setHasUpdates(pendingAndConfigItems?.length || false)

          setCurrentPeriodicity(periodicitiesOptions.find(el => el?.description === periodicityResponse?.description))
          setPeriod(periodOptions?.find(el => el?.label === periodicityResponse?.type))

          if (periodicityResponse?.type === 'FREE_MONTHLY') {
            setFreeMonthlyDays(periodicityResponse?.days?.split(','))
          }

          if (periodicityResponse?.type === 'FREE_WEEKLY') {
            setFreeWeeklyDays(arrayDays?.filter(el => periodicityResponse?.days?.includes(el?.id)))
          }

          if (periodicityResponse?.type === 'BIMONTHLY' || period?.label === 'MONTHLY') {
            setFirstDayMonth(periodicityResponse?.first)
            setSecondDayMonth(periodicityResponse?.type === 'BIMONTHLY' ? periodicityResponse?.second : '')
          }

          if (periodicityResponse?.type === 'BIWEEKLY' || periodicityResponse?.type === 'WEEKLY') {
            setFirstDayWeek(arrayDays?.find(el => el?.id === periodicityResponse?.first))
            setSecondDayWeek(periodicityResponse?.type === 'BIWEEKLY' ? arrayDays?.find(el => el?.id === periodicityResponse?.second) : '')
          }
          
          setCallSettings((prevState) => ({
            ...prevState,
            mainLoading: false,
          }));
        }).catch(() => {
          setCallSettings((prevState) => ({
            ...prevState,
            mainLoading: false
          }));
        })
      })();
    },
    [filtersChanged, enableToLoadPeriodicities, enableToLoadStorageLocations, countDate]
  );

  const onSubmit = () => {
    if (!validateRequiredFields()) return null

    setLoading(true)
    const defaultPeriodicity = mainList?.periodicity
    const isFreePeriod = period?.label === 'FREE_MONTHLY' || period?.label === 'FREE_WEEKLY'
    const isMonthPeriod = period?.label === 'BIMONTHLY' || period?.label === 'MONTHLY'
    const isWeekPeriod = period?.label === 'BIWEEKLY' || period?.label === 'WEEKLY'
    let currentDays = defaultPeriodicity?.days
    let currentFirstDay = defaultPeriodicity?.first
    let currentSecondDay = defaultPeriodicity?.second
    
    if (isFreePeriod) {
      currentDays = period?.label === 'FREE_MONTHLY' ? freeMonthlyDays : freeWeeklyDays?.map(el => el?.id)
    }

    if (isMonthPeriod) {
      currentFirstDay = firstDayMonth
      currentSecondDay = secondDayMonth
      currentDays = []
    }

    if (isWeekPeriod) {
      currentFirstDay = firstDayWeek?.id
      currentSecondDay = secondDayWeek?.id
      currentDays = []
    }

    const body = {
      restaurantId: getId,
      user: userInfo.user,
      periodicity: {
        ...defaultPeriodicity,
        update: hasUpdates,
        days: currentDays,
        description: currentPeriodicity?.description,
        type: period?.label,
        first: currentFirstDay,
        second: currentSecondDay
      },
      products: updatedItems
    }

    Axios.post(
      `${environments.catalog}/cmvCount/setup`,
      body
    )
      .then(() => {
        setMessage({
          description: 'Configuração atualizada com sucesso.',
          status: 'success'
        });
        setLoading(false)
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: false } : modal
          )
        );
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          description: 'Erro ao atualizar configuração',
          status: 'error'
        });
      });
  }

  const submitButtonLabel = () => {
    if (currentPeriodicity?.id) {
      return loading ? 'Salvando...' : 'Salvar'
    }

    return loading ? 'Criando...' : 'Criar Contagem'
  }

  const renderMonthlyDoubleDaysFields = (showSecondDay) => (
    <>
      <TextField
        size="small"
        onChange={(e) => {
          setHasUpdates(true)
          setFirstDayMonth(e.target.value)
        }}
        name="firstDay"
        label="Dia 1"
        variant="outlined"
        value={firstDayMonth}
        type="number"
        fullWidth
        required={
          period?.label !== 'MONTHLY' &&
            period?.label !== 'BIMONTHLY'
            ? false
            : 'required'
        }
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }}
        inputProps={{ min: '1', max: '31', step: '1', maxLength: 2 }}
        className={`textFieldSmall ${validFields?.dayOneMonth && 'labelError'}`}
        placeholder="Digite aqui..."
        InputLabelProps={{
          shrink: true
        }}
      />

      {showSecondDay &&
        <TextField
          size="small"
          onChange={(e) => {
            setHasUpdates(true)
            setSecondDayMonth(e.target.value)
          }}
          name="secondDayWeek"
          label="Dia 2"
          variant="outlined"
          value={secondDayMonth}
          type="number"
          fullWidth
          required={
            period?.label !== 'BIMONTHLY'
              ? false
              : 'required'
          }
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
          }}
          inputProps={{ min: '1', max: '31', step: '1', maxLength: 2 }}
          className={`textFieldSmall ${validFields?.dayTwoMonth && 'labelError'}`}
          placeholder="Digite aqui..."
          InputLabelProps={{
            shrink: true
          }}
        />
      }
    </>
  )

  const renderWeeklyDoubleDaysFields = (showSecondDay = false) => (
    <>
      <Autocomplete
        getOptionSelected={(option, value) =>
          option.description === value.description
        }
        getOptionLabel={(option) => option.description}
        options={arrayDays.filter(
          (item) => item.description !== 'Todos'
        )}
        size="small"
        value={firstDayWeek}
        onChange={(_, value) => {
          setHasUpdates(true)
          setFirstDayWeek(value);
        }}
        fullWidth
        className={`autocompleteSmall ${validFields?.dayOneWeek && 'labelError'}`}
        renderInput={(params) => (
          <TextField
            {...params}
            id={params.id}
            name="type"
            label="Dia 1"
            variant="outlined"
            required={
              period?.label !== 'WEEKLY' &&
                period?.label !== 'BIWEEKLY'
                ? false
                : 'required'
            }
            placeholder="Digite aqui..."
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        renderOption={(params) => (
          <Typography
            style={{ fontSize: '12px', fontFamily: 'Lato' }}
          >
            {params.description}
          </Typography>
        )}
      />

      {showSecondDay && 
        <Autocomplete
          getOptionSelected={(option, value) =>
            option.description === value.description
          }
          getOptionLabel={(option) => option.description}
          options={arrayDays}
          size="small"
          fullWidth
          value={secondDayWeek}
          onChange={(_, value) => {
            setHasUpdates(true)
            setSecondDayWeek(value);
          }}
          className={`autocompleteSmall ${validFields?.dayTwoWeek && 'labelError'}`}
          renderInput={(params) => (
            <TextField
              {...params}
              id={params.id}
              name="type"
              label="Dia 2"
              variant="outlined"
              required={
                period?.label !== 'BIWEEKLY'
                  ? false
                  : 'required'
              }
              placeholder="Digite aqui..."
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          renderOption={(params) => (
            <Typography
              style={{ fontSize: '12px', fontFamily: 'Lato' }}
            >
              {params.description}
            </Typography>
          )}
        />
      }
    </>
  )

  useEffect(() => {
    if (selectedCard) {
      handleSelectedCard(selectedCard);
    }
  }, [selectedCard]);

  useEffect(() => {
    if (!periodicitiesOptions.length) {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/periodicities?page=1&size=1500&originId=${getId}`
        );
        const periodicityJson = await response.json();
        setPeriodicitiesOptions(periodicityJson?.content);
        setEnableToLoadPeriodicities(true)
      })();
    }

    if (!storageLocationOptions.length) {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/storageLocations?page=1&size=1500&originId=${getId}`
        );
        const storageLocationsJson = await response.json();
        setStorageLocationOptions(storageLocationsJson.content);
        setEnableToLoadStorageLocations(true)
      })();
    }
  }, [getId]);

  useEffect(() => {
    if (updateStorageLocations) {
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocationOptions,
        setStorageLocationOptions,
        getId
      );
    }
  }, [updateStorageLocations]);

  useEffect(() => {
    if (enableToLoadPeriodicities && enableToLoadStorageLocations) {
      getMainContent();
    }
  }, [getId, filtersChanged, page, size, enableToLoadPeriodicities, enableToLoadStorageLocations, countDate]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={() => {
        if (hasUpdates || hasProductUpdates) {
          setInternalDialogSettings(
            internalDialogSettings?.map((modal) =>
              modal.name === 'confirmCloseDialog' ? { ...modal, open: true } : modal
            )
          );
        } else {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: false } : modal
            )
          );
        }
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal similarSkuDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>
          CONFIGURAÇÃO DE CONTAGENS X CMV
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            if (hasUpdates || hasProductUpdates) {
              setInternalDialogSettings(
                internalDialogSettings?.map((modal) =>
                  modal.name === 'confirmCloseDialog' ? { ...modal, open: true } : modal
                )
              );
            } else {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: false } : modal
                )
              );
            }
          }}
        >
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="close modal"
          />
        </IconButton>
      </DialogTitle>

      <Container>
        <CmvAndCountSetupCard className="cardDefault pt0">
          <TableCurrentSetups>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                format="dd/MM"
                size="small"
                className="dateSmall"
                inputVariant="outlined"
                label="Data"
                placeholder="00/00"
                autoOk
                views={['year', 'month', 'date']}
                value={new Date(moment(countDate))}
                onChange={(date) => {
                  setCountDate(date)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                onMonthChange={(value) => {
                  const startOfMonth = moment(value).startOf('month').format('YYYY-MM-DD');
                  const endOfMonth   = moment(value).endOf('month').format('YYYY-MM-DD');
      
                  onDateFilterMonthChange(startOfMonth, endOfMonth)
                }}
                onOpen={(value) => {
                  const currentDate = countDate || actualDateLessSeven
                  const startOfMonth = moment(currentDate).startOf('month').format('YYYY-MM-DD');
                  const endOfMonth   = moment(currentDate).endOf('month').format('YYYY-MM-DD');
      
                  onDateFilterMonthChange(startOfMonth, endOfMonth)
                }}
                shouldDisableDate={(date) => (
                  disabledDates ? !disabledDates?.includes(moment(date)?.format('D')) : false
                )}
              />
            </MuiPickersUtilsProvider>

            {currentPeriodicity?.id ?
              <Autocomplete
                key={currentPeriodicity}
                id="controllable-states-demo"
                getOptionLabel={(option) => option?.description}
                options={periodicitiesOptions}
                size="small"
                value={currentPeriodicity}
                fullWidth
                disabled
                className="autocompleteSmall"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={params?.id}
                    name="counts"
                    label="Contagem"
                    variant="outlined"
                    placeholder="Selecione..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => (
                  <Typography
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    {params?.description}
                  </Typography>
                )}
              />
            :
              <TextField
                size="small"
                onChange={(e) => {
                  setHasUpdates(true)
                  setCurrentPeriodicity({
                    ...currentPeriodicity,
                    description: e.target.value
                  })
                }}
                name="count"
                label="Contagem"
                variant="outlined"
                value={currentPeriodicity?.description}
                fullWidth
                className={`textFieldSmall ${validFields?.description && 'labelError'}`}
                placeholder="Digite aqui..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            }

            <Autocomplete
              key={period}
              id="controllable-states-demo"
              getOptionLabel={(option) => option?.description}
              options={periodOptions}
              size="small"
              value={period}
              fullWidth
              className={`autocompleteSmall ${validFields?.periodDescription && 'labelError'}`}
              onChange={(_, value) => {
                setHasUpdates(true)
                setPeriod(period !== null ? value : '')
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={params?.id}
                  name="frequencies"
                  label="Frequência sugerida"
                  variant="outlined"
                  placeholder="Selecione..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(params) => (
                <Typography
                  style={{ fontSize: '12px', fontFamily: 'Lato' }}
                >
                  {params?.description}
                </Typography>
              )}
            />

            {(period?.label === 'BIMONTHLY' || period?.label === 'MONTHLY') &&
              renderMonthlyDoubleDaysFields(period?.label === 'BIMONTHLY')
            }

            {(period?.label === 'BIWEEKLY' || period?.label === 'WEEKLY') &&
              renderWeeklyDoubleDaysFields(period?.label === 'BIWEEKLY')
            }

            {period?.label === 'FREE_WEEKLY' &&
              <Autocomplete
                getOptionSelected={(option, value) =>
                  option.description === value.description
                }
                getOptionLabel={(option) => option.description}
                options={arrayDays}
                size="small"
                fullWidth
                multiple
                limitTags={2}
                value={freeWeeklyDays}
                required={
                  period?.label === 'FREE_WEEKLY'
                    ? false
                    : 'required'
                }
                onChange={(event, value) => {
                  setHasUpdates(true)
                  if (value.find((item) => item.description === 'Todos')) {
                    setFreeWeeklyDays(
                      arrayDays.filter((item) => item.description !== 'Todos')
                    );
                  } else {
                    setFreeWeeklyDays(value);
                  }
                }}
                className={`autocompleteSmall freeDaysField ${validFields?.weekDays && 'labelError'}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={params.id}
                    name="type"
                    label="Dias"
                    variant="outlined"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => (
                  <Typography
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    {params.description}
                  </Typography>
                )}
              />
            }

            {period?.label === 'FREE_MONTHLY' &&
              <Autocomplete
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={freeMonthDayOptions}
                multiple
                limitTags={10}
                size="small"
                fullWidth
                value={freeMonthlyDays}
                required={
                  period?.label !== 'FREE_MONTHLY'
                    ? false
                    : 'required'
                }
                onChange={(_, value) => {
                  setHasUpdates(true)
                  setFreeMonthlyDays(value);
                }}
                className={`autocompleteSmall freeDaysField ${validFields?.monthDays && 'labelError'}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={params.id}
                    name="type"
                    label="Dias"
                    variant="outlined"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => (
                  <Typography
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    {params}
                  </Typography>
                )}
              />
            }

            <ConfiguredProducts>
              {!mainLoading && mainList?.nTotalProducts !== null ?
                <>
                  <span style={{ color: configProductsStyle() }}>
                    {mainList?.nConfigProducts} / {mainList?.nTotalProducts} {" "}
                    {mainList?.nConfigProducts === 1 ? 'produto configurado' : 'produtos configurados'}
                  </span>

                  {mainList?.periodicity?.counts?.length ?
                    <Link
                      className={`dFlex alignCenter
                        ${!mainList?.periodicity?.counts?.length && 'disabledLink'}
                      `}
                      target="_blank"
                      to={`/inventario/contagem-diaria/${getId}/${mainList?.periodicity?.counts[0].countId}?countId=${mainList?.periodicity?.counts[0].countId}&countOrigin=COUNTS&countDate=${mainList?.periodicity?.counts[0].countDate}&countAssortDate=${mainList?.periodicity?.counts[0].countAssortDate}&countAssortUser=${mainList?.periodicity?.counts[0].countAssortUser}&periodicityInfos=${`${mainList?.periodicity?.id}_${mainList?.periodicity?.description}_${mainList?.periodicity?.type}_${mainList?.periodicity?.isCmvCount}_${mainList?.periodicity?.subtractDays}`}&countIds=${mainList?.periodicity?.counts?.map(el => el?.countId)}&storageLocations=${mainList?.periodicity?.counts?.map(el => `${el?.countId}_${el?.setup?.storageLocationId}_${el?.setup?.storageLocationDescription}`)}`}
                    >
                      <Tooltip
                        title={
                          <div style={{ padding: '4px' }}>
                            <p style={{ margin: '0' }}>
                              Visualizar contagem
                            </p>
                          </div>
                        }
                        aria-label="filters"
                        placement="right-start"
                      >
                        <img
                          src={AlertBlueIcon}
                          alt={AlertBlueIcon}
                          className={`${!mainList?.nConfigProducts && 'dNone'}`}
                          style={{ width: 18, height: 17 }}
                        />
                      </Tooltip>
                    </Link>
                  : null}
                </>
              : 
                <div className="countsCircularProgressBar dFlex justifyCenter">
                  <CircularProgress />
                </div>
              }
            </ConfiguredProducts>
          </TableCurrentSetups>

          <Table
            mainList={mainList}
            setMainList={setMainList}
            isLoading={mainLoading}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
            setPendentItems={setPendentItems}
            pendentItems={pendentItems}
            storageLocationOptions={storageLocationOptions}
            getInternalProductRelated={getInternalProductRelated}
            getProductRelatedMenus={getProductRelatedMenus}
            setUpdatedItems={setUpdatedItems}
            updatedItems={updatedItems}
            setHasProductUpdates={setHasProductUpdates}
          />
        </CmvAndCountSetupCard>

        <Button
          onClick={() => {
            onSubmit();
          }}
          disabled={loading || (!hasUpdates && !hasProductUpdates)}
          className="defaultButton submitButton mlAuto mt12"
          type="submit"
          design="contained"
          label={submitButtonLabel()}
          style={{ outline: 0 }}
        />
      </Container>

      {internalDialogSettings?.find((modal) => modal.name === 'internalProductRelated')?.open && (
        <InternalProductRelated
          mainList={internalProductRelated?.content?.cards}
          description={internalProductRelated?.content?.description}
          loading={internalProductRelated?.loading}
          getId={getId}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          open={internalDialogSettings?.find((modal) => modal.name === 'internalProductRelated')?.open}
          onClose={() => {
            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'internalProductRelated' ? { ...modal, open: false } : modal
              )
            );

            setInternalProductRelated({
              content: [],
              loading: false
            })
          }}
        />
      )}

      {internalDialogSettings?.find((modal) => modal.name === 'productRelatedMenus')?.open && (
        <ProductRelatedMenus
          mainList={productRelatedMenus?.content?.menus}
          description={productRelatedMenus?.content?.description}
          setSelectedProduct={setSelectedProduct}
          handleToCardEditPageClick={handleToCardEditPageClick}
          loading={productRelatedMenus?.loading}
          open={internalDialogSettings?.find((modal) => modal.name === 'productRelatedMenus')?.open}
          onClose={() => {
            setSelectedProduct()
            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'productRelatedMenus' ? { ...modal, open: false } : modal
              )
            );

            setProductRelatedMenus({
              content: [],
              loading: false
            })
          }}
        />
      )}

      {internalDialogSettings?.find((modal) => modal.name === 'confirmCloseDialog')?.open && (
        <ConfirmModal
          open={internalDialogSettings?.find((modal) => modal.name === 'confirmCloseDialog')?.open}
          content={
            <Typography className="bold">
              Atenção! <br/> 
              As alterações não foram salvas, deseja realmente sair?
            </Typography>
          }
          onConfirm={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: false } : modal
              )
            );

            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'confirmCloseDialog' ? { ...modal, open: false } : modal
              )
            );
          }}
          onClose={() => {
            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'confirmCloseDialog' ? { ...modal, open: false } : modal
              )
            );
          }}
          cancelText="Não"
          confirmText="Sim"
        />
      )}

      {internalDialogSettings?.find((modal) => modal.name === 'confirmCloseEditCardMenu')?.open && (
        <ConfirmModal
          open={internalDialogSettings?.find((modal) => modal.name === 'confirmCloseEditCardMenu')?.open}
          content={
            <Typography className="bold">
              Deseja realmente cancelar a edição desse item?
            </Typography>
          }
          onConfirm={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'cardMenuDialog' ? { ...modal, open: false } : modal
              )
            );

            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'confirmCloseEditCardMenu' ? { ...modal, open: false } : modal
              )
            );
          }}
          onClose={() => {
            setInternalDialogSettings(
              internalDialogSettings?.map((modal) =>
                modal.name === 'confirmCloseEditCardMenu' ? { ...modal, open: false } : modal
              )
            );
          }}
          cancelText="Não"
          confirmText="Sim"
        />
      )}

      {internalDialogSettings?.find((modal) => modal.name === 'cardMenuDialog')?.open && (
        <Dialog
          open={internalDialogSettings?.find((modal) => modal.name === 'cardMenuDialog')?.open}
          onClose={() => {
            setInternalDialogSettings(
              internalDialogSettings.map((modal) =>
                modal.name === 'cardMenuDialog' ? { ...modal, open: false } : modal
              )
            );
          }}
          className="defaultModal menuModal"
          maxWidth="md"
          fullWidth
          PaperComponent={PaperComponent}
        >
          <DialogTitle
            id="draggable-dialog-title"
            className="modalHeader cursorMove"
          >
            <Typography className="bold textAlignCenter fontSize16">
              CADASTRO DE CARDÁPIO
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setInternalDialogSettings(
                  internalDialogSettings.map((modal) =>
                    modal.name === 'cardMenuDialog' ? { ...modal, open: false } : modal
                  )
                );
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none' }}
            >
              <img
                src={CloseIconNavy}
                alt="CloseIconNavy"
                style={{ width: 15 }}
              />
            </IconButton>
          </DialogTitle>

          <DialogContent 
            className="modalContent"
          >
            {BodyModal}
          </DialogContent>
        </Dialog>
      )}

      {selectedCard && (
        <TechnicalSheetDialog
          maxWidth
          open={internalDialogSettings.find((modal) => modal.name === 'technicalSheet').open}
          handleClose={() =>
            setInternalDialogSettings(
              internalDialogSettings.map((modal) =>
                modal.name === 'technicalSheet'
                  ? { ...modal, open: false }
                  : modal
              )
            )
          }
          cardDataTable={cardDataTable}
          cardTransformation={cardTransformation}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          ucPrimarySelectedId={ucPrimarySelectedId}
          getCardsContent={() => {
            getProductRelatedMenus(selectedProduct)
          }}
          salesAverageDiscount={salesAverageDiscount}
          setSalesAverageDiscount={setSalesAverageDiscount}
          openModalMenu={internalDialogSettings?.find((modal) => modal.name === 'confirmCloseEditCardMenu')?.open}
          setHasMenuModalChange={setHasMenuModalChange}
          hasMenuModalChange={hasMenuModalChange}
          showInactiveItems={filtersChanged}
        />
      )}
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CardsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CmvAndCountSetup);

