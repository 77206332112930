/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress, Fade, Tooltip, useMediaQuery } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';

import StockNoEntryOrExit from 'images/icons/arrows/arrowUpAndDownGrayOutline.svg';
import StockEntryNoExit from 'images/icons/arrows/arrowUpGrayAndDownNavyBlueOutline.svg';
import StockNoEntryExit from 'images/icons/arrows/arrowUpRedAndDownGrayOutline.svg';
import stockEntryAndExit from 'images/icons/arrows/arrowUpRedAndDownNavyBlue.svg'
import ExtractDialogIcon from 'images/icons/objects/clocks/clockArrowBackLightBlueOutline.svg';
import InfoIcon from 'images/icons/signals/infos/info.svg';
import StockDialog from 'pages/inventory/diary-count/dialogs/StockDialog';
import Environment from 'utils/environments';

export const calcQuantityDifference = (product) => {
  if (
    product.realStock !== null ||
    product.realStock !== undefined ||
    product.otherStock !== null ||
    product.otherStock !== undefined
  ) {
    const otherStock = parseFloat(Number(product.otherStock)) || 0;
    const realStock = parseFloat(Number(product.realStock)) || 0;
    const theoricalStock =
      parseFloat(Number(product.theoricalStock)) ||
      parseFloat(Number(product?.theoreticalStock)) ||
      0;

    return otherStock + realStock - theoricalStock;
  }
  return null;
};

export const calcValueDifference = (product) => {
  if (typeof parseFloat(product.realStock) === 'number') {
    return Number(calcQuantityDifference(product)) * Number(product.cost);
  }
  return 0;
};

function setColor(percentage, lossPercentage, isFirst, diff, consumed) {
  const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];
  let color;

  if (isFirst)
    color =
      percentage >= range[0] && percentage <= range[1]
        ? '#D2F9D6'
        : percentage >= lossPercentage
          ? '#FFF2CC'
          : '#F7D4D7';
  else
    color =
      percentage >= range[0] && percentage <= range[1]
        ? '#006608'
        : percentage >= lossPercentage
          ? '#AA8208'
          : '#F53D4C';

  if (!consumed) {
    if (isFirst)
      color = diff == 0 ? '#D2F9D6' : diff > 0 ? '#FFF2CC' : '#F7D4D7';
    else color = diff == 0 ? '#006608' : diff > 0 ? '#AA8208' : '#F53D4C';
  }

  return color;
}

function validateNumber(number) {
  if (number === '-0,000') number = number.replace('-', '');

  return number;
}

function ValueList({
  skuIndex,
  providerIndex,
  productIndex,
  internalProduct,
  countDate,
  hasCountedOthersStocks,
  isSelectedRealDiff = false,
  parametersInfos,
  countGroupInfos,
  showColumns,
  consumedInOutInfos,
  setConsumedInOutInfos,
  loadingInOutInfos,
  setLoadingInOutInfos,
  handleGetExtract,
}) {
  const isMobile = useMediaQuery('(max-width: 821px)');
  const value = internalProduct[productIndex];
  const unitMeasurements = value?.consumptionUnitsPrimary?.abbreviation
  const environments = Environment(process.env.REACT_APP_ENV);
  const reportTabObj = countGroupInfos?.countGroup?.counts?.find(
    (el) => el?.type === 'report'
  );
  const lossPercentage = value.lossPercentage
    ? parseFloat(value.lossPercentage)
    : 0;
  const [show, setShow] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hoveredOut, setHoveredOut] = useState(false);
  const [realStock, setRealStock] = useState([]);
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'stockModal', open: false, fullScreen: false }
  ]);

  const mouseEnterHandler = () => {
    setHovered(true);
  };

  const mouseLeaveHandler = () => {
    setHovered(false);
    setHoveredOut(true);
  };

  const onTimeout = () => {
    axios
      .get(
        `${environments.catalog}/inventories/counts/${value.id}/stocks/${countDate}`
      )
      .then((response) => {
        setRealStock(response.data.content);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const realStockItemValues = useMemo(
    () => (
      <div style={{ width: '20px' }}>
        {value?.stocks?.totalStocks > 0 && (
          <div onMouseOver={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={
                <div
                  className="dFlex flexColumn"
                  style={{ width: '380px', paddingTop: 5 }}
                >
                  {realStock.map((item) => (
                    <div className="dFlex justifyBetween">
                      <p
                        style={{
                          width: '50%',
                          padding: '0 8px 0 0',
                          margin: '0px 5px 5px 0px',
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12
                        }}
                      >
                        {item.stock}
                      </p>

                      <p
                        style={{
                          width: '25%',
                          padding: '0 8px 0 0',
                          margin: 0,
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12,
                          textAlign: 'right'
                        }}
                      >
                        {item.quantity
                          ? parseFloat(item.quantity).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                          : '0,000'}
                      </p>

                      <p
                        style={{
                          width: '25%',
                          padding: '0 8px 0 0',
                          margin: 0,
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12,
                        }}
                      >
                        {item.user ? item.user : '-'}
                      </p>
                    </div>
                  ))}
                </div>
              }
            >
              <img src={InfoIcon} alt="Info Icon" style={{ marginLeft: 5 }} />
            </Tooltip>
          </div>
        )}
      </div>
    ),
    [realStock, value?.stocks?.totalStocks]
  );

  useEffect(() => {
    const timer = hovered && setTimeout(onTimeout, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [hovered]);

  useEffect(() => {
    if (value?.skus) {
      for (const sku of value?.skus) {
        for (const provider of sku.providers) {
          if (provider.stock != null && !isNaN(provider.stock)) {
            setShow(true);
          } else {
            setShow(false);
          }
        }
        // if (sku.unityAdditional != null && !isNaN(sku.unityAdditional)) {
        //   show = true;
        // }
      }
    }
  }, [internalProduct, value])

  const otherStock = value.otherStock != null ? Number(value.otherStock) : 0;
  const totalCost =
    (parseFloat(otherStock) +
      (value.realStock ? parseFloat(value.realStock) : 0)) *
    parseFloat(value.cost);
  const difference =
    value.realStock !== null ||
      value.realStock !== undefined ||
      value.otherStock !== null ||
      value.otherStock !== undefined
      ? (value.otherStock ? parseFloat(Number(value.otherStock)) : 0) +
      (value.realStock ? parseFloat(Number(value.realStock)) : 0) -
      (value.theoricalStock ? parseFloat(Number(value.theoricalStock).toFixed(4)) : 0)
      : null;

  const percentage = value.consumed
    ? (difference / parseFloat(Number(value.consumed))) * 100
    : 0;
  const percent = percentage
    ? percentage.toLocaleString('pt-br', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
    : '0,00';
  const differenceInReal =
    typeof parseFloat(value.realStock) === 'number'
      ? Number(difference) * Number(value.cost)
      : null;
  const parsedDifferenceInReal = differenceInReal
    ? differenceInReal.toLocaleString('pt-br', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
    : '0,00';
  const diff = difference
    ? difference.toLocaleString('pt-br', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    })
    : '0,00';
  const entryAndExitValue = ((value?.theoricalStock || 0) - (value?.historical?.realStock || 0))
  const entryAndExitValues = () => {
    if (value.hasIn && value.hasOut) {
      return stockEntryAndExit
    }

    if (!value.hasIn && !value.hasOut) {
      return StockNoEntryOrExit
    }

    if (value.hasIn && !value.hasOut) {
      return StockEntryNoExit
    }

    if (!value.hasIn && value.hasOut) {
      return StockNoEntryExit
    }
  }
  const entryAndExitSignal = entryAndExitValue === 0 || entryAndExitValue < 0
    ? ''
    : '+'

  const previousAndBeforeDate = () => {
    if (moment(countDate).isSameOrAfter(moment(), 'day')) {
      return moment(countDate).isSameOrAfter(moment(), 'day');
    }
  };

  return (
    <>
      <td
        width="5"
        id={`otherStock${productIndex}`}
        className={`otherStock description right p8 ${!showColumns?.includes('OUTROS ESTOQUES') && 'dNone'
          }`}
        style={{
          borderLeft: 'solid 1px #E6E7F0',
          borderTop: isMobile ? '1px solid #ccc' : 'transparent',
          borderBottom: !skuIndex && !providerIndex ? '1px solid #ccc' : 'transparent',
          color: '#1F2445',
          maxWidth: '10px',
        }}
      >
        {!skuIndex && !providerIndex && (
          <div className="dFlex justifyBetween alignCenter othersContent">
            <div className="dFlex alignCenter">
              <div className="othersStocksVolume">
                <span>
                  {value?.stocks?.totalStocks > 0
                    ? `${value.stocks.countedStocks}/${value.stocks.totalStocks}`
                    : value?.stocks?.countedStocks
                  }
                </span>
                
                <div 
                  onClick={() => {
                    onTimeout()
                    setModalSettings(
                      modalSettings.map((modal) =>
                        modal.name === 'stockModal' ? { ...modal, open: true } : modal
                      )
                    );
                  }} 
                  className='iconInfo'
                >
                  <img src={InfoIcon} alt="Info Icon" style={{ marginLeft: 5 }} />
                </div>
              </div>

              <span className="mb5 tooltipOuthersQuoter">
                {realStockItemValues}
              </span>
            </div>

            <p className='content-report-desk bold' style={{ marginBottom: 0 }}>
              {!reportTabObj?.active
                ? `${(value?.otherStock || 0).toLocaleString('pt-br', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3
                })} ${unitMeasurements}`
                : null
              }
            </p>

            <p className='content-report-tablet bold' style={{ marginBottom: 0 }}>
              {!reportTabObj?.active
                ? `${(value?.otherStock || 0).toLocaleString('pt-br', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3
                })}`
                : null
              }
              <br />{unitMeasurements}
            </p>
          </div>
        )}
      </td>

      <td
        width="5"
        id={`lastRealStock${productIndex}`}
        className={`realStock description right p8 ${!showColumns?.includes('ÚLTIMA') && 'dNone'
          }`}
        style={{
          maxWidth: 20,
          color: '#333541',
          borderLeft: 'solid 1px #0B1A8E',
          borderTop: isMobile ? '1px solid #ccc' : 'transparent',
          borderBottom: !skuIndex && !providerIndex ? '1px solid #ccc' : 'transparent',
        }}
      >
        <p className='content-report-desk' style={{ marginBottom: 0 }}>
          {!skuIndex && !providerIndex
            ? `${value?.historical?.realStock}${unitMeasurements}`
              ? `${value?.historical?.realStock?.toLocaleString('pt-br', {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3
              })} ${unitMeasurements}`
              : `0,000 ${unitMeasurements}`
            : null
          }
        </p>

        <p className='content-report-tablet' style={{ marginBottom: 0 }}>
          {!skuIndex && !providerIndex
            ? `${value?.historical?.realStock}${unitMeasurements}`
              ? `${value?.historical?.realStock?.toLocaleString('pt-br', {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3
              })}`
              : `0,000`
            : null
          }
          <br />{unitMeasurements}
        </p>
      </td>

      <td
        width="5"
        id={`consumed${productIndex}`}
        className={`realStock description right p8 ${!showColumns?.includes('CONSUMO') && 'dNone'
          }`}
        style={{ 
          color: entryAndExitValue === 0 ? '#9296AC' : entryAndExitValue > 0 ? '#0B1A8E' : '#F53D4C', 
          borderLeft: 'solid 1px #E6E7F0',
          borderBottom: !skuIndex && !providerIndex ? '1px solid #ccc' : 'transparent',
          maxWidth: 20 
        }}
      >
        <div className="dFlex justifyEnd">
          <p className='pConsumedDesk' style={{ marginBottom: 0 }}>
            {!skuIndex && !providerIndex
              ? `${entryAndExitValue}${unitMeasurements}`
                ? `${entryAndExitSignal}${entryAndExitValue.toLocaleString('pt-br', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3
                })} ${unitMeasurements}`
                : `0,000 ${unitMeasurements}`
              : null
            }
          </p>

          <p className='pConsumedTablet' style={{ marginBottom: 0 }}>
            {!skuIndex && !providerIndex
              ? `${entryAndExitValue}${unitMeasurements}`
                ? `${entryAndExitSignal}${entryAndExitValue.toLocaleString('pt-br', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3
                })}`
                : `0,000`
              : null
            }
            <br />{unitMeasurements}
          </p>
          
          {!skuIndex && !providerIndex ?
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={
                <div
                  className="dFlex flexColumn"
                  style={{ paddingTop: 5 }}
                >
                  {loadingInOutInfos ?
                    <div className="countsCircularProgressBar dFlex justifyCenter">
                      <CircularProgress color="#FFF" />
                    </div>  
                  : null}

                  {!loadingInOutInfos && !consumedInOutInfos?.positive?.length && !consumedInOutInfos?.negative?.length ?
                    <span className="white mb5">
                      Nenhuma entrada ou saída encontrada
                    </span>
                  : null}

                  {consumedInOutInfos?.positive?.length ?
                    <div className="consumedEntryBox mb10">
                      <span className="consumedEntryTitle">
                        ENTRADAS
                      </span>

                      {consumedInOutInfos?.positive.map((item) => (
                        <div className="dFlex justifyStart">
                          <p>
                            {moment(item?.date).format('DD/MM')} - 
                          </p>

                          <p className="ml2">
                            {item?.description}:
                          </p>

                          <p className="ml2">
                            {item.quantity
                              ? `+${parseFloat(item.quantity).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })}`
                              : '0,000'
                            } {" "}
                            {item?.unity?.abbreviation}
                          </p>
                        </div>
                      ))}
                    </div>
                  : null}

                  {consumedInOutInfos?.negative?.length ?
                    <div className="consumedExitBox">
                      <span className="consumedExitTitle">
                        SAÍDAS
                      </span>

                      {consumedInOutInfos?.negative.map((item) => (
                        <div className="dFlex justifyStart">
                          <p>
                            {moment(item?.date).format('DD/MM')} - 
                          </p>

                          <p className="ml2">
                            {item?.description}:
                          </p>

                          <p className="ml2">
                            {item.quantity
                              ? `-${parseFloat(Math.abs(item.quantity)).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })}`
                              : '0,000'
                            } {" "}
                            {item?.unity?.abbreviation}
                          </p>
                        </div>
                      ))}
                    </div>
                  : null}
                </div>
              }
            >
              <img
                src={entryAndExitValues()} 
                alt="Stock In and Out Icon" 
                style={{ marginLeft: 5 }} 
                onMouseEnter={() => {
                  setLoadingInOutInfos(true)
                  handleGetExtract(value, false)
                }}
                onMouseLeave={() => {
                  setConsumedInOutInfos({
                    positive: [],
                    negative: []
                  })
                }}
              />
            </Tooltip>
          : null}
        </div>
      </td>

      <Tooltip
        id={`theoricalStock${productIndex}`}
        TransitionComponent={Fade}
        disableHoverListener
        TransitionProps={{ timeout: 600 }}
        title={`Consumo: ${!skuIndex && !providerIndex
          ? value.consumed
            ? value.consumed.toLocaleString('pt-br', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3
            })
            : '0,000'
          : null
          }`
        }
      >
        <td
          id="teoricValue"
          width="5%"
          className={`teoricValue description right p8 ${!showColumns?.includes('TEÓRICO') && 'dNone'}`}
          style={{
            color: '#454A67',
            borderLeft: 'solid 1px #E6E7F0',
            borderBottom: !skuIndex && !providerIndex ? '1px solid #ccc' : 'transparent',
            cursor: 'pointer'
          }}
          onClick={() => {
            handleGetExtract(value);
          }}
        >
          <div className="dFlex alignCenter justifyEnd">
            <p
              id="teoricValue"
              className="pTeoricValueDesk mb0 lightBlue valueTeoricTablet"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleGetExtract(value);
              }}
            >
              {!skuIndex && !providerIndex
                ? value.theoricalStock
                  ? `${value.theoricalStock.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })} ${unitMeasurements} `
                  : `0,000${unitMeasurements}`
                : null
              }
            </p>

            <p className='pTeoricValueTablet lightBlue' style={{ marginBottom: 0 }}>
              {!skuIndex && !providerIndex ? value.theoricalStock ? `${value.theoricalStock.toLocaleString('pt-br', {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3
              })} `
                : `0,000`
                : null
              }
              <br />{unitMeasurements}
            </p>
            
            {!skuIndex && !providerIndex && (
              <img
                src={ExtractDialogIcon} 
                alt="Stock In and Out Icon" 
                style={{ marginLeft: 5 }} 
              />
            )}
          </div>
        </td>
      </Tooltip>

      <td
        width="5"
        id={`realStock${productIndex}`}
        className={`realStock description pr8 ${!showColumns?.includes('REAL') && 'dNone'
          }`}
        style={{
          background: 'transparent',
          borderLeft: 'solid 1px #E6E7F0',
          borderBottom: !skuIndex && !providerIndex ? '1px solid #ccc' : 'transparent',
          maxWidth: '10px',
          fontWeight: 'bold',
          color: '#1F2445'
        }}
      >
        {!skuIndex && !providerIndex && (
          <Tooltip
            TransitionProps={{ timeout: 600 }}
            placement="right-end"
            title={
              <div className="dFlex flexColumn">
                <p
                  className="mb0"
                  style={{ padding: '4px 6px 0' }}
                >
                  Usuário: {value?.user || '-'}
                </p>

                <p
                  className="mb0"
                  style={{ padding: '0 6px 4px' }}
                >
                  Data/hora: {moment(value?.date).format('DD/MM/YYYY')} - {moment(value?.date).format('HH:mm')}hs
                </p>

                <p
                  className="mb0"
                  style={{ padding: '8px 6px 4px' }}
                >
                  Última contagem: {(value?.historical?.realStock || 0)?.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })} {unitMeasurements}
                </p>
              </div>
            }
          >
            <div>
              <div className="dFlex justifyEnd realContentDesk">
                {typeof parseFloat(value.realStock) === 'number'
                  ? (value.realStock + value.otherStock).toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })
                  : '0,000'
                }{' '}
                {unitMeasurements}
              </div>

              <div className="dFlex justifyEnd realContentTablet">
                {typeof parseFloat(value.realStock) === 'number'
                  ? (value.realStock + value.otherStock).toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })
                  : '0,000'
                }{' '}
                <br />{unitMeasurements}
              </div>
            </div>
          </Tooltip>
        )}
      </td>

      {isSelectedRealDiff && (
        <td
          width="5"
          id={`difference${productIndex}`}
          className={`realStock description right ${!showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
            } p8`}
          style={{
            maxWidth: '10px',
            borderLeft: 'solid 1px #5062F0',
            borderBottom: !skuIndex && !providerIndex ? '1px solid #ccc' : 'transparent',
            background: show
              ? !skuIndex && !providerIndex
                ? value.realStock == null && value.otherStock == null
                  ? 'transparent'
                  : setColor(
                    percentage,
                    lossPercentage,
                    true,
                    difference,
                    value.consumed
                  )
                : 'transparent'
              : 'transparent',
            fontWeight: 'bold',
            color: setColor(
              percentage,
              lossPercentage,
              false,
              difference,
              value.consumed
            )
          }}
        >
          {show &&
            !skuIndex &&
            !providerIndex &&
            `R$ ${parsedDifferenceInReal}`
          }
        </td>
      )}

      {!isSelectedRealDiff && (
        <td
          width="5"
          id={`difference${productIndex}`}
          className={`realStock description right ${!showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
            } p8`}
          style={{
            maxWidth: '10px',
            borderLeft: 'solid 1px #5062F0',
            borderBottom: !skuIndex && !providerIndex ? '1px solid #ccc' : 'transparent',
            background: show
              ? !skuIndex && !providerIndex
                ? value.realStock == null && value.otherStock == null
                  ? 'transparent'
                  : setColor(
                    percentage,
                    lossPercentage,
                    true,
                    difference,
                    value.consumed
                  )
                : 'transparent'
              : 'transparent',
            fontWeight: 'bold',
            color: setColor(
              percentage,
              lossPercentage,
              false,
              difference,
              value.consumed
            )
          }}
        >
          <p className='pConsumedDesk' style={{ marginBottom: 0 }}>
            {show
              ? !skuIndex && !providerIndex
                ? (value.realStock != null || value.otherStock != null) &&
                  difference != null
                  ? `${validateNumber(diff)} ${unitMeasurements}`
                  : '-'
                : null
              : null
            }
          </p>

          <p className='pConsumedTablet' style={{ marginBottom: 0, width: '90%' }}>
            {show
              ? !skuIndex && !providerIndex
                ? (value.realStock != null || value.otherStock != null) &&
                  difference != null
                  ? `${validateNumber(diff)} ${unitMeasurements}`
                  : '-'
                : null
              : null
            }
          </p>
        </td>
      )}

      <td
        width="5"
        id={`percentage${productIndex}`}
        className={`percentStock subtitle center ${!showColumns?.includes('DIFERENÇA EM %') && 'dNone'
          }`}
        style={{
          maxWidth: '10px',
          borderBottom: !skuIndex && !providerIndex ? '1px solid #ccc' : 'transparent',
          background: show
            ? !skuIndex && !providerIndex
              ? value.realStock == null && value.otherStock == null
                ? 'transparent'
                : setColor(
                  percentage,
                  lossPercentage,
                  true,
                  difference,
                  value.consumed
                )
              : 'transparent'
            : 'transparent',
          fontWeight: 'bold',
          color: setColor(
            percentage,
            lossPercentage,
            false,
            difference,
            value.consumed
          ),
          borderLeft: 'solid 1px #E6E7F0'
        }}
      >
        <Tooltip
          TransitionProps={{ timeout: 600 }}
          placement="right-end"
          disableHoverListener={!show || skuIndex || providerIndex || value.realStock === null || value.otherStock === null || percentage === null || !value?.consumed}
          title={
            <div className="dFlex flexColumn">
              <p
                className="mb0"
                style={{ padding: '4px 6px' }}
              >
                Consumo: {(value?.consumed || 0)?.toLocaleString('pt-br', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3
                })} {unitMeasurements}
              </p>
            </div>
          }
        >
          <div className="dFlex justifyEnd pr8">
            <p className='pConsumedDesk' style={{ marginBottom: 0 }}>
              {show
                ? !skuIndex && !providerIndex
                  ? (value.realStock != null || value.otherStock != null) &&
                    percentage != null
                    ? value.consumed
                      ? `${validateNumber(percent)} %`
                      : '-'
                    : null
                  : null
                : null
              }
            </p>

            <p className='pConsumedTablet' style={{ marginBottom: 0, marginRight: '5px', textAlign: 'end' }}>
              {show
                ? !skuIndex && !providerIndex
                  ? (value.realStock != null || value.otherStock != null) &&
                    percentage != null
                    ? value.consumed
                      ? `${validateNumber(percent)}`
                      : '-'
                    : null
                  : null
                : null
              }
              <br />
              {show
                ? !skuIndex && !providerIndex
                  ? (value.realStock != null || value.otherStock != null) &&
                    percentage != null
                    ? value.consumed
                      ? `%`
                      : ''
                    : null
                  : null
                : null
              }
            </p>
          </div>
        </Tooltip>
      </td>

      <Tooltip
        TransitionProps={{ timeout: 600 }}
        disableHoverListener={!(skuIndex === 0 && providerIndex === 0  && value.date && value.user)}
        title={
          <p
            className="mb0"
            style={{ padding: '2px 6px' }}
          >
            {skuIndex === 0 && providerIndex === 0 ? value.date ? moment(value.date).format('DD-MM-YYYY HH:mm') : '-' : ''}
          </p>
        }
        placement="right-end"
      >
        <td
          width="5"
          className={`pl12 ${!showColumns?.includes('USUÁRIO') && 'dNone'}`}
          style={{ borderLeft: 'solid 1px #BFC0CB' }}
        >
          <p className='pConsumedDesk' style={{ marginBottom: 0 }}>
            {skuIndex === 0 && providerIndex === 0 ? value.user ? value.user : '-' : ''}
          </p>
        </td>
      </Tooltip>
      
      <StockDialog
        modalSettings={modalSettings}
        setModalSettings={setModalSettings}
        stocks={realStock}
      />
    </>
  );
}

export default ValueList;
