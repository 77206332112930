/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import axios from 'axios';
import format from 'date-fns/format';
import moment from 'moment';
import Draggable from 'react-draggable';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import Button from 'components/Button/button';
import ImageWrapper from 'components/ImageWrapper';
import CurrencyTextFieldSumeValues from 'components/Inputs/CurrencyTextFieldSumeValues';
import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import ProductionDefaultImage from 'images/defaultAndEmpties/defaultAndEmptyImageRectangleNoBg.svg'
import RefreshWhiteIcon from 'images/icons/arrows/arrowRefreshWhiteOutline.svg';
import selectorInputArrow from 'images/icons/arrows/selectorInputArrow.svg';
import EngineIconWhite from 'images/icons/configsAndSetups/configIconWhite.svg';
import EngineIcon from 'images/icons/configsAndSetups/configLightBlueBgWhiteRoundedLightBlueBorder.svg';
import EngineIconGrey from 'images/icons/configsAndSetups/configLightGrayBgWhiteRoundedLightGrayBorder.svg';
import ListIconBlue from 'images/icons/objects/clipboards/lightBlueListWhiteBgShortRoundLightBlueBorder.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import AddDisabledIcon from 'images/icons/signals/addsAndCreates/addDisabled.svg';
import VirtualProduction from 'images/icons/signals/alerts/alertWhiteYellowBgCircleShortRoundLightBlueBorder.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import deleteWhite from 'images/icons/signals/closesAndCancels/closeWhiteNavyGrayBgRounded.svg';
import Environment from 'utils/environments';
import { increaseDate } from 'utils/formatDate';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

import {
  ModalContent,
  CardInfoHeaderDrillDownContent,
  CardIngredientsContainer,
  ArrowDown
} from './style';

import { productionCardStatus } from '../../utils';
import CardIngredients from '../CardIngredients/index';
import { CardIngredientsDrillDownHead } from '../CardIngredients/style';
import {
  CardInfoHeader,
  CardInfoContent,
  CardContentTitle,
  CardContentElement,
  ProductionPlay,
  CardsIngredientsWrapper,
  IngredientLossWrapper,
  IngredientCount,
  LossCount
} from '../style';
import { setPopoverDataStatus } from '../utils';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

function MobileCardDetail({
  setCurrentCard,
  handleUpdateViewMethod,
  handleItemDiscard,
  forceEnable,
  setModal,
  mainModalStates,
  updateQuantityInitialValue,
  manufactures,
  setManufactures,
  noActionMode = false,
  transformationInfos,
  index,
  description,
  manufactureYield,
  newDuration,
  limiteProd,
  manufactureQuantity,
  manufactureTotalYield,
  manufactureStatus,
  dateStatus,
  items,
  actions,
  manufacture,
  optionsDiscard,
  manufactureStock,
  manufactureDuration,
  lossManufactured,
  manufactured,
  totalItemsQuantity,
  cardYield,
  type,
  income,
  setCardDate,
  setCheckSumeItems,
  setCurrentTransformationCardItems,
  handlePreparationClick,
  handleDeleteButton,
  cardIconByStatus
}) {
  const isFinished = manufacture?.status?.toUpperCase() === 'FINISH'
  const actionFinished = isFinished ? manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'FINISH') : null
  const actionStarted = manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'START') || null

  const duration = moment.duration(moment(actionFinished?.date).startOf('minute').diff(moment(actionStarted?.date || manufacture?.created_at).startOf('minute')));
  const diffHours = Math.round(duration.asHours());
  const diffMinutes = Math.round(duration.asMinutes()) % 60;
  const [cardProductionMobileInputControl, setCardProductionMobileInputControl] = React.useState(null);
  const productionInputControlPopoverOpen = Boolean(cardProductionMobileInputControl);
  const StatusIcon = productionCardStatus[manufactureStatus]?.iconFull;
  const [updateValue, setUpdateValue] = useState(false);
  const [openDrillDown, setOpenDrillDown] = useState(false);
  const [showCardProductionQuantity, setShowCardProductionQuantity] = useState(manufacture?.viewMethod);
  const isPhone = useMediaQuery('(max-width: 767px)');
  const { id: paramsID } = useParams();
  const environments = Environment(process.env.REACT_APP_ENV);
  const fullWidth = true;
  const fixedInitialYield = (
    manufacture?.initialYield || income
  )?.toLocaleString('pt-br', { minimumFractionDigits: 3 });
  const maxWidth = 'sm';
  const [durationData, setDurationData] = useState({
    product: {},
    data: {},
    type: '',
    newDuration: false
  });
  const [modalStates, setModalStates] = useState({
    modalYieldLabelQuantity: false,
    modalManufactureDuration: false
  });

  useEffect(() => {
    setTimeout(() => {
      if (
        modalStates.modalYieldLabelQuantity &&
        document.querySelector('.sumeValuesProductionDialog input')
      ) {
        document.querySelector(`.sumeValuesProductionDialog input`).select();
      }
    }, 100);
  }, [modalStates.modalYieldLabelQuantity]);

  const handleCloseModal = () => {
    setModalStates({
      modalYieldLabelQuantity: false,
      modalInvalidQuantity: false,
      modalManufactureDuration: false
    });
  };

  const getDuration = (productId, stock, product, type, newDuration) => {
    axios
      .get(
        `${environments.engine}/rupture?productId=${productId}&originId=${paramsID}&days=8&stock=${stock}`
      )
      .then((response) => {
        const responseItems = response.data;
        setDurationData({
          product,
          data: responseItems,
          type,
          newDuration
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const replaceDayDurationData = (data) => {
    if (data === 'D0') return 'Hoje';
    return data;
  };

  const handleStatusButton = (e, backStep = false) => {
    const { statusButton } = actions;
    const { type, status } = manufacture;

    setCardDate(manufacture.date);

    if (backStep) {
      actions.handleUpdate(manufacture, backStep);
      return;
    }

    if (status === 'WAITING' || status === 'PAUSE') {
      setTimeout(() => {
        actions.handleUpdate(manufacture, 'START');
      }, 100);
    } else {
      setCurrentTransformationCardItems({
        ...manufacture,
        items: manufacture?.items?.map((el) => ({
          ...el,
          quantity: null
        }))
      });
      statusButton.setManufacture(manufacture);
      statusButton.setPopoverData(setPopoverDataStatus(type, status));
      statusButton.handlePopoverClick(e);
      type === 'TRANSFORMATION' && setCheckSumeItems(true);
    }
  };

  const handleDiscardInfo = () => {
    const { statusButton } = actions;
    statusButton.setManufacture(manufacture);
  };

  const handleQuantity = (value) => {
    actions.handleCardQuantity(
      index,
      parseFloat(value?.replace(',', '.')),
      undefined,
      undefined,
      undefined,
      '',
      '',
      '',
      noActionMode ? manufacture : ''
    );
  };

  const handleYield = (value) => {
    actions.handleCardQuantity(
      index,
      manufactureQuantity,
      undefined,
      undefined,
      parseFloat(value.replace(',', '.'))
    );
  };

  const inputDisabledStatus = noActionMode ? false : manufactureStatus !== 'WAITING' && !forceEnable;

  const total = manufacture.items.reduce(
    (total, value) =>
      value
        ? parseFloat(total) + parseFloat(value.quantity)
        : parseFloat(total),
    0
  );
  const cardQuantity = manufacture.quantity || 1;

  return (
    <ModalContent>
      <div className="dFlex pRelative flexColumn" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
        <div className="dFlex w100">
          <div id="draggable-dialog-card-production-dialog" className="cardImageBox" style={{ background: "#FFFFFF", borderRight: `solid 2px ${type === 'RECIPE' ? '#1D8FC9' : '#F2B90D'}` }}>
            <ImageWrapper
              width="135px"
              height="135px"
              url={type === 'RECIPE' 
                ? manufacture?.image || ProductionDefaultImage
                : manufacture?.itemTransformation?.image?.url || manufacture?.itemTransformation?.image || ProductionDefaultImage
              }
              customClass="productionImagePopover"
              placementMain="center"
              placement="center"
            />
          </div>
          
          <div className="cardHeaderMainInfosBox">
            <div
              className="dFlex justifyBetween"
              style={{
                width: '100%',
                padding: '3px 8px 0 0',
                margin: '0',
              }}
            >
              <div id="draggable-dialog-card-production-dialog" className="dFlex typeAndDateBox" style={{ width: "70%" }}>
                <div
                  className="dFlex justifyCenter alignCenter mr8"
                  style={{
                    background: type === 'RECIPE' ? '#086391' : '#AA8208',
                    borderRadius: 20,
                    width: "70%"
                  }}
                >
                  <p
                    style={{
                      fontSize: isPhone ? 12 : 18
                    }}
                    className="mb0 bold white uppercase"
                  >
                    {type === 'RECIPE' ? `RECEITA` : 'TRANSFORMAÇÃO'}
                  </p>
                </div>
                
                <div className="dFlex alignCenter" style={{ margin: "5px 0" }}>
                  <span
                    className="bold"
                    style={{
                      fontSize: isPhone ? 9 : 13,
                      lineHeight: '1'
                    }}
                  >
                    DATA PRODUÇÃO
                  </span>

                  <span
                    className="bold ml10"
                    style={{ fontSize: isPhone ? 12 : 16 }}
                  >
                    {increaseDate(manufacture.date)}
                  </span>
                </div>
              </div>
              
              <div className="dFlex actionsAndHourBox">
                <div className="dFlex justifyEnd" style={{ height: 25 }}>
                  {manufacture?.preparationMethod?.url ? (
                    <Link
                      to={`/preparation/${manufacture?.preparationMethod?.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{
                          width: 25,
                          marginRight: '3px',
                          marginLeft: 15
                        }}
                        src={ListIconBlue}
                        alt="Preparation Method"
                        onClick={() => {
                          handlePreparationClick(
                            manufacture?.preparationMethod?.url
                          )
                        }}
                      />
                    </Link>
                  ) : null}

                  {manufacture.origin === 'M' ? (
                    <Tooltip
                      title={
                        <div style={{ padding: '4px' }}>
                          <p style={{ margin: '0' }}>
                            Produção fora da programação
                          </p>
                        </div>
                      }
                      aria-label="filters"
                      placement="right-start"
                      enterTouchDelay={0}
                    >
                      <img
                        alt={VirtualProduction}
                        src={VirtualProduction}
                        style={{ 
                          width: 25, 
                          height: 25,
                          marginLeft: 15 
                        }}
                      />
                    </Tooltip>
                  ) : manufacture?.engine?.quantity ? (
                    <Tooltip
                      title={
                        <p style={{ padding: '3px 8px', margin: 0 }}>
                          <b>Sugestão Copiloto:</b>
                          <br />
                          <b>Qtde de acordo com a sugestão</b>
                        </p>
                      }
                      aria-label="filters"
                      placement="right-start"
                      enterTouchDelay={0}
                    >
                      <img 
                        alt={EngineIcon} 
                        src={EngineIcon} 
                        style={{ 
                          width: 25, 
                          height: 25,
                          marginLeft: 15 
                        }} 
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <p style={{ padding: '3px 8px', margin: 0 }}>
                          <b>Sugestão Copiloto:</b>
                          <br />
                          <b>Qtde sugerida alterada pelo usuário</b>
                        </p>
                      }
                      aria-label="filters"
                      placement="right-start"
                      enterTouchDelay={0}
                    >
                      <img
                        alt={EngineIconGrey}
                        src={EngineIconGrey}
                        style={{ 
                          width: 25, 
                          height: 25,
                          marginLeft: 15 
                        }}
                      />
                    </Tooltip>
                  )}
                </div>

                <div className="dFlex alignCenter" style={{ margin: "5px 0" }}>
                  <span
                    className="bold"
                    style={{
                      fontSize: isPhone ? 9 : 10,
                      lineHeight: '1'
                    }}
                  >
                    HORÁRIO LIMITE
                  </span>

                  <span
                    className="bold ml10"
                    style={{ fontSize: isPhone ? 12 : 16 }}
                  >
                    {manufacture.limit
                      ? format(new Date(manufacture.limit), 'HH:m')
                      : '-'
                    }
                  </span>
                </div>
              </div>
            </div>

            <div className="header">
              <h5
                className="mb0 bold childTdFontSize16"
                wrap="hard"
                style={{
                  color: '#010311',
                  width: isPhone && '170px',
                  fontSize: isPhone ? '12px' : '16px' 
                }}
              >
                {ValidationLength(description, 70)}
              </h5>
            </div>
          </div>
        </div>
        
        <div
          className="subHeader dFlex justifyBetween pRelative"
          style={{
            padding: '3px 0px 3px 8px',
            background: type === 'TRANSFORMATION' ? '#F2B90D' : '#1D8FC9',
            zIndex: 99
          }}
        >
          <p
            style={{
              fontSize: isPhone ? 12 : 18,
              color: '#FFF'
            }}
            className="mb0 bold uppercase withStroke"
          >
            {type === 'RECIPE' ? `DEFINA A QTDE DE RECEITAS OU RENDIMENTO:` : 'DEFINA A QTDE DO PRODUTO À SER TRANSFORMADO:'}
          </p>

          {manufacture?.origin === 'S' && (
            <img
              src={EngineIconWhite}
              alt={EngineIconWhite}
              style={{ width: '24px', margin: '0 7px 0 auto' }}
            />
          )}
        </div>
      </div>

      <div
        className="pRelative"
        style={{
          marginBottom: '-8px',
          background: "#FFF"
        }}
      >
        <div
          className={`dFlex alignCenter ${type !== 'RECIPE' && 'justifyBetween'}`}
          style={{ padding: `12px 8px 0 8px` }}
        >
          {type === 'RECIPE' ? (
            <div
              className="dFlex alignCenter mlAuto"
              style={{ 
                background: "#1f2445e6",
                padding: '5px 0px 5px 8px',
                height: 50,
              }}
            >
              <span
                onClick={(event) => {
                  setCardProductionMobileInputControl(event.currentTarget)
                  setCurrentCard(manufacture)
                }}
                className="qtde-item pRelative dFlex alignCenter bold justifyCenter"
                style={{
                  height: 34,
                  fontSize: isPhone ? '12px' : '18px',
                  color: '#5062F0',
                  background: '#FFF',
                  padding: '5px 10px',
                  width: 142,
                  borderRadius: 4,
                }}
              >
                {showCardProductionQuantity === 1 ? "RECEITAS" : "RENDIMENTO"}
              </span>
            </div>
          ) : (
            <div
              className="bgWhite dFlex justifyBetween"
              style={{
                height: 55,
                lineHeight: 1.2,
                width: manufacture?.isPreviewCard ? "74%" : "72%"
              }}
            >
              <p
                className="mb0 uppercase bold ml5 childTdFontSize16"
                style={{
                  color: '#010311',
                  fontSize: isPhone ? '12px' : '16px',
                  paddingRight: 60,
                  lineHeight: 1.2
                }}
              >
                {ValidationLength(transformationInfos?.description || '-', 70)}
              </p>
              
              <div style={{ margin: 'auto 10px' }}>
                <ImageWrapper
                  width="40px"
                  height="40px"
                  url={manufacture?.itemTransformation?.image?.url || manufacture?.itemTransformation?.image || ProductionDefaultImage}
                  borderType={manufacture?.itemTransformation?.type}
                  customClass="productionImagePopover"
                  placementMain="center"
                  placement="center"
                />
              </div>
            </div>
          )}

          <CardIngredientsDrillDownHead
            justifyContent="flex-end"
          >
            {/* <span
              className="dFlex alignCenter mr10"
              onClick={() => {
                if (inputDisabledStatus) return;

                setModalStates({
                  ...modalStates,
                  modalYieldLabelQuantity: true
                });
              }}
            >
              <img
                style={{ width: '30px' }}
                src={
                  manufacture?.yieldLabelQuantity ? AddIcon : AddDisabledIcon
                }
                alt="AddIcon"
              />
            </span> */}

            <div
              className="qtde-item dFlex alignCenter white bold uppercase textAlignRight"
              style={{
                height: isPhone ? '41px' : '50px',
                fontSize: isPhone ? '12px' : '16px',
                lineHeight: 1.4,
                background: '#1f2445e6',
                padding: 0,
                width: 197,
              }}
            >
              <span
                style={{ 
                  padding: '5px'
                }}
              >
                QTDE
              </span>
            
              {showCardProductionQuantity === 1 ?
                <span
                  className="qtde-number qtde-card"
                  style={{
                    border: `1px solid ${
                      inputDisabledStatus ? '#9296AC' : '#1f2445e6'
                    }`,
                    zIndex: 999,
                    background: '#FFF',
                    height: 37,
                  }}
                >
                  <InputNumber
                    // onBlur={(e, value) => {
                    //   actions.handleCardQuantity(manufacture.id, manufacture.quantity, index, value, undefined)
                    // }}
                    updateQuantityInitialValue={updateQuantityInitialValue}
                    setModalStates={setModal}
                    modalStates={mainModalStates}
                    setUpdateValue={setUpdateValue}
                    updateValue={updateValue}
                    minimumValue="0"
                    maximumValue="999999999"
                    initialValue={manufactureQuantity?.toFixed(3)}
                    disabled={inputDisabledStatus}
                    handleValue={handleQuantity}
                  />
                </span>
              : 
                <span
                  id="yieldCardField"
                  className="qtde-number yield-qtde-number pRelative alignCenter"
                  style={{ 
                    background: '#FFF',
                    height: 37,
                    margin: '5px 8px 8px 0px'
                  }}
                  onClick={() => {
                    if (!manufacture?.yieldLabelQuantity || inputDisabledStatus)
                      return;

                    setModalStates({
                      ...modalStates,
                      modalYieldLabelQuantity: true
                    });
                  }}
                >
                  <InputNumber
                    updateQuantityInitialValue={updateQuantityInitialValue}
                    setModalStates={setModal}
                    modalStates={mainModalStates}
                    setUpdateValue={setUpdateValue}
                    updateValue={updateValue}
                    minimumValue="0"
                    maximumValue="9999999999999999999999"
                    initialValue={
                      type === 'RECIPE'
                        ? parseFloat(manufacture?.yield?.toFixed(3))
                        : manufactureQuantity?.toFixed(3)
                    }
                    onKeyDown={(event) => {
                      if (
                        (event.keyCode === 9 || event.keyCode === 13) &&
                        items.length
                      ) {
                        setTimeout(() => {
                          document.querySelector(`#manufacture-0`).select();
                        }, 100);
                      }
                    }}
                    disabled={
                      inputDisabledStatus || manufacture?.yieldLabelQuantity
                    }
                    handleValue={(value) => {
                      type === 'RECIPE'
                        ? actions.handleCardQuantity(
                            index,
                            manufacture.quantity || 1,
                            undefined,
                            undefined,
                            parseFloat(value),
                            '',
                            '',
                            '',
                            noActionMode ? manufacture : ''
                          )
                        : actions.handleCardQuantity(
                            index,
                            parseFloat(value),
                            undefined,
                            undefined,
                            undefined,
                            '',
                            '',
                            '',
                            noActionMode ? manufacture : ''
                          );
                    }}
                  />

                  <span
                    className="pAbsolute bold"
                    style={{
                      color: '#010311',
                      right: 12,
                      fontSize: isPhone ? 12 : 16
                    }}
                  >
                    {manufacture.un.abbreviation}
                  </span>
                </span>
              }
            </div>
          </CardIngredientsDrillDownHead>

          {/* <div
            className="item-arrow pAbsolute"
            style={{ right: 5 }}
            onClick={() => setOpenDrillDown(!openDrillDown)}
          >
            <ArrowDown
              openDrillDown={openDrillDown}
              src={selectorInputArrow}
              id="CardIngredientInputArrow"
            />
          </div> */}
        </div>

        <CardInfoHeaderDrillDownContent openDown={openDrillDown}>
          <CardInfoHeader
            className="card-header-modal w100 pt0"
            style={{ background: type === 'RECIPE' ? '#D9EAF2' : '#FFF2CC' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                lineHeight: 1.4
              }}
            >
              <div className="dFlex">
                <CardInfoContent style={{ marginRight: '20%', width: '70%' }}>
                  <CardContentTitle
                    style={{
                      fontSize: isPhone ? '9px' : '14px',
                      lineHeight: '1.4',
                      color: '#454A67',
                      textAlign: 'end'
                    }}
                  >
                    ESTOQUE PRODUTO
                  </CardContentTitle>

                  <CardContentElement style={{ justifyContent: 'flex-end' }}>
                    <span
                      style={{
                        fontSize: isPhone ? '12px' : '22px',
                        lineHeight: '1.4',
                        fontWeight: 'bold'
                      }}
                    >
                      {numberToReal(manufactureStock)}
                      {manufacture.un.abbreviation}
                    </span>
                  </CardContentElement>
                </CardInfoContent>

                <CardInfoContent style={{ width: '20%' }}>
                  <CardContentTitle
                    style={{
                      fontSize: isPhone ? '9px' : '14px',
                      lineHeight: '1.4',
                      color: '#454A67'
                    }}
                  >
                    DURAÇÃO
                  </CardContentTitle>

                  <CardContentElement
                    onClick={(e) => {
                      if (manufactureDuration) {
                        getDuration(
                          manufacture.productId ? manufacture.productId : null,
                          manufacture.stock,
                          manufacture
                        );
                        setModalStates({
                          ...modalStates,
                          modalManufactureDuration: true
                        });
                      }
                    }}
                  >
                    <span className="special-date-result">
                      <span>{manufactureDuration || '-'}</span>

                      {!!manufactureDuration &&
                        (manufactureDuration > 1 || manufactureDuration === 0
                          ? 'dias'
                          : 'dia')}
                    </span>
                  </CardContentElement>
                </CardInfoContent>
              </div>

              <div
                className="dFlex justifyEnd"
                style={{ width: '60%', paddingRight: '30px' }}
              >
                <CardInfoContent>
                  <CardContentTitle
                    style={{
                      fontSize: isPhone ? '9px' : '14px',
                      lineHeight: '1.4',
                      color: '#454A67'
                    }}
                  >
                    NOVA DUR.
                  </CardContentTitle>

                  <CardContentElement
                    onClick={(e) => {
                      if (!newDuration || newDuration === '-') return;

                      if (type === 'RECIPE') {
                        getDuration(
                          manufacture.productId ? manufacture.productId : null,
                          manufacture.stock + manufacture.manufactured,
                          manufacture,
                          'recipe',
                          true
                        );
                      } else {
                        getDuration(
                          manufacture.productId ? manufacture.productId : null,
                          manufacture.stock - manufacture.quantity,
                          manufacture,
                          '',
                          true
                        );
                      }
                      setModalStates({
                        ...modalStates,
                        modalManufactureDuration: true
                      });
                    }}
                  >
                    <span className="special-date-result">
                      {newDuration || '-'}
                    </span>
                  </CardContentElement>
                </CardInfoContent>
              </div>
            </div>
          </CardInfoHeader>
        </CardInfoHeaderDrillDownContent>
        
        <p
          className="mb0 dFlex justifyEnd w100 bold"
          style={{
            color: type === 'RECIPE' ? '#004466' : '#664C00',
            fontSize: 16,
            padding: '8px'
          }}
        >
          {type === 'RECIPE'
            ? `${manufactureQuantity?.toLocaleString('pt-br', { minimumFractionDigits: 3 })} 
              ${manufactureQuantity === 1 ? 'RECEITA' : 'RECEITAS'} = 
              ${parseFloat(manufacture?.yield).toLocaleString('pt-br', { minimumFractionDigits: 3 })}
              ${manufacture.un.abbreviation}`
            : `${manufacture.loss.toLocaleString('pt-br', { minimumFractionDigits: 2 })}% = ${(
                1 -
                (1 - (manufacture.loss * (manufacture.quantity || 1)) / 100)
              )?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
              ${
                manufacture?.items?.[0]?.un
                  ? manufacture?.items?.[0]?.un?.abbreviation
                  : ''
              }`
          }
        </p>
      </div>

      <CardIngredientsContainer>
        <CardsIngredientsWrapper open maxHeight={noActionMode && "255px"}>
          <IngredientLossWrapper>
            <IngredientCount
              className="withStroke"
              background={type === 'TRANSFORMATION' ? '#F2B90D' : '#1D8FC9'}
            >
              {items.length > 1 || items.length === 0
                ? type === 'TRANSFORMATION'
                  ? `TRANSFORMAR EM ${items.length} PRODUTOS:`
                  : 'PRODUTOS DESTA RECEITA:'
                : type === 'TRANSFORMATION'
                ? `TRANSFORMAR EM ${items.length} PRODUTO:`
                : 'PRODUTO DESTA RECEITA:'
              }
            </IngredientCount>

            <LossCount
              background={type === 'TRANSFORMATION' ? '#F2B90D' : '#1D8FC9'}
            >
              {/* {type === "TRANSFORMATION" &&
                  <>
                    PERDA FICHA:
                    <p
                      className="uppercase mb0"
                      style={{
                        marginLeft: 5,
                        color: "#D0D3E2",
                      }}
                    >
                      {(1 - (1 * (manufacture.loss / 100)))?.toFixed(3)}
                      {manufacture?.items?.[0]?.un ? manufacture?.items?.[0]?.un?.abbreviation : ""}
                    </p>

                    <p
                      className="uppercase mb0 bold dBlock"
                      style={{
                        marginRight: isPhone ? 0 : 30,
                        marginLeft: 'auto',
                        color: cardLostTotal?.toFixed(3) > ((1 - (1 * (manufacture.loss / 100)))?.toFixed(3))
                          ? '#F76E79'
                          : '#8CF295'
                      }}
                    >
                      {produced ?
                        <>
                          {cardLostTotal?.toFixed(3)}
                          {manufacture?.items?.[0]?.un ? manufacture?.items?.[0]?.un?.abbreviation : ""}
                        </>
                      : null}
                    </p>
                  </>
                } */}

              {/* {productionCardStatus[manufactureStatus].statusText === 'Finalizado' ? total === 0 ? (<>0%</>) : (<>{loss}</>) : null}
                {productionCardStatus[manufactureStatus].statusText === 'Finalizado' ? '/' : null}
                {productionCardStatus[manufactureStatus].statusText === 'Finalizado' ? produced : null} */}
            </LossCount>
          </IngredientLossWrapper>

          {items.map((item, itemIndex) => (
            <CardIngredients
              handleItemDiscard={handleItemDiscard}
              updateQuantityInitialValue={updateQuantityInitialValue}
              setProductDialogModalStates={setModal}
              mainModalStates={mainModalStates}
              manufactures={manufactures}
              setManufactures={setManufactures}
              totalItems={items}
              durationData={durationData}
              setDurationData={setDurationData}
              content={item}
              itemss={item}
              manufacture={manufacture}
              optionsDiscard={optionsDiscard}
              manufactureStatus={manufactureStatus}
              handleDiscardInfo={handleDiscardInfo}
              handleCardQuantity={actions?.handleCardQuantity}
              cardIndex={index}
              itemIndex={itemIndex}
              newProductDuration={newDuration}
              noActionMode={noActionMode}
            />
          ))}
        </CardsIngredientsWrapper>
      </CardIngredientsContainer>

      {/* {manufacture.type === "TRANSFORMATION" ?
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "14px", marginBottom: "12px", padding: "0px 8px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#fff", fontSize: isPhone ? "9px" : "14px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px" }}>/</h1>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "12px" : "22px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px" }}>Perda</h1>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "9px" : "14px", fontFamily: 'Lato', fontWeight: "bold", marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>ESPERADA</h1>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "12px" : "22px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>{manufacture.loss ? parseFloat(manufacture.loss).toLocaleString('pt-br', { minimumFractionDigits: 3 }) : "0,000"}%</h1>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "9px" : "14px", fontFamily: 'Lato', fontWeight: "bold", marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>QTDE.</h1>
              <h1 style={{ color: cardQuantity && total ? parseFloat(cardQuantity) - parseFloat(cardYield) < cardLostTotal ? '#F76E79' : '#454A67' : '#454A67', fontSize: isPhone ? "12px" : "22px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px", textAlign: "end", textTransform: "uppercase" }}>
                {cardQuantity && total ? (cardLostTotal).toLocaleString('pt-br', { minimumFractionDigits: 3 }) : (parseFloat(cardQuantity) - parseFloat(cardYield)).toLocaleString('pt-br', { minimumFractionDigits: 3 })}{manufacture?.items?.[0]?.un ? manufacture?.items?.[0]?.un?.abbreviation : ""}
              </h1>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "9px" : "14px", fontFamily: 'Lato', fontWeight: "bold", marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>PERDA X PRODUÇÂO</h1>
              <h1 style={{ color: parseFloat(100 * cardLostTotal) / parseFloat(cardQuantity) > parseFloat(loss) ? '#F76E79' : '#454A67', fontSize: isPhone ? "12px" : "22px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>
              {total === 0 ? (
                <>
                  &nbsp;
                </>
              ) : (
                <>
                  {(parseFloat(100 * cardLostTotal) / parseFloat(cardQuantity)).toLocaleString('pt-br', { minimumFractionDigits: 3 }) + '%'}
                </>
              )}
              </h1>
            </div>
          </div>
        : null} */}

      {!noActionMode ?
        <div
          className="dFlex justifyBetween alignCenter iconButtonFullContainer pRelative"
          onClick={(e) => {
            if (
              manufacture.status === 'START' ||
              manufacture.status === 'FINISH' ||
              manufacture.status === 'CANCELED'
            )
              return;
              
            handleStatusButton(e);
          }}
          style={{
            padding: '8px',
            background:
              productionCardStatus[manufactureStatus]?.labelBackGroundColor,
            margin: '0 8px 8px',
            borderRadius: 34,
            overflow: 'hidden'
          }}
        >
          {manufacture.status === 'START' && !noActionMode && (
            <div
              className="dFlex alignCenter flexColumn justifyCenter"
              style={{
                zIndex: 9999,
                right: 0,
                top: 15,
                borderRadius: '12px 0px 0px 12px',
                background: '#454A67',
                height: 70,
                marginLeft: '-8px',
                paddingLeft: 8,
                marginRight: 8
              }}
              onClick={(e) => {
                if (
                  manufacture.status === 'FINISH' ||
                  manufacture.status === 'CANCELED'
                )
                  return;
                handleStatusButton(e, 'WAITING');
              }}
            >
              <div
                className="dFlex alignCenter justifyCenter "
              >
                <img
                  style={{ width: 18 }}
                  className="cursorPointer"
                  src={RefreshWhiteIcon}
                  alt="RefreshIcon"
                />
              </div>

              <p
                className="mb0 uppercase dFlex alignCenter justifyCenter"
                style={{
                  marginTop: 4,
                  fontSize: 12,
                  padding: '0 10px 0 8px',
                  color: '#FFF',
                }}
              >
                Voltar
              </p>
            </div>
          )}

          <div
            className="iconButtonFull dFlex alignCenter justifyCenter"
            onClick={(e) => {
              if (
                manufacture.status !== 'START' ||
                manufacture.status === 'FINISH' ||
                manufacture.status === 'CANCELED'
              )
                return;

              handleStatusButton(e);
            }}
            style={{
              background:
                productionCardStatus[manufactureStatus]?.borderBackgroundColor,
            }}
          >
            {cardIconByStatus(manufacture?.status)}
          </div>

          <div
            className="dFlex alignStart justifyStart ml8"
            style={{ width: '91%', height: 70 }}
            onClick={(e) => {
              if (
                manufacture.status !== 'START' ||
                manufacture.status === 'FINISH' ||
                manufacture.status === 'CANCELED'
              )
                return;

              handleStatusButton(e);
            }}
          >
            <p
              className="bold mb0 dFlex flexColumn mt10"
              style={{
                color:
                  productionCardStatus[manufactureStatus]?.labelStatusDialogColor,
                marginRight: 10,
                fontSize: 16
              }}
            >
              <span className="uppercase dFlex">
                {`${productionCardStatus[manufactureStatus]?.statusText} `}

                <span className="lowercase ml2">
                  {manufacture.status === 'PAUSE' && ' - Clique para reiniciar'}
                </span>

                <span className="lowercase ml2">
                  {manufacture.status === 'START' && ' - Clique para finalizar'}
                </span>

                <span className="lowercase mt2">
                  {manufacture?.cancellationReason ? (
                    <span
                      className="dFlex bold uppercase"
                      style={{ margin: '0px 0 0 3px' }}
                    >
                      {ValidationLength(`- ${manufacture?.cancellationReason}`, 20)}
                    </span>
                  ) : null}
                </span>
              </span>

              <span style={{ color: productionCardStatus[manufactureStatus]?.labelStatusDialogColor }}>
                {dateStatus && (
                  <>
                    {manufacture.status === 'START' && 'INICIADO'} às {format(new Date(dateStatus?.date), 'kk:mm')}{' '}
                    {dateStatus?.user && `(${dateStatus?.user})`}
                  </>
                )}
              </span>
            </p>
          </div>

          {isFinished ?
            <div className={`finishedTimeCircle ${diffHours || diffMinutes ? 'positiveTime' : 'noTime'}`}>
              <span>
                {`
                  ${diffHours > 0 ? diffHours : ''}
                  ${diffHours > 0 && diffMinutes > 0 ? ':' : ''}
                  ${diffMinutes > 0 
                    ? diffMinutes < 10
                      ? `0${diffMinutes}`
                      : diffMinutes
                    : '0'
                  }
                `}
              </span>

              <span className="mt0">
                {diffHours > 0 
                ? diffHours === 1 
                  ? 'h'
                  : 'hs'
                : 'min'
                }
              </span>
            </div>
          : null}
        </div>
      : null}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalManufactureDuration}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal w100"
        transitionDuration={0}
      >
        <DialogTitle className="modalHeader navyBlue bold textAlignCenter cursorMove">
          <Typography
            id="draggable-dialog-title"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '250px',
              marginRight: '30px'
            }}
          >
            {durationData.product.description || durationData.product.item} -
            Estoque atual
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '8px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          <div
            width="100%"
            cellPadding="3"
            cellSpacing="0"
            border="0"
            className="dFlex flexRow justifyBetween"
            style={{
              boxShadow: 'none'
            }}
          >
            <div className="dFlex flexColumn justifyBetween w100">
              <div
                width="60"
                className="subtitle center white"
                style={{
                  background: '#7583F0',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  padding: '12px'
                }}
              >
                Atual
              </div>

              {durationData.data.days && durationData.data.days.length
                ? durationData.data.days.map((day) => (
                    <div
                      width="60"
                      className="subtitle p12 left dFlex justifyCenter alignCenter mb8"
                    >
                      {replaceDayDurationData(day.numberDay)}
                    </div>
                  ))
                : null}
            </div>

            <div>
              <div
                className="p0"
                colSpan={
                  durationData.data.days && durationData.data.days.length
                    ? durationData.data.days.length + 1
                    : 0
                }
              >
                <hr className="m0" />
              </div>
            </div>

            <div className="dFlex flexColumn justifyBetween w100">
              <div
                className="description right white dFlex alignCenter justifyCenter"
                style={{
                  background: '#7583F0',
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  padding: '12px'
                }}
              >
                <b className="dBlock textAlignCenter" style={{ width: 'auto' }}>
                  {durationData.type === 'recipe'
                    ? durationData.newDuration
                      ? parseFloat(
                          durationData.product.stock +
                            durationData.product.manufactured
                        ).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                      : durationData.product.stock
                      ? parseFloat(durationData.product.stock).toLocaleString(
                          'pt-br',
                          { minimumFractionDigits: 3 }
                        )
                      : '0,000'
                    : durationData.newDuration
                    ? durationData.type === 'transformationItem'
                      ? parseFloat(
                          (durationData.product.stock || 0) +
                            durationData.product.quantity
                        ).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                      : parseFloat(
                          durationData.product.stock -
                            durationData.product.quantity
                        ).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                    : durationData.product.stock
                    ? parseFloat(durationData.product.stock).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3 }
                      )
                    : '0,000'}
                </b>

                {durationData.product.un &&
                  durationData.product.un.abbreviation}
              </div>

              {durationData.data.days && durationData.data.days.length
                ? durationData.data.days.map((day) => (
                    <div
                      width="60"
                      className="subtitle p12 right dFlex alignCenter justifyCenter"
                    >
                      <span>
                        {day.quantity
                          ? day.quantity.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          : '0,00'}
                      </span>

                      {durationData.product.un.abbreviation}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Popover
        open={productionInputControlPopoverOpen}
        anchorEl={cardProductionMobileInputControl}
        onClose={() => {
          setCardProductionMobileInputControl(null);
          setCurrentCard('')
        }}
        className="mobileProductionCurrentInputPopover"
        PaperProps={{
          style: {
            padding: '10px',
            borderRadius: '4px',
            maxWidth: '400px'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className="dFlex flexColumn">
          <div className="cardProductionMainInfos">
            <span className="bold">
              {description}
            </span>

            <span>
              1 Receita rende {parseFloat(income?.replace(',', '.'))} {manufacture.un.abbreviation}
            </span>
          </div>

          <div className="cardProductionActionButtons">
            <div className="dFlex justifyEnd mb10">
              <Button
                className={`defaultButton ${showCardProductionQuantity === 1 ? 'backButton' : 'submitButton' }`}
                design="outlined"
                onClick={() => {
                  setShowCardProductionQuantity(2)
                  handleUpdateViewMethod(2)
                }}
                label="RENDIMENTO"
                color="primary"
              />

              <Button
                className={`defaultButton ${showCardProductionQuantity === 1 ? 'submitButton' : 'backButton' }`}
                design="contained"
                label="RECEITAS"
                onClick={() => {
                  setShowCardProductionQuantity(1)
                  handleUpdateViewMethod(1)
                }}
                color="primary"
                autoFocus
              />
            </div>

            <span className="dFlex justifyEnd lightBlue bold">
              {`${manufactureQuantity?.toLocaleString('pt-br', { minimumFractionDigits: 3 })} 
              ${manufactureQuantity === 1 ? 'RECEITA' : 'RECEITAS'} = 
              ${parseFloat(manufacture?.yield)?.toLocaleString('pt-br', { minimumFractionDigits: 3 })} 
              ${manufacture.un.abbreviation}`}
            </span>
          </div>
        </div>
      </Popover>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalYieldLabelQuantity}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal sumeValuesProductionDialog"
        transitionDuration={0}
        style={{ width: '100%' }}
      >
        <DialogTitle className="modalHeader navyBlue cursorMove bold">
          <Typography style={{ fontSize: 20, width: 300 }}>
            Soma de rendimento
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '12px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>
        
        <DialogContent className="modalContent">
          <FormControl fullWidth>
            <CurrencyTextFieldSumeValues
              id="sumeValuesDialogField"
              key="sumeValuesDialogField"
              name="Quantity"
              placeholder="Digite aqui..."
              onBlur={(value) => {
                const sumeValue = value
                  .split('+')
                  .map((item) => item?.replace(',', '.'))
                  .reduce(
                    (total, value) =>
                      value ? parseFloat(value) + parseFloat(total) : total + 0,
                    0
                  );

                if (value && sumeValue > 0) {
                  type === 'RECIPE'
                    ? actions.handleCardQuantity(
                        index,
                        manufacture.quantity || 1,
                        undefined,
                        undefined,
                        value
                          .split('+')
                          .map((item) => item?.replace(',', '.'))
                          .reduce(
                            (total, value) =>
                              value
                                ? parseFloat(value) + parseFloat(total)
                                : total + 0,
                            0
                          ),
                        false,
                        '',
                        value,
                        noActionMode ? manufacture : ''
                      )
                    : actions.handleCardQuantity(
                        index,
                        value
                          .split('+')
                          .map((item) => item?.replace(',', '.'))
                          .reduce(
                            (total, value) =>
                              value
                                ? parseFloat(value) + parseFloat(total)
                                : total + 0,
                            0
                          ),
                        undefined,
                        undefined,
                        undefined,
                        false,
                        '',
                        value,
                        noActionMode ? manufacture : ''
                      );
                } else {
                  setModal({
                    ...mainModalStates,
                    modalInvalidQuantity: true
                  });

                  setManufactures(
                    manufactures.map((manufacture) => ({
                      ...manufacture
                    }))
                  );
                }
              }}
              onFocus={() => {
                setTimeout(() => {
                  document
                    .querySelector(`.sumeValuesProductionDialog input`)
                    .select();
                }, 100);
              }}
              defaultValue={manufacture?.yieldLabelQuantity}
              updateQuantityInitialValue={updateQuantityInitialValue}
              setModalStates={setModal}
              modalStates={mainModalStates}
            />
          </FormControl>
        </DialogContent>
      </Dialog>
    </ModalContent>
  );
}

export default MobileCardDetail;
