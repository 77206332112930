/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useRef } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { HelperCount } from 'components/Dialogs/HelperDialogs/counts';
import Graphic from 'images/icons/arrows/arrowGoalWhiteOutline.svg';
import Adjustments from 'images/icons/configsAndSetups/Adjustments.svg';
import Paper from 'images/icons/files/Paper.svg';
import Bell from 'images/icons/objects/bells/Bell.svg';
import OrderIcon from 'images/icons/objects/bells/productionWhiteOutline.svg';
import Book from 'images/icons/objects/books/Book.svg';
import Box from 'images/icons/objects/boxesAndPackages/Box.svg';
import Notation from 'images/icons/objects/clipboards/Notation.svg';
import QuotationMenuIcon from 'images/icons/objects/currencies/quotations.svg';
import Home from 'images/icons/objects/houses/Home.svg';
import MiseEnPlaceIcon from 'images/icons/objects/pots/cookingPanWhiteOutline.svg';
import Interrogation from 'images/icons/signals/questionMarks/interrogation.svg'
import Clientes from 'images/menu-items/clients.svg';
import history from 'utils/history';

import HoverItems from './HoverItems/HoverItems';

import './styles.scss';

function NewSideBarMenu({
  userInfo,
  signOut,
  confirmModal,
  setConfirmModal,
  storedQuotationProductsSelected,
  isQuotationPage
}) {
  const items = JSON.parse(localStorage.getItem('token'));
  const { id: paramsID } = useParams();
  const [openHover, setOpenHover] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [topDistance, setTopDistance] = useState(0);
  const [currentRole, setCurrentRole] = useState({});
  const [currentRoleHovered, setCurrentRoleHovered] = useState();
  const [restaurantId, setRestaurantId] = useState();
  const selectedRouteName = items?.data?.roles
    .flat()
    .find(
      (role) =>
        role.subs?.find(
          (sub) => `/${sub.url}/${restaurantId}` === history.location.pathname
        ) ||
        (role.url === '/'
          ? `/${restaurantId}`
          : `/${role?.url}/${restaurantId}`) === history.location.pathname
    );

  const [newItem = null] = items.data.roles;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (!Array.isArray(newItem)) {
      signOut();
    }
  }, [items.data.roles, newItem, signOut]);

  useEffect(() => {
    if (paramsID !== undefined) {
      setRestaurantId(paramsID);
    } else {
      setRestaurantId(userInfo.companiesActive.id);
    }
  }, [paramsID, userInfo.companiesActive.id]);

  const rolesImages = {
    home: {
      src: Home,
      active: Home
    },
    notifications: {
      src: Bell,
      active: Bell
    },
    indicators: {
      src: Graphic,
      active: Graphic
    },
    catalog: {
      src: Paper,
      active: Paper
    },
    menu: {
      src: Book,
      active: Book
    },
    stocks: {
      src: Box,
      active: Box
    },
    productions: {
      src: OrderIcon,
      active: OrderIcon
    },
    orders: {
      src: Notation,
      active: Notation
    },
    miseEnPlace: {
      src: MiseEnPlaceIcon,
      active: MiseEnPlaceIcon
    },
    adminRestaurant: {
      src: Adjustments,
      active: Adjustments
    },
    adminPlataform: {
      src: Adjustments,
      active: Adjustments
    },
    providersCatalog: {
      src: Paper,
      active: Paper
    },
    providersClient: {
      src: Clientes,
      active: Clientes
    },
    quotation: {
      src: QuotationMenuIcon,
      active: QuotationMenuIcon
    },
    cataloguing: {
      src: Adjustments,
      active: Adjustments
    }
  };

  const getDropDownContent = useRef(null);
  const getMenuButtonContent = useRef(null);

  const handleOpenMouseHover = (role, event, id) => {
    setCurrentRole({ ...role });

    const { clientY } = event;

    setTimeout(() => {
      const currentElement = document.getElementById(id);
      const currentElementButtonRect = currentElement.getBoundingClientRect();

      const screenHeight = window.innerHeight;
      const currentElementSize = currentElementButtonRect.bottom;

      if (currentElementSize + 100 > screenHeight) {
        setTopDistance(`${currentElementButtonRect.top - 270}px`);
        setOpenHover(true);
        return;
      }

      if (clientY >= 533) {
        setTopDistance(`${currentElementButtonRect.top - 250}px`);
        setOpenHover(true);
        return;
      }

      setTopDistance(`${currentElementButtonRect.top - 75}px`);
      setOpenHover(true);
    }, 150);
  };

  const handleOnMouseLeaveSideBar = () => {
    if (openHover) {
      setOpenHover(false);
    }
  };

  return (
    <div className="newSideBarWrapper" onMouseLeave={handleOnMouseLeaveSideBar}>
      <div className="newSideBar">
        <div
          className="dropdown"
          style={{ display: openHover ? 'block' : 'none' }}
        >
          <div
            className="dropdown-wrapper"
            onMouseLeave={handleOnMouseLeaveSideBar}
          >
            <div style={{ top: topDistance }} className="dropdown-content">
              <HoverItems
                restaurantId={restaurantId}
                setOpenHover={handleOnMouseLeaveSideBar}
                currentRole={currentRole}
                reference={getDropDownContent}
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
                storedQuotationProductsSelected={
                  storedQuotationProductsSelected
                }
                isQuotationPage={isQuotationPage}
              />
            </div>
          </div>
        </div>

        {Array.isArray(newItem) &&
          items?.data?.roles?.map((dataList, index) => (
            <div className="iconsGroup01">
              {dataList?.map((role, roleIndex) => (
                <div
                  style={{
                    margin: '4px 0',
                    width: '100%',
                    height: '100%'
                  }}
                  className={`${selectedRouteName?.name === role?.name ? 'selectedIcon' : ''
                    } ${role?.name}`}
                  onMouseEnter={(event) => {
                    handleOpenMouseHover(
                      role,
                      event,
                      `dataItemMenu-${index}${roleIndex}`
                    )
                  }}
                  ref={getMenuButtonContent}
                  id={`dataItemMenu-${index}${roleIndex}`}
                >
                  {role.url ? (
                    <button
                      type="button"
                      className="noStyle"
                      style={{
                        color: 'white',
                        width: '40px',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      onMouseEnter={() => {
                        setCurrentRoleHovered(role?.name)
                      }}
                      onClick={(event) => {
                        handleOpenMouseHover(
                          role,
                          event,
                          `dataItemMenu-${index}${roleIndex}`
                        );
                      }}
                    >
                      <img 
                        alt="" 
                        src={selectedRouteName?.name === role?.name
                          ? rolesImages[role.name]?.active 
                          : rolesImages[role.name]?.src || ''
                        } 
                      />
                    </button>
                  ) : (
                    <img alt="" src={rolesImages[role.name]?.src || ''} />
                  )}
                </div>
              ))}
            </div>
          ))}

        {window.location.pathname?.includes('contagens') | window.location.pathname?.includes('contagem') ? (
          <>
            <div className="iconsGroup01" style={{ backgroundColor: "#f2b80d" }}>
              <div
                style={{
                  // margin: '4px 0',
                  width: '100%',
                  height: '55px',
                  backgroundColor: "#f2b80d"
                }}
                onClick={() => setOpenHelper(true)}
              >
                <button
                  type="button"
                  className="noStyle"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => setOpenHelper(true)}
                  style={{
                    color: 'white',
                    width: isHovered ? '200px' : '40px',
                    height: isHovered && '55px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "#f2b80d",
                    marginLeft: isHovered ? '142px' : '-3px',
                    borderRadius: isHovered && '0px 12px 12px 0px',
                    zIndex: isHovered && 99
                  }}
                >
                  <img alt="" src={rolesImages.stocks.src} />
                  <img src={Interrogation} style={{ marginTop: '20px', marginLeft: '3px' }} />
                  {isHovered && (
                    <>
                      <div style={{
                        width: '5px',
                        backgroundColor: '#000',
                        margin: '0 2px',
                      }} />
                      <p style={{ marginTop: 13 }}>Entenda como contar!</p>
                    </>

                  )}
                </button>
              </div>
            </div>
            <HelperCount
              openModal={openHelper}
              onClose={() => setOpenHelper(false)}
            />
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(NewSideBarMenu);
