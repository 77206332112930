/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { isValid } from 'date-fns';
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';

import RotateLeftIcon from 'images/icons/arrows/RotateLeftIcon.svg';

import { DateRangeContainer, IconRotateLeft } from './index.style';

function DateRangeField({
  handleFilter,
  filterNameStart,
  filterNameFinal,
  size,
  inputSizes,
  initialDate,
  endDate,
  minDate,
  maxDate,
  labelFirstName,
  labelSecondName,
  views,
  dateFormat,
  shouldCleanFilter,
  hideClearButton,
  customMinStartDate = '',
  marginRight,
  shouldDisableStartDate,
  shouldDisableEndDate,
  customHandleStartDate,
  onMonthStartDateChange = false,
  onMonthEndDateChange = false,
}) {
  const minDateValue = minDate === true ? '1900-01-01' : minDate;
  const maxDateValue = maxDate === true ? '2100-12-31' : maxDate;

  const [startDate, setStartDate] = useState(initialDate);
  const [finalDate, setFinalDate] = useState(endDate);
  const [minStartDateOption, setMinStartDateOption] = useState();
  const [minDateOption, setMinDateOption] = useState(minDateValue);
  const [maxDateOption, setMaxDateOption] = useState(maxDateValue);

  const handleStartDateChange = (date) => {
    if (!isValid(date)) return;

    setStartDate(date);

    if (minDate) {
      setMinDateOption(minDateValue);
    }

    if (customHandleStartDate) {
      customHandleStartDate(date, finalDate, filterNameStart, filterNameFinal, shouldDisableStartDate, setFinalDate)

      return
    }

    if (new Date(finalDate) < date) {
      if (dateFormat === 'MM/yyyy') {
        const newDate = moment(date).startOf('month').format('YYYY-MM-DD');
        handleFilter([
          [filterNameStart, newDate],
          [filterNameFinal, newDate]
        ]);
      } else {
        handleFilter([
          [filterNameStart, format(date, 'yyyy-MM-dd')],
          [filterNameFinal, format(date, 'yyyy-MM-dd')]
        ]);
      }
      setFinalDate(date);
      return;
    }

    if (dateFormat === 'MM/yyyy') {
      const newDate = moment(date).startOf('month').format('YYYY-MM-DD');
      handleFilter([[filterNameStart, newDate]]);
    } else {
      handleFilter([[filterNameStart, format(date, 'yyyy-MM-dd')]]);
    }
  };

  const handleMinStartDateOption = (date) => {
    if (customMinStartDate?.type === 'subtract') {
      setMinStartDateOption(moment(date).subtract(customMinStartDate?.quantity, customMinStartDate?.period))
    }

    if (customMinStartDate?.type === 'add') {
      setMinStartDateOption(moment(date).add(customMinStartDate?.quantity, customMinStartDate?.period))
    }
  }

  const handleFinalDateChange = (date) => {
    if (!isValid(date)) return;

    setFinalDate(date);

    if (maxDate) {
      setMaxDateOption(maxDateValue);
    }

    if (customMinStartDate) {
      handleMinStartDateOption(date)
    }

    if (date < new Date(startDate)) {
      if (dateFormat === 'MM/yyyy') {
        const newDate = moment(date).startOf('month').format('YYYY-MM-DD');
        handleFilter([
          [filterNameStart, newDate],
          [filterNameFinal, newDate]
        ]);
      } else {
        handleFilter([
          [filterNameStart, format(date, 'yyyy-MM-dd')],
          [filterNameFinal, format(date, 'yyyy-MM-dd')]
        ]);
      }

      setStartDate(date);
      return;
    }

    if (dateFormat === 'MM/yyyy') {
      const newDate = moment(date).endOf('month').format('YYYY-MM-DD');
      handleFilter([[filterNameFinal, newDate]]);
    } else {
      handleFilter([[filterNameFinal, format(date, 'yyyy-MM-dd')]]);
    }
  };

  const clearFilter = () => {
    setStartDate(initialDate);
    setFinalDate(endDate);
    setMinDateOption(minDateValue);
    setMaxDateOption(maxDateValue);
    handleFilter([
      [filterNameStart, initialDate ? moment(initialDate).format('YYYY-MM-DD') : ''],
      [filterNameFinal, endDate ? moment(endDate).format('YYYY-MM-DD') : '']
    ]);
  };

  useEffect(() => {
    if (shouldCleanFilter) {
      setStartDate(initialDate || '');
      setFinalDate(endDate || '');
    }
  }, [endDate, initialDate, shouldCleanFilter]);

  useEffect(() => {
    if (onMonthStartDateChange) {
      setStartDate(initialDate || '');
      setFinalDate(endDate || '');
    }
  }, [initialDate, endDate])

  return (
    <DateRangeContainer marginRight={marginRight}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <KeyboardDatePicker
          margin="normal"
          id="dataInicio"
          variant="inline"
          autoOk
          label={labelFirstName || 'Inicio'}
          style={{ width: inputSizes[size] }}
          views={views}
          format={dateFormat || 'dd/MM'}
          value={startDate || null}
          minDate={minStartDateOption || minDateOption}
          maxDate={maxDateOption}
          placeholder="Selecione"
          maxDateMessage="A data inicial deve ser menor que a data final"
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          InputLabelProps={{
            shrink: true
          }}
          onMonthChange={(value) => {
            const startOfMonth = moment(value).startOf('month').format('YYYY-MM-DD');
            const endOfMonth   = moment(value).endOf('month').format('YYYY-MM-DD');

            onMonthStartDateChange && onMonthStartDateChange(startOfMonth, endOfMonth)
          }}
          shouldDisableDate={(date) => (
            shouldDisableStartDate ? !shouldDisableStartDate?.includes(moment(date)?.format('D')) : !!onMonthStartDateChange
          )}
        />

        <span style={{ marginRight: '6px', marginLeft: '6px' }} />

        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          variant="inline"
          autoOk
          label={labelSecondName || 'Fim'}
          style={{ width: inputSizes[size] }}
          views={views}
          format={dateFormat || 'dd/MM'}
          value={finalDate || null}
          minDate={minDateOption}
          maxDate={maxDateOption}
          placeholder="Selecione"
          minDateMessage="A data final deve ser maior que a data inicial"
          onChange={handleFinalDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          InputLabelProps={{
            shrink: true
          }}
          onMonthChange={(value) => {
            const startOfMonth = moment(value).startOf('month').format('YYYY-MM-DD');
            const endOfMonth   = moment(value).endOf('month').format('YYYY-MM-DD');

            onMonthEndDateChange && onMonthEndDateChange(startOfMonth, endOfMonth)
          }}
          shouldDisableDate={(date) => (
            shouldDisableEndDate ? !shouldDisableEndDate?.includes(moment(date)?.format('D')) : !!onMonthStartDateChange
          )}
        />
      </MuiPickersUtilsProvider>

      {!hideClearButton && (
        <IconRotateLeft
          src={RotateLeftIcon}
          alt="Back to default"
          onClick={clearFilter}
        />
      )}
    </DateRangeContainer>
  );
}

export default DateRangeField;
