/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useRef, useMemo } from 'react';


import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Cancel,
  CheckCircleOutline,
  CheckCircleSharp,
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import axios from 'axios';
import { addHours, format, getDay, isBefore, startOfToday } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Subject } from 'rxjs';
import styled from 'styled-components';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import FullScreenLoading from 'components/FullScreenLoading';
import ImageWrapper from 'components/ImageWrapper';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
// import SearchInLineEAN from 'components/SearchInlineEAN';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import ReturnedIcon from 'images/icons/arrows/returned.svg';
import HistoryNotesDialog from 'images/icons/combinations/historyTimeWithBg.svg';
import UploadIcon from 'images/icons/downloadUpload/upload.svg';
import TooltipNote from 'images/icons/objects/currencies/tooltipNote.svg';
import searchIcon from 'images/icons/searches/searchInputIcon.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import * as OrderActions from 'store/actions/order';
import { Container, GlobalStyle } from 'styles/general';
import convertToBase64 from 'utils/convertToBase64';
import Environment from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import {
  getFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import { providerNameValidation } from 'utils/providerNameValidation';
import ValidationLength from 'utils/utils';

import DialogCompleteNote from './dialogs/DialogCompleteNote';
import DialogCostsItems from './dialogs/DialogCostsItems';
import DialogImportedNfeHistory from './dialogs/DialogImportedNfeHistory';
import MobileRecebimento from './MobileReceipt/index';

import axiosInstanceOrders from '../../services/middlewares/apis/orders_validate';
import { InputFile } from '../quotesUpload/style';


const environments = Environment(process.env.REACT_APP_ENV);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },

  nested: {
    paddingLeft: theme.spacing(4)
  },

  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },

  input: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },

  modal: {
    cursor: 'pointer'
  },

  inputRoot: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },

  textField: {
    fontFamily: 'Lato',
    fontSize: '4px'
  }
}));

const handleColorType = (color) => {
  const colorOptions = {
    primary: 'color: #5062F0;',
    secondary: 'color: #383d41;',
    success: 'color: #3DCC6E;',
    danger: 'color: #D94141;',
    warning: 'color: #E6A117;',
    info: 'color: #0c5460;',
    dark: 'color: #1b1e21;',
    default: 'color: #818182;'
  };

  return colorOptions[color] ? colorOptions[color] : colorOptions.default;
};

const handleBackgroundType = (color) => {
  const colorOptions = {
    primary: 'color: #D3D7FB;',
    secondary: 'color: #e2e3e5;',
    success: 'color: #DFF7E8;',
    danger: 'color: #F7DFDF;',
    warning: 'color: #FCF5E3;',
    info: 'color: #d1ecf1;',
    dark: 'color: #d6d8d9;',
    default: 'color: #fefefe;'
  };

  return colorOptions[color] ? colorOptions[color] : colorOptions.default;
};

const ButtonChip = styled(Chip)`
  width: 80px;
  height: 20px;
  font-family: Lato;
  font-size: 11px;
  margin: 0;
  padding: 0;
  line-height: 35px;
  cursor: pointer;
  ${({ background }) => handleBackgroundType(background)};
  ${({ color }) => handleColorType(color)};
  :hover {
    ${({ background }) => handleBackgroundType(background)};
    ${({ color }) => handleColorType(color)};
  }
`;

export function Recebimentos({ userInfo, setOrder }) {
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const actualDate = new Date();
  const dateLessSeven = new Date().setDate(actualDate.getDate() - 7);
  const formatActualDate = format(actualDate, 'yyyy-MM-dd');
  const formatDateLessSeven = format(dateLessSeven, 'yyyy-MM-dd');
  const { id: paramsID } = useParams();
  const smallScreen = useMediaQuery('(max-width: 1366px)');
  const queryParams = useLocation()?.search;
  const location = useLocation()
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [valueNf, setValueNf] = useState('')
  const splitUrl = location.pathname.split('orderNumber=')[1]
  const orderNumber = splitUrl?.split('&')[0]

  const history = useHistory();  // Usando useHistory no lugar de useNavigate

  const clearUrlParams = () => {
    // Remove os parâmetros da URL e mantém apenas o caminho base
    history.push(`/recebimento/${paramsID}`);
  };

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem("isRefreshed");
    
    if (isRefreshed) {
      clearUrlParams()
    } else {
      sessionStorage.setItem("isRefreshed", "true");
    }
  }, []);

  // eslint-disable-next-line radix
  const orderStorage = queryParams?.includes('orderValidId')
    ? decodeURI(
      queryParams
        ?.split('&')
        ?.find((el) => el.includes('orderValidId'))
        ?.split('=')[1]
    )
    : orderNumber;
  const orderId = queryParams?.includes('orderId')
    ? decodeURI(
      queryParams
        ?.split('&')
        ?.find((el) => el.includes('orderId'))
        ?.split('=')[1]
    )
    : '';
  const listReceivedQueryParam = queryParams?.includes('listReceived')
    ? decodeURI(
      queryParams
        ?.split('&')
        ?.find((el) => el.includes('listReceived'))
        ?.split('=')[1]
    )
    : '';
  const [productIdQueryParam, setProductIdQueryParam] = useState(
    queryParams?.includes('productId')
      ? queryParams
        ?.split('&')
        ?.find((el) => el.includes('productId'))
        ?.split('=')[1]
      : ''
  );
  const [productDescriptionQueryParam, setProductDescriptionQueryParam] =
    useState(
      queryParams?.includes('productDescription')
        ? decodeURI(
          queryParams
            ?.split('&')
            ?.find((el) => el.includes('productDescription'))
            ?.split('=')[1]
        )
        : ''
    );
  const [statusQueryParam, setStatusQueryParam] = useState(
    queryParams?.includes('status')
      ? decodeURI(
        queryParams
          ?.split('&')
          ?.find((el) => el.includes('status'))
          ?.split('=')[1]
      )
      : ''
  );

  const [dateTypeQueryParam, setDateTypeQueryParam] = useState(
    queryParams?.includes('dateType')
      ? decodeURI(
        queryParams
          ?.split('&')
          ?.find((el) => el.includes('dateType'))
          ?.split('=')[1]
      )
      : ''
  );

  const [initialDateFilterDefaultValue, setInitialDateFilterDefaultValue] =
    useState(
      queryParams?.includes('startAt')
        ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('startAt'))
          ?.split('=')[1]
        : ''
    );
  const [endDateFilterDefaultValue, setEndDateFilterDefaultValue] = useState(
    queryParams?.includes('endAt')
      ? queryParams
        ?.split('&')
        ?.find((el) => el.includes('endAt'))
        ?.split('=')[1]
      : ''
  );

  const dateTypeFilterDefaultValue =
    dateTypeQueryParam === 'true'
      ? { title: 'Recebimento', value: true }
      : { title: 'Pedido', value: '' };

  const statusFilterDefaultValue =
    statusQueryParam === 'RECEIVED'
      ? { title: 'Recebido', value: 'RECEIVED' }
      : statusQueryParam === 'IN PROGRESS'
        ? { title: 'Andamento', value: 'IN PROGRESS' }
        : statusQueryParam === 'OVERDUE'
          ? { title: 'Atrasado', value: 'OVERDUE' }
          : statusQueryParam === 'RETURNED'
            ? { title: 'Devolvido', value: 'RETURNED' }
            : statusQueryParam === 'NOT RECEIVED'
              ? { title: 'Não recebido', value: 'NOT RECEIVED' }
              : statusQueryParam === 'PENDING'
                ? { title: 'Aprovação', value: 'PENDING' }
                : { title: 'Todos', value: 'ALL' };
  const [params, setParams] = useState([
    {
      param: 'listReceived',
      value: listReceivedQueryParam || true,
      urlParam: `listReceived=${listReceivedQueryParam || 'true'}`
    },
    {
      param: 'dateStart',
      value: initialDateFilterDefaultValue || formatDateLessSeven,
      urlParam: `dateStart=${initialDateFilterDefaultValue || formatDateLessSeven
        }`
    },
    {
      param: 'dateFinish',
      value: endDateFilterDefaultValue || formatActualDate,
      urlParam: `dateFinish=${endDateFilterDefaultValue || formatActualDate}`
    },
    productIdQueryParam && {
      param: 'productId',
      value: productIdQueryParam,
      urlParam: `productId=${productIdQueryParam}`
    },
    statusQueryParam && {
      param: 'status',
      value: statusQueryParam,
      urlParam: `status=${statusQueryParam}`
    },
    dateTypeQueryParam && {
      param: 'dateType',
      value: dateTypeQueryParam,
      urlParam: `dateType=${dateTypeQueryParam}`
    }
  ]);

  const [getId, setGetId] = useState(paramsID);
  const [_, setState] = useState('');
  const [errMsg, setErrMsg] = useState(false);
  const [providersSku, setProvidersSku] = useState([]);
  const [dataReceives, setDataReceives] = useState([]);
  const [totalReceives, setTotalReceives] = useState()
  const [debounceTimeList, setDebounceTimeList] = useState([]);
  const [toastProps, setToastProps] = useState({});

  const [filtersChanged, setFiltersChanged] = useState(params);
  const [openModal, setOpenModal] = useState(false);
  const [openModalOrder, setOpenModalOrder] = useState(false);
  const [openModalNfce, setOpenModalNfce] = useState(false)
  const [fullScreenLoad, setFullScreenLoad] = useState({
    open: false,
    message: ''
  });
  const [collapseSettings, setCollapseSettings] = useState([]);
  const [skuSelect, setSkuSelect] = useState('');
  const [skuSelectItem, setSkuSelectItem] = useState('');
  const [itemSelectPut, setItemSelectPut] = useState('');
  const classes = useStyles();
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [openCostsModal, setOpenCostsModal] = useState(false);
  const [openImportedNfeHistory, setOpenImportedNfeHistory] = useState(false);
  const [orderCosts, setOrderCosts] = useState();
  const [fullWidth] = useState(true);
  const inputRef = useRef();
  const [maxWidth] = useState('sm');
  const [total] = useState([]);
  // variaveis do novo pedido
  const [newOrderOpenModal, setNewOrderOpenModal] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedReceiveDate, setSelectedReceiveDate] = useState(new Date());
  const [contacts, setContacts] = useState([]);
  const [contactTel, setContactTel] = useState('');
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [contactsValue, setContactsValue] = useState('');
  const [inputKey, setInputKey] = useState('')
  // const [inputValid, setInputValid] = useState(false)
  const [alertNfce, setAlertNfce] = useState()
  const [nfceType, setNfceType] = useState({valid: false, code: ''})

  const receiveCreatedDateMinusOneDay = useMemo(
    () => moment(itemSelectPut.orderDate).subtract(1, 'd'),
    [itemSelectPut]
  );

  const createdAt = new Date(itemSelectPut?.deliveryDate)

  const validateDate = () => {
    const selected = new Date(selectedReceiveDate);

    if (selected < createdAt) {
      return true
    } return false
  };
  
  // datas do topo das paginas desk e mobile
  const dataToday = new Date();
  const dias = [
    'DOMINGO',
    'SEGUNDA',
    'TERÇA',
    'QUARTA',
    'QUINTA',
    'SEXTA',
    'SÁBADO'
  ];
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusCode, setStatusCode] = useState();
  const onDismiss = () => setAlertErr(false);
  const [loading, setLoading] = useState(false);
  const [newOrder, setNewOrder] = useState(false);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentProviderCnpj, setCurrentProviderCnpj] = useState('');
  const [size, setSize] = useState(50);
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [providerId, setProviderId] = useState('');
  const [openDivergenceModal, setOpenDivergenceModal] = useState(false);
  const [divergentQuantityEdited, setDivergentQuantityEdited] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [onSearchTextField$] = useState(() => new Subject());
  const [disabled, setDisabled] = useState(true);
  const [receivedSuccess, setReceivedSuccess] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [skuItems, setSkuItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [selectedSku, setSelectedSku] = useState('');
  const [numberReq, setNumberReq] = useState(0)
  const [infoLoading, setInfoLoading] = useState()
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'skuModal', open: false, fullScreen: false, maxWidth: 'sm' },
    {
      id: 2,
      name: 'findNoteOnSefazModal',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    }
  ]);
  const [noteValue, setNoteValue] = useState('');
  const [statusCertificate, setStatusCertificate] = useState({ canShowAlert: false, typeAlert: null, textAlert: null })


  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Produtos',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        optionReference: 'description',
        options: [],
        size: 'medium',
        searchByTermMode: true,
        storedValue: productDescriptionQueryParam || ''
      },
      {
        label: 'SKU',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/skus/providers?isReceivement=true`,
        key: 'skusDescriptionProvider',
        optionReference: 'descriptionSku',
        options: [],
        size: 'medium',
        searchByTermMode: true
      },
      {
        label: 'Fornecedores',
        filterName: 'providerIds',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/suppliers/groups`,
        key: 'providersIdList',
        optionReference: 'description',
        options: [],
        size: 'medium',
        filterParamsOff: true,
        ignoreFilterContent: true
      },
      {
        label: 'Status',
        filterName: 'status',
        placeHolder: 'Selecione...',
        size: 'medium',
        type: 'autoComplete',
        key: 'providers',
        optionReference: 'description',
        isSelect: true,
        multiple: true,
        storedValue: statusFilterDefaultValue?.title,
        defaultValue: [{ title: 'Todos', value: '' }],
        options: [
          { title: 'Todos', value: '' },
          { title: 'Recebido', value: 'RECEIVED' },
          { title: 'Recebido parcial', value: 'PARTIALLY_RECEIVED' },
          { title: 'Andamento', value: 'IN PROGRESS' },
          { title: 'Atrasado', value: 'OVERDUE' },
          { title: 'Devolvido', value: 'RETURNED' },
          { title: 'Não recebido', value: 'NOT RECEIVED' },
          { title: 'Aprovação', value: 'PENDING' }
        ]
      },
      {
        label: 'Data',
        filterName: 'dateType',
        placeHolder: 'Selecione...',
        size: 'small',
        type: 'autoComplete',
        key: 'dateType',
        optionReference: 'providerName',
        isSelect: true,
        storedValue: dateTypeFilterDefaultValue?.title,
        options: [
          { title: 'Pedido', value: '' },
          { title: 'Recebimento', value: true }
        ]
      },
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'dateStart',
        filterNameSecond: 'dateFinish',
        initialDate: initialDateFilterDefaultValue
          ? moment(initialDateFilterDefaultValue).add(3, 'hours')
          : dateLessSeven,
        endDate: endDateFilterDefaultValue
          ? moment(endDateFilterDefaultValue).add(3, 'hours')
          : actualDate
        // minDate: true,
        // maxDate: true
      },
      {
        label: '',
        type: 'multipleCheckbox',
        checkBoxInputs: [
          {
            name: 'SKUs à catalogar',
            referenceName: 'notCatalogued'
          }
        ]
      }
    ],
    []
  ]);

  function buildDate() {
    const day = dataToday.getDate();
    let month = dataToday.getMonth() + 1;
    const year = dataToday.getFullYear();

    if (month < 10) month = `0${month}`;

    return `${day}/${month}/${year}`;
  }

  const dateStart = buildDate();

  const handleCloseModal = () => {
    setInputValue('');
    setContactsValue('');
    setContactTel('');
    setValue('');
    setContacts([]);
    setSelectedEndDate(new Date());
    setSelectedReceiveDate(new Date());
    setOpenNewOrder(false);
    setOpenCostsModal(false);
    setOpenModal(false);
    setOpenModalOrder(false);
    setOpenModalNfce(false)
    setErrMsg(false);
    setSubmitted(false);
    setLoading(false);
    setOpenDivergenceModal(false);
    setOpenImportedNfeHistory(false);
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'findNoteOnSefazModal'
          ? { ...modal, open: false }
          : modal
      )
    );
  };

  const handleClose = (_event, _reason) => {
    setReceivedSuccess(false);
    setErrMsg(false);
    setLoading(false);
    handleCloseModal();
  };

  const handleClickOpenNewOrder = () => {
    setOpenNewOrder(!openNewOrder);
  };

  const handleClickOpenModal = (data, item) => {
    if (
      !item?.itemsPaymentDocument?.packageValue &&
      !item?.itemsPaymentDocument?.quantity
    ) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
    setOpenModal(true);
    setSkuSelect(item.sku);
    setSkuSelectItem(item);
    setItemSelectPut(data);
    setNewOrderOpenModal(data);
  };

  const handleClickOpenModalOrder = (data) => {
    setOpenModalOrder(true);
    setItemSelectPut(data);
    setNewOrderOpenModal(data);
  };

  const handleClickOpenModalNfce = (data) => {
    setOpenModalNfce(true)
  }

  const handleClickOpenCostsModal = (data) => {
    if (data) {
      setOpenCostsModal(true);
      setOrderCosts(data);
    }
  };

  function handleEndDateChange(date) {
    setSelectedEndDate(date);
  }

  const handleReceiveDateChange = (date) => {
    setSelectedReceiveDate(date);
  };
  
  // funcoes valores totais
  dataReceives?.map((order) => {
    if (order.orderItems.length > 1) {
      total.push({
        id: order._id,
        orderItems: order.orderItems.reduce(
          (total, valor) =>
            valor.costs
              ? parseFloat(total) + parseFloat(valor.costs.total)
              : total + 0,
          0
        )
      });
    } else {
      return null;
    }
  });

  useEffect(() => {
    setGetId(paramsID);

    document.querySelector(`#findKeyNoteOnSefazField`).select();
  }, [paramsID]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useEffect(() => {
    (async () => {
      const response = await fetch(`${environments.providers}/providers`);
      const provider = await response.json();
      setProvidersSku(provider.content);
    })();
  }, []);

  useEffect(() => {
    if (!modalSettings.find((modal) => modal.name === 'skuModal').open) return;

    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        getId
      );
    !productOptions.length &&
      getOptions(
        `${environments.catalog}/products`,
        'products',
        productOptions,
        setProductOptions,
        getId,
        null,
        null,
        10
      );
    !skuItems.length &&
      getOptions(
        `${environments.catalog}/skus/providers`,
        'skus',
        skuItems,
        setSkuItems,
        getId
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        getId
      );
    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        getId
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        getId
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        getId
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        getId
      );
    !providerOptions.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providerOptions,
        setProviderOptions,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
  }, [getId, modalSettings.find((modal) => modal.name === 'skuModal').open]);

  // array dos recebimentos da tabela
  useEffect(() => {
    const delayFilterFetchData = setTimeout(() => {
      (async () => {
        setLoading(true);
        const filters = filtersChanged
          .map((filter) => filter.urlParam)
          .join('&');

        await axiosInstanceOrders.get(
          `${environments.orders}/orders?page=${page}&size=${size}&originId=${getId}&${filters}`
        )
        .then((response) => {
          setLoading(false);
          setNewOrder(false);
          setTotalReceives(response.data.totalOrderAmount)
          setDataReceives(response?.data?.content);
          setOrders(response?.data?.content);
          setTotalPages(response?.data?.totalPages);
          setNumberOfElements(response?.data?.numberOfElements);
          setTotalElements(response?.data?.totalElements);
          setCollapseSettings(
            response?.data.content?.map((orderItems) => ({
              id: orderItems._id,
              open: false
            }))
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
          setLoading(false);
        });
      })();
    }, 1000);

    return () => clearTimeout(delayFilterFetchData);
  }, [getId, filtersChanged, newOrder, page, params, updateList, orderId]);

  const getOrder = () => {
      (async () => {
        setLoading(true);
        const filters = filtersChanged
          .map((filter) => filter.urlParam)
          .join('&');
        const response = await axiosInstanceOrders.get(
          `${environments.orders}/orders?page=${page}&size=${size}&originId=${getId}&${filters}`
        );
        const data = await response.data;
        setLoading(false);
        setNewOrder(false);
        setDataReceives(data.content);
        setOrders(data.content);
        setTotalPages(data.totalPages);
        setNumberOfElements(data.numberOfElements);
        setTotalElements(data.totalElements);
        setCollapseSettings(
          data.content.map((orderItems) => ({
            id: orderItems._id,
            open: false
          }))
        );
      })();

  }

  useEffect(() => {
    orders.map((orderItems) => {
      const exists = collapseSettings
        ? collapseSettings.find((x) => x.id === orderItems._id)
        : null;
      if (collapseSettings.length < orders.length && !exists) {
        collapseSettings.push({ id: orderItems._id, open: false });
      }
    });
  }, [orders]);

  // Pegar contatos com base no id do fornecedor selecionado
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.providers}/providers/${providerId}`
      );
      const content = await response.json();
      const contato = content?.contacts;
      if (contato?.length > 0) {
        setContacts(contato);
      }
    })();
  }, [getId, providerId]);

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  // Verificar validade do certificado digital (sefaz)
  useEffect(() => {
    (async () => {
      const response = await fetch(`${environments.integration}/nfe/validityCertificate?restaurantIds=${getId}`)
      const content = await response.json()

      // Se eu tiver algum conteúdo, pega 
      if (content && content[getId]) {
        const { valid, date } = content[getId]
        const status = {
          canShowAlert: false,
          typeAlert: null,
          textAlert: null
        }

        const DATE_TODAY = moment()
        const N_DAYS_SHOW_ALERT = 7

        const isSameDay = moment(date).isSame(DATE_TODAY, 'day');
        // Se não tiver válido, vou montar o texto de certificado inválido
        // Se tiver válido, e a diferença de hoje para a data de vencimento for igual ou menor que 7 dias, vou montar o texto de certificado prestes a expirar
        if (!valid || isSameDay) {
          status.canShowAlert = true
          status.typeAlert = "EXPIRED_CERTIFICATE"
          status.textAlert = `ATENÇÃO! Seu certificado digital está vencido. Data de validade do certificado atual: ${moment(date).format('DD/MM/YYYY')}`
        } else if (valid && moment(date).diff(DATE_TODAY, 'days') <= N_DAYS_SHOW_ALERT) {
          status.canShowAlert = true
          status.typeAlert = "EXPIRING_CERTIFICATE"
          status.textAlert = `ATENÇÃO! Seu certificado digital vencerá em breve. Data de validade do certificado atual: ${moment(date).format('DD/MM/YYYY')}`
        }

        setStatusCertificate(status)
      }
    })()
  }, [getId])

  // put de edição das orders
  const putEditOrders = (newStatus) => {
    axios
      .put(
        `${environments.orders}/orders/${itemSelectPut._id}/status/skus${newStatus === 'RECEIVED' ||
          newStatus === 'NOT RECEIVED' ||
          newStatus === 'RETURNED'
          ? `?receiveDate=${format(selectedReceiveDate, 'yyyy-MM-dd')}`
          : ''
        }`,
        {
          status: newStatus,
          user: userInfo.user
        }
      )
      .then((res) => {
        setSelectedReceiveDate(new Date());
        setDataReceives((receive) =>
          receive.map((order) =>
            order._id === itemSelectPut._id ? res.data : order
          )
        );
        setState({ dataReceives });
        setSubmitted(false);
        setOpenModalOrder(false);
        setOpenNewOrder(false);
      })
      .catch((error) => {
        setSubmitted(false);
        setAlertErr(true);
        setAlertMessage(error.response.data.message);
        setStatusCode(error.response.status);
      });
  };

  const putEditTransfer = (item, status) => {
    const body = {
      id: item?.orderItems[0]?.product?.id,
      description: item?.orderItems[0]?.product?.description,
      averageValue: item?.orderItems[0]?.itemsPaymentDocument?.unitaryValue,
      lastValue: item?.orderItems[0]?.itemsPaymentDocument?.unitaryValue,
      status,
      itemId: item?.orderItems[0]?._id,
      receivedQuantity: item?.orderItems[0]?.itemsPaymentDocument?.quantity || 0,
      receivedPrice: item?.orderItems[0]?.itemsPaymentDocument?.unitaryValue || 0,
      receivedPriceTotal: item?.orderItems[0]?.itemsPaymentDocument?.total || 0,
      user: userInfo?.user
    }

    const receiveDate = format(selectedReceiveDate || new Date(), 'yyyy-MM-dd')

    axios.put(`${environments.orders}/orders/${item._id}/transfer?receiveDate=${receiveDate}`, body, config)
      .then(() => {
        setOpenModal(false);
        setSubmitted(false)
        getOrder()
      }).catch((error) => {
        setSubmitted(false);
        setAlertErr(true);
        setAlertMessage(error.response.data.message);
        setStatusCode(error.response.status);
      });
  }


  const verifyValues = ({ items, param }) => {
    let isValid = true;

    for (const item of items) {
      if (!item[param] || isNaN(item[param])) {
        isValid = false;
      }
    }

    return isValid;
  };

  // put de edicao do status do sku dependendo da escolha no modal de recebimento
  const putEditSku = async (newStatus, divergenceStatus, isGroupReceive) => {
    let body = {};

    if (isGroupReceive) {
      body = itemSelectPut.orderItems.map((item) => ({
        id: item?.sku?.id,
        isBonus: item?.itemsPaymentDocument?.isBonus,
        description: item?.sku?.description,
        descriptionProvider: item?.sku?.descriptionProvider,
        images: item?.sku?.images,
        stock: item?.sku?.stock,
        averageValue: item?.sku?.averageValue,
        lastValue: item?.sku?.lastValue,
        curve: item?.sku?.curve,
        status: newStatus,
        itemId: item?._id,
        receivedQuantity: item?.itemsPaymentDocument?.quantity || 0,
        receivedPrice: item?.itemsPaymentDocument?.packageValue || 0,
        receivedPriceTotal: item?.itemsPaymentDocument?.total || 0,
        user: userInfo.user,
        adjustOrder: !!(divergenceStatus && divergenceStatus === 'adjustOrder'),
        newOrder: !!(divergenceStatus && divergenceStatus === 'newOrder')
      }));
    } else {
      body = {
        id: skuSelect?.id,
        isBonus: skuSelectItem?.itemsPaymentDocument?.isBonus,
        description: skuSelect?.description,
        descriptionProvider: skuSelect?.descriptionProvider,
        images: skuSelect?.images,
        stock: skuSelect?.stock,
        averageValue: skuSelect?.averageValue,
        lastValue: skuSelect?.lastValue,
        curve: skuSelect?.curve,
        status: newStatus,
        itemId: skuSelectItem?._id,
        receivedQuantity: skuSelectItem?.itemsPaymentDocument?.quantity || 0,
        receivedPrice: skuSelectItem?.itemsPaymentDocument?.packageValue || 0,
        receivedPriceTotal: skuSelectItem?.itemsPaymentDocument?.total || 0,
        user: userInfo.user,
        adjustOrder: !!(divergenceStatus && divergenceStatus === 'adjustOrder'),
        newOrder: !!(divergenceStatus && divergenceStatus === 'newOrder')
      };
    }

    const items = isGroupReceive ? body : [body];

    const [
      isSKUSelectedQuantityValid,
      isSKUSelectedPackageValid,
      isSKUSelectedTotalValid
    ] = await Promise.all([
      verifyValues({ items, param: 'receivedQuantity' }),
      verifyValues({ items, param: 'receivedPrice' }),
      verifyValues({ items, param: 'receivedPriceTotal' })
    ]);

    const isBonusValidate = items?.some(el => el?.isBonus) ? true : isSKUSelectedPackageValid && isSKUSelectedTotalValid
    
    if (
      isSKUSelectedQuantityValid &&
      isBonusValidate
    ) {
      axios
        .put(
          `${environments.orders}/orders/${itemSelectPut._id}/skus${newStatus === 'RECEIVED' ||
            newStatus === 'NOT RECEIVED' ||
            newStatus === 'RETURNED'
            ? `?receiveDate=${format(selectedReceiveDate, 'yyyy-MM-dd')}`
            : ''
          }`,
          body,
          config
        )
        .then((res) => {
          setSelectedReceiveDate(new Date());
          if (res.data?.status === 'RECEIVED') {
            const dataArray = dataReceives;
            dataArray.map((item, index) => {
              if (item._id === res.data._id) {
                dataArray.splice(index, 1);
              }
            });
            setDataReceives(dataArray);
            setReceivedSuccess(true);
          } else {
            setDataReceives((receive) =>
              receive.map((order) =>
                order._id === itemSelectPut._id
                  ? {
                    ...order,
                    status: res.data?.status,
                    orderItems: order.orderItems.map((stateItem) =>
                      stateItem._id === skuSelectItem._id
                        ? {
                          ...stateItem,
                          sku: {
                            ...stateItem.sku,
                            status: newStatus
                          }
                        }
                        : stateItem
                    )
                  }
                  : order
              )
            );
          }
          setSubmitted(false);
          setOpenModal(false);
          setOpenNewOrder(false);
        })
        .catch((error) => {
          setSubmitted(false);
          setAlertErr(true);
          setAlertMessage(error.response.data.message);
          setStatusCode(error.response.status);
        });
    } else {
      setErrMsg(true);
      setSubmitted(false);
    }
  };

  // put de edicao de valores do sku de pedido e Recebimento
  const putEditOrder = (data, _item) => {
    setTimeout(() => {
      axios
        .put(`${environments.orders}/orders/${data._id}`, data, config)
        .then((_res) => {
          setSubmitted(false);
        })
        .catch((error) => {
          setAlertErr(true);
          setAlertMessage(error.response.data.message);
          setStatusCode(error.response.status);
          setSubmitted(false);
        });
    }, 400);
  };

  // post novo pedido de SKU no modal de recebimento
  const submitNewOrder = (data) => {
    if (contactTel !== '') {
      data.orderItems.map((order) => {
        if (order.sku.id === skuSelect.id) {
          data.orderItems = [
            {
              unitsMeasurements: order.unitsMeasurements,
              consumptionUnits: order.consumptionUnits,
              sku: order.sku,
              costs: order.costs
            }
          ];
        }
      });

      const body = {
        user: userInfo.user,
        status: 'PENDING',
        statusPaymentDocument: 'WAITING',
        quotation: false,
        orderDate: format(new Date(), 'yyyy/MM/dd'),
        deliveryDate: format(selectedEndDate, 'yyyy/MM/dd'),
        providerId: inputValue.providerId,
        contact: `55${contactTel}`,
        type: 'M',
        ordersList: [data]
      };

      axios
        .post(`${environments.orders}/orders`, body, config)
        .then((_response) => {
          putEditSku('NOT RECEIVED');
          setNewOrder(true);
          setInputValue('');
          setContactsValue('');
          setContactTel('');
          setValue('');
          setSelectedEndDate(new Date());
          setContacts([]);
          setLoading(false);
          setOpenModal(false);
        })
        .catch((_error) => {
          setLoading(false);
          setAlertErr(true);
          setAlertMessage(_error.response.data.message);
          setStatusCode(_error.response.status);
        });
    } else {
      setLoading(false);
      setAlertErr(true);
    }
  };

  // funcao filtros
  const handleFilter = (param, selectedValue, _, paramSecond) => {
    setPage(1);
    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!selectedValue) {
      setInitialDateFilterDefaultValue(
        param === 'dateStart' ? '' : initialDateFilterDefaultValue
      );
      setEndDateFilterDefaultValue(
        param === 'dateFinish' ? '' : endDateFilterDefaultValue
      );
      setProductDescriptionQueryParam(
        param === 'productDescription' ? '' : productDescriptionQueryParam
      );
      setStatusQueryParam(param === 'status' ? '' : statusQueryParam);
      setDateTypeQueryParam(param === 'dateType' ? '' : dateTypeQueryParam);

      if (hasFilterWithFirstName || hasFilterWithSecondName) {
        formattedFilters = filtersChanged?.filter(
          (el) => el?.param !== paramSecond && el?.param !== param
        );
      }
    }

    const defaultParamsNumber = 3;
    const isRemovingOption =
      formattedFilters.some(
        (p) => p.param === param || p.param === paramSecond
      ) && !selectedValue;

    const isReturningToDefaultParams =
      isRemovingOption && formattedFilters.length - 1 === defaultParamsNumber;

    const newParams = params
      ?.filter((el) => el)
      ?.map((newParam) => {
        const listReceivedParamValue = param === 'dateStart' || param === 'dateFinish' ? false : true

        if (newParam.param === param || newParam.param === paramSecond) {
          return {
            param,
            value: selectedValue,
            urlParam: `${param}=${selectedValue}`
          };
        }

        if (newParam.param === 'listReceived') {
          return {
            param: 'listReceived',
            value: listReceivedParamValue,
            urlParam: `listReceived=${listReceivedParamValue}`
          }
        }

        return newParam;
      });

    if (
      !newParams.some((par) => par.param === param || par.param === paramSecond)
    ) {
      newParams.push({
        param,
        value: selectedValue,
        urlParam: `${param}=${selectedValue}`
      });
    }

    setParams(newParams);

    // Case user clicks on 'X' on an empty field and no other field is set
    const isDefaultParams =
      formattedFilters.length === defaultParamsNumber && !selectedValue;

    if (isDefaultParams || isReturningToDefaultParams) {
      const paramsAndValues = [
        [param, selectedValue],
        ['listReceived', true]
      ];

      return handleMultipleFilters(
        paramsAndValues,
        newParams,
        setFiltersChanged
      );
    }

    const paramsAndValues = [
      [param, selectedValue],
      ['listReceived', false]
    ];

    handleMultipleFilters(paramsAndValues, newParams, setFiltersChanged);
  };

  const handleMultipFilterCallBack = (paramsAndValues) => {
    setPage(1);

    const [param, newValue] = paramsAndValues[0];

    const newParams = params.map((newParam) => {
      const listReceivedParamValue = param === 'dateStart' || param === 'dateFinish' ? false : true

      if (newParam.param === param) {
        return {
          param,
          value: newValue,
          urlParam: `${param}=${newValue}`
        };
      }
      
      if (newParam.param === 'listReceived') {
        return {
          param: 'listReceived',
          value: listReceivedParamValue,
          urlParam: `listReceived=${listReceivedParamValue}`
        }
      }

      return newParam;
    });

    setParams(newParams);

    handleMultipleFilters(paramsAndValues, newParams, setFiltersChanged);
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, params, setFiltersChanged);
  };

  const handleSearchTextField = (e) => {
    debounceTimeList.forEach((el) => clearTimeout(el));
    setValueNf(e.target.value)
    const newTimer = setTimeout(() => {
      handleFilter('orderNumber', e.target.value);
    }, 500);

    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  useEffect(() => {
    if(orderStorage) {
      if (orderId) {
        handleFilter('orderNumber', orderId)
        localStorage.setItem('orderIdValid', 0)
        setValueNf(orderId)
        getOrder()
      } else {
        setValueNf(orderNumber)
      }
    } else {
      setValueNf('')
    }
  }, [orderStorage])

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      params,
      page,
      true
    );
  };

  function colorStatus(data) {
    const statusOrder = data.status || '';
    const statusDocument = data.statusPaymentDocument || '';
    const dataStatus = { statusOrder, statusDocument };
    const { orderItems } = data

    let background = '';
    let color = '';
    let title = '';
    let label = '';

    if(!dataStatus.statusDocument) dataStatus.statusDocument = "VALIDATE"
    
    if (
      dataStatus.statusOrder.toUpperCase() === 'CANCELED' &&
      dataStatus.statusDocument.toUpperCase() === 'PENDING'
    ) {
      background = '#F7DFDF';
      color = '#AA0816';
      title = 'Pedido cancelado, sem Nfe.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Cancelado - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''
        }`;
    } else if (
      orderItems?.some(el => el?.sku?.status === "RECEIVED") && 
      !orderItems.every(el => el?.sku?.status === "RECEIVED") && 
      dataStatus.statusOrder.toUpperCase() === 'IN PROGRESS'
    ) {
      background = '#DFF7E8';
      color = '#006608';
      title = 'Pedido em andamento Aguardando Nfe';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      color = '#006608';
      label = `Recebido parcial - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''}`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'IN PROGRESS' &&
      isBefore(addHours(new Date(data.deliveryDate), 3), startOfToday())
    ) {
      background = '#FCF5E3';
      color = '#AA8208';
      title = 'Pedido Atrasado.';
      label = 'Atrasado';
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'NOT RECEIVED' &&
      dataStatus.statusDocument.toUpperCase() === 'PENDING'
    ) {
      background = '#F7D56E';
      color = '#AA0816';
      title = 'Pedido não recebido, sem Nfe.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      background = '#F7D56E';
      label = `Não Recebido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''}`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'NOT RECEIVED' &&
      dataStatus.statusDocument.toUpperCase() === 'DIVERGENT'
    ) {
      background = '#F7D56E';
      color = '#AA0816';
      title = 'Pedido não recebido, com Nfe divergente.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Não Recebido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''
        }`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'NOT RECEIVED' &&
      dataStatus.statusDocument.toUpperCase() === 'VALIDATE'
    ) {
      background = '#F7D56E';
      color = '#AA0816';
      title = 'Pedido não recebido, com Nfe validada.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Não Recebido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''}`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'IN PROGRESS' &&
      dataStatus.statusDocument.toUpperCase() === 'PENDING' 
    ) {
      background = '#D3D7FB';
      color = '#5062F0';
      title = 'Nfe não recebida.';
      label = 'Andamento';
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'IN PROGRESS' &&
      dataStatus.statusDocument.toUpperCase() === 'VALIDATE'
    ) {
      background = '#D3D7FB';
      color = '#5062F0';
      title = 'Pedido com Nfe validada.';
      label = 'Andamento';
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'IN PROGRESS' &&
      dataStatus.statusDocument.toUpperCase() === 'DIVERGENT'
    ) {
      background = '#F7DFDF';
      color = '#AA0816';
      title = 'Pedido com divergência.';
      label = 'Andamento';
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'IN PROGRESS' &&
      dataStatus.statusDocument.toUpperCase() === 'WAITING'
    ) {
      background = '#D3D7FB';
      color = '#5062F0';
      title = 'Pedido em andamento Aguardando Nfe';
      label = 'Andamento';
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'RECEIVED' &&
      dataStatus.statusDocument.toUpperCase() === 'VALIDATE'
    ) {
      background = '#DFF7E8';
      color = '#006608';
      title = 'Pedido Validado com Nfe.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Recebido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''}`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'RECEIVED' &&
      dataStatus.statusDocument.toUpperCase() === 'PENDING'
    ) {
      background = '#DFF7E8';
      color = '#006608';
      title = 'Recebido nota pendente';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Recebido a - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''
        }`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'RETURNED' &&
      dataStatus.statusDocument.toUpperCase() === 'DIVERGENT'
    ) {
      background = '#F7DFDF';
      color = '#AA0816';
      title = 'Pedido com divergência.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Devolvido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''
        }`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'RETURNED' &&
      dataStatus.statusDocument.toUpperCase() === 'VALIDATE'
    ) {
      background = '#F7DFDF';
      color = '#AA0816';
      title = 'Pedido devolvido Nfe validada.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Devolvido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''}`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'RETURNED' &&
      dataStatus.statusDocument.toUpperCase() === 'PENDING'
    ) {
      background = '#F7DFDF';
      color = '#AA0816';
      title = 'Pedido devolvido com Nfe não recebida.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Devolvido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''}`;
      color = "#AA0816"
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'RECEIVED' &&
      dataStatus.statusDocument.toUpperCase() === 'WAITING'
    ) {
      background = '#DFF7E8';
      color = '#006608';
      title = 'Pedido Recebido aguardando Nfe.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Recebido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''
        }`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'RECEIVED' &&
      dataStatus.statusDocument.toUpperCase() === 'DIVERGENT'
    ) {
      background = '#DFF7E8';
      color = '#006608';
      title = 'Pedido Recebido com Nfe divergente.';
      const { receiveDate } = data;
      const date = addHours(new Date(receiveDate), 3);
      label = `Recebido - ${receiveDate ? format(new Date(date), 'dd/MM/yyyy') : ''
        }`;
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'PENDING' &&
      dataStatus.statusDocument.toUpperCase() === 'PENDING'
    ) {
      background = '#D3D3D3';
      color = '#5062F0';
      title = 'Nfe não recebida.';
      label = 'Aprovaçao';
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'PENDING' &&
      dataStatus.statusDocument.toUpperCase() === 'VALIDATE'
    ) {
      background = '#D3D3D3';
      color = '#5062F0';
      title = 'Pedido com Nfe validada.';
      label = 'Aprovaçao';
    } else if (
      dataStatus.statusOrder.toUpperCase() === 'PENDING' &&
      dataStatus.statusDocument.toUpperCase() === 'DIVERGENT'
    ) {
      background = '#D3D3D3';
      color = '#AA0816';
      title = 'Pedido com divergência.';
      label = 'Aprovaçao';
    }

    return (
      <Tooltip title={title}>
        <td
          style={{ width: '21.5%' }}
          className="description center"
        >
          <ButtonChip
            label={label}
            style={{
              width: '89%',
              boxShadow: '0px 4px 4px 0px #00000040',
              borderRadius: '20px',
              marginRight: '8px',
              marginLeft: '0px',
              marginBottom: '5px',
              color,
              backgroundColor: background
            }}
          />
        </td>
      </Tooltip>
    );
  }

  function colorChip(data) {
    const statusOrder = data.status || '';
    const statusDocument = data.statusPaymentDocument || '';
    const dataStatus = { statusOrder, statusDocument };
    let color = '';
    let title = '';

    switch (dataStatus.statusOrder.toUpperCase()) {
      case 'CANCELED':
        switch (dataStatus.statusDocument.toUpperCase()) {
          case 'PENDING':
            color = '#D94141';
            title = 'Pedido cancelado, sem Nfe.';
            break;
        }
        break;

      case 'IN PROGRESS':
        if (Date() > new Date(data.deliveryDate)) {
          color = '#E6A117';
          title = 'Pedido Atrasado.';
        } else {
          switch (dataStatus.statusDocument.toUpperCase()) {
            case 'PENDING':
              color = '#E6A117';
              title = 'Nfe não recebida.';
              break;
            case 'DIVERGENT':
              color = '#D94141';
              title = 'Pedido com Divergencia.';
              break;
            case 'VALIDATE':
              color = '#3DCC6E';
              title = 'Pedido validado com Nfe.';
              break;
            case 'WAITING':
              color = '#5062F0';
              title = 'Pedido em andamento Aguardando Nfe';
              break;
          }
        }
        break;
      case 'NOT RECEIVED':
        switch (dataStatus.statusDocument.toUpperCase()) {
          case 'PENDING':
            color = '#D94141';
            title = 'Pedido não recebido, sem Nfe.';
            break;
          case 'DIVERGENT':
            color = '#D94141';
            title = 'Pedido não recebido, com Nfe divergente.';
            break;
          case 'VALIDATE':
            color = '#D94141';
            title = 'Pedido não recebido, com Nfe validada.';
            break;
        }
        break;
      case 'RETURNED':
        switch (dataStatus.statusDocument.toUpperCase()) {
          case 'PENDING':
            color = '#D94141';
            title = 'Pedido devolvido com Nfe não recebida.';
            break;
          case 'DIVERGENT':
            color = '#D94141';
            title = 'Pedido com divergência.';
            break;
          case 'VALIDATE':
            color = '#D94141';
            title = 'Pedido devolvido Nfe validada.';
            break;
        }
        break;
      case 'RECEIVED':
        switch (dataStatus.statusDocument.toUpperCase()) {
          case 'PENDING':
            color = '#3DCC6E';
            title = 'Recebido nota pendente';
            break;
          case 'DIVERGENT':
            color = '#D94141';
            title = 'Pedido Recebido com Nfe divergente.';
            break;
          case 'VALIDATE':
            color = '#3DCC6E';
            title = 'Pedido Validado com Nfe.';
            break;
          case 'VALIDATE':
            color = '#3DCC6E';
            title = 'Pedido Recebido aguardando Nfe.';
            break;
        }
        break;
      case 'PENDING':
        switch (dataStatus.statusDocument.toUpperCase()) {
          case 'PENDING':
            color = '#E6A117';
            title = 'Pedido pendente, sem Nfe.';
            break;
          case 'DIVERGENT':
            color = '#D94141';
            title = 'Pedido pendente, com Nfe divergente.';
            break;
          case 'VALIDATE':
            color = '3DCC6E';
            title = 'Pedido pendente, com Nfe validada.';
            break;
          case 'WAITING':
            color = '#5062F0';
            title = 'Pedido pendente, Aguardando Nfe';
            break;
        }
        break;
    }
    return (
      <Tooltip title={title}>
        <ButtonChip
          color={color}
          style={{ width: '5px', background: color }}
        />
      </Tooltip>
    );
  }

  const renderStatusIcon = (
    outline,
    inProgress,
    returned,
    notReceived,
    color
  ) => {
    if (outline) {
      return <CheckCircleOutline style={{ fontSize: 17, color }} />;
    }
    if (inProgress || notReceived) {
      return <Cancel style={{ fontSize: 18, color }} />;
    }
    if (returned) {
      return (
        <img style={{ width: '16px' }} src={ReturnedIcon} alt="ReturnedIcon" />
      );
    }
    return <CheckCircleSharp style={{ fontSize: 17, color }} />;
  };

  const validationOrder = (data) => {
    const statusOrder = data?.status;
    let countNotSku = 0;
    const statusItem =
      data?.orderItems.length !== 0
        ? data?.orderItems.map((item) => item.sku.status)
        : null;

    const isSku =
      data?.orderItems.length !== 0
        ? data?.orderItems.map((item) => item.sku)
        : null;

    isSku &&
      isSku.map((count) => {
        if (count.notValidated == true) {
          countNotSku++;
        }
      });

    let title = '';
    let color = '';
    let outline = false;
    let inProgress = false;
    let returned = false;
    let notReceived = false;
    let onClick;

    // STATUS ORDER ---------------------
    if (statusOrder === 'PENDING') {
      title = 'Aguarde a aprovação';
    }
    // Recebido
    if (
      statusOrder === 'RECEIVED' &&
      (statusItem ? statusItem.every((status) => status === 'RECEIVED') : true)
    ) {
      title = 'Recebido';
      color = '#5062F0';
      onClick = () => handleClickOpenModalOrder(data);
    } else if (
      statusOrder === 'RECEIVED' &&
      (statusItem ? statusItem.some((status) => status === 'WAITING') : true)
    ) {
      title = 'Recebimento em andamento';
      color = '#E6A117';
      inProgress = true;
      onClick = () => handleClickOpenModalOrder(data);
    } else if (
      statusOrder === 'RECEIVED' &&
      (statusItem
        ? statusItem.some(
          (status) => status === 'NOT RECEIVED' || 'RETURNED' || 'RECEIVED'
        )
        : true)
    ) {
      title = 'Recebimento finalizado';
      color = '#5062F0';
      onClick = () => handleClickOpenModalOrder(data);
    }

    // Não iniciou
    else if (
      (statusOrder === 'WAITING' || statusOrder === 'IN PROGRESS') &&
      (statusItem ? statusItem.every((status) => status === 'WAITING') : true)
    ) {
      title = disabled
        ? 'Não há valores no recebimento'
        : 'Não iniciou o recebimento';
      color = '#313347';
      onClick = () => handleClickOpenModalOrder(data);
      outline = true;
    }

    // Em andamento
    else if (
      statusOrder === 'IN PROGRESS' &&
      (statusItem
        ? statusItem.some((status) => status === 'WAITING') &&
        isSku.some((sku) => sku.notValidated)
        : true)
    ) {
      title = `Nota com ${countNotSku}${countNotSku === 1 ? ' item sem catalogar' : ' items sem catalogar'
        }`;
      color = '#313347';
      onClick = () => handleClickOpenModalOrder(data);
      outline = true;
    } else if (
      statusOrder === 'IN PROGRESS' &&
      (statusItem ? statusItem.some((status) => status === 'WAITING') : true)
    ) {
      title = 'Recebimento em andamento';
      color = '#E6A117';
      inProgress = true;
      onClick = () => handleClickOpenModalOrder(data);
    } else if (
      statusOrder === 'IN PROGRESS' &&
      (statusItem
        ? statusItem.some(
          (status) => status === 'NOT RECEIVED' || 'RETURNED' || 'RECEIVED'
        )
        : true)
    ) {
      title = 'Recebimento finalizado';
      color = '#5062F0';
      onClick = () => handleClickOpenModalOrder(data);
    }

    // Não recebido
    else if (
      statusOrder === 'NOT RECEIVED' &&
      (statusItem
        ? statusItem.every((status) => status === 'NOT RECEIVED')
        : true)
    ) {
      title = 'Não Recebido';
      color = '#D94141';
      notReceived = true;
      onClick = () => handleClickOpenModalOrder(data);
    } else if (
      statusOrder === 'NOT RECEIVED' &&
      (statusItem ? statusItem.some((status) => status === 'WAITING') : true)
    ) {
      title = 'Recebimento em andamento';
      color = '#E6A117';
      inProgress = true;
      onClick = () => handleClickOpenModalOrder(data);
    } else if (
      statusOrder === 'NOT RECEIVED' &&
      (statusItem
        ? statusItem.some(
          (status) => status === 'NOT RECEIVED' || 'RETURNED' || 'RECEIVED'
        )
        : true)
    ) {
      title = 'Recebimento finalizado';
      color = '#5062F0';
      onClick = () => handleClickOpenModalOrder(data);
    }

    // Devolvido
    else if (
      statusOrder === 'RETURNED' &&
      (statusItem ? statusItem.every((status) => status === 'RETURNED') : true)
    ) {
      title = 'Devolvido';
      color = '#D94141';
      returned = true;
      onClick = () => handleClickOpenModalOrder(data);
    } else if (
      statusOrder === 'RETURNED' &&
      (statusItem ? statusItem.some((status) => status === 'WAITING') : true)
    ) {
      title = 'Recebimento em andamento';
      color = '#E6A117';
      inProgress = true;
      onClick = () => handleClickOpenModalOrder(data);
    } else if (
      statusOrder === 'RETURNED' &&
      (statusItem
        ? statusItem.some(
          (status) => status === 'NOT RECEIVED' || 'RETURNED' || 'RECEIVED'
        )
        : true)
    ) {
      title = 'Recebimento finalizado';
      color = '#5062F0';
      onClick = () => handleClickOpenModalOrder(data);
    }
    //-----------------------------------

    return (
      <Tooltip title={title}>
        <IconButton aria-label="bonus" onClick={onClick} style={{ padding: 0 }}>
          {renderStatusIcon(outline, inProgress, returned, notReceived, color)}
        </IconButton>
      </Tooltip>
    );
  };

  // funcao de validacao de botoes a partir do status do sku
  const validationSku = (data, item) => {
    const statusItem = item?.sku?.status;
    const orders = data?.orderItems;
    const statusOrder = data?.status;

    let title = '';
    let color = '';
    let outline = false;
    let returned = false;
    let notReceived = false;
    let onClick;

    if (statusOrder === 'PENDING') {
      title = 'Aguarde a aprovação';
    }
    // STATUS SKU´S ---------------------
    else if (statusItem === 'RECEIVED') {
      title = 'Recebido';
      color = '#5062F0';
      onClick = () => handleClickOpenModal(data, item);
    } else if (statusItem === 'WAITING' || !statusItem) {
      if (orders.length == 1) {
        title = disabled
          ? 'Não há valores no recebimento'
          : 'Não iniciou o recebimento';
        color = '#313347';
        outline = true;
        onClick = () => handleClickOpenModal(data, item);
      } else {
        let countNotSku = 0;
        for (let index = 0; index < orders.length; index++) {
          if (orders[index].sku.notValidated == true) {
            countNotSku++;
          }
        }
        title = disabled
          ? 'Não há valores no recebimento'
          : 'Não iniciou o recebimento';
        color = '#313347';
        outline = true;
        onClick = () => handleClickOpenModal(data, item);
      }
    } else if (statusItem === 'RETURNED') {
      title = 'Devolvido';
      color = '#D94141';
      returned = true;
      onClick = () => handleClickOpenModal(data, item);
    } else if (statusItem === 'NOT RECEIVED') {
      title = 'Não Recebido';
      color = '#D94141';
      notReceived = true;
      onClick = () => handleClickOpenModal(data, item);
    } else if (statusItem === 'IN PROGRESS') {
      title = 'Não Recebido';
      color = '#D94141';
      notReceived = true;
      onClick = () => handleClickOpenModal(data, item);
    }
    //-----------------------------------

    return (
      <Tooltip title={title}>
        <IconButton aria-label="bonus" onClick={onClick} style={{ padding: 0 }}>
          {renderStatusIcon(outline, false, returned, notReceived, color)}
        </IconButton>
      </Tooltip>
    );
  };

  function handleTotalByItem(item, _status) {
    let packageValue = 0;
    let total = 0;

    if (!item.itemsPaymentDocument) return { packageValue, total };

    packageValue = item.itemsPaymentDocument.packageValue;
    total = item.itemsPaymentDocument.total;

    // if (status === "RECEIVED" && item.sku.provider) {
    //   const shipping = item?.sku?.provider?.shipping ? item.sku.provider.shipping : 0;
    //   const otherCosts = item?.sku?.provider?.otherCosts ? item.sku.provider.otherCosts : 0;
    //   const segCosts = item?.sku?.provider?.segCosts ? item.sku.provider.segCosts : 0;

    //   const discount = item.sku.provider.discount ? item.sku.provider.discount * item.costs.quantity : 0;

    //   total = total + Number(otherCosts) + Number(segCosts) + Number(shipping) - Number(discount);
    //   packageValue = total / item.costs.quantity;
    // }

    const fixedpackageValue = packageValue ? packageValue.toFixed(3) : 0;
    const fixedTotalValue = total ? total.toFixed(3) : 0;

    return { packageValue, total, fixedpackageValue, fixedTotalValue };
  }

  function handleTotal(_data, item) {
    let packageValue = 0;
    let total = 0;

    if (!item.itemsPaymentDocument) return { packageValue, total };

    packageValue = item.itemsPaymentDocument.packageValue;
    total = item.itemsPaymentDocument.total;

    // if (data.status === "RECEIVED") {
    //   const otherCosts = data.otherCosts ? data.otherCosts : 0;
    //   const segCosts = data.segCosts ? data.segCosts : 0;
    //   const shipping = data.shipping ? data.shipping : 0;
    //   const discount = data.discount ? data.discount : 0;

    //   total = total + otherCosts + segCosts + shipping - discount;
    //   packageValue = total / item.costs.quantity;
    // }

    const fixedPackageValue = packageValue ? packageValue.toFixed(3) : '';
    const fixedTotalValue = total ? total.toFixed(3) : '';

    return { packageValue, total, fixedPackageValue, fixedTotalValue };
  }

  const descriptionLength = () => {
    if (window.innerWidth <= 1366) {
      return 35
    }
    if (window.innerWidth > 1366 && window.innerWidth < 1920) {
      return 40
    }
    if (window.innerWidth >= 1920) {
      return 60
    }
  }

  // funcao que renderiza os dados de skus da tabela
  function HasList(data, index) {
    // eslint-disable-next-line react/destructuring-assignment, no-param-reassign
    if(!data.statusPaymentDocument) data.statusPaymentDocument = "VALIDATE"

    if (data.orderItems.length > 1) {
      const exists = collapseSettings
        ? collapseSettings.find((x) => x.id === data._id)
        : null;

      if (collapseSettings.length < orders.length && !exists) {
        collapseSettings.push({ id: data._id, open: false });
      }

      const total = data.orderItems.reduce(
        (total, valor) =>
          valor.itemsPaymentDocument
            ? parseFloat(total) + parseFloat(valor.itemsPaymentDocument.total)
            : total + 0,
        0
      );

      return (
        <td className="description" colSpan="14">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
            style={{ display: 'inline' }}
          >
            <table
              width="100%"
              className=""
              cellPadding="0"
              cellSpacing="0"
              border="0"
            >
              <tbody>
                <tr>
                  {colorStatus(data)}

                  <td width="26%" className="description">
                    <ListItem
                      button
                      onClick={() =>
                        setCollapseSettings((collapse) =>
                          collapse.map((x) =>
                            x && x.id === data._id ? { ...x, open: !x.open } : x
                          )
                        )
                      }
                      style={{
                        padding: 0,
                        height: '31.7px',
                        width: '100%',
                        borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
                      }}
                    >
                      {collapseSettings.find((x) => x.id === data._id) ? (
                        collapseSettings.find((x) => x.id === data._id).open ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : (
                        false
                      )}

                      <b>{`${data.orderItems.length} itens`}</b>
                    </ListItem>
                  </td>

                  <td
                    width="6%"
                    style={{
                      height: '31.7px',
                      borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                      background: 'rgba(80, 98, 240, .15)'
                    }}
                    className="pedido-qtde description right bgcolor1 p10"
                  >
                    -
                  </td>

                  <td
                    width="6%"
                    style={{ height: '31.7px', background: 'rgba(80, 98, 240, .15)', borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                    className="pedido-un description right bgcolor1 p10"
                  >
                    -
                  </td>

                  <td
                    width="6%"
                    style={{ height: '31.7px', background: 'rgba(80, 98, 240, .15)', borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                    className="pedido-unit description right bgcolor1 p10"
                  >
                    -
                  </td>

                  <td
                    width="7%"
                    className="pedido-total description right bgcolor1 p10"
                    style={{ height: '31.7px', background: 'rgba(80, 98, 240, .15)', borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                  >
                    {data.orderItems
                      .reduce(
                        (total, valor) =>
                          valor.costs
                            ? parseFloat(total) + parseFloat(valor.costs.total)
                            : total + 0,
                        0
                      )
                      .toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                  </td>

                  <td
                    width="6%"
                    style={{
                      height: '31.7px',
                      borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                      background: '#F1EBF4'
                    }}
                    className="nota-qtde description right bgcolor2 p10"
                  >
                    -
                  </td>

                  <td
                    width="6%"
                    style={{
                      height: '31.7px',
                      borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                      background: '#F1EBF4'
                    }}
                    className="nota-un description right bgcolor2 p10"
                  >
                    -
                  </td>

                  <td
                    width="6%"
                    style={{
                      height: '31.7px',
                      borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                      background: '#F1EBF4'
                    }}
                    className="nota-unit description right bgcolor2 p10"
                  >
                    -
                  </td>

                  <td
                    width="7%"
                    className="nota-total description right bgcolor2 p10"
                    style={{
                      height: '31.7px',
                      borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                      background: '#F1EBF4'
                    }}
                  >
                    {isNaN(total)
                      ? '0,000'
                      : total.toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })}
                  </td>

                  <td
                    width="36.7px"
                    style={{
                      height: '31.7px',
                      borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
                    }}
                    className="status description center"
                  >
                    {validationOrder(data)}
                  </td>
                </tr>
              </tbody>
            </table>

            <Collapse
              in={
                collapseSettings.find((x) => x.id === data._id)
                  ? collapseSettings.find((x) => x.id === data._id).open
                  : false
              }
              timeout="0"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem style={{ padding: '0 0 0 0' }}>
                  <table
                    width="100%"
                    className="dFlex flexColumn"
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                  >
                    {data.orderItems.map((item, itemIndex) => (
                      <tbody className="dFlex">
                        <tr>
                          <td colSpan="10" height="1" />
                        </tr>

                        <tr className="dFlex w100">
                          <td
                            className="description"
                            style={{
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                              borderBottom: 'solid 1px #A0A1A7',
                              height: '31.7px',
                              marginLeft: '21.5%',
                              width: '26%',
                              color: `${item.sku.notValidated ? '#FF0000' : '#5062F0'}`
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingRight: '10px'
                              }}
                            >
                              <div className="dFlex">
                                <div className="dFlex" style={{ width: 26, height: 26, margin: '0 3px 0 10px' }}>
                                  <ImageWrapper
                                    width="26px"
                                    height="26px"
                                    url={item?.sku?.images?.url}
                                    borderType={item?.isTransference ? 'INTERNAL' : 'EXTERNAL'}
                                  />
                                </div>

                                <Tooltip
                                  placement="bottom"
                                  title={
                                    <p className="mb0" style={{ padding: '2px 4px' }}>
                                      {!item?.sku?.descriptionProvider || !item?.sku?.description 
                                        ? item?.product?.description
                                        : `${item?.sku?.descriptionProvider || item?.sku?.description} - Cod.Fornecedor ${item?.sku?.provider?.codeProvider}`
                                      }
                                    </p>
                                  }
                                >
                                  <div
                                    className={`cursorPointer ml5 ${item?.sku?.status === 'RECEIVED' && 'pl5'}`}
                                    onClick={() => {
                                      getOptions(
                                        `${environments.catalog}/skus/${item?.sku?.id}`,
                                        'skus',
                                        selectedSku,
                                        setSelectedSku,
                                        getId,
                                        null,
                                        null,
                                        null,
                                        true
                                      );
                                      setModalSettings(
                                        modalSettings.map((modal) =>
                                          modal.name === 'skuModal'
                                            ? { ...modal, open: true }
                                            : modal
                                        )
                                      );
                                    }}
                                  >
                                    {!item?.sku?.descriptionProvider || !item?.sku?.description 
                                      ? ValidationLength(item?.product?.description, smallScreen ? 40 : 45, true) 
                                      : ValidationLength(
                                          `${item?.sku?.descriptionProvider || item?.sku?.description}`,
                                          descriptionLength(),
                                          true,
                                          null,
                                          null,
                                          `${item?.sku?.descriptionProvider || item?.sku?.description} - Cod.Fornecedor ${item?.sku?.provider?.codeProvider}`
                                        )
                                    }
                                  </div>
                                </Tooltip>
                              </div>

                              {/* {item?.sku?.notValidated && (
                                <SearchInLineEAN
                                  setDataReceives={setDataReceives}
                                  dataReceives={dataReceives}
                                  itemIndex={itemIndex}
                                  orderIndex={index}
                                  productDescription={
                                    item?.sku ? item?.sku?.description : ''
                                  }
                                  paramsID={paramsID}
                                  userInfo={userInfo}
                                  orderItem={item || {}}
                                  order={data}
                                  itemProviderId={data.providerId}
                                  setUpdateMainList={() => {
                                    setFiltersChanged(filtersChanged);
                                  }}
                                />
                              )} */}
                            </div>
                          </td>

                          {data.statusPaymentDocument === 'VALIDATE' ||
                            data.statusPaymentDocument === 'PENDING' ||
                            data.status === 'CANCELED' ||
                            data.status === 'RETURNED' ||
                            item.sku.status === 'RECEIVED' ||
                            item.sku.status === 'RETURNED' ||
                            item.sku.status === 'CANCELED' ||
                            item.sku.status === 'NOT RECEIVED' ||
                            !item.itemsPaymentDocument ? (
                            <td
                              style={{
                                borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                                borderBottom: 'solid 1px #A0A1A7',
                                height: '31.7px',
                                width: '6%',
                                background: 'rgba(80, 98, 240, .15)'
                              }}
                              className="pedido-qtde description right bgcolor1 p10"
                            >
                              {item.costs
                                ? item.costs?.quantity?.toLocaleString(
                                  'pt-br',
                                  { minimumFractionDigits: 3 }
                                )
                                : '-'}
                            </td>
                          ) : (
                            <td
                              style={{
                                borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                                borderBottom: 'solid 1px #A0A1A7',
                                height: '31.7px',
                                width: '6%',
                                background: 'rgba(80, 98, 240, .15)'
                              }}
                              className="pedido-qtde description right bgcolor1 p10"
                            >
                              <form
                                className={classes.root}
                                noValidate
                                autoComplete="off"
                              >
                                <CurrencyTextField
                                  minimumValue="0"
                                  id="standard-basic2"
                                  size="small"
                                  className="paymentDocument bgcolor1"
                                  currencySymbol=""
                                  outputFormat="string"
                                  decimalCharacter=","
                                  digitGroupSeparator="."
                                  decimalPlaces={3}
                                  decimalPlacesShownOnFocus={3}
                                  style={{
                                    width: '100%',
                                    height: '30px',
                                    borderColor: 'none',
                                    margin: '0',
                                    textAlign: 'right'
                                  }}
                                  InputProps={{
                                    style: {
                                      fontFamily: 'Lato',
                                      fontSize: '12px'
                                    }
                                  }}
                                  InputLabelProps={{
                                    style: { fontFamily: 'Lato' }
                                  }}
                                  value={
                                    item.costs && item.costs.quantity
                                      ? item.costs.quantity
                                      : 0
                                  }
                                  onChange={(_e, value) => {
                                    setDataReceives((receive) =>
                                      receive.map((order) =>
                                        order?._id === data?._id
                                          ? {
                                            ...order,
                                            orderItems: order.orderItems.map(
                                              (stateItem) => {
                                                const currentValidation = stateItem?.sku?.provider?.id === item?.sku?.provider?.id

                                                return currentValidation
                                                  ? {
                                                    ...stateItem,
                                                    costs: {
                                                      ...stateItem?.costs,
                                                      quantity: parseFloat(
                                                        value
                                                          .replace('.', ',')
                                                          .replace(
                                                            /,/g,
                                                            '.'
                                                          )
                                                      ),
                                                      total:
                                                        parseFloat(
                                                          value
                                                            .replace(
                                                              '.',
                                                              ','
                                                            )
                                                            .replace(
                                                              /,/g,
                                                              '.'
                                                            )
                                                        ) *
                                                        parseFloat(
                                                          stateItem?.costs
                                                            .packageValue
                                                        )
                                                    }
                                                  }
                                                  : stateItem
                                              })
                                          }
                                          : order
                                      )
                                    );
                                  }}
                                  onBlur={() => putEditOrder(data, item)}
                                />
                              </form>
                            </td>
                          )}

                          <td
                            style={{
                              height: '31.7px',
                              background: 'rgba(80, 98, 240, .15)',
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                              borderBottom: 'solid 1px #A0A1A7'
                            }}
                            width="6%"
                            className="pedido-un description right bgcolor1 p10"
                          >
                            {item.sku.provider ? (
                              <>
                                E{item?.sku?.provider?.quantity}x
                                {item?.sku?.quantity}
                                {item?.unitsMeasurements?.abbreviation}
                              </>
                            ) : (
                              <>
                                {item.unitsMeasurements
                                  ? item.unitsMeasurements?.abbreviation
                                  : '-'}
                              </>
                            )}
                          </td>

                          <td
                            style={{
                              height: '31.7px',
                              background: 'rgba(80, 98, 240, .15)',
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                              borderBottom: 'solid 1px #A0A1A7'
                            }}
                            width="6%"
                            className="pedido-unit description right bgcolor1 p10"
                          >
                            {item.costs
                              ? Number(item.costs.packageValue).toLocaleString(
                                'pt-br',
                                { minimumFractionDigits: 3 }
                              )
                              : '-'}
                          </td>

                          <td
                            width="7%"
                            className="pedido-total description right bgcolor1 p10"
                            style={{
                              borderBottom: 'solid 1px #A0A1A7',
                              height: '31.7px',
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                              background: 'rgba(80, 98, 240, .15)'
                            }}
                          >
                            {item.costs
                              ? Number(item.costs.total).toLocaleString(
                                'pt-br',
                                { minimumFractionDigits: 3 }
                              )
                              : '-'}
                          </td>

                          {/* NOTA DIVERGENTE ATUALIZAR QTD E UNID.MED */}
                          <td
                            style={{
                              borderBottom: 'solid 1px #A0A1A7',
                              height: '31.7px',
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                              background: '#F1EBF4'
                            }}
                            width="6%"
                            className="nota-qtde2 description right p10"
                          >
                            <div className="paymentDocument description bgcolor2 editable">
                              <CurrencyTextField
                                minimumValue="0"
                                id="standard-basic1"
                                size="small"
                                className="paymentDocument bgcolor2"
                                currencySymbol=""
                                outputFormat="string"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                decimalPlaces={3}
                                decimalPlacesShownOnFocus={3}
                                InputProps={{
                                  style: {
                                    fontFamily: 'Lato',
                                    fontSize: '12px',
                                    color: 'black'
                                  }
                                }}
                                InputLabelProps={{
                                  style: { fontFamily: 'Lato', color: 'black' }
                                }}
                                value={
                                  item.itemsPaymentDocument &&
                                    item.itemsPaymentDocument.quantity
                                    ? item.itemsPaymentDocument.quantity
                                    : 0
                                }
                                onBlur={(_e, value) => {
                                  setDataReceives((receive) =>
                                    receive.map((order) =>
                                      order._id === data._id
                                        ? {
                                          ...order,
                                          orderItems: order.orderItems.map(
                                            (stateItem) => {
                                              const currentValidation = stateItem?.sku?.provider?.id === item?.sku?.provider?.id

                                              return currentValidation ? {
                                                  ...stateItem,
                                                  itemsPaymentDocument: {
                                                    ...stateItem.itemsPaymentDocument,
                                                    quantity: parseFloat(
                                                      value
                                                        .replace('.', ',')
                                                        .replace(/,/g, '.')
                                                    ),
                                                    packageValue:
                                                      (stateItem.itemsPaymentDocument &&
                                                        stateItem
                                                          .itemsPaymentDocument
                                                          .total
                                                        ? parseFloat(
                                                          stateItem
                                                            .itemsPaymentDocument
                                                            .total
                                                        )
                                                        : 0) /
                                                      parseFloat(
                                                        value
                                                          .replace('.', ',')
                                                          .replace(
                                                            /,/g,
                                                            '.'
                                                          )
                                                      )
                                                  }
                                                }
                                                : stateItem
                                          })
                                        }
                                        : order
                                    )
                                  );
                                }}
                              />
                            </div>
                          </td>

                          <td
                            style={{
                              borderBottom: 'solid 1px #A0A1A7',
                              height: '31.7px',
                              background: '#F1EBF4',
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
                            }}
                            width="6%"
                            className="nota-un description right bgcolor2 p10"
                          >
                            {item.itemsPaymentDocument &&
                              item.itemsPaymentDocument.unitsMeasurements &&
                              item.itemsPaymentDocument.provider ? (
                              `E${item.sku.provider.quantity}x${item.sku.quantity}${item.itemsPaymentDocument.unitsMeasurements.abbreviation}`
                            ) : (
                              <>
                                {item.sku.provider ? (
                                  <>
                                    E{item.sku.provider.quantity}x
                                    {item.sku.quantity}
                                    {item?.unitsMeasurements?.abbreviation}
                                  </>
                                ) : (
                                  <>
                                    {item.unitsMeasurements
                                      ? item.unitsMeasurements.abbreviation
                                      : '-'}
                                  </>
                                )}
                              </>
                            )}
                          </td>

                          <td
                            width="6%"
                            className="nota-unit description right bgcolor2 editable p10"
                            style={{
                              borderBottom: 'solid 1px #A0A1A7',
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                              height: '31.7px',
                              color:
                                item.itemsPaymentDocument &&
                                  item.itemsPaymentDocument.packageValue !==
                                  item.costs.packageValue
                                  ? 'red'
                                  : 'black',
                              background: '#F1EBF4'
                            }}
                          >
                            <CurrencyTextField
                              minimumValue="0"
                              id="standard-basic1"
                              size="small"
                              className="paymentDocument bgcolor2"
                              currencySymbol=""
                              outputFormat="string"
                              decimalCharacter=","
                              digitGroupSeparator="."
                              decimalPlaces={3}
                              decimalPlacesShownOnFocus={3}
                              InputProps={{
                                style: {
                                  fontFamily: 'Lato',
                                  fontSize: '12px',
                                  color: 'black'
                                }
                              }}
                              InputLabelProps={{
                                style: { fontFamily: 'Lato', color: 'black' }
                              }}
                              value={
                                handleTotalByItem(item, data.status)
                                  .fixedpackageValue
                              }
                              onBlur={(_e, value) => {
                                setDataReceives((receive) =>
                                  receive.map((order) =>
                                    order._id === data._id
                                      ? {
                                        ...order,
                                        orderItems: order.orderItems.map(
                                          (stateItem) => {
                                            const currentValidation = stateItem?.sku?.provider?.id === item?.sku?.provider?.id
                                              
                                            return currentValidation ? {
                                              ...stateItem,
                                              itemsPaymentDocument: {
                                                ...stateItem.itemsPaymentDocument,
                                                packageValue:
                                                  value !==
                                                    stateItem.itemsPaymentDocument?.packageValue?.toFixed(
                                                      3
                                                    )
                                                    ? parseFloat(
                                                      value
                                                        .replace(
                                                          '.',
                                                          ','
                                                        )
                                                        .replace(
                                                          /,/g,
                                                          '.'
                                                        )
                                                    )
                                                    : stateItem
                                                      .itemsPaymentDocument
                                                      ?.packageValue ||
                                                    0,
                                                total:
                                                  value !==
                                                    stateItem.itemsPaymentDocument?.packageValue?.toFixed(
                                                      3
                                                    )
                                                    ? parseFloat(
                                                      value
                                                        .replace(
                                                          '.',
                                                          ','
                                                        )
                                                        .replace(
                                                          /,/g,
                                                          '.'
                                                        )
                                                    ) *
                                                    (stateItem.itemsPaymentDocument &&
                                                      stateItem
                                                        .itemsPaymentDocument
                                                        .quantity
                                                      ? parseFloat(
                                                        stateItem
                                                          .itemsPaymentDocument
                                                          .quantity
                                                      )
                                                      : 0)
                                                    : stateItem
                                                      .itemsPaymentDocument
                                                      .total
                                              }
                                            }
                                            : stateItem
                                        })
                                      }
                                      : order
                                  )
                                );
                              }}
                            />
                          </td>

                          <td
                            width="7%"
                            className="nota-total description right bgcolor2 editable p10"
                            style={{
                              borderBottom: 'solid 1px #A0A1A7',
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                              height: '31.7px',
                              color:
                                item.itemsPaymentDocument &&
                                  item.itemsPaymentDocument.total !==
                                  item.costs.total
                                  ? 'red'
                                  : 'black',
                              background: '#F1EBF4'
                            }}
                          >
                            <CurrencyTextField
                              minimumValue="0"
                              id="standard-basic3"
                              size="small"
                              className="paymentDocument bgcolor2"
                              currencySymbol=""
                              outputFormat="string"
                              decimalCharacter=","
                              digitGroupSeparator="."
                              decimalPlaces={3}
                              decimalPlacesShownOnFocus={3}
                              InputProps={{
                                style: {
                                  fontFamily: 'Lato',
                                  fontSize: '12px',
                                  color: 'black'
                                }
                              }}
                              InputLabelProps={{
                                style: { fontFamily: 'Lato', color: 'black' }
                              }}
                              value={
                                handleTotalByItem(item, data.status)
                                  .fixedTotalValue
                              }
                              onBlur={(_e, value) => {
                                setDataReceives((receive) =>
                                  receive.map((order) =>
                                    order._id === data._id
                                      ? {
                                        ...order,
                                        orderItems: order.orderItems.map(
                                          (stateItem) => {
                                            const currentValidation = stateItem?.sku?.provider?.id === item?.sku?.provider?.id
                                            
                                            return currentValidation ? {
                                              ...stateItem,
                                              itemsPaymentDocument: {
                                                ...stateItem.itemsPaymentDocument,
                                                packageValue:
                                                  parseFloat(
                                                    value
                                                      .replace('.', ',')
                                                      .replace(/,/g, '.')
                                                  ) /
                                                  (stateItem.itemsPaymentDocument &&
                                                    stateItem
                                                      .itemsPaymentDocument
                                                      .quantity
                                                    ? parseFloat(
                                                      stateItem
                                                        .itemsPaymentDocument
                                                        .quantity
                                                    )
                                                    : 0),
                                                total: parseFloat(
                                                  value
                                                    .replace('.', ',')
                                                    .replace(/,/g, '.')
                                                )
                                              }
                                            }
                                            : stateItem
                                        })
                                      }
                                      : order
                                  )
                                );
                              }}
                            />
                          </td>

                          <td
                            style={{
                              height: '31.7px',
                              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                              borderBottom: 'solid 1px #A0A1A7'
                            }}
                            width="36.7px"
                            className="status description center skuStatus"
                          >
                            {validationSku(data, item)}
                          </td>
                        </tr>
                      </tbody>
                    ))}

                    <tbody className="dFlex">
                      <tr className="dFlex w100">
                        <td
                          width="47.5%"
                          className="descriptionSku3 subtitle fontSizeDefault"
                          style={{
                            padding: '0 0 0 12px',
                            background: '#DDDDDD'
                          }}
                        >
                          Total Pedido e NF
                        </td>
                        <td
                          width="6%"
                          className="p10"
                          style={{
                            borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                            background: '#DDDDDD'
                          }}
                        />
                        <td
                          width="6%"
                          className="p10"
                          style={{ background: '#DDDDDD' }}
                        />
                        <td
                          width="6%"
                          className="p10"
                          style={{ background: '#DDDDDD' }}
                        />
                        <td
                          width="7%"
                          className="subtitle right p10 fontSizeDefault"
                          style={{ background: '#DDDDDD' }}
                        >
                          {data.orderItems
                            .reduce(
                              (total, valor) =>
                                valor.costs
                                  ? parseFloat(total) +
                                  parseFloat(valor.costs.total)
                                  : total + 0,
                              0
                            )
                            .toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                        </td>
                        <td
                          width="6%"
                          className="p10"
                          style={{
                            borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                            background: '#DDDDDD'
                          }}
                        />
                        <td
                          width="6%"
                          className="p10"
                          style={{ background: '#DDDDDD' }}
                        />
                        <td
                          width="6%"
                          className="p10"
                          style={{ background: '#DDDDDD' }}
                        />
                        <td
                          width="7%"
                          className="subtitle right p10 fontSizeDefault"
                          style={{ background: '#DDDDDD' }}
                        >
                          {isNaN(total)
                            ? '0,000'
                            : total.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                        </td>
                        <td width="2.5%" style={{ background: '#DDDDDD' }} />
                      </tr>
                    </tbody>
                  </table>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </td>
      );
    }
    
    if (data.orderItems.length === 0) {
      return (
        <td className="description" colSpan="14">
          {colorStatus(data)}

          <td
            className="pl10"
            style={{
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
              width: '26%',
              height: '31.7px'
            }}
          >
            &nbsp;
          </td>

          <td
            style={{
              width: '6%',
              height: '31.7px',
              background: 'rgba(80, 98, 240, .15)',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
            }}
            className="description right bgcolor1 p10"
          >
            &nbsp;
          </td>

          <td
            style={{
              width: '6%',
              height: '31.7px',
              background: 'rgba(80, 98, 240, .15)',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
            }}
            className="description right bgcolor1 p10"
          >
            &nbsp;
          </td>

          <td
            style={{
              width: '6%',
              height: '31.7px',
              background: 'rgba(80, 98, 240, .15)',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
            }}
            className="description right bgcolor1 p10"
          >
            &nbsp;
          </td>

          <td
            className="description right bgcolor1 p10"
            style={{
              width: '7%',
              height: '31.7px',
              background: 'rgba(80, 98, 240, .15)',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
            }}
          >
            {data.totalOrderAmount && data.totalOrderAmount
              ? Number(data.totalOrderAmount).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
              : '-'}
          </td>

          {/* NOTA DIVERGENTE ATUALIZAR QTD E UNID.MED */}
          <td
            style={{
              width: '6%',
              height: '31.7px',
              background: '#F1EBF4',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
            }}
            className="description right bgcolor2 editable p10"
          >
            &nbsp;
          </td>

          <td
            style={{
              width: '6%',
              height: '31.7px',
              background: '#F1EBF4',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
            }}
            className="description right bgcolor2 p10"
          >
            &nbsp;
          </td>

          <td
            style={{
              width: '6%',
              height: '31.7px',
              background: '#F1EBF4',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
            }}
            className="description right bgcolor2 editable p10"
          >
            &nbsp;
          </td>

          <td
            className="description right bgcolor2 editable p10"
            style={{
              width: '7%',
              height: '31.7px',
              background: '#F1EBF4',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
            }}
          >
            <CurrencyTextField
              minimumValue="0"
              id="standard-basic3"
              size="small"
              className="paymentDocument bgcolor2"
              currencySymbol=""
              outputFormat="string"
              decimalCharacter=","
              digitGroupSeparator="."
              decimalPlaces={3}
              decimalPlacesShownOnFocus={3}
              InputProps={{
                style: { fontFamily: 'Lato', fontSize: '12px', color: 'black' }
              }}
              InputLabelProps={{
                style: { fontFamily: 'Lato', color: 'black' }
              }}
              value={data.totalOrderAmount ? data.totalOrderAmount : 0}
              onBlur={(_e, value) => {
                setDataReceives((receive) =>
                  receive.map((order) =>
                    order._id === data._id
                      ? {
                        ...order,
                        totalOrderReceivedAmount: parseFloat(
                          value.replace('.', ',').replace(/,/g, '.')
                        )
                      }
                      : order
                  )
                );
              }}
            />
          </td>

          <td
            style={{
              width: '36.7px',
              height: '31.7px',
              borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
            }}
            className="description center"
          >
            {validationOrder(data)}
          </td>
        </td>
      );
    }
    return (
      <>
        {data.orderItems.map((item, itemIndex) => (
          <td className="description" colSpan="14">
            {colorStatus(data)}

            <td
              style={{
                borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                color: `${item?.sku?.notValidated ? '#FF0000' : '#5062F0'}`,
                width: '26%',
                height: '31.7px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: '10px'
                }}
              >
                <div className="dFlex">
                  <div className="dFlex" style={{ width: 26, height: 26, margin: '0 3px 0 10px' }}>
                    <ImageWrapper
                      width="26px"
                      height="26px"
                      url={item?.sku?.images?.url}
                      borderType={item?.isTransference ? 'INTERNAL' : 'EXTERNAL'}
                    />
                  </div>

                  <Tooltip
                    placement="bottom"
                    title={
                      <p className="mb0" style={{ padding: '2px 4px' }}>
                        {!item?.sku?.descriptionProvider || !item?.sku?.description 
                          ? item?.product?.description
                          : `${item?.sku?.descriptionProvider || item?.sku?.description} - Cod.Fornecedor ${item?.sku?.provider?.codeProvider}`
                        }
                      </p>
                    }
                  >
                    <div>
                      <Tooltip
                        disableHoverListener={!item?.sku?.notValidated}
                        placement="right-end"
                        title={
                          <p className="mb0" style={{ padding: '4px 6px' }}>
                            Sku não catalogado
                          </p>
                        }
                      >
                        <div
                          className={`cursorPointer ml5 ${item?.sku?.status === 'RECEIVED' && 'pl5'}`}
                          onClick={() => {
                            getOptions(
                              `${environments.catalog}/skus/${item?.sku?.id}`,
                              'skus',
                              selectedSku,
                              setSelectedSku,
                              getId,
                              null,
                              null,
                              null,
                              true
                            );
                            setModalSettings(
                              modalSettings.map((modal) =>
                                modal.name === 'skuModal'
                                  ? { ...modal, open: true }
                                  : modal
                              )
                            );
                          }}
                        >
                          {!item?.sku?.descriptionProvider || !item?.sku?.description 
                            ? ValidationLength(item?.product?.description, smallScreen ? 40 : 45, true) 
                            : ValidationLength(
                                `${item?.sku?.descriptionProvider || item?.sku?.description}`,
                                descriptionLength(),
                                true,
                                null,
                                null,
                                `${item?.sku?.descriptionProvider || item?.sku?.description} - Cod.Fornecedor ${item?.sku?.provider?.codeProvider}`
                              )
                          }
                        </div>
                      </Tooltip>
                    </div>
                  </Tooltip>
                </div>

                {/* {data?.orderItems.length === 1 &&
                  data?.orderItems[0].sku?.notValidated && (
                    <SearchInLineEAN
                      setDataReceives={setDataReceives}
                      dataReceives={dataReceives}
                      itemIndex={itemIndex}
                      orderIndex={index}
                      productDescription={
                        data?.orderItems
                          ? data?.orderItems[0]?.sku?.description
                          : ''
                      }
                      paramsID={paramsID}
                      userInfo={userInfo}
                      orderItem={data?.orderItems[0] || {}}
                      order={data}
                      itemProviderId={data.providerId}
                      setUpdateMainList={() => {
                        setFiltersChanged(filtersChanged);
                      }}
                    />
                  )} */}
              </div>
            </td>

            {data?.statusPaymentDocument === 'VALIDATE' ||
              data?.statusPaymentDocument === 'PENDING' ||
              data?.status === 'CANCELED' ||
              data?.status === 'RETURNED' ||
              item?.sku?.status === 'RECEIVED' ||
              item?.sku?.status === 'RETURNED' ||
              item?.sku?.status === 'CANCELED' ||
              item?.sku?.status === 'NOT RECEIVED' ||
              !item?.itemsPaymentDocument ? (
              <td
                style={{
                  width: '6%',
                  height: '31.7px',
                  background: 'rgba(80, 98, 240, .15)',
                  borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
                }}
                className="description right bgcolor1 p10"
              >
                {item?.costs
                  ? item?.costs.quantity?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '-'}
              </td>
            ) : (
              <Tooltip
                TransitionProps={{ timeout: 600 }}
                title={`Qtde. - Pedido ${item.costs && item.costs.quantity && item.costs.quantity
                  }`}
              >
                <td
                  style={{
                    width: '6%',
                    height: '31.7px',
                    background: 'rgba(80, 98, 240, .15)',
                    borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
                  }}
                  className="description right bgcolor1 p10"
                >
                  <form className={classes.root} noValidate autoComplete="off">
                    <CurrencyTextField
                      minimumValue="0"
                      id="standard-basic2"
                      size="small"
                      className="paymentDocument bgcolor1"
                      currencySymbol=""
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      style={{
                        width: '100%',
                        height: '30px',
                        borderColor: 'none',
                        color:
                          item.costs.quantity !==
                            item.itemsPaymentDocument.quantity
                            ? '#ff8080'
                            : '#fff',
                        margin: '0',
                        textAlign: 'right'
                      }}
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                      value={
                        item.itemsPaymentDocument &&
                          item.itemsPaymentDocument.quantity
                          ? item.itemsPaymentDocument.quantity
                          : 0
                      }
                      onChange={(_e, value) => {
                        setDataReceives((receive) =>
                          receive.map((order) =>
                            order?._id === data?._id
                              ? {
                                ...order,
                                orderItems: order?.orderItems.map(
                                  (stateItem) => {
                                    const currentValidation = stateItem?.sku?.provider?.id === item?.sku?.provider?.id

                                    return currentValidation ? {
                                      ...stateItem,
                                      costs: {
                                        ...stateItem?.costs,
                                        quantity: parseFloat(
                                          value
                                            .replace('.', ',')
                                            .replace(/,/g, '.')
                                        ),
                                        total:
                                          parseFloat(
                                            value
                                              .replace('.', ',')
                                              .replace(/,/g, '.')
                                          ) *
                                          parseFloat(
                                            stateItem?.itemsPaymentDocument
                                              ?.packageValue
                                          )
                                      }
                                    }
                                    : stateItem
                                })
                              }
                              : order
                          )
                        );

                        setDivergentQuantityEdited(true);
                      }}
                      onBlur={() => putEditOrder(data, item)}
                    />
                  </form>
                </td>
              </Tooltip>
            )}

            <td
              style={{
                width: '6%',
                height: '31.7px',
                background: 'rgba(80, 98, 240, .15)',
                borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
              }}
              className="description right bgcolor1 p10"
            >
              {item?.sku?.provider ? (
                <>
                  E{item?.sku?.provider?.quantity}x{item?.sku?.quantity}
                  {item?.unitsMeasurements?.abbreviation}
                </>
              ) : (
                ` E1x1${item?.unitsMeasurements?.abbreviation}` || '-'
              )}
            </td>

            {data.statusPaymentDocument === 'DIVERGENT' ? (
              <Tooltip
                TransitionProps={{ timeout: 600 }}
                title={`R$ Emb - Pedido ${item.costs &&
                  Number(item.costs.packageValue).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  }`}
              >
                <td
                  className="description right bgcolor1 p10"
                  style={{
                    color:
                      item.itemsPaymentDocument &&
                        item.itemsPaymentDocument.packageValue !==
                        item.costs.packageValue
                        ? 'red'
                        : 'black',
                    width: '6%',
                    height: '31.7px',
                    background: 'rgba(80, 98, 240, .15)',
                    borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                  }}
                >
                  {item.itemsPaymentDocument
                    ? Number(
                      item.itemsPaymentDocument.packageValue
                    ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
                    : '-'}
                </td>
              </Tooltip>
            ) : (
              <td
                style={{
                  width: '6%',
                  height: '31.7px',
                  background: 'rgba(80, 98, 240, .15)',
                  borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                }}
                className="description right bgcolor1 p10"
              >
                {item.costs &&
                  Number(item.costs.packageValue).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
              </td>
            )}

            {data.statusPaymentDocument === 'DIVERGENT' &&
              !divergentQuantityEdited ? (
              <Tooltip
                TransitionProps={{ timeout: 600 }}
                title={`R$ Total - Pedido ${item.costs &&
                  item.costs.total &&
                  Number(item.costs.total).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  }`}
              >
                <td
                  className="description right bgcolor1 p10"
                  style={{
                    width: '7%',
                    color:
                      item.itemsPaymentDocument &&
                        item.itemsPaymentDocument.total !== item.costs.total
                        ? 'red'
                        : 'black',
                    height: '31.7px',
                    background: 'rgba(80, 98, 240, .15)',
                    borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                  }}
                >
                  {item.itemsPaymentDocument && item.itemsPaymentDocument.total
                    ? Number(item.itemsPaymentDocument.total).toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 3 }
                    )
                    : '-'}
                </td>
              </Tooltip>
            ) : (
              <td
                className="description right bgcolor1 p10"
                style={{
                  width: '7%',
                  color:
                    item.costs && item.costs.total !== item.costs.total
                      ? 'red'
                      : 'black',
                  height: '31.7px',
                  background: 'rgba(80, 98, 240, .15)',
                  borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                }}
              >
                {item.costs && item.costs.total
                  ? Number(item.costs.total).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '-'}
              </td>
            )}

            {/* NOTA DIVERGENTE ATUALIZAR QTD E UNID.MED */}
            <td
              style={{
                width: '6%',
                height: '31.7px',
                background: '#F1EBF4',
                borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
              }}
              className="description right bgcolor2 editable p10"
            >
              <CurrencyTextField
                minimumValue="0"
                id="standard-basic3"
                size="small"
                className="paymentDocument bgcolor2"
                currencySymbol=""
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                decimalPlaces={3}
                decimalPlacesShownOnFocus={3}
                InputProps={{
                  style: {
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    color: 'black'
                  }
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Lato', color: 'black' }
                }}
                value={
                  item.itemsPaymentDocument &&
                    item.itemsPaymentDocument.quantity
                    ? item.itemsPaymentDocument.quantity
                    : 0
                }
                onBlur={(_e, value) => {
                  setDataReceives((receive) =>
                    receive.map((order) =>
                      order?._id === data?._id
                        ? {
                          ...order,
                          orderItems: order.orderItems.map((stateItem) => {
                            const currentValidation = stateItem?.sku?.provider?.id === item?.sku?.provider?.id

                            return currentValidation ? {
                              ...stateItem,
                              itemsPaymentDocument: {
                                ...stateItem.itemsPaymentDocument,
                                quantity: parseFloat(
                                  value
                                    .replace('.', ',')
                                    .replace(/,/g, '.')
                                ),
                                packageValue:
                                  (stateItem.itemsPaymentDocument &&
                                    stateItem.itemsPaymentDocument.total
                                    ? parseFloat(
                                      stateItem.itemsPaymentDocument
                                        .total
                                    )
                                    : 0) /
                                  parseFloat(
                                    value
                                      .replace('.', ',')
                                      .replace(/,/g, '.')
                                  )
                              }
                            }
                            : stateItem
                          })
                        }
                        : order
                    )
                  );
                }}
              />
            </td>

            <td
              style={{
                width: '6%',
                height: '31.7px',
                background: '#F1EBF4',
                borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
              }}
              className="description right bgcolor2 p10"
            >
              {item?.itemsPaymentDocument?.unitsMeasurements &&
                item?.itemsPaymentDocument?.provider ? (
                `E${item?.sku?.provider?.quantity}x${item?.sku?.quantity}${item?.itemsPaymentDocument?.unitsMeasurements?.abbreviation}`
              ) : item?.sku?.provider ? (
                <>
                  E{item?.sku?.provider?.quantity}x{item?.sku?.quantity}
                  {item?.unitsMeasurements?.abbreviation}
                </>
              ) : item?.unitsMeasurements ? (
                ` E1x1${item?.unitsMeasurements?.abbreviation}`
              ) : (
                '-'
              )}
            </td>

            <td
              style={{
                width: '6%',
                height: '31.7px',
                background: '#F1EBF4',
                borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
              }}
              className="description right bgcolor2 editable p10"
            >
              <CurrencyTextField
                minimumValue="0"
                id="standard-basic3"
                size="small"
                className="paymentDocument bgcolor2"
                currencySymbol=""
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                decimalPlaces={3}
                decimalPlacesShownOnFocus={3}
                InputProps={{
                  style: {
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    color: 'black'
                  }
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Lato', color: 'black' }
                }}
                value={handleTotal(data, item).packageValue}
                onBlur={(_e, value) => {
                  setDataReceives((receive) =>
                    receive.map((order) =>
                      order?._id === data?._id
                        ? {
                          ...order,
                          orderItems: order.orderItems.map((stateItem) =>
                            stateItem?.sku?.id === item?.sku?.id &&
                              stateItem?.sku?.description === item?.sku?.description
                              ? {
                                ...stateItem,
                                itemsPaymentDocument: {
                                  ...stateItem.itemsPaymentDocument,
                                  packageValue:
                                    value !==
                                      stateItem.itemsPaymentDocument?.packageValue?.toFixed(
                                        3
                                      )
                                      ? parseFloat(
                                        value
                                          .replace('.', ',')
                                          .replace(/,/g, '.')
                                      )
                                      : stateItem.itemsPaymentDocument
                                        ?.packageValue || 0,
                                  total:
                                    value !==
                                      stateItem.itemsPaymentDocument?.packageValue?.toFixed(
                                        3
                                      )
                                      ? parseFloat(
                                        value
                                          .replace('.', ',')
                                          .replace(/,/g, '.')
                                      ) *
                                      (stateItem.itemsPaymentDocument &&
                                        stateItem.itemsPaymentDocument
                                          .quantity
                                        ? parseFloat(
                                          stateItem.itemsPaymentDocument
                                            .quantity
                                        )
                                        : 0)
                                      : stateItem.itemsPaymentDocument
                                        .total || 0
                                }
                              }
                              : stateItem
                          )
                        }
                        : order
                    )
                  );
                }}
              />
            </td>

            <td
              className="description right bgcolor2 editable p10"
              style={{
                width: '7%',
                height: '31.7px',
                background: '#F1EBF4',
                borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
              }}
            >
              <CurrencyTextField
                minimumValue="0"
                id="standard-basic3"
                size="small"
                className="paymentDocument bgcolor2"
                currencySymbol=""
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                decimalPlaces={3}
                decimalPlacesShownOnFocus={3}
                InputProps={{
                  style: {
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    color: 'black'
                  }
                }}
                InputLabelProps={{
                  style: { fontFamily: 'Lato', color: 'black' }
                }}
                value={handleTotal(data, item).total}
                onBlur={(_e, value) => {
                  setDataReceives((receive) =>
                    receive.map((order) =>
                      order?._id === data?._id
                        ? {
                          ...order,
                          orderItems: order.orderItems.map((stateItem) => {
                            const currentValidation = stateItem?.sku?.provider?.id === item?.sku?.provider?.id
                                      
                            return currentValidation ? {
                              ...stateItem,
                              itemsPaymentDocument: {
                                ...stateItem.itemsPaymentDocument,
                                packageValue:
                                  parseFloat(
                                    value
                                      .replace('.', ',')
                                      .replace(/,/g, '.')
                                  ) /
                                  (stateItem.itemsPaymentDocument &&
                                    stateItem.itemsPaymentDocument.quantity
                                    ? parseFloat(
                                      stateItem.itemsPaymentDocument
                                        .quantity
                                    )
                                    : 0),
                                total: parseFloat(
                                  value
                                    .replace('.', ',')
                                    .replace(/,/g, '.')
                                )
                              }
                            }
                            : stateItem
                          })
                        }
                        : order
                    )
                  );
                }}
              />
            </td>

            <td
              style={{
                width: '36.7px',
                height: '31.7px',
                borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
              }}
              className="description center"
            >
              {validationSku(data, item)}
            </td>
          </td>
        ))}
      </>
    );
  }

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  const uploadSpreadsheet = async (inputRef, event) => {
    const formatCompressionValid = ['zip', 'x-zip-compressed'];
    const formatFileValid = ['zip', 'rar'];
    const folderName = event.target.files[0]?.name;
    if (
      !formatCompressionValid.includes(
        event.target.files[0]?.type?.split('/').pop()
      ) ||
      !formatFileValid.includes(event.target.files[0]?.name?.split('.').pop())
    ) {
      setToastProps({
        severity: 'error',
        message:
          'Formato de arquivo inválido. Favor utilizar arquivos em formato .zip ou .rar'
      });
      setToastOpen(true);
      return;
    }
    const endpoint = `${environments.integration}/notes`;
    const spreadsheetBase64 = await convertToBase64(event.target.files[0]);

    const body = {
      restaurantId: parseInt(getId),
      folderName,
      user: userInfo.user,
      content: spreadsheetBase64
    };

    axios
      .post(endpoint, body)
      .then((res) => {
        setToastProps({
          severity: 'success',
          message: `Arquivo(s) importado(s). O processamento pode levar algum tempo. Volte em breve!`
        });

        setToastOpen(true);

        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'manualOrdersModal'
              ? {
                ...modal,
                open: true,
                message: `Foram importados ${res?.data?.importedNote?.count
                  } pedidos ${res?.data?.alreadyImported?.total
                    ? `e ${res?.data?.alreadyImported?.total} já haviam sido importados`
                    : ''
                  }`
              }
              : modal
          )
        );
      })
      .catch((err) => {
        if (err.response.data.type == 'Incorrect format') {
          setToastProps({
            severity: 'error',
            message:
              'A planilha não está no formato correto para ser lida. As colunas necessárias para o sucesso da importação podem estar faltando.'
          });
        } else if (err.response.data?.count) {
          setToastProps({
            severity: 'error',
            message: `Limite de arquivos importados atingido - ${err.response.data?.count}.`
          });
        } else {
          setToastProps({
            severity: 'error',
            message:
              'Não foi possível importar a planilha. Tente novamente mais tarde.'
          });
        }
        setToastOpen(true);
      });

    const { current: { value } = {} } = inputRef;

    if (value) {
      inputRef.current.value = null;
    }
  };

  const totalReceveid = dataReceives.filter((n) => n.status == 'RECEIVED');

  const showTooltipNotes = (order) => {
    for (let i = 0; i < order.length; i++) {
      const { shipping, discount, otherCosts, segCosts, icmsst, ipi, fcpst } =
        order[i]?.sku?.provider ?? {}
      if (
        !order[i]?.sku?.provider?.hasOwnProperty('shipping') ||
        !order[i]?.sku?.provider?.hasOwnProperty('discount') ||
        !order[i]?.sku?.provider?.hasOwnProperty('otherCosts') ||
        !order[i]?.sku?.provider?.hasOwnProperty('segCosts') ||
        !order[i]?.sku?.provider?.hasOwnProperty('icmsst') ||
        !order[i]?.sku?.provider?.hasOwnProperty('ipi') ||
        !order[i]?.sku?.provider?.hasOwnProperty('fcpst')
      )
        return false;
      if (
        shipping !== 0 ||
        discount !== 0 ||
        otherCosts !== 0 ||
        segCosts !== 0 ||
        icmsst !== 0 ||
        ipi !== 0 ||
        fcpst !== 0
      ) {
        return true;
      }
      return false;
    }
  };

  const findKeyNote = (value, secondCall) => {
    axios
      .get(
        `${environments.orders}/orders/noteKey?originId=${getId}&noteKey=NFe${value.replace(/[^0-9]/g, '')}`
      )
      .then((res) => {
        setDataReceives(res.data?.order ? [res.data.order] : []);
        setInfoLoading(res.data?.order)
        if (!res.data.order && !res.data.canCallSefaz) {
          setToastProps({
            severity: 'error',
            message: `Limite de chamadas para a Sefaz atingida, tente novamente mais tarde.`
          });

          setToastOpen(true);
        } else {
          setCollapseSettings(
            [res?.data?.order].map((orderItems) => ({
              id: orderItems?._id,
              open: false
            }))
          );

          setToastProps({
            severity: 'success',
            message:
              !res.data?.order && `Nota não encontrada em nossa base de dados`
          });

          setToastOpen(!res.data?.order);
        }

        setInputKey('')

        setNoteValue(value);
        setNumberReq(numberReq + 1)
        setOpenModalNfce(false)

        !res.data?.order &&
        res?.data?.canCallSefaz &&
        !secondCall &&
        !nfceType.valid && 
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'findNoteOnSefazModal'
                ? { ...modal, open: true }
                : modal
            )
          );
        setLoading(false)

      })
      .catch(() => {
        setToastProps({
          severity: 'error',
          message: 'Não foi possível verificar a nota em nossa base de dados.'
        });
        setToastOpen(true);
        setLoading(false)
      })
  };

  const findNoteOnSefaz = (value) => {
    setFullScreenLoad({
      open: true,
      message: 'Buscando nota no Sefaz, por favor aguarde.'
    });

    axios
      .post(
        `${environments.integrationSefaz}/integration-sefaz/${getId}/manualIntegration?chnfe=${value}`
      )
      .then((res) => {
        setToastProps({
          severity: res?.data?.statusResponse !== 201 ? 'error' : 'success',
          message: res?.data?.message
        });
        setToastOpen(true);

        res?.data?.statusResponse === 201 && findKeyNote(value, true);
        setLoading(false)
        setNoteValue('');
        setFullScreenLoad({
          open: false,
          message: ''
        });
        setInputKey('')
      })
      .catch(() => {
        setToastProps({
          severity: 'error',
          message: 'Não foi possível encontrar sua nota no Sefaz'
        });

        setFullScreenLoad({
          open: false,
          message: ''
        });
        setLoading(false)

        setToastOpen(true);
        setNoteValue('');
        setInputKey('')
      })
  };

  useEffect(() => {
    if (infoLoading) {
      setFullScreenLoad({
        open: false,
        message: ''
      });

      setNumberReq(0)
    }
  }, [infoLoading])

  const handleFindKeyNoteValue = (value) => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      findKeyNote(value);
    }, 1500);

    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  const totalReceivements = useMemo(() => {
    const orderItems = dataReceives.map((item) => {
      const otherCosts = item?.otherCosts || 0;
      const segCosts = item?.segCosts || 0;
      const shipping = item?.shipping || 0;
      const discount = item?.discount || 0;

      return (
        item.orderItems.reduce(
          (total, value) =>
            value?.itemsPaymentDocument?.total
              ? parseFloat(value?.itemsPaymentDocument?.total) +
              parseFloat(total)
              : total + 0,
          0
        ) +
        otherCosts +
        segCosts +
        shipping -
        discount
      );
    });

    const totalReceivement = orderItems.reduce(
      (total, value) =>
        value ? parseFloat(value) + parseFloat(total) : total + 0,
      0
    );

    return totalReceivement;
  }, [dataReceives]);

  const uploadSpreadsheetButton = () => (
    <Tooltip title="Importar notas manuais" aria-label="add">
      <IconButton
        className="p0 ml10 cursorPointer"
        color="primary"
        aria-label="add"
        component="span"
      >
        <img
          className="cursorPointer"
          style={{ width: '25px' }}
          src={UploadIcon}
          alt="UploadIcon"
        />

        <InputFile
          type="file"
          title=" "
          id="spreadsheetImport"
          name="spreadsheetImport"
          onChange={(event) => uploadSpreadsheet(inputRef, event)}
          accept=".zip"
          ref={inputRef}
          aria-label="upload"
        />
      </IconButton>
    </Tooltip>
  );

  const uploadedNfesHistory = () => (
    <Tooltip title="Histórico de notas integradas" aria-label="add">
      <IconButton
        onClick={() => setOpenImportedNfeHistory(true)}
        className="p0 ml10 cursorPointer"
        color="primary"
        aria-label="add"
        component="span"
      >
        <img
          className="cursorPointer"
          style={{ width: '25px' }}
          src={HistoryNotesDialog}
          alt="HistoryNotesDialog"
        />
      </IconButton>
    </Tooltip>
  );

  const getCurrentProviderCnpj = (provider) => {
    const isGroup = provider?.providerGroup?.providerIds?.length;
 
    if (provider?.providerId)
      axios
        .get(`${environments.providers}/providers/${provider?.providerId}`)
        .then((response) => {
          setCurrentProviderCnpj(isGroup ? '' : response?.data?.cnpjNumber);
        })
        .catch((error) => {
          setCurrentProviderCnpj('');
          setAlertErr(true);
          setAlertMessage(error.response.data.message);
          setStatusCode(error.response.status);
        });
  };

  useEffect(() => {
    (async () => {
      if (productIdQueryParam) {
        await axios
          .get(
            `${environments.catalog}/products/${productIdQueryParam}?originId=${getId}`
          )
          .then((response) => {
            const items = response.data;
            const newFilters = [...filterFields];

            newFilters[0][0].storedValue = items?.description;

            setFilterFields(newFilters);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })();
  }, [getId]);

  // return principal
  return (
    <>
      {/* TABLE DESKTOP */}
      <Layout>
        <GlobalStyle />

        <Container>
          <TitleHead title="Recebimento" />

          <PageTitleAndFilter
            title={`Recebimentos ${dias[getDay(new Date())]}, ${dateStart}`}
            subTitle={`${dataReceives.length} Pedidos - ${totalReceveid.length} Recebidos`}
            activeFilterFilds={filterFields}
            handleFilter={handleFilter}
            handleMultipleFilters={handleMultipFilterCallBack}
            getFilterhandle={getFilterhandle}
            supplementarybutton={uploadSpreadsheetButton}
            complementarybutton={uploadedNfesHistory}
          />

          <FullScreenLoading
            width="220"
            height="135"
            display={fullScreenLoad.open}
            description={fullScreenLoad.message}
          />

          <MobileRecebimento
            data={dataReceives}
            getId={getId}
            loading={loading}
            inputKey={inputKey}
          />

          <Snackbar
            open={receivedSuccess}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              Item recebido com sucesso
            </Alert>
          </Snackbar>

          {
            statusCertificate.canShowAlert ?
              <div
                className="alertCertificate"
                style={
                  {
                    backgroundColor: statusCertificate.typeAlert === "EXPIRING_CERTIFICATE" ? "#F2B90D" : "#F53D4C",
                    color: statusCertificate.typeAlert === "EXPIRING_CERTIFICATE" ? "#1F2445" : "white"
                  }
                }
              >
                {statusCertificate.textAlert}
              </div>
              : null
          }
          
          <table
            className="desktop pt20 receivementsTable"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="withoutHover">
                <td colSpan="14">
                  <div className="dFlex">
                    <Grid
                      className="pRelative dFlex alignCenter pr16"
                      item
                      xs={12}
                      sm={3}
                      style={{
                        marginLeft: '12px',
                        marginBottom: '12px',
                        maxWidth: '100%'
                      }}
                    >
                      <label
                        className="bold dFlex flexEnd"
                        style={{
                          margin: '13px 10px 5px 0px',
                          fontSize: '14px',
                          lineHeight: 'normal'
                        }}
                      >
                        Pedido/NF
                      </label>

                      <div className="pRelative" style={{ width: '75%' }}>
                        <img
                          alt="search"
                          src={searchIcon}
                          className="pAbsolute"
                          style={{
                            width: '12px',
                            top: '15px',
                            left: '0px'
                          }}
                        />

                        <TextField
                          onChange={handleSearchTextField}
                          value={valueNf}
                          type="text"
                          name="OrderFilter"
                          variant="outlined"
                          placeholder="Digite aqui..."
                          size="small"
                          fullWidth
                          className="textFieldSmall nfInput"
                          InputProps={{
                            style: {
                              fontFamily: 'Lato',
                              fontSize: '12px',
                              height: '35px',
                              paddingLeft: 20
                            }
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid
                      className="pRelative dFlex alignCenter"
                      item
                      xs={12}
                      sm={4}
                      style={{
                        marginLeft: '12px',
                        marginBottom: '12px'
                      }}
                    >
                      <label
                        className="bold dFlex flexEnd"
                        style={{
                          width: '22%',
                          margin: '13px 10px 5px 0px',
                          fontSize: '14px',
                          lineHeight: 'normal'
                        }}
                      >
                        Chave da Nota
                      </label>
                      
                      <div className="pRelative dFlex" style={{ width: '78%' }}>
                        <img
                          alt="SearchIcon"
                          src={searchIcon}
                          className="pAbsolute"
                          style={{
                            width: '12px',
                            top: '15px',
                            left: '0px'
                          }}
                        />

                        <TextField
                          id="findKeyNoteOnSefazField"
                          value={inputKey}
                          onChange={(e) => {
                            setInputKey(e.target.value.replace(/[^0-9]/g, ''))
                            if (e.target.value) {
                              handleFindKeyNoteValue(e.target.value);
                            } else {
                              setUpdateList(!updateList);
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              if (e.target.value?.length === 44) {
                                findKeyNote(e.target.value);
                              } else {
                                setToastProps({
                                  severity: 'error',
                                  message:
                                    'Por favor, preencha os 44 caracteres.'
                                });

                                setToastOpen(true);
                              }
                            }
                          }}
                          type="text"
                          name="OrderFilter"
                          variant="outlined"
                          placeholder="Digite aqui..."
                          size="small"
                          fullWidth
                          className="textFieldSmall nfInput"
                          InputProps={{
                            style: {
                              fontFamily: 'Lato',
                              fontSize: '12px',
                              height: '35px',
                              paddingLeft: 20,
                              maxLength: 44
                            }
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid
                      className="pRelative dFlex alignCenter"
                      justify="flex-end"
                      item
                      xs={12}
                      sm={5}
                      style={{ margin: '0 25px 0 12px' }}
                    >
                      {dataReceives.length ? (
                        <>
                          <span className="bold mr20">Total:</span>

                          <span className="bold ml10">
                            R${' '}
                            {totalReceives?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                          </span>
                        </>
                      ) : null}
                    </Grid>
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan="14">
                  <hr />
                </td>
              </tr>

              <tr className="headerTitle">
                <th width="5%" className="subtitle left" style={{ padding: '0', paddingLeft: '17px' }}>
                  TIPO{' '}
                  <OrderBy
                    filterName="type"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th width="5.5%" className="subtitle left" style={{ paddingLeft: '5px' }}>
                  NF
                </th>

                <th width="4.5%" className="subtitle" />

                <th width="6.5%" className="subtitle left">
                  PREVISÃO{' '}
                  <OrderBy
                    filterName="deliveryDate"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th
                  width="26%"
                  className="subtitle p10"
                  style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                >
                  FORNECEDOR / SKUS{' '}
                  <OrderBy
                    filterName="provider"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th
                  className="subtitle left p10"
                  width="25%"
                  colSpan="4"
                  style={{
                    borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                    background: '#5062F0'
                  }}
                >
                  PEDIDO
                </th>

                <th
                  className="subtitle left p10"
                  width="25%"
                  colSpan="4"
                  style={{
                    borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                    background: '#4D0073'
                  }}
                >
                  RECEBIMENTO
                </th>

                <th width="36.7px">
                  &nbsp;
                </th>
              </tr>

              <tr>
                <td colSpan="14">
                  <hr />
                </td>
              </tr>

              <tr className="headerSubtitle">
                <th colSpan="5">&nbsp;</th>

                <th
                  width="6%"
                  className="subtitle right p10"
                  style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                >
                  QTDE.
                </th>

                <th width="6%" className="subtitle right p10">
                  EMB.
                </th>

                <th width="6%" className="subtitle right p10">
                  EMB. R$
                </th>

                <th
                  width="7%"
                  className="subtitle right p10 orderWithoutMargin"
                >
                  TOTAL R${' '}
                  <OrderBy
                    filterName="totalOrderAmount"
                    handleFilter={handleOrdernationFilter}
                    tinyArrow
                  />
                </th>

                <th
                  width="6%"
                  className="subtitle right p10"
                  style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                >
                  QTDE.
                </th>

                <th width="6%" className="subtitle right p10">
                  EMB.
                </th>

                <th width="6%" className="subtitle right p10">
                  EMB. R$ 
                </th>
                
                <th width="7%" className="subtitle right p10 pr8">
                  TOTAL R${' '}
                  <OrderBy
                    filterName="totalPaymentDocument"
                    handleFilter={handleOrdernationFilter}
                    tinyArrow
                  />
                </th>

                <th width="36.7px">&nbsp;</th>
              </tr>
            </thead>

            {loading && (
              <tr>
                <th colSpan="17" sytle={{ margin: '10px' }}>
                  <LinearProgress style={{ height: '7px' }} variant="query" />
                </th>
              </tr>
            )}

            {dataReceives?.length ? (
              dataReceives.map((orders, index) => {
                const nfsReceived = [];
                const nfsUnique = [];

                const nfsCountItemsReceived = orders.orderItems.filter(
                  (order) => order?.sku?.status === 'RECEIVED'
                );
                nfsCountItemsReceived.map((items) => {
                  if (
                    items.itemsPaymentDocument &&
                    nfsReceived.indexOf(
                      items.itemsPaymentDocument.paymentDocumentNumber !== -1
                    )
                  ) {
                    nfsReceived.push(
                      items.itemsPaymentDocument.paymentDocumentNumber
                    );
                  }
                });

                // função que exibe as notas fiscais únicas recebidas por pedido
                const setNFSUniqueReceived = new Set(nfsReceived);

                orders.orderItems.map((items) => {
                  if (
                    items.itemsPaymentDocument &&
                    nfsUnique.indexOf(
                      items.itemsPaymentDocument.paymentDocumentNumber !== -1
                    )
                  ) {
                    nfsUnique.push(
                      items.itemsPaymentDocument.paymentDocumentNumber
                    );
                  }
                });

                // função que exibe quantidade de notas fiscais únicas por pedido
                const setNFS = new Set(nfsUnique);

                return (
                  <tbody>
                    <tr key={orders._id} valign="top">
                      <td
                        style={{ width: '5%' }}
                        className="description left"
                      >
                        <div className="dFlex alignCenter" style={{ height: 31.7 }}>
                          {colorChip(orders)}

                          <p
                            className="white mb0 dFlex alignCenter justifyCenter"
                            style={{
                              background: '#5062F0',
                              borderRadius: '50px',
                              padding: '5px',
                              width: '20px',
                              height: '20px',
                              marginLeft: '10px'
                            }}
                          >
                            {orders.type ? orders.type : '-'}
                          </p>
                        </div>
                      </td>
                      
                      <td
                        style={{ width: '5.5%' }}
                        className="description left pr8 pl5"
                      >
                        <Tooltip
                          TransitionProps={{ timeout: 600 }}
                          title={`Pedido: ${orders._id}`}
                        >
                          <Link
                            to={`/pedidos/cadastro/${paramsID}`}
                            onClick={() => setOrder(orders)}
                          >
                            <span>
                              {orders.isTransference ? 'TRANSF.' :
                                [...setNFS].length
                                  ? [...setNFS].map((nfe) => (
                                    <>
                                      {[...setNFS].length
                                        ? nfe
                                        : 'NFe não recebida'}
                                      <br />
                                    </>
                                  ))
                                  : 'NFe não recebida'
                              }
                            </span>
                          </Link>
                        </Tooltip>
                      </td>

                      <Tooltip
                        TransitionProps={{ timeout: 600 }}
                        title={
                          [...setNFS].length
                            ? [...setNFS].map((nfe) => (
                              <>
                                {[...setNFS].length
                                  ? nfe
                                  : 'NFe não recebida'}
                                <br />
                              </>
                            ))
                            : 'NFe não recebida'
                        }
                      >
                        <td
                          style={{
                            width: '4.5%',
                            textAlign: 'left'
                          }}
                        >
                          <span style={{ fontSize: '12px', margin: 0 }}>
                            {`${[...setNFSUniqueReceived].length}/${[...setNFS].length
                              }`}
                          </span>
                        </td>
                      </Tooltip>

                      <Tooltip
                        TransitionProps={{ timeout: 600 }}
                        title={
                          orders.deliveryDate
                            ? `Data do Pedido: ${format(
                              new Date(orders.deliveryDate).valueOf() +
                              10800000,
                              'dd/MM/yyyy'
                            )}`
                            : '-'
                        }
                      >
                        <td
                          style={{ width: '6.5%' }}
                          className="description left"
                        >
                          {orders.deliveryDate
                            ? format(
                              new Date(orders.deliveryDate).valueOf() +
                              10800000,
                              'dd/MM/yyyy'
                            )
                            : null}
                        </td>
                      </Tooltip>

                      <td
                        style={{
                          width: '26%',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)'
                        }}
                        className="description pl10"
                      >
                        <div className="dFlex justifyBetween pr10">
                          <Tooltip
                            placement="right-end"
                            onOpen={() => getCurrentProviderCnpj(orders)}
                            onClose={() => setCurrentProviderCnpj('')}
                            title={
                              <div
                                className="dFlex flexColumn"
                                style={{ padding: '4px 6px' }}
                              >
                                <p className="mb3">
                                  {providerNameValidation(orders)}
                                </p>

                                {currentProviderCnpj && (
                                  <p className="mb0">
                                    CNPJ:{' '}
                                    {formatDocumentNumber(
                                      currentProviderCnpj
                                    )}
                                  </p>
                                )}
                              </div>
                            }
                          >
                            <span>
                              {providerNameValidation(orders).length > 49
                                ? `${providerNameValidation(orders).substring(
                                  0,
                                  50
                                )}...`
                                : providerNameValidation(orders)}
                            </span>
                          </Tooltip>
                        </div>
                      </td>

                      <td
                        style={{
                          width: '6%',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                          background: 'rgba(80, 98, 240, .15)'
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          width: '6%',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                          background: 'rgba(80, 98, 240, .15)'
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          width: '6%',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                          background: 'rgba(80, 98, 240, .15)'
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          width: '7%',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                          background: 'rgba(80, 98, 240, .15)'
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          width: '6%',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                          background: '#F1EBF4'
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          width: '6%',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                          background: '#F1EBF4'
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          width: '6%',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                          background: '#F1EBF4'
                        }}
                      >
                        &nbsp;
                      </td>

                      <td
                        style={{
                          width: '7%',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                          background: '#F1EBF4'
                        }}
                      >
                        <div className="dFlex justifyEnd pRelative">
                          {showTooltipNotes(orders.orderItems) && (
                            <IconButton
                              className="p0"
                              onClick={() =>
                                handleClickOpenCostsModal(orders)
                              }
                            >
                              <img
                                src={TooltipNote}
                                alt="TooltipNote"
                                style={{
                                  width: '20px',
                                  margin: '8px',
                                  cursor: 'pointer'
                                }}
                              />
                            </IconButton>
                          )}
                        </div>
                      </td>

                      <td
                        style={{
                          width: '36.81px',
                          height: '31.7px',
                          borderLeft: 'solid 1px rgba(208, 211, 226, 1)',
                        }}
                      >
                        &nbsp;
                      </td>
                    </tr>

                    <tr>{HasList(orders, index)}</tr>

                    <tr>
                      <td colSpan="14">
                        <hr style={{ border: '1px solid #D3D3D3' }} />
                      </td>
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tr>
                <th
                  colSpan="13"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  Nenhum recebimento encontrado
                </th>
              </tr>
            )}

            {/* RECEBIMENTO DIALOG */}
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openModal}
              onClose={handleCloseModal}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
              className="defaultModal"
            >
              <DialogTitle
                className="modalHeader navyBlue"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  cursor: 'move',
                  fontFamily: 'Lato'
                }}
                id="draggable-dialog-title"
              >
                <Typography>{skuSelect?.description}</Typography>

                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseModal}
                >
                  <img
                    style={{ width: '16px' }}
                    src={CloseIconNavy}
                    alt="CloseIconNavy"
                  />
                </IconButton>
              </DialogTitle>

              <DialogContent className="modalContent p12">
                <Grid container spacing={6} className="w100 m0">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="pl0 pb10 pr10"
                    style={{ paddingTop: '24px' }}
                  >
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                      <DatePicker
                        disableFuture="true"
                        fullWidth
                        format="dd/MM/yyyy"
                        size="small"
                        className="dateSmall"
                        inputVariant="outlined"
                        label="Data de recebimento"
                        autoOk
                        views={['year', 'month', 'date']}
                        value={selectedReceiveDate}
                        onChange={handleReceiveDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        shouldDisableDate={(date) =>
                          date < receiveCreatedDateMinusOneDay
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} sm={6} className="pt20 p10 pr0 pl10">
                    {validateDate() &&
                    <Tooltip
                      placement='bottom-end'
                      title={
                      <p style={{margin: '0'}}>
                        Não e possivel receber com uma data retroativa
                      </p>
                    }>
                      <div>
                        <Button
                          variant="contained"
                          style={{
                            background: submitted ? '#AFBCF3' : '#5062F0',
                            color: '#FFFFFF',
                            borderRadius: '8px',
                            textTransform: 'initial',
                            fontFamily: 'Lato',
                            height: '40px'
                          }}
                          fullWidth
                          disabled={submitted || validateDate()}
                          onClick={() => {
                            if (
                              skuSelectItem.itemsPaymentDocument &&
                              skuSelectItem.itemsPaymentDocument.quantity <
                              skuSelectItem.costs.quantity
                            ) {
                              setOpenDivergenceModal(true);
                              return;
                            }
                            setSubmitted(true);
                            if (itemSelectPut?.isTransference) {
                              putEditTransfer(itemSelectPut, 'RECEIVED')
                            } else {
                              putEditSku('RECEIVED');
                            }
                          }}
                        >
                          Receber
                        </Button>
                      </div>
                    </Tooltip>
                  }
                  {!validateDate() &&
                    <Button
                      variant="contained"
                      style={{
                        background: submitted ? '#AFBCF3' : '#5062F0',
                        color: '#FFFFFF',
                        borderRadius: '8px',
                        textTransform: 'initial',
                        fontFamily: 'Lato',
                        height: '40px'
                      }}
                      fullWidth
                      disabled={submitted}
                      onClick={() => {
                        if (
                          skuSelectItem.itemsPaymentDocument &&
                          skuSelectItem.itemsPaymentDocument.quantity <
                          skuSelectItem.costs.quantity
                        ) {
                          setOpenDivergenceModal(true);
                          return;
                        }
                        setSubmitted(true);
                        if (itemSelectPut?.isTransference) {
                          putEditTransfer(itemSelectPut, 'RECEIVED')
                        } else {
                          putEditSku('RECEIVED');
                        }
                      }}
                    >
                      Receber
                    </Button>
                    }
                  </Grid>
                </Grid>

                <Grid container spacing={6} className="w100 m0">
                  <Grid item xs={12} sm={!itemSelectPut?.isTransference ? 4 : 6} className="pt20 pb10 pl0 p10">
                    <Button
                      variant="contained"
                      style={{
                        background: '#FCF5E3',
                        color: '#E6A117',
                        borderRadius: '8px',
                        textTransform: 'initial',
                        fontFamily: 'Lato',
                        height: '40px'
                      }}
                      fullWidth
                      disabled={submitted}
                      onClick={() => {
                        setSubmitted(true);
                        if (itemSelectPut?.isTransference) {
                          putEditTransfer(itemSelectPut, 'NOT RECEIVED')
                        } else {
                          putEditSku('NOT RECEIVED');
                        }
                      }}
                    >
                      Não recebido
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={!itemSelectPut?.isTransference ? 4 : 6} className="pt20 p10 pl0 pr0">
                    <Button
                      variant="contained"
                      id="rejectButton"
                      style={{
                        background: '#F7DFDF',
                        color: '#D94141',
                        borderRadius: '8px',
                        border: 'solid 1px #D94141',
                        textTransform: 'initial',
                        fontFamily: 'Lato',
                        height: '40px'
                      }}
                      fullWidth
                      disabled={submitted}
                      onClick={() => {
                        setSubmitted(true);
                        if (itemSelectPut?.isTransference) {
                          putEditTransfer(itemSelectPut, 'RETURNED')
                        } else {
                          putEditSku('RETURNED');
                        }
                      }}
                    >
                      Devolver
                    </Button>
                  </Grid>

                  {!itemSelectPut?.isTransference &&
                    <Grid item xs={12} sm={4} className="pt20 pb10 pr0 p10">
                      <Button
                        variant="contained"
                        style={{
                          background: 'transparent',
                          color: '#313347',
                          borderRadius: '8px',
                          border: 'solid 1px #313347',
                          textTransform: 'initial',
                          fontFamily: 'Lato',
                          height: '40px'
                        }}
                        fullWidth
                        disabled={submitted}
                        onClick={handleClickOpenNewOrder}
                      >
                        Novo pedido
                      </Button>
                    </Grid>
                  }
                </Grid>

                <Collapse in={openNewOrder} timeout="0" unmountOnExit>
                  {/* NOVO PEDIDO MODAL */}
                  {alertErr ? (
                    <Alert
                      style={{ width: '100%' }}
                      color="danger"
                      isOpen={alertErr}
                      toggle={onDismiss}
                    >
                      Erro ao cadastrar novo pedido
                    </Alert>
                  ) : null}

                  <Grid
                    container
                    spacing={3}
                    style={{ padding: '30px 0 15px' }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        size="small"
                        id="fornecedores"
                        getOptionLabel={(option) => option.fantasyName}
                        options={providersSku}
                        value={inputValue}
                        onChange={(_event, newValue) => {
                          setProviderId(
                            newValue != null ? newValue.providerId : ''
                          );
                          setInputValue(newValue != null ? newValue : '');
                        }}
                        className="autocompleteSmall"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"
                            variant="outlined"
                            label="Fornecedores"
                            value={inputValue}
                            required
                            InputLabelProps={{
                              style: { fontFamily: 'Lato', fontSize: '12px' }
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        size="small"
                        id="contatos"
                        options={contacts}
                        getOptionLabel={(option) => option?.fullName}
                        value={contactsValue}
                        disabled={!inputValue}
                        onChange={(_event, newValue) => {
                          setContactTel(
                            newValue !== null ? newValue?.telephoneNumber : ''
                          );
                          setContactsValue(newValue !== null ? newValue : '');
                        }}
                        className="autocompleteSmall"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"
                            variant="outlined"
                            label="Contatos"
                            value={contactsValue}
                            required
                            InputLabelProps={{
                              style: { fontFamily: 'Lato', fontSize: '12px' }
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} className="pt20">
                      <MuiPickersUtilsProvider
                        locale={ptBR}
                        utils={DateFnsUtils}
                      >
                        <DatePicker
                          fullWidth
                          format="dd/MM/yyyy"
                          size="small"
                          className="dateSmall"
                          inputVariant="outlined"
                          label="Data de entrega"
                          views={['year', 'month', 'date']}
                          value={selectedEndDate}
                          onChange={handleEndDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} className="pt20">
                      <Button
                        className="defaultButton submitButton"
                        onClick={() => submitNewOrder(newOrderOpenModal)}
                        fullWidth
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Collapse>

                {submitted && <LinearProgress variant="query" />}

                <Snackbar
                  open={errMsg}
                  autoHideDuration={10000}
                  onClose={handleClose}
                >
                  <Alert
                    className="alert-error"
                    onClose={handleClose}
                    severity="error"
                  >
                    Preencha os valores de recebimentos.
                  </Alert>
                </Snackbar>
              </DialogContent>
            </Dialog>

            <DialogCompleteNote
              openModalOrder={openModalOrder}
              handleCloseModal={handleCloseModal}
              PaperComponent={PaperComponent}
              selectedReceiveDate={selectedReceiveDate}
              submitted={submitted}
              handleReceiveDateChange={handleReceiveDateChange}
              setSubmitted={setSubmitted}
              putEditSku={putEditSku}
              errMsg={errMsg}
              handleClose={handleClose}
              selectedItem={itemSelectPut}
            />

            {openCostsModal && (
              <DialogCostsItems
                openCostsModal={openCostsModal}
                handleCloseModal={handleCloseModal}
                handleClose={handleClose}
                PaperComponent={PaperComponent}
                order={orderCosts}
              />
            )}

            {openImportedNfeHistory && (
              <DialogImportedNfeHistory
                openModal={openImportedNfeHistory}
                handleCloseModal={handleCloseModal}
                originId={getId}
              />
            )}

            {modalSettings.find(
              (modal) => modal.name === 'findNoteOnSefazModal'
            ).open && (
                <Dialog
                  fullWidth={
                    modalSettings.find(
                      (modal) => modal.name === 'findNoteOnSefazModal'
                    ).fullWidth
                  }
                  maxWidth={
                    modalSettings.find(
                      (modal) => modal.name === 'findNoteOnSefazModal'
                    ).maxWidth
                  }
                  open={
                    modalSettings.find(
                      (modal) => modal.name === 'findNoteOnSefazModal'
                    ).open
                  }
                  onClose={handleCloseModal}
                  PaperComponent={PaperComponent}
                  aria-labelledby="draggable-dialog-title"
                  className="defaultModal"
                >
                  <DialogTitle
                    className="modalHeader bold textAlignCenter cursorMove"
                    id="draggable-dialog-title"
                  >
                    <IconButton
                      className="pAbsolute"
                      style={{ top: '15px', right: '10px' }}
                      edge="start"
                      color="inherit"
                      onClick={handleCloseModal}
                    >
                      <img
                        style={{ width: '16px' }}
                        src={CloseIconNavy}
                        alt="CloseIconNavy"
                      />
                    </IconButton>
                  </DialogTitle>

                  <DialogContent className="modalContent p12 pt12 pb12">
                    <p className="mb0 bold" style={{ fontSize: 16 }}>
                      NF ainda à caminho, mas podemos buscar agora na Sefaz para
                      fazer o recebimento.
                    </p>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="pt16 dFlex justifyCenter w100"
                      style={{ margin: 'auto' }}
                    >
                      <Button
                        className="defaultButton backButton mr10"
                        onClick={() => {
                          setModalSettings(
                            modalSettings.map((modal) =>
                              modal.name === 'findNoteOnSefazModal'
                                ? { ...modal, open: false }
                                : modal
                            )
                          );
                        }}
                        fullWidth
                      >
                        Cancelar
                      </Button>

                      <Button
                        className="defaultButton submitButton"
                        onClick={() => {
                          findNoteOnSefaz(noteValue);
                          setModalSettings(
                            modalSettings.map((modal) =>
                              modal.name === 'findNoteOnSefazModal'
                                ? { ...modal, open: false }
                                : modal
                            )
                          );
                        }}
                        fullWidth
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </DialogContent>
                </Dialog>
              )}

            {modalSettings.find((modal) => modal.name === 'skuModal').open &&
              selectedSku?.description &&
              productOptions.length &&
              unitsMeasurements.length &&
              brands.length &&
              packageTypes.length ? (
              <SkuModal
                fullScreen={
                  modalSettings.find((modal) => modal.name === 'skuModal')
                    .fullScreen
                }
                maxWidth={
                  modalSettings.find((modal) => modal.name === 'skuModal')
                    .maxWidth
                }
                open={
                  modalSettings.find((modal) => modal.name === 'skuModal').open
                }
                data={selectedSku?.providers || []}
                getId={getId}
                editMode
                sku={selectedSku}
                userInfo={userInfo}
                items={skuItems}
                providers={providerOptions}
                packageTypes={packageTypes}
                products={productOptions}
                categories={categories}
                brands={brands}
                storageLocations={storageLocations}
                periodicities={periodicities}
                unitsMeasurements={unitsMeasurements}
                consumptionUnits={consumptionUnits}
                skus={[]}
                onClose={(_e) => {
                  setModalSettings(
                    modalSettings.map((modal) =>
                      modal.name === 'skuModal'
                        ? {
                          ...modal,
                          open: false
                        }
                        : modal
                    )
                  );

                  setSelectedSku('');
                }}
                adminCatalogModal={isAdminPage}
                onlyViewMode
              />
            ) : null}

            {/* Receivement With Divergence */}
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openDivergenceModal}
              onClose={(_e) => setOpenDivergenceModal(false)}
              PaperComponent={PaperComponent}
              aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle
                className="modalHeader navyBlue bold textAlignCenter cursorMove"
                id="draggable-dialog-title"
              >
                <Typography>Nota com divergência</Typography>

                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpenDivergenceModal(false)}
                >
                  <img
                    style={{ width: '16px' }}
                    src={CloseIconNavy}
                    alt="CloseIconNavy"
                  />
                </IconButton>
              </DialogTitle>

              <DialogContent className="modalContent p12">
                <Grid container spacing={6} className="w100 m0">
                  <Grid item xs={12} sm={6} className="pt20 pb10 pl0 p10">
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={submitted}
                      className="defaultButton submitButton"
                      onClick={() => {
                        setSubmitted(true);
                        if (itemSelectPut?.isTransference) {
                          putEditTransfer(itemSelectPut, 'RECEIVED')
                        } else {
                          putEditSku('RECEIVED', 'adjustOrder');
                        }
                        setOpenDivergenceModal(false);
                      }}
                    >
                      Ajustar pedido
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6} className="pt20 pb10 pr0 p10">
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={submitted}
                      className="defaultButton defaultButton"
                      onClick={() => {
                        setSubmitted(true);
                        putEditSku('RECEIVED', 'newOrder');
                        setOpenDivergenceModal(false);
                      }}
                    >
                      Novo pedido
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </table>

          <div className="pagination">
            <Pagination
              count={totalPages}
              onChange={(_event, page) => setPage(page)}
              variant="outlined"
              shape="rounded"
              size="small"
              color="primary"
            />

            <ItemsPerPage
              numberOfElements={numberOfElements}
              size={totalElements}
            />
          </div>

          <Snackbar
            className={`${toastProps.severity === 'error' && 'snackBarError'}`}
            open={toastOpen}
            autoHideDuration={6000}
            onClose={handleCloseToast}
          >
            <Alert onClose={handleCloseToast} severity={toastProps.severity}>
              {toastProps.message}
              {toastProps.lineErrors
                ? `\nLinha(s) com erro: ${toastProps.lineErrors}`
                : ''}
            </Alert>
          </Snackbar>
        </Container>

        <CommonAlert
          open={alertErr}
          onClose={() => {
            setAlertErr(false);
            setAlertMessage('')
          }}
          indexMessage={alertMessage}
          statusCode={statusCode}
        />

        <CommonAlert
          open={alertNfce}
          onClose={() => {
            setAlertNfce('')
          }}
          // indexMessage={alertNfce?.message}
          messagePersonal={alertNfce?.message}
          severity={alertNfce?.status}
        />
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  orderInfo: state.order
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Recebimentos);
