import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = '#1F2445';
ChartJS.defaults.font.family = 'Lato'

function GroupedVerticalBarGraphic({ mainContent = [], paramsId }) {

  const labels = mainContent?.labels || [];

  const data = {
    labels,
    datasets: mainContent?.contentFormatted?.length ? 
      mainContent?.contentFormatted?.map(el => (
        {
          label: el?.title,
          data: el?.data,
          dataFormatted: el?.dataFormatted,
          backgroundColor: el?.color,
          borderRadius: 15,
          barThickness: 10,
        }
      )) 
    : [],
  };

  const options = {
    onClick: (event, chartElements) => {
      if (chartElements.length > 0) {
        const chartInstance = event.chart;
        const activeElement = chartElements[0];
        const datasetIndex = activeElement.datasetIndex;
        const dataIndex = activeElement.index;
    
        const dataset = chartInstance.config.data.datasets[datasetIndex];
        const clickedLabel = chartInstance.config.data.labels[dataIndex]; // Data no formato dd/mm
    
        // Obtém o ano atual
        const currentYear = new Date().getFullYear();
    
        // Converte para o formato yyyy-mm-dd
        const [day, month] = clickedLabel.split('/'); // Divide 'dd/mm'
        const formattedDate = `${currentYear}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    
        let status = ''

        if (dataset.label === 'recebido') {
          status = 'RECEIVED'
        }
        if (dataset.label === 'à receber') {
          status = 'IN PROGRESS'
        }
        // if (dataset.label === 'recebido s/ catalogar') {
        //   status = 'PARTIALLY_RECEIVED'
        // }
        else {
          status = ''
        }

        window.open(
          `/recebimento/${paramsId}?&startAt=${formattedDate}&endAt=${formattedDate}&status=${status}`,
          '_blank'
        );
      }
    },
    onHover: (event, chartElements) => {
      const target = event.native?.target;
      if (target) {
        // Se o mouse está sobre uma barra
        target.style.cursor = chartElements.length > 0 ? 'pointer' : 'default';
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            weight: 700,
          }
        }
      },
      tooltip: {
        footerFont: 'normal',
        displayColors: mainContent?.contentFormatted?.some(el => el?.data?.length) ? !mainContent?.contentFormatted[0]?.dataFormatted[0].tooltipExtraInfo : false,
        callbacks: {
          title() {
            return null
          },
          label(context) {
            let currentLabel = ''

            mainContent?.contentFormatted?.map(el => {
              if (el?.title === context?.dataset?.label) {
                currentLabel = el?.dataFormatted?.find(item => item?.index === context?.dataIndex)?.item
              } 
            })

            return currentLabel
          },
          footer(context) {
            let currentLabel = ''
            
            if (!mainContent?.contentFormatted[0]?.dataFormatted[0].tooltipExtraInfo) return ''

            mainContent?.contentFormatted?.map(el => {
              if (el?.title === context[0]?.dataset?.label) {
                currentLabel = el?.dataFormatted?.find(item => item?.index === context[0]?.dataIndex)?.tooltipExtraInfo
              } 
            })

            return currentLabel
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          color: '#915'
        },
        ticks: {
          font: {
            weight: 'bold'
          } 
        },
        grid: {
          color: '#FFF',
          borderColor: '#FFF',
          tickColor: '#FFF'
        },
        border: {
          color: '#FFF',
        }
      },
      y: {
        title: {
          color: '#915'
        },
        ticks: {
          font: {
            weight: 'bold'
          },
          stepSize: mainContent?.contentFormatted?.length && mainContent?.contentFormatted.some(el => Array.from(new Set(el?.data))?.length > 5) ? 3 : 1, // Incrementos de 1 em 1
          beginAtZero: true, // Começa do zero
          autoSkip: false, // Não pula nenhum valor
        },
        grid: {
          color: '#FFF',
          borderColor: '#FFF',
          tickColor: '#FFF'
        },
        border: {
          color: '#FFF',
        }
      }
    }
  };

  return (
    <Bar 
      options={options} 
      data={data} 
    />
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupedVerticalBarGraphic);
