/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-new */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { TreeSelect } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import DefaultImage from 'images/defaultAndEmpties/defaultImage.svg';
import CloseGrayIcon from 'images/icons/signals/closesAndCancels/closeGray.svg';
import getOptions from 'services/getOptions';
import { addUniqueIdAutoComplete } from 'utils/customMaterialTable/addUniqueIdAutocomplete';
import Environment from 'utils/environments';
import findAndReplaceCategory from 'utils/findAndReplaceCategory';
import { setSubGroupsAndStores } from 'utils/setSubGroupsAndStores';

import ImageCropper from './ImageCropper';
import { calcMkpValue, calcCMVValue, calcMargin } from './utils';

import ProductTable from '../AddCardsForm/productTable';
import Button from '../Button/button';
import FullScreenLoading from '../FullScreenLoading/index';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  formControl: {
    width: 200,
    margin: theme.spacing(1),
    minWidth: 200
  },
  inputSelect: {
    height: '50px'
  },
  inputRoot: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  input: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  label: {
    fontFamily: 'Lato'
  }
}));

function Register({
  userInfo,
  menuInfo,
  handleModalToggle,
  data,
  menuId,
  replaceCategoryOf,
  updateList,
  salesAverageDiscount,
  setSalesAverageDiscount,
  percentageDiscountValue,
  openModalMenu,
  hasMenuModalChange,
  setHasMenuModalChange,
  showInactiveItems, 
  onlyView = false,
  setMenuLowAutomaticParams,
  menuLowAutomaticParams
}) {
  const { handleSubmit, errors } = useForm();
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const cardId = window?.location?.pathname?.split("/")?.[5];
  const blockUserNoAdm = userLocal?.profile[0][0].name !== "restaurants" && !!cardId;
  const [state, setState] = useState();
  const [loading, setLoading] = useState(false);
  const { id: paramsID } = useParams();
  const [originId, setOriginId] = useState(paramsID);
  const [originName, setOriginName] = useState();
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 10,
    filters: []
  });
  const { getId } = callSettings;
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'onSuccess', open: false, fullScreen: null, maxWidth: null },
    { id: 2, name: 'onError', open: false, fullScreen: null, maxWidth: null },
    {
      id: 3,
      name: 'imageCropper',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    }
  ]);
  const [options, setOptions] = useState({
    cards: [],
    unitsMeasurements: [],
    periods: [],
    menu: [],
    categories: [],
    status: [
      { id: 1, value: 'active', description: 'Ativo' },
      { id: 1, value: 'inactive', description: 'Inativo' }
    ]
  });
  const [contentMainCard, setContentMainCard] = useState([]);
  const { unitsMeasurements, periods, menu, categories, cards } = options;
  const environments = Environment(process.env.REACT_APP_ENV);
  const [categoriesError, setCategoriesError] = useState(false);
  const [editMode, setEditMode] = useState(
    menuInfo.action === 'EDIT' || menuId
  );
  const [editData, setEditData] = useState(
    editMode && !menuId && menuInfo.data ? menuInfo.data.data : {}
  );
  const storeSegment = userInfo?.companiesActive?.segment
  const [editCards, setEditCards] = useState(false);
  const [mainCard, setMainCard] = useState(null);
  const [mainCardId, setMainCardId] = useState();
  const [subMenuOptionsLoaded, setSubMenuOptionsLoaded] = useState(false);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [simulationValues, setSimulationValues] = useState({
    price: 0,
    mkp: 0,
    cmv: 0,
    margin: 0
  });
  const [newData, setNewData] = useState({
    card: null,
    data: {
      description: '',
      originId: null,
      unitsMeasurementsId: null,
      categoryId: null,
      cardsId: null,
      status: 'ACTIVE',
      cards: {
        description: '',
        resale: false,
        draft: false,
        tax: false,
        costs: {
          discountPrice: 0,
          averagePrice: 0,
          markup: 0,
          cmv: 0,
          costs: 0,
          costTotal: 0
        },
        productions: {
          yield: 1,
          yieldTotal: 1,
          loss: 0
        },
        items: [],
        deliveryItems: [],
        sales: null,
        stocks: {
          duration: 0,
          stockTotal: 0
        },
        unitsMeasurementsMenu: null
      },
      images: null,
      lowAutomatic: false,
      period: [],
      startDateCurrent: null,
      endDateCurrent: null,
      similarMenu: null,
      percentageDiscount: 0,
      pdv: {
        description: '',
        code: '',
        categoryId: null,
        integrated: false
      },
      salesAverage: 0,
      salesAverageDiscount: 0,
      // salesPrice: editData ? editData.salesPrice : null,
      margin: 0,
      mkp: 0,
      cmv: 0,
      curve: '',
      averagePriceSimulation: 0,
      markupSimulation: 0,
      cmvSimulation: 0,
      marginSimulation: 0,
      isSubMenu: false,
      mainMenus: [],
    }
  });
  const [cropper, setCropper] = useState();

  const classes = useStyles();
  const [newFreshMenu, setNewFreshMenu] = useState([]);
  const [userGroups, setUserGroups] = useState({
    groups: [
      {
        subGroups: [
          {
            stores: [{}]
          }
        ]
      }
    ]
  });
  const [statusCode, setStatusCode] = useState();
  const [commonMessage, setCommonMessage] = useState({description: '', status: ''})
  const [parameters, setParameters] = useState('');
  const [restaurantSubGroups, setRestaurantSubGroups] = useState([]);
  const [restaurantStores, setRestaurantStores] = useState([]);
  const [restaurantGroups, setRestaurantGroups] = useState([
    userInfo.companies.groups[0]
  ]);
  const [restaurantsId, setRestaurantsId] = useState(
    userInfo.companies.groups[0].subGroups[0].stores
  );

  const isEdition = menuInfo.data !== null;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const query = '';
  const isGroupManage = getId?.includes(',');

  const getMenuInfos = useCallback(
    () => {
      (async () => {
        if (menuId !== undefined) {
          (async () => {
            const statusValue = !(
              showInactiveItems === false || showInactiveItems === 'inactive'
            );
            const isSubMenu = storeSegment === 'Restaurante/Rodizio' ? `&isSubMenu=${menuInfo?.data?.data?.isSubMenu ? menuInfo?.data?.data?.isSubMenu : true}` : '';
            const dateParams = menuLowAutomaticParams?.lowAutomatic || isSubMenu ? `&startDate=${menuLowAutomaticParams?.startDate}&endDate=${menuLowAutomaticParams?.endDate}` : ''
            const params = `?status=${statusValue}${isSubMenu}${dateParams}`;
            const response = await fetch(
              `${environments.catalog}/menu/${menuId}${params}`
            );
            const cardMenu = await response.json();
            const salesAverageDiscountValue = cardMenu.salesAverageDiscount || 0;
    
            setEditData(cardMenu);
            setSalesAverageDiscount(salesAverageDiscountValue);
          })();
        }
      })();
    },
    [menuId]
  );

  const subMenuOption = () => {
    if (editData.isSubMenu && editData?.mainMenus?.length) {
      return 'subficha'
    } if (editData.isSubMenu) {
      return 'principal'
    }
  }

  const validSubMenu = () => {
    if (mainCard === 'subficha' && mainCardId.length) {
      return false
    } if (mainCard === 'principal') {
      return false
    } if (!mainCard) {
      return false
    } return true
  }

  useEffect(() => {
    getMenuInfos();
  }, [menuId]);

  // Parâmetros
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${getId}/parameters`
      );
      const parametersJson = await response.json();
      const { product: { type } = '' } = parametersJson;
      setParameters(type);
    })();
  }, [getId]);

  // Sales
  useEffect(() => {
    if (menuLowAutomaticParams?.lowAutomatic && editData?.pdv) {
      (async () => {
        const response = await fetch(
          `${environments.sales}/sales/${editData?.pdv?.code}/sumQuantity?restaurantId=${getId}&startDate=${menuLowAutomaticParams?.startDate}&endDate=${menuLowAutomaticParams?.endDate}`
        );
        
        const salesJson = await response.json();
       
        setMenuLowAutomaticParams({
          ...menuLowAutomaticParams,
          salesInfos: salesJson
        });
      })();
    }
  }, [getId, editData?.pdv]);

  useEffect(() => {
    if (!openModalMenu) return;

    if (!unitsMeasurements?.length) {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/consumptionUnits?page=1&size=50`
        );
        const items = await response.json();
        setOptions((state) => ({ ...state, unitsMeasurements: items.content }));
      })();
    }

    if (!menu?.length) {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/menu?originId=${getId}&menus=true`
        );
        const items = await response.json();
        setOptions((state) => ({ ...state, menu: items }));
      })();
    }

    if (!periods?.length) {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/periods?page=1&size=100`
        );
        const items = await response.json();
        setOptions((state) => ({ ...state, periods: items.content }));
      })();
    }

    if (!categories?.length) {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/categories?page=1&size=10000&originId=${getId}&type=Sales&description=${query}`
        );
        const items = await response.json();
        setOptions((state) => ({ ...state, categories: items.content }));
      })();
    }
  }, [getId]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.catalog}/menu?originId=${getId}&autoComplete=true&mainMenu=true&dateStart=${menuLowAutomaticParams?.startDate}&dateEnd=${menuLowAutomaticParams?.endDate}`
      );
      const items = await response.json();
      setContentMainCard(items)
      setSubMenuOptionsLoaded(true)
    })();
  }, [mainCard])

  useEffect(() => {
    if (subMenuOptionsLoaded) {
      setNewData({
        ...newData,
        data: {
          id: editData ? editData.id : null,
          created_at: editData?.created_at || null,
          updated_at: editData?.updated_at || null,
          user: editData?.user || null,
          description: editData ? editData.description : '',
          originId: getId,
          status: editData?.status || '',
          unitsMeasurements: editData?.unitsMeasurements || '',
          unitsMeasurementsId:
            editData && editData.unitsMeasurements
              ? editData.unitsMeasurements.id
              : null,
          categoryId:
            editData && editData.categories ? editData.categories.id : null,
          cardsId: editData && editData.cards ? editData.cards.id : null,
          cards: editData?.cards 
          ? {
            ...editData.cards,
            tax: editData?.tax
          }  
          : {
            ...newData.data.cards,
            tax: newData.data?.tax
          },
          itemsMenu: editData?.itemsMenu ? editData.itemsMenu : newData.data.itemsMenu,
          images:
            editData && editData.images ? editData.images : newData.data.images,
          lowAutomatic: editData && editData.lowAutomatic ? editData.lowAutomatic : newData.data.lowAutomatic,
          period:
            editData && editData.periods
              ? editData.periods.map((period) => ({ periodId: period.id }))
              : null,
          startDateCurrent: editData ? editData.startDateCurrent : null,
          endDateCurrent: editData ? editData.endDateCurrent : null,
          similarMenu: editData ? editData.similarMenu : null,
          pdv: editData && editData.pdv ? editData.pdv : newData.data.pdv,
          salesAverage: editData ? editData.salesAverage : 0,
          salesAverageDiscount:
            editData && salesAverageDiscount
              ? salesAverageDiscount
              : editData && editData.salesAverageDiscount
                ? editData.salesAverageDiscount
                : 0,
          mkp:
            editData &&
              salesAverageDiscount &&
              editData.cards &&
              editData.cards.costs
              ? calcMkpValue(
                salesAverageDiscount.toFixed(3),
                editData.cards.costs.costTotal.toFixed(3)
              )
              : editData && editData.mkp
                ? editData.mkp.toFixed(3).toString().replace('.', ',')
                : 0,
          cmv:
            editData &&
              salesAverageDiscount &&
              editData.cards &&
              editData.cards.costs
              ? calcCMVValue(
                salesAverageDiscount.toFixed(3),
                editData.cards.costs.costTotal.toFixed(3)
              )
              : editData && editData.cmv
                ? editData.cmv.toFixed(3).toString().replace('.', ',')
                : 0,
          margin:
            editData &&
              salesAverageDiscount &&
              editData.cards &&
              editData.cards.costs
              ? calcMargin(
                salesAverageDiscount.toFixed(3),
                editData?.cards?.costs?.costTotal.toFixed(3)
              )
              : editData && editData.margin
                ? editData.margin.toFixed(3).toString().replace('.', ',')
                : 0,
          percentageDiscount:
            editData && percentageDiscountValue
              ? percentageDiscountValue.toFixed(3).toString().replace('.', ',')
              : editData && editData.percentageDiscount
                ? editData.percentageDiscount
                  .toFixed(3)
                  .toString()
                  .replace('.', ',')
                : 0,
          curve: editData && editData.curve ? editData.curve : '',
          submenus: editData?.submenus || [],
          isSubMenu: editData?.isSubMenu,
          mainMenus: editData?.mainMenus,
        }
      });

      setMainCard(subMenuOption());
      setMainCardId(contentMainCard?.filter((el) => editData?.mainMenus?.map(item => item?.mainMenuId)?.includes(el?.id)))

      setSimulationValues({
        price: editData?.salesAverage || 0,
        mkp:
          editData.cards && editData.cards.costs
            ? calcMkpValue(
              editData?.averagePriceSimulation?.toFixed(3) ||
              editData?.salesAverage?.toFixed(3),
              editData.cards.costs.costTotal.toFixed(3)
            )
            : 0,
        cmv:
          editData.cards && editData.cards.costs
            ? calcCMVValue(
              editData?.averagePriceSimulation?.toFixed(3) ||
              editData?.salesAverage?.toFixed(3),
              editData.cards.costs.costTotal.toFixed(3)
            )
            : 0,
        margin:
          editData.cards && editData.cards.costs
            ? calcMargin(
              editData?.averagePriceSimulation?.toFixed(3) ||
              editData?.salesAverage?.toFixed(3),
              editData.cards.costs.costTotal.toFixed(3)
            )
            : 0
      });
    }
  }, [editData, salesAverageDiscount, subMenuOptionsLoaded]);

  const getCropData = () => {
    setNewData({
      ...newData,
      data: {
        ...newData?.data,
        images: {
          ...newData?.data?.images,
          baseImage: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9),
          url: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9)
        }
      }
    });
  };

  useEffect(() => {
    if (editCards) {
      const inputSim = simulationValues.price ? simulationValues.price : 0;
      const newDataTotal = newData.data.cards.items.length
        ? newData.data.cards.items.reduce(
            (total, value) =>
              value
                ? parseFloat(Number(value.quantity)) *
                    parseFloat(
                      Number(
                        parameters === 'LAST_PURCHASE'
                          ? value.products.lastCostStock ||
                              value.products.lastCost
                          : value.products.averageCostStock ||
                              value.products.averageCost
                      )
                    ) +
                  parseFloat(total)
                : total,
            0
          )
        : 0;

      setNewData({
        ...newData,
        data: {
          ...newData.data,
          mkp:
            newData.data.salesAverageDiscount !== 0
              ? calcMkpValue(
                  newData.data.salesAverageDiscount.toFixed(3),
                  newDataTotal.toFixed(3)
                )
              : 0,
          cmv:
            newData.data.salesAverageDiscount !== 0
              ? calcCMVValue(
                  newData.data.salesAverageDiscount.toFixed(3),
                  newDataTotal.toFixed(3)
                )
              : 0,
          margin: calcMargin(
            newData.data.salesAverageDiscount.toFixed(3),
            newDataTotal.toFixed(3)
          )
        }
      });

      const newPrice =
        typeof simulationValues?.price === 'string'
          ? parseFloat(simulationValues?.price)
          : simulationValues?.price
          ? parseFloat(simulationValues?.price.toFixed(3))
          : '0,00';

      setSimulationValues({
        ...simulationValues,
        mkp: calcMkpValue(newPrice, newDataTotal.toFixed(3)),
        cmv: calcCMVValue(newPrice, newDataTotal.toFixed(3)),
        margin: calcMargin(newPrice, newDataTotal.toFixed(3)),
        price: newPrice
      });
    }
  }, [newData.data.cards]);

  const initialValues = {
    description: editData.description || '',
    unitsMeasurementsId: editData.unitsMeasurementsId ?? null,
    period: editData.periods ?? [],
    categoryId: editData.categoryId ?? null,
    startDateCurrent: editData.startDateCurrent ?? null,
    endDateCurrent: editData.endDateCurrent ?? null,
    similarMenu: editData.similarMenu ?? null,
    status: editData.status ?? null,
    ...(editData.pdv && { pdv: editData.pdv }),
    ...(editData.cards && { cards: editData.cards })
  };

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };


  const createData = () => {
    setLoading(true);

    const validateIsSubMenu = storeSegment === 'Restaurante/Rodizio' ? mainCard && !mainCardId?.length : false
    if (!newData.data.lowAutomatic && !newData?.data?.cards?.items?.length && !newData?.data?.cards?.tax && !validateIsSubMenu) {
      setModalSettings(
        modalSettings.map((modal) => {
          if (modal.name === 'onError') {
            return {
              ...modal,
              open: true,
              description: 'Favor adicionar um produto.'
            };
          }
          return { ...modal };
        })
      );

      setLoading(false);
      return;
    }

    new Promise((resolve, reject) => {
      if (newData.data.categoryId) {
        newData.data.groups = userGroups.groups.map((group) =>
          group.hasOwnProperty('id')
        )[0]
          ? restaurantGroups.map((group) => ({
              id: group.id,
              name: group.name,
              subGroups: buildSubGroups(group)
            }))
          : null;
        if (editMode && newData.data.id) {
          const { id } = newData.data;
          const {
            price = '',
            cmv = '',
            mkp = '',
            margin = ''
          } = simulationValues;
          newData.data.description = newData.data.description.trim();
          newData.data.averagePriceSimulation = parseFloat(price);
          newData.data.user = userInfo?.user;
          newData.data.cmvSimulation = parseFloat(
            cmv?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.markupSimulation = parseFloat(
            mkp?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.marginSimulation = parseFloat(
            margin?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.salesAverageDiscount = newData.data.salesAverageDiscount;
          newData.data.margin = parseFloat(
            newData.data.margin
              ?.toString()
              ?.replace(/\./g, '')
              .replace(',', '.')
          );
          newData.data.mkp = parseFloat(
            newData.data.mkp?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.cmv = parseFloat(
            newData.data.cmv?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.percentageDiscount = parseFloat(
            newData.data.percentageDiscount
              ?.toString()
              ?.replace(/\./g, '')
              .replace(',', '.')
          );
          newData.data.images = newData.data.images;
          newData.data.lowAutomatic = newData.data.lowAutomatic ? newData.data.lowAutomatic : false,
          newData.data.isSubMenu = !!mainCard,
          newData.data.mainMenus = mainCardId?.map(el => el?.id),
          newData.data.cards = {
            ...newData.data.cards,
            description:
              newData.data.cards.description === ''
                ? newData.data.description
                : newData.data.cards.description,
            draft: newData.data.cards.draft ? newData.data.cards.draft : false,
            stocks: {
              stockTotal: newData.data.cards.resale
                ? newData.data.cards.items.reduce(
                    (total, value) =>
                      value
                        ? parseFloat(value.products.theoreticalStock) +
                          parseFloat(total)
                        : total,
                    0
                  )
                : 1,
              duration: 0
            },
            costs: {
              ...newData.data.cards.costs,
              costTotal: newData.data.cards.resale
                ? newData.data.cards.items.reduce(
                    (total, value) =>
                      value
                        ? parseFloat(Number(value.cost)) + parseFloat(total)
                        : total,
                    0
                  ) / newData.data.cards.items.length
                : newData.data.cards.items.reduce(
                    (total, value) =>
                      value
                        ? parseFloat(Number(value.cost)) + parseFloat(total)
                        : total,
                    0
                  )
            },
            productions: {
              yield:
                newData.data.cards.productions &&
                newData.data.cards.productions.yield
                  ? newData.data.cards.productions.yield
                  : 1,
              yieldTotal:
                newData.data.cards.productions &&
                newData.data.cards.productions.yieldTotal
                  ? newData.data.cards.productions.yieldTotal
                  : 1,
              loss:
                newData.data.cards.productions &&
                newData.data.cards.productions.loss
                  ? newData.data.cards.productions.loss
                  : 0
            },
            user: userInfo.user
          };

          axios
            .put(`${environments.catalogV2}/menu/${id}`, newData.data, config)
            .then((_response) => {
              resolve();
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'onSuccess' ? { ...item, open: true } : item
                )
              );
              const localStates = {
                editData,
                unitsMeasurements,
                periods,
                cards,
                newFreshMenu,
                setNewFreshMenu,
                getId: paramsID
              };
              if (!replaceCategoryOf) {
                findAndReplaceCategory(
                  data,
                  'editMenuDescription',
                  newData.data,
                  id,
                  localStates,
                  setState
                );
              }
              if (updateList) {
                updateList();
              }
              setLoading(false);
              handleModalToggle(true);
            })
            .catch((error) => {
              console.log(error);
              const alreadyDescriptionErrorMessage =
                error?.response?.data?.message ===
                'Already exists a menu with this description in one of the restaurat sent';

              const isUnityError = error?.response?.data?.message === "Cannot read property 'id' of undefined" || error?.response?.data?.message === "Cannot read property 'id' of null";

              const translateErrorMessage = alreadyDescriptionErrorMessage
                ? 'Já existe um cardápio com esta descrição em algum dos restaurantes selecionados.'
                : isUnityError ? 'Existem produtos inválidos na aba Delivery' : 'Ocorreu um erro ao tentar salvar os dados.';

              setLoading(false);
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'onError'
                    ? {
                        ...item,
                        open: true,
                        description: translateErrorMessage
                      }
                    : item
                )
              );
              reject();
            });
        } else {
          const {
            price = '',
            cmv = '',
            mkp = '',
            margin = ''
          } = simulationValues;
          newData.data.user = userInfo.user;
          newData.data.endDateCurrent = newData.data.endDateCurrent
            ? format(new Date(newData.data.endDateCurrent), 'yyyy-MM-dd')
            : null;
          newData.data.startDateCurrent = newData.data.startDateCurrent
            ? format(new Date(newData.data.startDateCurrent), 'yyyy-MM-dd')
            : null;
          newData.data.pdv.code =
            newData.data.pdv.code === '' ? null : newData.data.pdv.code;
          newData.data.averagePriceSimulation = parseFloat(price);
          newData.data.cmvSimulation = parseFloat(
            cmv?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.markupSimulation = parseFloat(
            mkp?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.marginSimulation = parseFloat(
            margin?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.salesAverageDiscount = newData.data.salesAverageDiscount;
          newData.data.margin = parseFloat(
            newData.data.margin
              ?.toString()
              ?.replace(/\./g, '')
              .replace(',', '.')
          );
          newData.data.mkp = parseFloat(
            newData.data.mkp?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.cmv = parseFloat(
            newData.data.cmv?.toString()?.replace(/\./g, '').replace(',', '.')
          );
          newData.data.percentageDiscount = parseFloat(
            newData.data.percentageDiscount
              ?.toString()
              ?.replace(/\./g, '')
              .replace(',', '.')
          );
          newData.data.images = newData.data.images;
          newData.data.lowAutomatic = newData.data.lowAutomatic,
          newData.data.isSubMenu = !!mainCard,
          newData.data.mainMenus = mainCardId?.map(el => el?.id),
          newData.data.cards = {
            ...newData.data.cards,
            description: newData.data.description,
            draft: newData.data.cards.draft,
            stocks: {
              stockTotal: newData.data.cards.resale
                ? newData.data.cards.items.reduce(
                    (total, value) =>
                      value
                        ? parseFloat(value.products.theoreticalStock) +
                          parseFloat(total)
                        : total,
                    0
                  )
                : 1,
              duration: 0
            },
            costs: {
              ...newData.data.cards.costs,
              costTotal: newData.data.cards.resale
                ? newData.data.cards.items.reduce(
                    (total, value) =>
                      value
                        ? parseFloat(Number(value.cost)) + parseFloat(total)
                        : total,
                    0
                  ) / newData.data.cards.items.length
                : newData.data.cards.items.reduce(
                    (total, value) =>
                      value
                        ? parseFloat(Number(value.cost)) + parseFloat(total)
                        : total,
                    0
                  )
            },
            productions: {
              yield:
                newData.data.cards.productions &&
                newData.data.cards.productions.yield
                  ? newData.data.cards.productions.yield
                  : 1,
              yieldTotal:
                newData.data.cards.productions &&
                newData.data.cards.productions.yieldTotal
                  ? newData.data.cards.productions.yieldTotal
                  : 1,
              loss:
                newData.data.cards.productions &&
                newData.data.cards.productions.loss
                  ? newData.data.cards.productions.loss
                  : 0
            }
          };
          delete newData.data.id;

          axios
            .post(`${environments.catalog}/menu`, newData.data, config)
            .then((response) => {
              const localStates = {
                editData: {
                  ...editData,
                  categories: { id: response.data.categoryId }
                },
                unitsMeasurements,
                periods,
                cards,
                getId: paramsID
              };
              if (!replaceCategoryOf) {
                findAndReplaceCategory(
                  data,
                  'addOnMenu',
                  response.data,
                  0,
                  localStates,
                  setState
                );
              }
              if (updateList) {
                updateList();
              }
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'onSuccess' ? { ...item, open: true } : item
                )
              );
              setLoading(false);
              handleModalToggle(true);
            })
            .catch((error) => {
              const alreadyDescriptionErrorMessage =
                error?.response?.data?.message ===
                'Already exists a menu with this description in one of the restaurat sent';
              const translateErrorMessage = alreadyDescriptionErrorMessage
                ? 'Já existe um cardápio com esta descrição em algum dos restaurantes selecionados.'
                : 'Ocorreu um erro ao tentar salvar os dados.';

              setLoading(false);
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'onError'
                    ? {
                        ...item,
                        open: true,
                        description: translateErrorMessage
                      }
                    : item
                )
              );
              console.log(error);
            });
        }
      } else {
        setLoading(false);
        setCategoriesError(true);
        setState({ categoriesError });
      }
    });
  };

  useEffect(() => {
    setOriginId(userInfo.companiesActive.id);
    setOriginName(userInfo.companiesActive.name);
    setUserGroups({ groups: userInfo.companies.groups });

    !storageLocations?.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        isGroupManage ? getId?.split(',')[0] : getId
      );

    !periodicities?.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        isGroupManage ? getId?.split(',')[0] : getId
      );
    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        isGroupManage ? getId?.split(',')[0] : getId
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null
      );
  }, [userInfo]);

  const fetchRestaurantsOfMenu = async (menuId) => {
    const response = await fetch(
      `${environments.catalog}/menu/${menuId}/groups`
    );
    const groups = await response.json();

    return groups;
  };

  const handleRadioChange = (event) => {
    setHasMenuModalChange(true)
    const newValue = event.target.value;
    setMainCard((prevValue) => (prevValue === newValue ? null : newValue));
  };

  useEffect(() => {
    (async () => {
      const groupActive =
        userInfo.companies.groups.length > 0
          ? userInfo.companies.groups.find((group) =>
              group.subGroups.some((subGroup) =>
                subGroup.stores.some((store) =>
                  store.id === isGroupManage
                    ? userInfo.companiesActive.id?.split(',')[0]
                    : userInfo.companiesActive.id
                )
              )
            )
          : {};
      const subGroupActive =
        userInfo.companies.groups.length > 0
          ? groupActive.subGroups.find((subGroup) =>
              subGroup.stores.some((store) =>
                store.id === isGroupManage
                  ? userInfo.companiesActive.id?.split(',')[0]
                  : userInfo.companiesActive.id
              )
            )
          : {};

      if (editMode && menuId) {
        const origins = await fetchRestaurantsOfMenu(menuId);

        if (origins && origins.length > 0) {
          const { selectedSubGroups, selectedStores } = setSubGroupsAndStores({
            subGroups: groupActive.subGroups,
            originIds: origins
          });

          setRestaurantSubGroups(selectedSubGroups);
          setRestaurantStores(selectedStores);
        } else {
          setRestaurantSubGroups([subGroupActive]);
          setRestaurantStores(subGroupActive.stores);
        }
      } else {
        setRestaurantSubGroups([subGroupActive]);
        setRestaurantStores(subGroupActive.stores);
      }
    })();
  }, [userInfo.companies.groups, userInfo.companiesActive]);

  const renderHeaderOptions = () => {
    if (userGroups.groups.map((group) => group.hasOwnProperty('id'))[0]) {
      return (
        <>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              disabled={onlyView}
              className="autocompleteSmall"
              limitTags={1}
              options={userGroups.groups}
              disableCloseOnSelect
              size="small"
              value={restaurantGroups}
              getOptionLabel={(option) => option.name}
              classes={{ input: classes.input, option: classes.option }}
              onChange={(_e, value) => {
                setHasMenuModalChange(true);

                if (value) {
                  setRestaurantGroups(value);
                  setRestaurantSubGroups(
                    value
                      .reduce((acc, group) => [...acc, group.subGroups], [])
                      .flat()
                  );
                  setRestaurantStores(
                    value
                      .reduce((acc, group) => [...acc, group.subGroups], [])
                      .flat()
                      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                      .flat()
                  );
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Grupos"
                  placeholder="Grupos"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              disabled={onlyView}
              className="autocompleteSmall"
              limitTags={1}
              options={restaurantGroups
                .reduce((acc, group) => [...acc, group.subGroups], [])
                .flat()}
              value={restaurantSubGroups}
              disableCloseOnSelect
              size="small"
              getOptionLabel={(option) => option.name}
              classes={{ input: classes.input, option: classes.option }}
              onChange={(_e, value) => {
                setHasMenuModalChange(true);
                if (value) {
                  setRestaurantSubGroups(value);
                  setRestaurantStores(
                    value
                      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                      .flat()
                  );
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Subgrupos"
                  placeholder="Subgrupos"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              disabled={onlyView}
              className="autocompleteSmall"
              limitTags={1}
              options={restaurantSubGroups
                .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                .flat()}
              value={restaurantStores}
              disableCloseOnSelect
              size="small"
              getOptionLabel={(option) => option.name}
              classes={{ input: classes.input, option: classes.option }}
              onChange={(_e, value) => {
                setHasMenuModalChange(true);

                if (value) {
                  setRestaurantStores(value);
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Restaurantes"
                  placeholder="Restaurantes"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>
        </>
      );
    }
    return (
      <Grid item xs={12}>
        <Autocomplete
          options={userInfo.companies.groups[0].subGroups[0].stores}
          disabled={onlyView}
          getOptionLabel={(option) => option.name}
          size="small"
          limitTags={1}
          value={restaurantsId}
          multiple
          className="autocompleteSmall"
          onChange={(_event, value) => {
            setHasMenuModalChange(true);

            if (value) {
              setRestaurantsId(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={params.id}
              type="text"
              name="restaurantsId"
              label="Restaurantes"
              variant="outlined"
              InputLabelProps={{
                style: { fontFamily: 'Lato', fontSize: '12px' }
              }}
            />
          )}
        />
      </Grid>
    );
  };

  const pluckIds = (arr) => arr.map((obj) => obj.id);

  const buildSubGroups = (group) => {
    const subGroups = group.subGroups.filter((subGroup) =>
      pluckIds(restaurantSubGroups).includes(subGroup.id)
    );

    return subGroups.map((subGroup) => ({
      id: subGroup.id,
      name: subGroup.name,
      stores: buildStores(subGroup)
    }));
  };

  const buildStores = (subGroup) => {
    const stores = subGroup.stores.filter((store) =>
      pluckIds(restaurantStores).includes(store.id)
    );

    return stores.map((store) => ({
      id: store.id,
      name: store.name
    }));
  };

  const onChangeImage = (e) => {
    if (!e?.target?.files?.length) return;

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setHasMenuModalChange(true);
    const reader = new FileReader();

    reader.onload = () => {
      setNewData({
        ...newData,
        data: {
          ...newData.data,
          images: {
            url: reader.result,
            baseImage: reader.result,
            name: files?.length && files[0]?.name
          }
        }
      });

      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'imageCropper'
            ? { ...modal, open: true, currentSkuIndex: menuId }
            : modal
        )
      );
    };
    reader.readAsDataURL(files?.length && files[0]);
  };

  const handleSelectImage = (_e, index) => {
    if (index || index === 0) {
      document.querySelector(`#fileInput${index}`).click();
    } else {
      document.querySelector(`#fileInput`).click();
    }
  };

  const handleClearFileInput = (index) => {
    if (index || index === 0) {
      document.querySelector(`#fileInput${index}`).value = null;
    } else {
      document.querySelector(`#fileInput`).value = null;
    }
  };

  const handleRemoveImage = () => {
    setNewData({
      ...newData,
      data: {
        ...newData.data,
        images: null
      }
    });
  };

  return (
    <>
      <FullScreenLoading display={loading} description="Salvando" />

      <form onSubmit={handleSubmit(createData)}>
        <Grid container xs={9}>
          <div
            className="dFlex flexColumn"
            style={{ width: 'calc(100% - 10px)' }}
          >
            <Grid xs={12} className="cardDefault withoutInputs mb10">
              <Grid container item xs={12} spacing={5}>
                {renderHeaderOptions()}
              </Grid>

              <Grid container item xs={12} spacing={5}>
                <Grid item xs={3}>
                  <TextField
                    onChange={(e) => {
                      setHasMenuModalChange(true);
                      setNewData({
                        ...newData,
                        data: { ...newData.data, description: e.target.value }
                      });
                    }}
                    type="text"
                    variant="outlined"
                    label="Cardápio"
                    fullWidth
                    defaultValue={
                      newData.data && newData.data.description
                        ? newData.data.description
                        : newData.data.pdv.description
                    }
                    value={newData?.data?.description}
                    size="small"
                    required="Campo obrigatório"
                    InputProps={{
                      maxLength: 8,
                      classes: { input: classes.input },
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={onlyView}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Autocomplete
                    disabled={onlyView}
                    disablePortal
                    size="small"
                    id="asynchronous-demo"
                    onOpen={() => {
                      addUniqueIdAutoComplete();
                    }}
                    onChange={(_event, value) => {
                      setHasMenuModalChange(true);

                      if (value) {
                        setNewData({
                          ...newData,
                          data: {
                            ...newData.data,
                            unitsMeasurementsId: value.id
                          }
                        });
                      }
                    }}
                    getOptionSelected={(optionsMenu, value) =>
                      optionsMenu.id === value.id
                    }
                    getOptionLabel={(optionsMenu) => optionsMenu.abbreviation}
                    options={unitsMeasurements}
                    value={
                      newData.data && newData.data.unitsMeasurementsId
                        ? unitsMeasurements.find(
                            (x) => x.id === newData.data.unitsMeasurementsId
                          )
                        : newData.data.pdv.integrated === true
                        ? (newData.data.unitsMeasurementsId = 3)
                        : null
                    }
                    className="autocompleteSmall"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Un."
                        error={newData.data.unitsMeasurementsId === ''}
                        variant="outlined"
                        required="Campo obrigatório"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.abbreviation}
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Autocomplete
                    disabled={onlyView}
                    size="small"
                    id="asynchronous-demo"
                    onOpen={() => {
                      addUniqueIdAutoComplete();
                    }}
                    onChange={(_event, value) => {
                      setHasMenuModalChange(true);

                      if (value) {
                        setNewData({
                          ...newData,
                          data: {
                            ...newData.data,
                            period: value.map((item) => ({ periodId: item.id }))
                          }
                        });
                      }
                    }}
                    getOptionSelected={(optionsMenu, value) =>
                      optionsMenu.description === value.description
                    }
                    getOptionLabel={(optionsMenu) => optionsMenu.description}
                    options={periods}
                    multiple
                    limitTags={1}
                    className="autocompleteSmall"
                    ChipProps={{
                      size: 'small',
                      style: { fontFamily: 'Lato', fontSize: 12, height: 20 }
                    }}
                    value={
                      periods.length && newData.data && newData.data.period
                        ? newData.data.period.map((period) =>
                            periods.find((item) => item.id === period.periodId)
                          )
                        : []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Período"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    disabled={onlyView}
                    onChange={(event) => {
                      setHasMenuModalChange(true);
                      setNewData({
                        ...newData,
                        data: {
                          ...newData.data,
                          pdv: {
                            ...newData.data.pdv,
                            code: event.target.value ? event.target.value : null
                          }
                        }
                      });
                    }}
                    variant="outlined"
                    label="Código PDV"
                    fullWidth
                    size="small"
                    error={errors.pdvCode}
                    value={newData.data.pdv ? newData.data.pdv.code : ''}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      maxLength: 8,
                      classes: { input: classes.input },
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    onChange={(event) => {
                      setHasMenuModalChange(true);

                      if (event.target.value) {
                        setNewData({
                          ...newData,
                          data: {
                            ...newData.data,
                            pdv: {
                              ...newData.data.pdv,
                              description: event.target.value
                            }
                          }
                        });
                      }
                    }}
                    type="text"
                    variant="outlined"
                    label="Descrição PDV"
                    fullWidth
                    disabled
                    size="small"
                    error={errors.pdvDescription}
                    value={newData.data.pdv ? newData.data.pdv.description : ''}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      maxLength: 8,
                      classes: { input: classes.input },
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={5}>
                <Grid item xs={3}>
                  <FormControl fullWidth className="selectSmallBox">
                    <label>Categorias</label>

                    <TreeSelect
                      value={newData.data.categoryId}
                      suffixIcon={
                        <svg
                          className="MuiSvgIcon-root pAbsolute"
                          style={{ right: 0, bottom: '-7px' }}
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M7 10l5 5 5-5z" />
                        </svg>
                      }
                      treeDefaultExpandAll
                      filterTreeNode={(search, item) =>
                        item?.title
                          ?.toLowerCase()
                          ?.normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          ?.indexOf(
                            search
                              ?.toLowerCase()
                              ?.normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                          ) >= 0
                      }
                      onChange={(e, value) => {
                        setHasMenuModalChange(true);
                        setNewData({
                          ...newData,
                          data: {
                            ...newData.data,
                            categoryId: e,
                            categoryFamilyDescription: value?.family
                          }
                        });
                        value
                          ? setCategoriesError(false)
                          : setCategoriesError(true);
                      }}
                      keygen="id"
                      rules={[{ required: true, message: 'Campo obrigatótio' }]}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={categories}
                      placeholder="Selecione..."
                      allowClear
                      showSearch
                      fullWidth
                      size="small"
                      disabled={onlyView}
                    />

                    <Tooltip
                      title="Preencha este campo."
                      placement="bottom-start"
                      open={categoriesError}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                    >
                      <span />
                    </Tooltip>
                  </FormControl>
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                  <Grid item xs={2}>
                    <KeyboardDatePicker
                      disabled={onlyView}
                      size="small"
                      id="time-picker"
                      label="Data Inicial"
                      format="dd/MM/yyyy"
                      ampm={false}
                      clearable
                      fullWidth
                      autoOk
                      maxDate={newData.data.endDateCurrent}
                      value={
                        newData.data.startDateCurrent
                          ? newData.data.startDateCurrent
                          : null
                      }
                      inputVariant="outlined"
                      className="dateSmall"
                      onChange={(date) => {
                        setHasMenuModalChange(true);

                        if (date) {
                          setNewData({
                            ...newData,
                            data: { ...newData.data, startDateCurrent: date }
                          });
                        } else {
                          setNewData({
                            ...newData,
                            data: { ...newData.data, startDateCurrent: null }
                          });
                        }
                      }}
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      KeyboardButtonProps={{
                        size: 'small',
                        'aria-label': 'change time',
                        style: { outline: 0 }
                      }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <KeyboardDatePicker
                      disabled={onlyView}
                      size="small"
                      id="time-picker"
                      label="Data Final"
                      format="dd/MM/yyyy"
                      ampm={false}
                      clearable
                      fullWidth
                      autoOk
                      minDate={newData.data.startDateCurrent}
                      value={
                        newData.data.endDateCurrent
                          ? newData.data.endDateCurrent
                          : null
                      }
                      inputVariant="outlined"
                      className="dateSmall"
                      onChange={(date) => {
                        setHasMenuModalChange(true);

                        if (date) {
                          setNewData({
                            ...newData,
                            data: { ...newData.data, endDateCurrent: date }
                          });
                        } else {
                          setNewData({
                            ...newData,
                            data: { ...newData.data, endDateCurrent: null }
                          });
                        }
                      }}
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      KeyboardButtonProps={{
                        size: 'small',
                        'aria-label': 'change time',
                        style: { outline: 0 }
                      }}
                      PopoverProps={{ style: { outline: 0 } }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

                <Grid item xs={3}>
                  <Autocomplete
                    disabled={onlyView}
                    size="small"
                    className="autocompleteSmall"
                    id="asynchronous-demo"
                    onOpen={() => {
                      addUniqueIdAutoComplete();
                    }}
                    onChange={(_event, value) => {
                      setHasMenuModalChange(true);

                      if (value) {
                        setNewData({
                          ...newData,
                          data: { ...newData.data, similarMenu: value.id }
                        });
                      } else {
                        setNewData({
                          ...newData,
                          data: { ...newData.data, similarMenu: null }
                        });
                      }
                    }}
                    getOptionSelected={(optionsMenu, value) =>
                      optionsMenu.id === value.id
                    }
                    getOptionLabel={(optionsMenu) => optionsMenu.description}
                    value={
                      newData.data && newData.data.similarMenu
                        ? menu.find(
                            (item) => item.id === newData.data.similarMenu
                          )
                        : null
                    }
                    options={menu}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Similar"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid item xs={2} className="pt0 pb0 flexColumn">
                  <FormControlLabel
                    className="toggleDefault"
                    style={{ margin: '-10px 0 0 -11px' }}
                    control={
                      <Switch
                        disabled={onlyView}
                        onChange={(e, checked) => {
                          setHasMenuModalChange(true);

                          if (e) {
                            setNewData({
                              ...newData,
                              data: {
                                ...newData.data,
                                cards: { ...newData.data.cards, draft: checked }
                              }
                            });
                          }
                        }}
                        checked={
                          newData.data && newData.data.cards
                            ? newData.data.cards.draft
                            : false
                        }
                        name="draft"
                      />
                    }
                    label="Rascunho"
                  />

                  <FormControlLabel
                    className="toggleDefault"
                    style={{ margin: '-5px 0 0 -11px' }}
                    control={
                      <Switch
                        disabled={onlyView}
                        onChange={(e, checked) => {
                          setHasMenuModalChange(true);

                          if (e) {
                            setNewData({
                              ...newData,
                              data: {
                                ...newData.data,
                                status: checked ? 'ACTIVE' : 'INACTIVE'
                              }
                            });
                          }
                        }}
                        checked={newData?.data?.status === 'ACTIVE'}
                        name="status"
                      />
                    }
                    label="Ativa"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={5}>
                <Grid item xs={2} className="pt0 pb0">
                  <div className="dFlex" style={{ marginBottom: '4px' }}>
                    <Checkbox
                      disabled={onlyView}
                      style={{ padding: '0 16px 0 8px' }}
                      name="resales"
                      checked={
                        newData.data && newData.data.cards
                          ? newData.data.cards.resale
                          : false
                      }
                      size="small"
                      onChange={(e, checked) => {
                        setHasMenuModalChange(true);

                        if (e) {
                          setNewData({
                            ...newData,
                            data: {
                              ...newData.data,
                              cards: { ...newData.data.cards, resale: checked }
                            }
                          });
                        }
                      }}
                    />
                    <span style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                      Revenda
                    </span>
                  </div>
                </Grid>

                <Grid item xs={2} className="pt0 pb0 pl0">
                  <div className="dFlex" style={{ marginBottom: '4px' }}>
                    <Checkbox
                      disabled={onlyView}
                      style={{ padding: '0 16px 0 8px' }}
                      name="resales"
                      checked={
                        newData?.data?.cards ? newData.data.cards.tax : false
                      }
                      size="small"
                      onChange={(e, checked) => {
                        setHasMenuModalChange(true);

                        if (e) {
                          setNewData({
                            ...newData,
                            data: {
                              ...newData.data,
                              cards: {
                                ...newData.data.cards,
                                tax: checked
                              }
                            }
                          });
                        }
                      }}
                    />

                    <span style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                      Taxas
                    </span>
                  </div>
                </Grid>

                {storeSegment ===
                  'Restaurante/Kg'
                  ? (
                    <Grid item xs={3} className="pt0 pb0 pl0">
                      <div className="dFlex" style={{ marginBottom: '4px' }}>
                        <Checkbox
                          style={{ padding: '0 16px 0 8px' }}
                          name="lowAutomatic"
                          checked={
                            newData?.data ? newData.data.lowAutomatic : false
                          }
                          size="small"
                          onChange={(e, checked) => {
                            setHasMenuModalChange(true);

                            if (e) {
                              setNewData({
                                ...newData,
                                data: {
                                  ...newData.data,
                                  lowAutomatic: checked
                                }
                              });
                            }
                          }}
                        />

                        <span style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                          Vendas por Kg
                        </span>
                      </div>
                    </Grid>
                  ) : storeSegment ===
                    'Restaurante/Rodizio' ? (
                    <div className="dFlex pt0 pb0 pl0" style={{ width: '50%', gap: '30px' }}>
                      <div className="dFlex item" style={{ marginBottom: '4px' }}>
                        {/* <Checkbox
                          disabled={onlyView}
                          style={{ padding: '0 16px 0 8px', height: '20px' }}
                          name="lowAutomatic"
                          checked={mainCard}
                          size="small"
                          onChange={(e) => {
                            setMainCard(!mainCard)
                            setHasMenuModalChange(true);
                          }}
                        /> */}

                        {/* <span style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                          Subficha
                        </span> */}

                    <FormControl component="fieldset">
                          <RadioGroup
                            name="type"
                            value={mainCard}
                            onChange={handleRadioChange}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '10px'
                            }}
                          >
                            <FormControlLabel
                              value="principal"
                              onClick={() => {mainCard && 
                                setMainCard(null)
                                setHasMenuModalChange(true)
                              }}
                              control={<Radio size="small" />}
                              label="Principal"
                            />
                            <FormControlLabel
                              value="subficha"
                              onClick={() => {mainCard && 
                                setMainCard(null)
                                setHasMenuModalChange(true);
                              }}
                              control={<Radio id="cardFormTransformRadio" size="small" />}
                              label="Subficha"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                        {mainCard === 'subficha' && (
                          <div style={{ width: '50%', marginTop: '19px' }}>
                            <Autocomplete
                              disabled={onlyView}
                              size="small"
                              multiple
                              limitTags={1}
                              className="autocompleteSmall"
                              id="asynchronous-demo"
                              getOptionSelected={(optionsMenu, value) =>
                                optionsMenu.id === value.id
                              }
                              getOptionLabel={(optionsMenu) => optionsMenu.description}
                              onChange={(_, value) => {
                                setMainCardId(value);
                                setHasMenuModalChange(true);
                              }}
                              value={mainCardId}
                              options={contentMainCard}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  label="Ficha Principal"
                                  variant="outlined"
                                  placeholder="Selecione..."
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              )}
                              renderOption={(params) => (
                                <Typography
                                  style={{ fontSize: '12px', fontFamily: 'Lato' }}
                                >
                                  {params.description}
                                </Typography>
                              )}
                            />
                          </div>
                        )}

                    </div>
                  ) : null}
              </Grid>
            </Grid>

            <ProductTable
              data={newData}
              setData={setNewData}
              options={options}
              cardId={newData.data.cardsId}
              setEditCards={setEditCards}
              unitsMeasurements={unitsMeasurements}
              consumptionUnits={unitsMeasurements}
              storageLocations={storageLocations}
              periodicities={periodicities}
              parameters={parameters}
              setHasMenuModalChange={setHasMenuModalChange}
              brands={brands}
              packageTypes={packageTypes}
              onlyView={onlyView}
              menuLowAutomaticParams={menuLowAutomaticParams}
              menuInfo={menuInfo}
              getMenuInfos={getMenuInfos}
              commonMessage={commonMessage}
              setCommonMessage={setCommonMessage}
            />
          </div>
        </Grid>

        <Grid container xs={3}>
          <div className="cardDefault productRightCard pt16">
            <Grid container spacing={1}>
              <Grid item xs={4} />

              <Grid item xs={4}>
                <label className="blue">Vendas</label>
              </Grid>

              <Grid item xs={4}>
                <label className="blue">Simulação</label>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <label>Preço Médio</label>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={(newData.data.salesAverageDiscount
                    ? newData.data.salesAverageDiscount
                    : 0
                  )
                    .toFixed(3)
                    .toString()
                    .replace('.', ',')}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <CurrencyTextField
                  disabled={onlyView}
                  variant="outlined"
                  size="small"
                  currencySymbol=""
                  maximumValue="99999999999999999999999999999999999999999"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  decimalPlaces={3}
                  decimalPlacesShownOnFocus={3}
                  type="text"
                  name="quantity"
                  required
                  fullWidth
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  value={simulationValues.price}
                  InputProps={{ classes: { input: classes.input } }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  onChange={(_e, value) => {
                    setHasMenuModalChange(true);
                    const newDataTotal = newData.data.cards.items.length
                      ? newData.data.cards.items.reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                                  parseFloat(
                                    Number(value.products.averageCost)
                                  ) +
                                parseFloat(total)
                              : total,
                          0
                        )
                      : 0;
                    const priceToPutOnCount = editCards
                      ? newDataTotal || 0
                      : editData?.cards?.costs?.costTotal || 0;

                    setSimulationValues({
                      price: value,
                      mkp: calcMkpValue(value, priceToPutOnCount.toFixed(3)),
                      cmv: calcCMVValue(value, priceToPutOnCount.toFixed(3)),
                      margin: calcMargin(value, priceToPutOnCount.toFixed(3))
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <label>Markup</label>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={newData.data.mkp ? newData.data.mkp : 0}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={simulationValues.mkp ? simulationValues.mkp : 0}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <label>CMV %</label>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={newData.data.cmv ? newData.data.cmv : 0}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={simulationValues.cmv ? simulationValues.cmv : 0}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <label>Margem</label>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={newData.data.margin ? newData.data.margin : 0}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={simulationValues.margin ? simulationValues.margin : 0}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <label>Desconto %</label>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={
                    newData.data.percentageDiscount
                      ? newData.data.percentageDiscount
                      : 0
                  }
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <label>Curva</label>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled
                  size="small"
                  value={newData.data.curve ? newData.data.curve : ''}
                  InputProps={{
                    maxLength: 8,
                    classes: { input: classes.input },
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  inputProps={{
                    style: { textAlign: 'right', paddingRight: 0 }
                  }}
                  className="textFieldSmall"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="cardDefault productRightCard widthImageBox mt12 spacingCard">
            <div className={`cropDataMediumBox `}>
              {newData?.data.images?.url ? (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    <div
                      className={`cropDataSmallBox skuImgPreview mr0 ${
                        newData?.images?.url && 'bgWhite'
                      } largeSize`}
                    >
                      <img src={newData?.data.images?.url} alt="image" />
                    </div>
                  }
                >
                  <div
                    className={`cropDataMediumBox ${
                      newData?.images?.url && 'bgWhite'
                    }`}
                  >
                    <img src={newData?.data.images?.url} alt="image" />
                  </div>
                </Tooltip>
              ) : (
                <div
                  className={`cropDataMediumBox ${
                    newData?.images?.url && 'bgWhite'
                  }`}
                >
                  <img src={DefaultImage} alt="No Menu Image" />
                </div>
              )}
            </div>
            <div className="dFlex flexColumn alignEnd pt3">
              <div className='dFlex justifyBetween w100'>
               <div className='dFlex alignCenter'> 
                  {newData?.data.images?.url &&
                    <img
                      style={{
                        margin: '0px 10px 0px 0px',
                        width: '12px'
                      }}
                      title="Remover imagem"
                      className="cursorPointer"
                      src={CloseGrayIcon}
                      alt="CloseGrayIcon"
                      onClick={() => {
                        setHasMenuModalChange(true);
                        handleClearFileInput(menuId)
                        handleRemoveImage()
                      }}
                    />
                  }
                </div>
                {newData?.data.images?.url ? (
                  <Button
                    disabled={onlyView}
                    className="defaultButton backButton sizeButtonMenu"
                    design="outlined"
                    label="Escolher outra imagem"
                    onClick={(e) => handleSelectImage(e, menuId)}
                    style={{ outline: 0 }}
                  />
                ) : (
                  <Button
                    disabled={onlyView}
                    className="defaultButton submitButton sizeButtonMenu"
                    design="outlined"
                    label="Selecionar imagem"
                    onClick={(e) => handleSelectImage(e, menuId)}
                    style={{ outline: 0 }}
                  />
                )}
              </div>
              <input
                id={`fileInput${menuId}`}
                type="file"
                onChange={(e) => onChangeImage(e)}
                style={{ display: 'none' }}
              />
              <input
                id="fileInput"
                type="file"
                onChange={onChangeImage}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </Grid>
        
        {!onlyView ?
          <Grid container spacing={5} className="mt10 alignCenter justifyEnd">
            {editMode && newData?.data?.created_at && (
              <div className="dFlex flexColumn mr20">
                <span className="bold fontSizeDefault">
                  Data da criação:{' '}
                  {moment(newData?.data?.created_at).format('DD/MM/YYYY')}
                </span>

                <span className="bold fontSizeDefault">
                  Última atualização:{' '}
                  {moment(newData?.data?.updated_at).format('DD/MM/YYYY')}
                </span>

                {newData?.data?.user ? (
                  <span className="bold fontSizeDefault">
                    Usuário: {newData?.data?.user}
                  </span>
                ) : null}
              </div>
            )}

            <Button
              className="defaultButton backButton"
              design="outlined"
              label="Voltar"
              style={{ outline: 0, margin: '0 5px 0 5px' }}
              onClick={() => handleModalToggle()}
            />

            <Button
              disabled={blockUserNoAdm || !hasMenuModalChange && isEdition || validSubMenu()}
              className="defaultButton submitButton"
              type="submit"
              design="contained"
              label={!loading ? 'Salvar' : 'Salvando...'}
              style={{ outline: 0, margin: '0 0 0 5px' }}
            />
          </Grid>
        : null}

        <ImageCropper
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          getCropData={getCropData}
          obj={newData}
          setObj={setNewData}
          setCropper={setCropper}
          handleSelectImage={handleSelectImage}
          handleClearFileInput={handleClearFileInput}
        />

        <CommonAlert
          open={commonMessage?.description}
          onClose={() => setCommonMessage({ description: '', status: '' })}
          severity={commonMessage?.status}
          indexMessage={commonMessage?.description}
          messagePersonal={commonMessage?.description}
          statusCode={statusCode}
        />

        <Snackbar
          open={modalSettings.find((x) => x.name === 'onSuccess').open}
          autoHideDuration={2000}
          onClose={() => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'onSuccess' ? { ...item, open: false } : item
              )
            );
          }}
        >
          <Alert
            onClose={() => {
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'onSuccess' ? { ...item, open: false } : item
                )
              );
            }}
            severity="success"
          >
            Cadastro realizado com sucesso!
          </Alert>
        </Snackbar>

        <Snackbar
          open={modalSettings.find((x) => x.name === 'onError').open}
          autoHideDuration={3000}
          className="snackBarError"
          onClose={() =>
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'onError'
                  ? { ...item, open: false, description: '' }
                  : item
              )
            )
          }
        >
          <Alert
            severity="error"
            onClose={() =>
              setModalSettings((modal) =>
                modal.map((item) =>
                  item.name === 'onError'
                    ? { ...item, open: false, description: '' }
                    : item
                )
              )
            }
          >
            {
              modalSettings.find((modal) => modal.name === 'onError')
                .description
            }
          </Alert>
        </Snackbar>
      </form>
    </>
  );
}

export default Register;
