/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Typography
} from '@material-ui/core';
import Draggable from 'react-draggable';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#internalProductRelatedDialog"
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

function InternalProductRelated({
  mainList,
  loading,
  open,
  onClose,
  description,
  selectedCard,
  setSelectedCard,
  getId
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={() => {
        onClose()
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="internalProductRelatedDialog"
      className="defaultModal"
      style={{ width: "40%" }}
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="internalProductRelatedDialog"
      >
        <Typography>
          {description} - Produtos internos relacionados
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            onClose()
          }}
        >
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="close modal"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <table className="desktop">
          <thead>
            <tr className="headerTitle">
              <th width="100%" className="subtitle fontSizeDefault">
                DESCRIÇÃO
              </th>
            </tr>
          </thead>

          {loading && (
            <tr>
              <th colSpan="1" className="m10 textAlignCenter">
                <LinearProgress
                  style={{ height: '7px' }}
                  id="loading"
                  variant="query"
                />
              </th>
            </tr>
          )}

          <tbody>
            {mainList?.length
              ? mainList?.map((card) => (
                <>
                  <tr className="lineBorder">
                    <td className="description p16 pt0 pb0">
                      <span 
                        className="lightBlue cursorPointer"
                        onClick={() => {
                          getOptions(
                            `${environments.catalog}/cards/${card?.id}`,
                            'cards',
                            selectedCard,
                            setSelectedCard,
                            getId,
                            null,
                            null,
                            null,
                            true
                          );
                        }}
                      >
                        {ValidationLength(card?.description, 60)}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="1" className="m10 textAlignCenter p0">
                      <hr />
                    </td>
                  </tr>
                </>
              ))
            : null}
            
            {!mainList?.length ? (
              <tr>
                <th colSpan="1" className="m10 textAlignCenter">
                  Não há dados a serem exibidos.
                </th>
              </tr>
            ) : null}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default InternalProductRelated;
