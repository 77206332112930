/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, forwardRef } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  makeStyles,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  ImportContacts,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { setHours, setMinutes } from 'date-fns';
import MaterialTable from 'material-table';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Register from 'components/Register';
import days from 'data/days.json';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import RegisterProductModal from 'pages/catalog/products/ProductForm';
import getOptions from 'services/getOptions';
import * as ProductsActions from 'store/actions/products';
import Environment from 'utils/environments';

const formattedDaysOptions = days.map((day) => ({
  ...day,
  id: day.id
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-technical-sheet"
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  input: {
    fontFamily: 'Lato',
    fontSize: 12
  },
  option: {
    fontFamily: 'Lato',
    fontSize: 12
  }
}));

const DEFAULT_CARD_TRANSFORMATION = {
  type: 'product',
  productId: null,
  stock: 0,
  consumptionUnitsPrimaryId: null,
  consumptionUnitsPrimary: null,
  cost: 0,
  averageCost: 0,
  lastCost: 0,
  quantity: 0
};

function TechnicalSheetDialog({
  userInfo,
  productInfo,
  setEditProducts,
  maxWidth = null,
  open,
  handleClose,
  selectedCard,
  getCardsContent,
  salesAverageDiscount,
  setSalesAverageDiscount,
  openModalMenu,
  setHasMenuModalChange,
  hasMenuModalChange,
  showInactiveItems,
  setRequestStorages,
  requestStorages
}) {
  const classes = useStyles();
  const environments = Environment(process.env.REACT_APP_ENV);
  const [daysSelect, setDaysSelect] = useState();
  const [onlyViewProduct, setOnlyViewProduct] = useState(false);
  const [getId, setGetId] = useState(userInfo?.companiesActive?.id);
  const [percentAccepted, setPercentAccepted] = useState();
  const [productionTime, setProductionTime] = useState();
  const [productionTimeValue, setProductionTimeValue] = useState({
    startTime: '',
    endTime: ''
  });
  const [selectedProduct, setSelectedProduct] = useState('');
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [storageList, setStorageList] = useState([])
  const [periodicities, setPeriodicities] = useState([]);
  const [periodicitiesList, setPeriodicitiesList] = useState([])
  const [modalStates, setModalStates] = useState({
    modalProduct: false,
    modalTechnicalSheet: false
  });
  const [selectedSecondCard, setSelectedSecondCard] = useState();
  const [cardDataTableSecond, setCardDataTableSecond] = useState([]);
  const [ucPrimarySelectedIdSecond, setUcPrimarySelectedIdSecond] = useState();
  const [cardTransformationSecond, setCardTransformationSecond] = useState(
    DEFAULT_CARD_TRANSFORMATION
  );
  const [cardDataTable, setCardDataTable] = useState([]);
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [cardTransformation, setCardTransformation] = useState(
    DEFAULT_CARD_TRANSFORMATION
  );

  const localization = {
    body: {
      emptyRowsWhenPaging: false,
      emptyDataSourceMessage: 'Não há dados a serem exibidos.',
      editTooltip: 'Editar',
      deleteTooltip: 'Deletar',
      addTooltip: 'Adicionar',
      editRow: {
        deleteText: 'Tem certeza que deseja excluir esta linha?',
        cancelTooltip: 'Cancelar',
        saveTooltip: 'Confirmar'
      }
    },

    toolbar: {
      searchTooltip: 'Pesquisar',
      searchPlaceholder: 'Pesquisar',
      showColumnsTitle: 'Exibir colunas',
      addRemoveColumns: 'Exibir ou esconder colunas',
      nRowsSelected: '{0} linha(s) selecionadas'
    },

    header: {
      actions: ''
    },

    pagination: {
      labelRowsSelect: 'linhas',
      labelDisplayedRows: ' {from}-{to} de {count}',
      firstTooltip: 'Primeira página',
      previousTooltip: 'Página anterior',
      nextTooltip: 'Próxima página',
      lastTooltip: 'Última página'
    }
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    ImportContacts: forwardRef((props, ref) => (
      <ImportContacts {...props} ref={ref} />
    ))
  };

  useEffect(() => {
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        getId
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
  }, [getId, modalStates.modalProduct]);


  useEffect(() => {
    const locationsId = selectedCard?.product[0]?.setups
    .reduce(
      (acc, item) => [
        ...acc,
      item?.config && item?.config?.storageLocation?.id
      ],
      []
      )
    .flat() || []
    const periodId = selectedCard?.product[0]?.setups
    .reduce(
      (acc, item) => [
        ...acc,
      item?.config && item?.config?.periodicity?.id
      ],
    []
    )
  .flat() || []
    const convertLocationIds = [...new Set(locationsId)]
    const storages = storageLocations?.filter((el) =>
    convertLocationIds?.includes(el.id))

    const convertPeriodicitiesIds = [...new Set(periodId)]
    const periods = periodicities?.filter((el) =>
    convertPeriodicitiesIds?.includes(el.id))

    setStorageList(storages)
    setPeriodicitiesList(periods);

  }, [storageLocations, periodicities, requestStorages, selectedCard])

  useEffect(() => {
    if (selectedProduct) {
      const arraySkus = [];
      selectedProduct.skus.forEach((skus) => {
        arraySkus.push({
          id: skus.skus ? skus.skus.id : null,
          description: skus.skus ? skus.skus.description : null,
          quantity: skus.skus
            ? parseFloat(skus.skus.quantity).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          unitsMeasurements: skus.skus
            ? skus.skus.unitsMeasurements.abbreviation
            : null,
          purchaseAverage:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseAverage
              : null,
          purchaseLast:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseLast
              : null,
          conversion: skus.skus
            ? parseFloat(skus.conversion).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          priceUc: skus ? skus.priceUc : null
        });
      });

      selectedProduct.skus = arraySkus;

      setEditProducts(selectedProduct || '');
    }
  }, [selectedProduct]);

  const handleSelectedCard = (
    card,
    setUcPrimary,
    setTransformation,
    setCard
  ) => {
    const cardDaysIds = card?.daysWeeks?.map(
      (item) => item?.daysWeekId
    );
    setDaysSelect(formattedDaysOptions?.filter((day) => cardDaysIds?.includes(day.id)) || [])

    setPercentAccepted(
      card?.type === 'RECIPE'
        ? {
            variation: card?.product[0]?.variationPercentage,
            loss: card?.product[0]?.lossPercentage
          }
        : {
            variation:
              card?.itemsTransformations?.products
                ?.variationPercentage || 0,
            loss: card?.items?.length
              ? card?.items[0].products?.lossPercentage
              : 0
          }
    );
    setUcPrimary(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    setProductionTime(
      card?.startTime || card?.endTime
        ? true
        : !!(card?.daysWeeks && card?.daysWeeks?.length > 0)
    );
    setProductionTimeValue({
      startTime: card?.startTime
      ? setMinutes(
          setHours(
            new Date(),
            card?.startTime[0] + card?.startTime[1]
          ),
          card?.startTime[3] + card?.startTime[4]
        )
      : null,
      endTime: card?.endTime
      ? setMinutes(
          setHours(
            new Date(),
            card?.endTime[0] + card?.endTime[1]
          ),
          card?.endTime[3] + card?.endTime[4]
        )
      : null,
    })
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setTransformation({
        type: 'PRODUCT',
        productId: card?.itemsTransformations?.productId,
        stock: card?.itemsTransformations?.products?.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations?.unitsMeasurements?.id,
        consumptionUnitsPrimary:
          card.itemsTransformations?.unitsMeasurements?.abbreviation,
        cost: card?.itemsTransformations?.cost,
        averageCost: card?.itemsTransformations?.products?.averageCostStock 
          || card?.itemsTransformations?.products?.averageCost 
          || '0,000',
        lastCost: card?.itemsTransformations?.products?.lastCost 
          || card?.itemsTransformations?.products?.lastCostStock 
          || card?.itemsTransformations?.products?.approximateCost 
          || '0,000',
        quantity: card?.itemsTransformations?.quantity,
        product: card?.itemsTransformations?.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                (product.products?.averageCostStock ||
                product.products?.averageCost ||
                '0,000').toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                }),
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products?.averageCostStock ||
                product.products?.averageCost ||
                '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              lossPercentage: (product?.products?.lossPercentage || 0)?.toLocaleString('pt-br', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              }),
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              targetCost: (product?.products?.targetCost || 0)?.toLocaleString('pt-br', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
              }),
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    const sortDataInfoItems = items => {
      const orderedItems = []
  
      if (items && Array.isArray(items) && items.length > 0) {
        // Separa os itens em principais e outros
        const mainItems = items.filter(item => item?.main)
        const otherItems = items.filter(item => !item?.main)
  
        // Ordena ambos os itens por ordem alfabética
        mainItems.sort((a, b) => a.description.localeCompare(b.description))
        otherItems.sort((a, b) => a.description.localeCompare(b.description))
  
        // Adiciona no array de itens ordenados
        orderedItems.push(...mainItems, ...otherItems)
      }
  
      return orderedItems
    }

    setCard(sortDataInfoItems(arrayProduct));

    setModalStates({ ...modalStates, modalTechnicalSheet: true });
  };

  useEffect(() => {
    if (selectedCard && !selectedSecondCard) {
      handleSelectedCard(
        selectedCard,
        setUcPrimarySelectedId,
        setCardTransformation,
        setCardDataTable
      );
    }
  }, [selectedCard]);

  useEffect(() => {
    if (selectedSecondCard) {
      handleSelectedCard(
        selectedSecondCard,
        setUcPrimarySelectedIdSecond,
        setCardTransformationSecond,
        setCardDataTableSecond
      );
    }
  }, [selectedSecondCard]);

  const BodyModal = (
    <Register
      userInfo={userInfo}
      menuInfo={{}}
      menuId={selectedCard?.menu[0]?.id}
      handleModalToggle={() => handleClose()}
      replaceCategoryOf
      updateList={getCardsContent}
      salesAverageDiscount={salesAverageDiscount}
      setSalesAverageDiscount={setSalesAverageDiscount}
      openModalMenu={openModalMenu}
      setHasMenuModalChange={setHasMenuModalChange}
      hasMenuModalChange={hasMenuModalChange}
      showInactiveItems={
        showInactiveItems?.find((el) => el.param === 'status')?.value
      }
    />
  );

  return (
    <>
      {selectedCard.type !== 'MENU' && (
        <Dialog
          PaperComponent={PaperComponent}
          fullScreen={false}
          fullWidth
          maxWidth={maxWidth}
          open={open}
          onClose={() => {
            handleClose();
            setStorageList([])
            setPeriodicitiesList([])
          }}
          className="defaultModal cardViewDialog"
        >
          <DialogTitle
            className="modalHeader bold textAlignCenter cursorMove"
            id="draggable-dialog-title-technical-sheet"
          >
            <Typography>Ficha - {selectedCard?.description}</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClose()
                setStorageList([])
                setPeriodicitiesList([])
              }}
              aria-label="close"
              style={{
                outline: '0',
                background: 'none',
                position: 'absolute',
                align: 'right',
                top: 5,
                right: 5
              }}
            >
              <img src={CloseIcon} alt="CloseIcon" />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Grid container spacing={3} className="pt0">
              <Grid item xs={6} sm={6} className="pt10">
                <FormControl className="dFlex flexRow">
                  <FormControlLabel
                    value="RECIPE"
                    control={
                      <Radio
                        disabled
                        checked={selectedCard?.type === 'RECIPE'}
                        size="small"
                      />
                    }
                    label={
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#5062F0'
                        }}
                      >
                        Receita
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="TRANSFORMATION"
                    control={
                      <Radio
                        disabled
                        checked={selectedCard?.type === 'TRANSFORMATION'}
                        size="small"
                      />
                    }
                    label={
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#5062F0'
                        }}
                      >
                        Transformação
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="MENU"
                    control={
                      <Radio
                        disabled
                        checked={selectedCard?.type === 'MENU'}
                        size="small"
                      />
                    }
                    label={
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#5062F0'
                        }}
                      >
                        Cardápio
                      </span>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              {selectedCard?.type !== 'TRANSFORMATION' &&
              selectedCard?.type !== 'RECIPE' &&
              selectedCard?.type !== 'MENU' ? null : (
                <Grid item xs={selectedCard?.type === 'RECIPE' ? 4 : 3} sm={selectedCard?.type === 'RECIPE' ? 4 : 3}>
                  <TextField
                    disabled
                    type="text"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={selectedCard?.description}
                    InputProps={{
                      maxLength: 50,
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    label="Descrição"
                    placeholder="Selecione..."
                    className="textFieldSmall"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              )}

              {selectedCard?.type !== 'RECIPE' ? null : (
                <>
                  <Grid item xs={1} sm={1}>
                    <TextField
                      disabled
                      type="text"
                      variant="outlined"
                      label="Un."
                      fullWidth
                      size="small"
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      placeholder="Selecione..."
                      className="textFieldSmall"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={
                        ucPrimarySelectedId
                          ? ucPrimarySelectedId.abbreviation
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <FormControl fullWidth>
                      <CurrencyTextField
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        disabled
                        style={
                          selectedCard?.type !== 'RECIPE'
                            ? { display: 'none' }
                            : null
                        }
                        type="text"
                        fullWidth
                        label="Rendimento"
                        placeholder="Selecione..."
                        className="textFieldSmall childTextAlignRight"
                        value={selectedCard?.productions.yield}
                        InputProps={{
                          maxLength: 8,
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              {selectedCard?.type !== 'TRANSFORMATION' &&
              selectedCard?.type !== 'RECIPE' ? null : (
                <Grid item xs={2} sm={2}>
                  <FormControl fullWidth>
                    <CurrencyTextField
                      variant="outlined"
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      value={selectedCard?.productionMin}
                      disabled
                      type="text"
                      fullWidth
                      label="Qtde Mínima"
                      placeholder="Selecione..."
                      className="textFieldSmall childTextAlignRight"
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px', textAlign: 'right' }
                      }}
                    />
                  </FormControl>
                </Grid>
              )}

              {selectedCard?.type !== 'TRANSFORMATION' &&
              selectedCard?.type !== 'RECIPE' ? null : (
                <Grid item xs={1} sm={1}>
                  <TextField
                    disabled
                    type="text"
                    variant="outlined"
                    label="Validade"
                    placeholder="Não cadastrado"
                    fullWidth
                    size="small"
                    value={selectedCard?.quantityValidDays}
                    InputProps={{
                      style: {
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        align: 'right',
                        textAlign: 'right'
                      }
                    }}
                    className="textFieldSmall childTextAlignRight"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              )}

              {selectedCard?.type !== 'TRANSFORMATION' &&
              selectedCard?.type !== 'RECIPE' &&
              selectedCard?.type !== 'MENU' ? null : (
                <Grid item xs={2} sm={2}>
                  <TextField
                    disabled
                    type="text"
                    variant="outlined"
                    label="Categoria"
                    placeholder="Não categorizado"
                    fullWidth
                    size="small"
                    value={
                      selectedCard?.categories
                        ? selectedCard?.categories.description
                        : ''
                    }
                    className="textFieldSmall"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                  />
                </Grid>
              )}

              {selectedCard?.type !== 'TRANSFORMATION' ? null : (
                <>
                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled
                      type="text"
                      variant="outlined"
                      label="Praça"
                      placeholder="Selecione..."
                      fullWidth
                      size="small"
                      value={
                        selectedCard?.court
                          ? selectedCard?.court.description
                          : ''
                      }
                      className="textFieldSmall"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                    />
                  </Grid>
                  
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    id="CardFormCategorieInput"
                    className="pr8"
                    style={{ maxWidth: "14%" }}
                  >
                    <FormControl fullWidth>
                      <CurrencyTextField
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        name="percentVariationAccepted"
                        fullWidth
                        disabled
                        value={percentAccepted?.variation}
                        InputProps={{
                          maxLength: 8,
                          classes: { input: classes.input },
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                        label="% Variação Produção"
                        className="textFieldSmall right"
                        placeholder="Digite aqui..."
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={3} className="mt20 mb10">
              {selectedCard?.type !== 'RECIPE' ? null : (
                <Grid item xs={2} sm={2}>
                  <TextField
                    disabled
                    type="text"
                    variant="outlined"
                    label="Praça"
                    placeholder="Selecione..."
                    fullWidth
                    size="small"
                    value={
                      selectedCard?.court
                        ? selectedCard?.court.description
                        : ''
                    }
                    className="textFieldSmall"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                  />
                </Grid>
              )}

              {selectedCard?.type !== 'RECIPE' ? null : (
                <Grid item xs={3} sm={3}>
                  <Autocomplete
                    disabled
                    id="controllable-states-demo"
                    getOptionLabel={(option) => option.description}
                    classes={{
                      input: classes.input,
                      option: classes.option
                    }}
                    options={[]}
                    multiple
                    limitTags={1}
                    size="small"
                    value={storageList}
                    fullWidth
                    className="autocompleteSmall"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params.id}
                        name="providers"
                        label="Locais de Estoque"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          id: 'locaisDeArmazenamento',
                          shrink: true
                        }}
                        style={{padding: '0 !important'}}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>
              )}

              {selectedCard?.type !== 'RECIPE' ? null : (
                <Grid item xs={3} sm={3}>
                  <Autocomplete
                    disabled
                    id="controllable-states-demo"
                    getOptionLabel={(option) => option.description}
                    classes={{
                      input: classes.input,
                      option: classes.option
                    }}
                    options={[]}
                    multiple
                    limitTags={1}
                    size="small"
                    value={periodicitiesList}
                    fullWidth
                    className="autocompleteSmall"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params.id}
                        name="providers"
                        label="Contagem"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                        style={{padding: '0 !important'}}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>
              )}

              {selectedCard?.type !== 'RECIPE' ? null : (
                <Grid
                  item
                  xs={2}
                  sm={2}
                  id="cardFormTransformUnityInput"
                  className="pl8"
                >
                  <FormControl fullWidth>
                    <CurrencyTextField
                      variant="outlined"
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      disabled
                      name="percentLossAccepted"
                      fullWidth
                      value={percentAccepted?.loss}
                      InputProps={{
                        maxLength: 8,
                        classes: { input: classes.input },
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      required
                      label="% Perda
                      Contagem"
                      className="textFieldSmall right minorInput"
                      placeholder="Digite aqui..."
                    />
                  </FormControl>
                </Grid>
              )}

              {selectedCard?.type !== 'RECIPE' ? null : (
                <Grid
                  item
                  xs={2}
                  sm={2}
                  id="CardFormCategorieInput"
                  className="pr8"
                >
                  <FormControl fullWidth>
                    <CurrencyTextField
                      variant="outlined"
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="percentVariationAccepted"
                      fullWidth
                      disabled
                      value={percentAccepted?.variation}
                      InputProps={{
                        maxLength: 8,
                        classes: { input: classes.input },
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      label="% Variação Produção"
                      className="textFieldSmall right"
                      placeholder="Digite aqui..."
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>

            {selectedCard?.type !== 'TRANSFORMATION' &&
            selectedCard?.type !== 'RECIPE' ? null : (
              <Grid container spacing={3} className="mb10">
                <Grid item xs={6} sm={6}>
                  <Checkbox
                    className="checkBoxSmall"
                    name="controlProduction"
                    disabled
                    checked={selectedCard?.controlProduction}
                    size="small"
                  />{' '}
                  <span style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                    Controlar Produção
                  </span>
                  <Checkbox
                    className="checkBoxSmall ml20"
                    name="priority"
                    disabled
                    checked={selectedCard?.priority}
                    size="small"
                  />{' '}
                  <span style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                    Prioritário
                  </span>
                  <Checkbox
                    className="checkBoxSmall ml20"
                    name="productionTime"
                    disabled
                    checked={selectedCard?.startTime || selectedCard?.endTime
                      ? true
                      : !!(
                          selectedCard?.daysWeeks &&
                          selectedCard?.daysWeeks?.length > 0
                        )}
                    size="small"
                  />{' '}
                  <span style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                    Definir agenda
                  </span>
                </Grid>
              </Grid>
            )}

            <Grid container spacing="3" className={`${!productionTime && 'dNone'}`}>
              <Grid item xs={3} sm={3}>
                <Autocomplete
                  id="controllable-states-demo"
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.description}
                  options={formattedDaysOptions}
                  multiple
                  limitTags={1}
                  size="small"
                  value={daysSelect}
                  disabled
                  className="autocompleteSmall commonInput personalInputDaily"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="providers"
                      label="Dias da semana"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={1} sm={1} className={`personalDatePicker ${!productionTime && 'dNone'}`}>
                  <KeyboardTimePicker
                    size="small"
                    id="time-picker"
                    label="Início"
                    ampm={false}
                    clearable
                    disabled
                    placeholder="00:00"
                    value={productionTimeValue?.startTime}
                    inputVariant="outlined"
                    className="dateSmall"
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </Grid>

                <Grid item xs={1} sm={1} className={`pr20 personalDatePicker ${!productionTime && 'dNone'}`}>
                  <KeyboardTimePicker
                    size="small"
                    id="time-picker"
                    label="Fim"
                    ampm={false}
                    clearable
                    disabled
                    value={productionTimeValue?.endTime}
                    inputVariant="outlined"
                    className="dateSmall"
                    placeholder="00:00"
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            {selectedCard?.type !== 'RECIPE' &&
            selectedCard?.type !== 'MENU' ? null : (
              <Grid
                item
                xs={12}
                sm={12}
                className="materialTableDefault withoutBoxShadow selfShadow"
              >
                <MaterialTable
                  columns={[
                    {
                      title: 'PRODUTO',
                      field: 'description',
                      editable: 'never',
                      width: '42%',
                      headerStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold'
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      },
                      render: (rowData) => {
                        if (rowData) {
                          return (
                            <Typography
                              onClick={() => {
                                if (rowData?.type === 'INTERNAL') {
                                  getOptions(
                                    `${environments.catalog}/cards/${rowData?.originIdCard}`,
                                    'cards',
                                    selectedSecondCard,
                                    setSelectedSecondCard,
                                    getId,
                                    null,
                                    null,
                                    null,
                                    true
                                  );
                                } else {
                                  getOptions(
                                    `${environments.catalog}/products/${rowData?.id}`,
                                    'products',
                                    selectedProduct,
                                    setSelectedProduct,
                                    getId,
                                    null,
                                    null,
                                    null,
                                    true
                                  );
                                }

                                setOnlyViewProduct(true);
                                setModalStates({
                                  ...modalStates,
                                  modalProduct: true
                                });
                              }}
                              className="cursorPointer lightBlue"
                              style={{
                                fontFamily: 'Lato',
                                fontSize: 12,
                                padding: 0
                              }}
                            >
                              {(rowData?.description || '-').toLocaleString(
                                'pt-br',
                                { minimumFractionDigits: 3 }
                              )}
                            </Typography>
                          );
                        }
                      }
                    },
                    {
                      title: 'ESTOQUE',
                      width: '10%',
                      field: 'stock',
                      type: 'number',
                      editable: 'never',
                      align: 'right',
                      headerStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold'
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 'MÉDIO R$',
                      width: '10%',
                      field: 'listCostAverage',
                      type: 'number',
                      editable: 'never',
                      align: 'right',
                      headerStyle: {
                        fontSize: '13px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold'
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 'ÚLTIMO R$',
                      width: '10%',
                      field: 'listCostLast',
                      type: 'number',
                      editable: 'never',
                      align: 'right',
                      headerStyle: {
                        fontSize: '13px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold'
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 'QTDE',
                      field: 'listQuantity',
                      type: 'number',
                      editable: 'never',
                      width: '12%',
                      align: 'right',
                      headerStyle: {
                        fontSize: '13px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold'
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 'UN.',
                      width: '4%',
                      field: 'consumptionUnitsPrimary',
                      editable: 'never',
                      align: 'center',
                      headerStyle: {
                        fontSize: '13px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold'
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 'CUSTO R$',
                      width: '12%',
                      field: 'listCost',
                      type: 'numeric',
                      editable: 'never',
                      align: 'right',
                      headerStyle: {
                        fontSize: '13px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold'
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    }
                  ]}
                  data={cardDataTable}
                  title=""
                  icons={tableIcons}
                  localization={localization}
                  options={{
                    search: false,
                    selection: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    padding: 'dense'
                  }}
                  style={{ border: 'none' }}
                />

                {selectedCard?.type === 'RECIPE' &&
                selectedCard?.costs.costTotal ? (
                  <table className="resultsTable" border="0" width="100%">
                    <tr>
                      <td
                        width="85%"
                        align="right"
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          padding: '12px 0'
                        }}
                      >
                        <b>
                          Custo Total{' '}
                          {`(${
                            selectedCard?.productions &&
                            selectedCard?.productions.yieldTotal
                              ? parseFloat(
                                  selectedCard?.productions.yieldTotal
                                ).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })
                              : '0,000'
                          }${
                            ucPrimarySelectedId
                              ? ` ${ucPrimarySelectedId.abbreviation}`
                              : ''
                          })`}
                          :
                        </b>
                      </td>
                      <td
                        width="15%"
                        align="right"
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          color: '#5062F0',
                          padding: '12px 12px 12px 0'
                        }}
                      >
                        <b>
                          <font fontFamily="Lato">
                            R${' '}
                            {selectedCard?.costs.costTotal.toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3
                              }
                            )}
                          </font>
                        </b>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="2" height="5" />
                    </tr>

                    <tr>
                      <td
                        align="right"
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          padding: '12px 0'
                        }}
                      >
                        <b>
                          Custo Unitário{' '}
                          {ucPrimarySelectedId
                            ? `(${ucPrimarySelectedId.abbreviation})`
                            : null}
                          :
                        </b>
                      </td>
                      <td
                        align="right"
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          color: '#5062F0',
                          padding: '12px 12px 12px 0'
                        }}
                      >
                        <b>
                          R${' '}
                          {selectedCard?.productions.yieldTotal &&
                          selectedCard?.costs.costTotal
                            ? parseFloat(
                                selectedCard?.productions.yieldTotal
                              ) !== 0 &&
                              parseFloat(selectedCard?.costs.costTotal) !== 0
                              ? (
                                  selectedCard?.costs.costTotal /
                                  parseFloat(
                                    selectedCard?.productions.yieldTotal
                                  )
                                ).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })
                              : '0,000'
                            : '0,000'}
                        </b>
                      </td>
                    </tr>
                  </table>
                ) : null}

                {selectedCard?.type === 'MENU' &&
                selectedCard?.costs.costTotal ? (
                  <table className="resultsTable" border="0" width="100%">
                    <tr>
                      <td
                        width="85%"
                        align="right"
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '13px',
                          padding: '12px 0'
                        }}
                      >
                        <b>Custo Total:</b>
                      </td>
                      <td
                        width="15%"
                        align="right"
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '13px',
                          color: '#5062F0',
                          padding: '12px 12px 12px 0'
                        }}
                      >
                        <b>
                          R${' '}
                          {selectedCard?.costs.costTotal.toLocaleString(
                            'pt-br',
                            {
                              minimumFractionDigits: 3
                            }
                          )}
                        </b>
                      </td>
                    </tr>
                  </table>
                ) : null}
              </Grid>
            )}

            {selectedCard?.type !== 'TRANSFORMATION' ? null : (
              <Grid container spacing={3} className="mb0">
                <Grid item xs={12} sm={12} className="pb5">
                  <TableContainer
                    component={Paper}
                    className="materialTableDefault selfShadow withoutBoxShadow"
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            width="42%"
                            className="pb0 pt0"
                            style={{ height: 30 }}
                          >
                            <span className="fontSizeDefault bold">
                              PRODUTO A SER TRANSFORMADO
                            </span>
                          </TableCell>

                          <TableCell
                            className="pb0 pt0 fontSizeDefault bold"
                            width="12%"
                            align="right"
                          >
                            ESTOQUE
                          </TableCell>

                          <TableCell
                            className="pb0 pt0 fontSizeDefault bold"
                            width="12%"
                            align="right"
                          >
                            MÉDIO R$
                          </TableCell>

                          <TableCell
                            className="pb0 pt0 fontSizeDefault bold"
                            width="12%"
                            align="right"
                          >
                            ÚLTIMO R$
                          </TableCell>

                          <TableCell
                            className="pb0 pt0 fontSizeDefault bold"
                            width="120"
                            align="right"
                          >
                            QTDE
                          </TableCell>

                          <TableCell
                            className="pb0 pt0 fontSizeDefault bold"
                            width="10%"
                            align="center"
                          >
                            UN.
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            width="300"
                            style={{ fontFamily: 'Lato', fontSize: '12px' }}
                          >
                            {cardTransformation?.product?.description}
                          </TableCell>

                          <TableCell
                            align="right"
                            size="small"
                            style={{ fontFamily: 'Lato', fontSize: '12px' }}
                          >
                            {cardTransformation.stock
                              ? cardTransformation.stock.toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 3
                                  }
                                )
                              : '0,000'}
                          </TableCell>

                          <TableCell
                            align="right"
                            size="small"
                            style={{ fontFamily: 'Lato', fontSize: '12px' }}
                          >
                            {cardTransformation.averageCost
                              ? cardTransformation.averageCost.toLocaleString(
                                  'pt-br',
                                  { minimumFractionDigits: 3 }
                                )
                              : '0,000'}
                          </TableCell>

                          <TableCell
                            align="right"
                            size="small"
                            style={{ fontFamily: 'Lato', fontSize: '12px' }}
                          >
                            {cardTransformation.lastCost
                              ? cardTransformation.lastCost.toLocaleString(
                                  'pt-br',
                                  { minimumFractionDigits: 3 }
                                )
                              : '0,000'}
                          </TableCell>

                          <TableCell
                            align="right"
                            style={{ fontFamily: 'Lato', fontSize: '12px' }}
                          >
                            {(cardTransformation?.quantity || 0)?.toLocaleString(
                              'pt-br', { minimumFractionDigits: 3 }
                            )}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontFamily: 'Lato', fontSize: '12px' }}
                          >
                            {cardTransformation.consumptionUnitsPrimary}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  container
                  direction="row"
                  justify="flex-end"
                >
                  <FormControl>
                    <CurrencyTextField
                      variant="outlined"
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="quantityToBeTransformed"
                      className="textFieldSmall right inLineTextfield"
                      fullWidth
                      label="% Perda"
                      placeholder="% Perda"
                      value={selectedCard?.productions.loss}
                      InputProps={{ classes: { input: classes.input } }}
                      disabled
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {selectedCard?.type !== 'TRANSFORMATION' ? null : (
              <Grid
                className="itensTable"
                item
                xs={12}
                sm={12}
                className="materialTableDefault withoutBoxShadow selfShadow"
              >
                <MaterialTable
                  columns={[
                    {
                      title: 'PRODUTO',
                      field: 'description',
                      editable: 'never',
                      width: '42%',
                      headerStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        padding: '0 12px',
                        height: 30
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      },
                      render: (rowData) => {
                        if (rowData) {
                          if (selectedCard?.id === rowData?.originIdCard) {
                            return (
                              <Typography
                                style={{
                                  fontFamily: 'Lato',
                                  fontSize: 12,
                                  padding: 0
                                }}
                              >
                                {(rowData?.description || '-').toLocaleString(
                                  'pt-br',
                                  { minimumFractionDigits: 3 }
                                )}
                              </Typography>
                            );
                          }
                          return (
                            <Typography
                              onClick={() => {
                                if (rowData?.type === 'INTERNAL') {
                                  getOptions(
                                    `${environments.catalog}/cards/${rowData?.originIdCard}`,
                                    'cards',
                                    selectedSecondCard,
                                    setSelectedSecondCard,
                                    getId,
                                    null,
                                    null,
                                    null,
                                    true
                                  );
                                } else {
                                  getOptions(
                                    `${environments.catalog}/products/${rowData?.id}`,
                                    'products',
                                    selectedProduct,
                                    setSelectedProduct,
                                    getId,
                                    null,
                                    null,
                                    null,
                                    true
                                  );
                                }
                                setOnlyViewProduct(true);
                                setModalStates({
                                  ...modalStates,
                                  modalProduct: true
                                });
                              }}
                              className="cursorPointer lightBlue"
                              style={{
                                fontFamily: 'Lato',
                                fontSize: 12,
                                padding: 0
                              }}
                            >
                              {(rowData?.description || '-').toLocaleString(
                                'pt-br',
                                { minimumFractionDigits: 3 }
                              )}
                            </Typography>
                          );
                        }
                      }
                    },
                    {
                      title: 'ESTOQUE',
                      width: '10%',
                      field: 'stock',
                      type: 'number',
                      editable: 'never',
                      align: 'right',
                      headerStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        padding: '0 12px',
                        height: 30
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 'CUSTO ESPERADO R$',
                      width: '24%',
                      field: 'targetCost',
                      type: 'number',
                      editable: 'never',
                      align: 'right',
                      headerStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        padding: '0 12px',
                        height: 30
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 'LOCAL DE ESTOQUE',
                      width: '26%',
                      hidden: true,
                      field: 'storageLocations.description',
                      editable: 'never',
                      headerStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        padding: '0 12px',
                        height: 30
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 'CONTAGEM',
                      width: '20%',
                      field: 'frequency.description',
                      hidden: true,
                      editable: 'never',
                      headerStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        padding: '0 12px',
                        height: 30
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                    {
                      title: 
                        <Tooltip
                          title="% de Perda aceita na Contagem"
                          placement="bottom-start"
                        >
                          <span>% PERDA CONTAGEM</span>
                        </Tooltip>,
                      width: '24%',
                      field: 'lossPercentage',
                      type: 'number',
                      editable: 'never',
                      align: 'right',
                      headerStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        padding: '0 12px',
                        height: 30
                      },
                      cellStyle: {
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }
                    },
                  ]}
                  data={cardDataTable}
                  title=""
                  icons={tableIcons}
                  localization={localization}
                  options={{
                    search: false,
                    selection: false,
                    sorting: false,
                    emptyRowsWhenPaging: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    padding: 'dense'
                  }}
                  style={{ border: 'none' }}
                />

                {selectedCard?.type === 'TRANSFORMATION' ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ marginTop: 20 }}
                    align="right"
                    className="pr16 pb10"
                  >
                    <p style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                      <b>Rendimento Total:</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>
                        <font style={{ color: '#5062F0' }}>
                          {cardTransformation.quantity
                            ? (
                                cardTransformation.quantity *
                                (1 - selectedCard?.productions.loss / 100)
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })
                            : '0,000'}
                          {cardTransformation.consumptionUnitsPrimary}
                        </font>
                      </b>
                    </p>
                  </Grid>
                ) : null}
              </Grid>
            )}
          </DialogContent>

          {modalStates.modalProduct && productInfo?.data && (
            <RegisterProductModal
              dialogOpen={modalStates.modalProduct}
              onlyView={onlyViewProduct}
              isModal
              productDataModal={{
                data: selectedProduct
              }}
              consumptionUnitOptions={consumptionUnits}
              periodicityOptions={periodicities}
              storageLocationOptions={storageLocations}
              onClose={() => {
                setEditProducts('');
                setModalStates({ ...modalStates, modalProduct: false });
              }}
            />
          )}

          {selectedSecondCard && (
            <TechnicalSheetDialog
              maxWidth={null}
              open={modalStates.modalTechnicalSheet}
              handleClose={() =>
                setModalStates({ ...modalStates, modalTechnicalSheet: false })
              }
              cardDataTable={cardDataTableSecond}
              cardTransformation={cardTransformationSecond}
              selectedCard={selectedSecondCard}
              ucPrimarySelectedId={ucPrimarySelectedIdSecond}
            />
          )}
        </Dialog>
      )}

      {selectedCard.type === 'MENU' && (
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
          }}
          className="defaultModal menuModal"
          maxWidth="md"
          fullWidth
          PaperComponent={PaperComponent}
        >
          <DialogTitle
            id="draggable-dialog-title"
            className="modalHeader cursorMove"
          >
            <Typography className="bold textAlignCenter fontSize16">
              CADASTRO DE CARDÁPIO
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose()}
              aria-label="close"
              style={{ outline: '0', background: 'none' }}
            >
              <img
                src={CloseIconNavy}
                alt="CloseIconNavy"
                style={{ width: 15 }}
              />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent">{BodyModal}</DialogContent>
        </Dialog>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TechnicalSheetDialog);
