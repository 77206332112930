import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  configs: [
    {
      counts: {
        showColumns: [
          'OUTROS ESTOQUES',
          'REAL',
          'TEÓRICO',
          'ÚLTIMA',
          'CONSUMO',
          'DIFERENÇA EM QTDE / R$',
          'DIFERENÇA EM %',
          'USUÁRIO'
        ]
      }
    },
    {
      extracts: {
        showColumns: [
          'CUSTO',
          'VALOR',
          'QTDE FINAL',
          'VALOR FINAL',
        ]
      }
    },
    {
      menus: {
        showColumns: [
          'CARD. R$',
          'DESC.',
          'QTDE.',
          'TOTAL R$',
          'CUSTO TOTAL R$',
          'CUSTO NOMINAL',
          'CUSTO % PROJ.',
          'CUSTO % REAL'
        ]
      }
    },
    {
      production: {
        currentSquare: ''
      }
    },
    {
      miseEnPlace: {
        currentSquare: ''
      }
    },
    {
      duration: {
        showProjection: ''
      }
    }
  ]
};

export const userConfigs = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    handleCountColumns: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { configs } = payload;

      const newConfigs = configs?.filter((config) => config.counts).length
        ? configs.find((config) => config?.counts)?.counts?.showColumns
        : currState.configs[0].counts.showColumns;

      localStorage.setItem('countConfigs', newConfigs);

      state.configs.find((config) => config.counts).counts.showColumns =
        newConfigs;
    },
    handleExtractDialogColumns: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { configs } = payload;

      const newConfigs = configs?.filter((config) => config.extracts).length
        ? configs.find((config) => config?.extracts)?.extracts?.showColumns
        : currState.configs[0].extracts.showColumns;

      localStorage.setItem('extractDialogConfigs', newConfigs);

      state.configs.find((config) => config.extracts).extracts.showColumns =
        newConfigs;
    },
    handleMenusColumns: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { configs } = payload;

      const newConfigs = configs?.filter((config) => config.menus).length
        ? configs.find((config) => config?.menus)?.menus?.showColumns
        : currState.configs[0].menus.showColumns;

      localStorage.setItem('menusConfigs', newConfigs);

      state.configs.find((config) => config.menus).menus.showColumns =
        newConfigs;
    },
    handleProductionSquare: (state, action) => {
      const { payload } = action;
      const { configs } = payload;

      localStorage.setItem('productionConfigs', JSON.stringify(configs));

      state.configs.find((config) => config.production).production.currentSquare = configs;
    },
    handleMiseEnPlaceSquare: (state, action) => {
      const { payload } = action;
      const { configs } = payload;

      localStorage.setItem('miseEnPlaceConfigs', JSON.stringify(configs));

      state.configs.find((config) => config.miseEnPlace).miseEnPlace.currentSquare = configs;
    },
    handleShowSalesProjection: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { configs } = payload;

      const newConfigs = configs.find((config) => config.duration).duration
      ? configs.find((config) => config.duration).duration.showProjection
      : currState.configs[0].duration.showProjection;

      localStorage.setItem('showProjectionConfigs', newConfigs);
      
      state.configs.find((config) => config?.duration).duration.showProjection = newConfigs;

    },
    clearAll: (state) => {
      state.products = [];
      state.selectedPages = [];
    },
  }
});

export const { 
  handleCountColumns,
  handleMenusColumns,
  handleExtractDialogColumns,
  handleProductionSquare,
  handleMiseEnPlaceSquare,
  handleShowSalesProjection,
  clearAll 
} = userConfigs.actions;

export default userConfigs.reducer;
