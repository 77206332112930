/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import { TreeSelect } from 'antd';
import { connect } from 'react-redux';

import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

const environments = Environment(process.env.REACT_APP_ENV);

function TreeSelectorFieldSquares({
  label,
  options,
  filterName,
  handleFilter,
  size,
  getFilterhandle,
  userInfo,
  defaultValue,
  multiple,
  notMultipleDefaultValue,
  storedValue,
  inputSizes = {
    small: 110,
    medium: 155,
    medium_to_plus: 165,
    medium_plus: 210,
    large: 250
  },
  onFormStyle,
  marginRight,
  placeholder,
  customSize,
  customHandleChange = false,
  customClassName = '',
  allObjValue = false,
  extraParam = '',
  showLabel = true,
}) {
  const [initialValue, setInitialValue] = useState(storedValue);
  const [squareOptions, setSquareOptions] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState(
    multiple || notMultipleDefaultValue
      ? defaultValue || undefined
      : defaultValue || undefined
  );
  const [open, setOpen] = useState(false);
  const { id: userId } = userInfo.companiesActive;
  const loading = open && options && options.length === 0;

  useEffect(() => {
    if (!loading) return;

    if (typeof getFilterhandle !== 'function') return;

    getOptions(
      `${environments.catalog}/courts`,
      'squares',
      squareOptions,
      setSquareOptions,
      userId,
      { 
        restaurantId: userId, 
        type: extraParam
      },
    );
  }, [loading, userId, open]);

  const handleChange = (element, obj) => {
    setAutoCompleteValue(element);

    customHandleChange(element, obj)
  }

  const handleFilterValue = (element, obj) => {
    setAutoCompleteValue(element);

    if (!element || element === null) {
      handleFilter(filterName, '');
      return;
    }

    if (multiple) {
      const value = element?.map((item) => item.title);
      handleFilter(filterName, value, element, obj);
    } else {
      const { id = '', title = '' } = obj;

      handleFilter(filterName, allObjValue ? obj : id || title, element, obj);
    }
  };

  return (
    <div
      style={{ width: customSize || inputSizes[size], marginRight: marginRight || 35 }}
      className={`flexColumn dFlex ${customClassName}`}
    >
      {showLabel ?
        <label
          className="fontSizeDefault pRelative bold mb0"
          style={{ marginTop: onFormStyle ? '4px' : '-3px' }}
        >
          {label}
        </label>
      : null}

      <TreeSelect
        suffixIcon={
          <svg
            className="MuiSvgIcon-root pAbsolute"
            style={{ right: 0, bottom: '-7px' }}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M7 10l5 5 5-5z" />
          </svg>
        }
        style={{ width: '100%', marginTop: onFormStyle ? '2px' : 'inherit' }}
        allowClear
        value={autoCompleteValue || initialValue || undefined}
        onDropdownVisibleChange={(event) => {
          setOpen(event);
        }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={squareOptions}
        placeholder={placeholder || "Selecione..."}
        showSearch
        filterTreeNode={(search, item) =>
          item?.title
            ?.toLowerCase()
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            ?.indexOf(
              search
                ?.toLowerCase()
                ?.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            ) >= 0
        }
        treeDefaultExpandAll
        onChange={(element) => {
          setInitialValue('');

          if (!element) {
            customHandleChange 
              ? handleChange(null, null)
              : handleFilterValue(null, null);
          }
        }}
        onSelect={(element, value) => {
          setInitialValue('');

          if (element) {
            customHandleChange 
              ? handleChange(element, value)
              : handleFilterValue(element, value);
          }
        }}
        showHitDescendants
        mode={2}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(TreeSelectorFieldSquares);
