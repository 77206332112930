import React from 'react';

import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import {
  CardEditModeContainer,
  CardEditTitle,
  AddIconContainer,
  ContainerEditMode
} from './index.style';

import { EditModeIcons } from '../utils';

const SortableItem = SortableElement(({ value }) => (
  <CardEditModeContainer size={value.size}>
    <CardEditTitle tabIndex={0}>{value.title}</CardEditTitle>
  </CardEditModeContainer>
));

const SortableList = SortableContainer(({ items }) => (
  <ContainerEditMode>
    {items.map((value, index) => (
      <SortableItem key={`item-${value.title}`} index={index} value={value} />
    ))}

    <CardEditModeContainer bgColor="#DADEF7">
      <CardEditTitle color="#5062F0">
        <AddIconContainer>
          <img alt={EditModeIcons.addNewCard} src={EditModeIcons.addNewCard} />
        </AddIconContainer>
        Adicionar módulo
      </CardEditTitle>
    </CardEditModeContainer>
  </ContainerEditMode>
));

function VisionsEditMode({ cardsInfo, setCardInfo }) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setCardInfo([]);
    setCardInfo(arrayMoveImmutable(cardsInfo, oldIndex, newIndex));
  };

  return <SortableList items={cardsInfo} onSortEnd={onSortEnd} axis="x,y" />;
}

export default VisionsEditMode;
