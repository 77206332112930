/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useRef } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  Tooltip
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import axios from 'axios';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import Layout from 'containers/layouts/layout';
import calenderIcon from 'images/icons/objects/calendars/calendarHD.svg';
import SaleIcon from 'images/icons/objects/stores/sale.svg';
import cloudIcon from 'images/icons/weathers/cloudHD.svg';
import waterDrops from 'images/icons/weathers/waterDrops.svg';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

const useStyles = makeStyles({
  table: {
    minWidth: 946,
    borderRadius: '20px',
    fontFamily: 'Lato'
  },
  cell: {
    fontFamily: 'Lato',
    borderRight: '1px solid #EEE',
    borderLeft: '1px solid #EEE',
    borderBottom: 'none',
    padding: '5px'
  },
  cellHeader: {
    fontWeight: 'bold',
    fontFamily: 'Lato',
    borderRight: '1px solid #EEE',
    borderLeft: '1px solid #EEE',
    borderBottom: 'none',
    padding: '5px',
    width: '219px'
  },
  totalKey: {
    fontFamily: 'Lato',
    textAlign: 'right',
    fontSize: '14px'
  },
  totalValue: {
    fontFamily: 'Lato',
    textAlign: 'right',
    fontSize: '14px'
  },
  input: {
    fontFamily: 'Lato',
    fontSize: '12px'
  }
});

function createNewData(data) {
  const result = [[], [], [], [], []];

  if (data) {
    if (
      new Date(data[0]).getDate() === 1 &&
      new Date(data[0]).getDay() > 4 &&
      data.length > 28
    ) {
      result.push([]);
    } else if (
      new Date(data[0]).getMonth() === 2 &&
      new Date(data[0]).getDay() === 0 &&
      data.length === 28
    ) {
      result.splice(0, 1);
    }

    for (let i = 0; i < result.length; i++) {
      let index = 0;
      let count = 0;
      for (let j = 0; j < 7; j++) {
        if (new Date(data[index]).getDay() === j) {
          result[i].push(new Date(data[index]).getDate());
          index++;
        } else if (new Date(data[index]).getDay() !== j) {
          result[i].push(null);
        }
      }
      result[i].map((value) => (!value ? count++ : null));
      data.splice(0, 7 - count);
    }
  }

  return result;
}

function getDaysInMonth(month, year) {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export function RegisterSalesProjection({ userInfo, projectionInfo }) {
  const query = new URLSearchParams(location.search);
  const date = query.get('date');

  const { loading } = useForm();
  const projectionStorage = projectionInfo.action
    ? projectionInfo
    : JSON?.parse(localStorage.getItem('projection'));
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(
    projectionStorage?.action
      ? projectionStorage?.action.toLowerCase() === 'edit'
      : false
  );
  const [editData, setEditData] = useState(
    projectionStorage?.data ? projectionStorage?.data : {}
  );
  const [projectionDate, setProjectionDate] = useState(projectionStorage?.data && projectionStorage?.data.formattedDate ? projectionStorage?.data.formattedDate : "")
  const [values, setValues] = useState(editMode ? editData.values : []);
  const [valuesChanged, setValuesChanged] = useState(values.values());
  const [totalForecast, setTotalForecast] = useState(
    values
      ? values.reduce(
          (total, value) =>
            value.forecastNoSafeMargin || value.forecast
              ? parseFloat(total) + parseFloat(Number(value.forecast))
              : parseFloat(total),
          0
        )
      : 0
  );
  const [totalProjection, setTotalProjection] = useState(
    values
      ? values.reduce(
          (total, value) =>
            value.projection
              ? parseFloat(total) + parseFloat(Number(value.projection))
              : parseFloat(total),
          0
        )
      : 0
  );
  const [totalGoal, setTotalGoal] = useState(0);
  const [saleRegister, setSaleRegister] = useState(
    projectionStorage?.data ? [projectionStorage?.data] : []
  );
  const [selectedStore, setSelectedStore] = useState(
    projectionStorage?.data ? projectionStorage?.data.restaurantId : null
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [baseDate, setBaseDate] = useState(
    date ? new Date(`${moment(date).format("YYYY/MM")}/01`) : editMode ? new Date(`${editData.date}/01`) : new Date()
  );
  const [monthDays, setMonthDays] = useState(
    getDaysInMonth(new Date().getMonth(), new Date().getFullYear())
  );
  const [days, setDays] = useState();
  const [rows, setRows] = useState(
    createNewData(
      getDaysInMonth(new Date().getMonth(), new Date().getFullYear())
    )
  );
  const [editable, setEditable] = useState(true);
  const { id: paramsID } = useParams();
  const [getId, setGetId] = useState(paramsID);
  const [stores, setStores] = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const [storeError, setStoreError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [safetyMargin, setSafetyMargin] = useState(0);
  const [weeksAgo, setWeeksAgo] = useState();
  const [parameters, setParameters] = useState({});
  const classes = useStyles();
  const theme = useTheme();
  const [showAlert, setShowAlert] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [rainPercentageForAlert, setRainPercentageForAlert] = useState(10);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [status, setStatus] = useState(
    projectionStorage?.data && projectionStorage?.data.status
      ? projectionStorage?.data.status === 'INACTIVE'
        ? 'INACTIVE'
        : 'ACTIVE'
      : 'ACTIVE'
  );
  const [selectedDate, handleDateChange] = useState(
    date ? moment(date).format("YYYY/MM") : editMode ? editData.date : new Date()
  );
  const environments = Environment(process.env.REACT_APP_ENV);

  const getParameterInfos = useCallback(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${userInfo.companiesActive.id}/parameters`
      );
      const paramsJson = await response.json();
      setParameters(paramsJson);
      setSafetyMargin(paramsJson?.salesProjection?.safetyMargin);
      setWeeksAgo(paramsJson?.salesProjection?.weeksAgo);
      setRainPercentageForAlert(paramsJson?.weather?.rainPercentageForAlert);
    })();
  }, []);

  const handleInputValues = (event, date, day) => {
    const value = event.target.value
      ? parseFloat(event.target.value.replace(/\./g, '').replace(',', '.'))
      : 0;
    const weekDay = new Date(
      `${date.getFullYear()}-${date.getMonth()}-${day}`
    ).getDay();
    const inputValue = {
      projection: parseFloat(value),
      day,
      dayWeek: weekDay
    };
    const index = values.length
      ? values.findIndex((a) => a.day === inputValue.day)
      : -1;

    if (index < 0) {
      values.push(inputValue);
      return;
    }

    values[index].projection = parseFloat(value);
    values[index].setByUser = true

    calculateTotalGoal(values)
  };

  const calculateTotalGoal = (newValues) => {
    const data = newValues || values
    let goal = 0

    for (const d of data) {
      let val = 0

      if ((d?.projection !== null || d?.projection !== undefined) && (d?.projection > 0 || d?.setByUser)) {
        val = d.projection
      } else if (d?.forecastNoSafeMargin && d?.forecastNoSafeMargin > 0) {
        val = d.forecastNoSafeMargin
      }

      goal += val
    }

    setTotalGoal(goal)
  }

  const handleValues = (data, day, info) => {
    if (!data) return '';
    const { contentItem: { values = [] } = {} } = data;
    const valueByDay = values.find((value) => {
      if (value) return value.day === day;
    });

    if (info === 'totalSales') {
      if (!valueByDay?.totalSales) return '';
      return valueByDay.totalSales;

      // eslint-disable-next-line no-else-return
    } else if (info === 'forecastNoMargin') {
      if (!valueByDay?.forecastNoSafeMargin) return '';
      return valueByDay.forecastNoSafeMargin;
    } else if (info === 'projection') {
      if (!valueByDay?.projection) return '';
      return valueByDay.projection;
    } else if (info === 'sales') {
      if (!valueByDay?.sales) return '';
      return valueByDay.sales;
    } else if (info === 'percentage') {
      const projectionOrMargin = valueByDay?.projection
        ? valueByDay?.projection
        : valueByDay?.forecastNoSafeMargin;

      const percent =
        (100 - (valueByDay.sales * 100) / projectionOrMargin) * -1;

      if (percent < 0) {
        return (
          <p
            style={{
              color: '#F53D4C',
              fontSize: '10px',
              padding: '2px 0 0 5px'
            }}
          >
            {percent.toFixed(2)}%
          </p>
        );
      }
      if (percent > 0) {
        return (
          <p
            style={{
              color: '#006608',
              fontSize: '10px',
              padding: '2px 0 0 5px'
            }}
          >
            +{percent.toFixed(2)}%
          </p>
        );
      }
      return percent.toFixed(2);
    } else if (info === 'weather') {
      if (valueByDay?.weather?.city) {
        return valueByDay?.weather;
      } 
        return false;
      
    } else if (info === 'holiday') {
      if (valueByDay?.holiday) {
        return valueByDay?.holiday;
      } 
        return false;
      
    } else if (info === 'canEdit') {
      if (valueByDay?.canEdit) {
        return valueByDay?.canEdit 
      } 
        return false;
      
    }else {
      return '';
    }
  };

  const handleFields = (day) => {
    const handleProjection = (data) => {
      if (!data) return '';
      const { contentItem: { values = [] } = {} } = data;
      const valueByDay = values.find((value) => {
        if (value) return value.day === day;
      });

      if (!valueByDay?.projection) return '';
      return valueByDay.projection;
    };

    const handleSales = (data) => {
      if (!data) return '';
      const { contentItem: { values = [] } = {} } = data;
      const valueByDay = values.find((value) => {
        if (value) return value.day === day;
      });

      if (!valueByDay?.sales) return '';
      return valueByDay.sales;
    };

    const handleForecastNoMargin = (data) => {
      if (!data) return '';
      const { contentItem: { values = [] } = {} } = data;
      const value = values.find((el) => el?.day === day);
      if (!value) return '';
      const { forecastNoSafeMargin } = value;
      return forecastNoSafeMargin;
    };

    const forecast = handleForecastNoMargin(editData)
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .toString();
    const projection = handleProjection(editData)
      .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      .toString();

    const ValidValue = forecast === projection;

    const ValidateColor = () => {
      if (ValidValue) {
        return 'disabledColor';
      } if (!ValidValue && projection) {
        return 'disabledColorChanged';
      } if (!projection) {
        return 'disabledColor';
      } return 'disabledColor';
    };
    
    const canEditVal = handleValues(
      editData,
      day,
      'canEdit'
    );

    if (editMode) {
      const currentDate = editData?.date || editData?.contentItem?.date
      const formattedDate = moment(new Date(`${currentDate}/${day}`)).format('DD/MM/YYYY')

      const isInvalidDate = 
        formattedDate === moment().format('DD/MM/YYYY') 
        ? false 
        : moment(
            new Date(`${currentDate}/${day}`),
            'DD/MM/YYYY'
          ).isBefore(moment())

      return (
        <div
          className={`positionFlex 
        ${ValidateColor()} 
        ${handleProjection(editData) && 'coloredValue'} ${
            !handleSales(editData) && 'fontSize'
          } 
        ${ValidValue && 'coloredValueBlack'}`}
        >
          <CurrencyTextField
            variant="outlined"
            size="small"
            currencySymbol="R$"
            minimumValue="0"
            outputFormat="number"
            decimalCharacter=","
            digitGroupSeparator="."
            decimalPlaces={2}
            decimalPlacesShownOnFocus={2}
            style={{ color: 'secondary' }}
            value={
              handleProjection(editData)
                ? handleProjection(editData)
                : handleForecastNoMargin(editData)
            }
            onBlur={(e) => {
              handleInputValues(e, baseDate, day);
            }}
            disabled={!canEditVal || isInvalidDate}
            className={`textFieldSmall right ${ !canEditVal? 'borderNone' : 'adjustInput'}`}
            InputProps={{
              classes: { input: classes.input },
              startAdornment: (
                <Typography
                  style={{
                    textAlign: 'start',
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    color: '#000',
                    margin: 0,
                    padding: 0,
                    width: '20%'
                  }}
                >
                  R$
                </Typography>
              )
            }}
          />
        </div>
      );
    }
    const item = saleRegister
      ? saleRegister.find((value) => value.restaurantId === selectedStore)
      : null;
    if (item) {
      if (day) {
        return (
          <CurrencyTextField
            variant="outlined"
            size="small"
            // disabled={!editable}
            currencySymbol="R$"
            minimumValue="0"
            outputFormat="number"
            decimalCharacter=","
            digitGroupSeparator="."
            decimalPlaces={2}
            decimalPlacesShownOnFocus={2}
            value={
              handleProjection(editData)
                ? handleProjection(editData)
                : handleForecastNoMargin(editData)
            }
            onBlur={(e) => {
              handleInputValues(e, baseDate, day);
            }}
            className={`textFieldSmall ${!canEditVal? 'borderNone' : 'adjustInput'}`}
            inputProps={{ style: { marginBottom: '10px' } }}
            InputProps={{
              classes: { input: classes.input },
              startAdornment: (
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    margin: 0,
                    padding: 0
                  }}
                >
                  R$
                </Typography>
              )
            }}
          />
        );
      }
    } else if (day) {
      return (
        <div
          className={`${ValidateColor()} 
        ${!handleSales(editData) && 'fontSize'} ${
            ValidValue && 'coloredValueBlack'
          }`}
        >
          <CurrencyTextField
            variant="outlined"
            size="small"
            currencySymbol="R$"
            minimumValue="0"
            outputFormat="number"
            decimalCharacter=","
            digitGroupSeparator="."
            decimalPlaces={2}
            decimalPlacesShownOnFocus={2}
            style={{ color: 'secondary' }}
            value={
              handleProjection(editData)
                ? handleProjection(editData)
                : handleForecastNoMargin(editData)
            }
            onBlur={(e) => {
              handleInputValues(e, baseDate, day);
            }}
            disabled={!canEditVal}
            className={`textFieldSmall right ${!canEditVal? 'borderNone' : 'adjustInput'}`}
            InputProps={{
              classes: { input: classes.input },
              startAdornment: (
                <Typography
                  style={{
                    textAlign: 'start',
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    color: '#000',
                    margin: 0,
                    padding: 0,
                    width: '20%'
                  }}
                >
                  R$
                </Typography>
              )
            }}
          />
        </div>
      );
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleClose(event, reason) {
    history.push(`/admin/projecao-de-vendas/${paramsID}`);
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleContinue = () => {
    setValues([]);
    handleDialogClose();
  };

  const handleMergedProjectionForecast = (values) =>
    values.filter((el) => {
      if (el.projection) {
        el.projection;
      }
      return el;
    });

  const onSubmit = () => {
    axios
        .put(
          `${environments.restaurants}/parameters/${parameters._id}`,
          {
            method: 'PUT',
            ...parameters,
            salesProjection: {
              ...parameters.salesProjection,
              safetyMargin,
              weeksAgo,
            },
          },
          config
        )
        .then(() => {
        })
        .catch((error) => {
          setMessageError(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setShowAlert(true);
        });

    const restaurantId = selectedStore || userInfo.companiesActive.id;

    const newValues = handleMergedProjectionForecast(values);

    const body = {
      date: new Date(baseDate.getFullYear(), baseDate.getMonth(), 1),
      user: userInfo.user,
      status,
      restaurantId,
      pendentFill: !(values.length === days),
      totalProjection: values.reduce(
        (total, value) =>
          value.projection
            ? parseFloat(total) + parseFloat(value.projection)
            : parseFloat(total) + 0,
        0
      ),
      totalForecast: values.reduce(
        (total, value) =>
          value.forecast
            ? parseFloat(total) + parseFloat(value.forecast)
            : parseFloat(total) + 0,
        0
      ),
      totalForecastNoSafeMargin: values.reduce(
        (total, value) =>
          value.forecastNoSafeMargin
            ? parseFloat(total) + parseFloat(value.forecastNoSafeMargin)
            : parseFloat(total) + 0,
        0
      ),
      values: newValues
    };

    const salesProjectionId =
      editData?.contentItem?._id || projectionInfo?.data?._id;

    if (!salesProjectionId) {
      axios
        .post(`${environments.restaurants}/salesProjection`, body, config)
        .then(() => {
          setOpen(true);
        })
        .catch((error) => {
          setMessageError(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setShowAlert(true);
        });

      return;
    }

    axios
      .put(
        `${environments.restaurants}/salesProjection/${salesProjectionId}`,
        body,
        config
      )
      .then(() => {
        setOpen(true);
      })
      .catch((error) => {
        setMessageError(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setShowAlert(true);
      });
  };

  const handleSave = () => {
    onSubmit();
    handleContinue();
  };

  function decimal(v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{2,2})$/, '$1,$2');
    return v;
  }

  const form = (
    <>
      <div className="cardDefault salesProjectionForm">
        <Grid
          container
          xs={12}
          spacing={2}
          direction="row"
          justify="space-evenly"
          alignItems="center"
          className="m0 w100"
        >
          <Grid item xs={1} sm={1} className="pl0">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                animateYearScrolling
                openTo="month"
                minDate={new Date('01/01/2000')}
                views={['year', 'month']}
                label="Mês e Ano"
                fullWidth
                size="small"
                autoOk
                className="dateSmall"
                format="MM/yyyy"
                value={new Date(`${selectedDate}/01`)}
                onChange={(date) => {
                  handleDateChange(date);
                  setBaseDate(date);
                  setEditData({});
                  setValuesChanged(values.values());
                  setSelectedStore(null);
                }}
              />
            </MuiPickersUtilsProvider>

            <Dialog
              fullScreen={fullScreen}
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle
                className="modalHeader navyBlue"
                style={{
                  fontWeight: 'bold',
                  cursor: 'move',
                  fontFamily: 'Lato'
                }}
                id="draggable-dialog-title"
              >
                <Typography>Existem dados não salvos na página.</Typography>
              </DialogTitle>

              <DialogContent className="modalContent">
                <Typography>Deseja salvá-los antes de continuar?</Typography>
              </DialogContent>

              <DialogActions className="justifyCenter">
                <Button
                  className="defaultButton"
                  design="outlined"
                  onClick={handleContinue}
                  label="Continuar"
                  color="primary"
                />

                <Button
                  className="defaultButton submitButton"
                  autoFocus
                  design="contained"
                  label="Salvar"
                  onClick={handleSave}
                  color="primary"
                />
              </DialogActions>
            </Dialog>
          </Grid>

          <Grid item xs={2} sm={2}>
            <TextField
              fullWidth
              select
              name="stores"
              size="small"
              variant="outlined"
              label="Loja"
              error={storeError}
              value={
                selectedStore === 0 || selectedStore
                  ? selectedStore
                  : userInfo.companiesActive.id
              }
              disabled={editMode && true}
              onChange={(e) => {
                const item = saleRegister
                  ? saleRegister.find(
                      (value) => value.restaurantId === e.target.value
                    )
                  : null;
                setSelectedStore(e.target.value);
                setStoreError(false);
                if (item) {
                  setValues(item.values ? item.values : null);
                }
              }}
              placeholder="Selecione..."
              className="textFieldSmall"
              InputLabelProps={{
                shrink: true
              }}
            >
              <MenuItem key={1} value={0}>
                Todos
              </MenuItem>

              {stores.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={2} sm={2}>
            <TextField
              fullWidth
              select
              size="small"
              variant="outlined"
              label="Status"
              error={statusError}
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                setStatusError(false);
              }}
              placeholder="Selecione..."
              className="textFieldSmall"
              InputLabelProps={{
                shrink: true
              }}
            >
              <MenuItem key={1} value="ACTIVE">
                Ativo
              </MenuItem>

              <MenuItem key={2} value="INACTIVE">
                Inativo
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={2} sm={2} className="dFlex" />

          <Grid item xs={5} className="dFlex justifyEnd alignStart p0">
            <div>
              <div
                style={{ width: '200px' }}
                className="dFlex alignEnd justifyBetween"
              >
                <Typography
                  className="dFlex alignEnd"
                  style={{ fontFamily: 'Lato', fontSize: '12px' }}
                >
                  Margem de Segurança (%):
                </Typography>

                <TextField
                  size="small"
                  onChange={(e) => {setSafetyMargin(e.target.value)}}
                  name="safetyMargin"
                  variant="outlined"
                  value={safetyMargin}
                  required="required"
                  onInput={(e) => {
                    e.target.value = decimal(e.target.value);
                  }}
                  inputProps={{ maxLength: 5 }}
                  style={{ width: 34 }}
                  className="textFieldSmall right"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

              <div className="dFlex alignEnd justifyBetween">
                <Typography
                  className="dFlex alignEnd"
                  style={{ fontFamily: 'Lato', fontSize: '12px' }}
                >
                  Semanas Anteriores (Qtde):
                </Typography>

                <TextField
                  size="small"
                  onChange={(e) => {setWeeksAgo(parseInt(e.target.value))}}
                  name="safetyMargin"
                  variant="outlined"
                  value={weeksAgo}
                  required="required"
                  onInput={(e) => {
                    e.target.value = decimal(e.target.value);
                  }}
                  inputProps={{ maxLength: 5 }}
                  style={{ width: 34 }}
                  className="textFieldSmall right"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          direction="row"
          justify="flex-end"
          alignItems="center"
          className="mt0"
        >
          <Grid item xs={12} sm={12}>
            <TableContainer
              className="materialTableDefault"
              style={{
                minWidth: '946px',
                borderRight: 'solid 1px #0B0D1D',
                borderBottom: 'solid 1px #0B0D1D',
                borderTopRightRadius: '4px',
                borderTopLeftRadius: '4px'
              }}
            >
              <Table
                className={classes.table}
                aria-label="simple table"
                style={{ borderRadius: 0 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="headerCell-projection">
                      DOMINGO
                    </TableCell>

                    <TableCell align="left" className="headerCell-projection">
                      SEGUNDA
                    </TableCell>

                    <TableCell align="left" className="headerCell-projection">
                      TERÇA
                    </TableCell>

                    <TableCell align="left" className="headerCell-projection">
                      QUARTA
                    </TableCell>

                    <TableCell align="left" className="headerCell-projection">
                      QUINTA
                    </TableCell>

                    <TableCell align="left" className="headerCell-projection">
                      SEXTA
                    </TableCell>

                    <TableCell align="left" className="headerCell-projection">
                      SÁBADO
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.map((week) => (
                    <TableRow>
                      {week.find((item) => item !== null) &&
                        week.map((day) => {
                          const currentDayFormatted = day
                            ? new Date(selectedDate)
                                .toLocaleDateString()
                                .slice(2)
                                .replace('', day < 10 ? `0${day}` : day)
                            : new Date().toLocaleDateString();

                          const formatDay = () => {
                            if (day) {
                              if (day < 10) {
                                return `0${day}`;
                              } 
                                return day;
                              
                            } 

                            return '';
                          };

                          const forecast = handleValues(
                            editData,
                            day,
                            'forecastNoMargin'
                          )
                            .toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                            .toString();

                          const projection = handleValues(
                            editData,
                            day,
                            'projection'
                          )
                            .toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                            .toString();

                          const ValidValue = forecast === projection;

                          const weather = handleValues(
                            editData,
                            day,
                            'weather'
                          );

                          const holiday = handleValues(
                            editData,
                            day,
                            'holiday'
                          );

                          const renderInput = () => {
                            if (ValidValue) {
                              return (
                                <Grid item xs={13} className="valueBox">
                                  <img
                                    style={{ width: '19px' }}
                                    src={SaleIcon}
                                    alt="AddIcon"
                                  />
                                  <div>{day && handleFields(day)}</div>
                                </Grid>
                              );
                            } if (
                              handleValues(editData, day, 'projection') &&
                              handleValues(editData, day, 'forecastNoMargin')
                            ) {
                              return (
                                <Tooltip
                                  placement="bottom"
                                  title={`Média de Vendas: R$ ${handleValues(
                                    editData,
                                    day,
                                    'forecastNoMargin'
                                  ).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  })}`}
                                >
                                  <Grid item xs={13} className="valueBox">
                                    <img
                                      style={{ width: '19px' }}
                                      src={SaleIcon}
                                      alt="AddIcon"
                                    />

                                    <div>
                                      {day && handleFields(day)}
                                    </div>
                                  </Grid>
                                </Tooltip>
                              );
                            } 
                              return (
                                <Grid item xs={13} className="valueBox">
                                  <img
                                    style={{ width: '19px' }}
                                    src={SaleIcon}
                                    alt="AddIcon"
                                  />

                                  <div>
                                    {day && handleFields(day)}
                                  </div>
                                </Grid>
                              );
                            
                          };

                          return (
                            <TableCell
                              align="center"
                              className={`p0 weekCell ${classes.cell}`}
                              style={
                                !editable
                                  ? { background: '#fafafa' }
                                  : { background: '#FFF' }
                              }
                            >
                              <Grid
                                container
                                spacing={1}
                                direction="row"
                                justify="center"
                                alignItems="center"
                                className={`pl0 m0 cardProjection ${
                                  holiday ? 'isHoliday' : ''
                                }`}
                              >
                                <Grid
                                  item
                                  xs={2}
                                  className={`dayBox ${
                                    moment(
                                      currentDayFormatted,
                                      'DD/MM/YYYY'
                                    ).isSameOrBefore(moment()) && 'colorDay'
                                  } ${holiday ? 'isHoliday' : ''}`}
                                >
                                  {formatDay()}
                                </Grid>

                                <Grid
                                  className="dateWeather"
                                  style={{
                                    display: holiday || weather ? '' : 'none'
                                  }}
                                >
                                  <div className="holiday">
                                    {holiday && (
                                      <Tooltip
                                        placement="bottom"
                                        title={holiday?.name}
                                      >
                                        <span>
                                          <img src={calenderIcon} alt="" />
                                        </span>
                                      </Tooltip>
                                    )}
                                  </div>

                                  {weather && (
                                    <div className="weather">
                                      <span>
                                        <img src={cloudIcon} alt="" />
                                        {weather.minimumTemperature}º/
                                        {weather.maximumTemperature}º
                                      </span>

                                      <span
                                        className={`${
                                          weather.rainProbability >=
                                          rainPercentageForAlert
                                            ? 'alertRain'
                                            : ''
                                        }`}
                                      >
                                        <img src={waterDrops} alt="" />
                                        {weather.rainProbability}%
                                      </span>
                                    </div>
                                  )}
                                </Grid>

                                {handleValues(editData, day, 'sales') && (
                                  <Grid item xs={13} className="valueForecast">
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        color: '#5062F0'
                                      }}
                                    >
                                      $
                                    </p>

                                    <div className="projectionValue">
                                      <span>R$ </span>

                                      {handleValues(
                                        editData,
                                        day,
                                        'sales'
                                      ).toLocaleString('pt-br', {
                                        minimumFractionDigits: 2
                                      })}
                                    </div>

                                    {handleValues(editData, day, 'percentage')}
                                  </Grid>
                                )}

                                {day ? (
                                  renderInput()
                                ) : (
                                  <Grid item xs={13} className="valueBox" />
                                )}
                              </Grid>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={() => handleClose()}
          >
            <Alert onClose={() => handleClose()} severity="success">
              Cadastro realizado com sucesso!
            </Alert>
          </Snackbar>
        </Grid>
      </div>

      <Grid container xs={12} sm={12} className="dFlex justifyBetween mt20">
        <div className="dFlex">
          <div>
            <div className="dFlex alignCenter containerDescription">
              <div>
                <img src={SaleIcon} alt="AddIcon" />
              </div>

              <p className="dFlex descriptionFont blue metaStore">
                Meta Loja
              </p>
            </div>

            <div className="dFlex alignCenter containerDescription">
              <p className="dollarSign">$</p>

              <p className="descriptionFont blue">Venda realizada</p>
            </div>
          </div>

          <div className="rSubtitle">
            <div className="dFlex alignCenter containerDescription">
              <div className="boxIcon">
                <img src={calenderIcon} alt="AddIcon" />
              </div>

              <p className="dFlex descriptionFont metaStore">Feriado</p>
            </div>

            <div className="dFlex alignCenter containerDescription">
              <div className="boxIcon">
                <img src={cloudIcon} alt="AddIcon" />
              </div>

              <p className="descriptionFont">Temperatura prevista</p>
            </div>

            <div className="dFlex alignCenter containerDescription">
              <div className="boxIcon">
                <img src={waterDrops} alt="AddIcon" />
              </div>

              <p className="descriptionFont">Previsão de chuva</p>
            </div>
          </div>
        </div>

        <div className="dFlex">
          <Link to={`/admin/projecao-de-vendas/${paramsID}`}>
            <Button
              className="defaultButton backButton"
              design="outlined"
              label="Voltar"
            />
          </Link>

          <Button
            className="defaultButton submitButton"
            type="submit"
            onClick={onSubmit}
            design="contained"
            style={{ marginLeft: '20px', maxHeight: '44px' }}
            label={loading ? 'Salvando...' : 'Salvar'}
          />
        </div>
      </Grid>

      <CommonAlert
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        indexMessage={messageError}
        statusCode={statusCode}
        width="50%"
      />
    </>
  );

  useEffect(() => {
    if (projectionInfo.action) {
      localStorage.setItem('projection', JSON.stringify(projectionInfo));
    }
    setGetId(paramsID);
  }, [paramsID]);

  useEffect(() => {
    setMonthDays(getDaysInMonth(baseDate.getMonth(), baseDate.getFullYear()));
  }, [baseDate]);

  useEffect(() => {
    setRows(createNewData(monthDays));
    setDays(
      new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 0).getDate()
    );
  }, [monthDays]);

  useEffect(() => {
    getParameterInfos();
  }, []);

  useEffect(() => {
    (async () => {
      const month =
        baseDate.getMonth() + 1 < 10
          ? `0${baseDate.getMonth() + 1}`
          : baseDate.getMonth() + 1;
      const response = await fetch(
        `${
          environments.restaurants
        }/salesProjection?page=0&size=10&originId=${getId}&startAt=${baseDate.getFullYear()}-${month}&endAt=${baseDate.getFullYear()}-${month}`
      );
      const content = await response.json();
      const contentValues = content?.content[0]?.values || [];

      if (content.totalElements) {
        const {
          content: [contentItem]
        } = content;
        setEditMode(true);
        setEditData({ ...editData, contentItem });
        setValues(contentValues);

        setTimeout(() => {
          setTotalForecast(
            values.reduce(
              (total, value) =>
                value.forecastNoSafeMargin || value.forecast
                  ? parseFloat(total) + parseFloat(Number(value.forecast))
                  : parseFloat(total),
              0
            )
          );

          calculateTotalGoal(values)
        }, 1000);

        setTotalProjection(
          content.content[0].values
            ? content.content[0].values.reduce(
                (total, value) =>
                  value.projection
                    ? parseFloat(total) + parseFloat(Number(value.projection))
                    : parseFloat(total),
                0
              )
            : 0
        );
      } else if (!content.totalElements) {
        setEditMode(false);
        setEditData({});
        setValues([]);
        setTotalForecast(0);
        setTotalProjection(0);
      }
      setSaleRegister(content.content);
    })();
  }, [getId, selectedDate]);

  useEffect(() => {
    if (values) {
      setTotalProjection(
        values.reduce(
          (total, value) =>
            value.projection
              ? parseFloat(total) + parseFloat(Number(value.projection))
              : parseFloat(total),
          0
        )
      );
      setTotalForecast(
        values.reduce(
          (total, value) =>
            value.forecastNoSafeMargin || value.forecast
              ? parseFloat(total) + parseFloat(Number(value.forecast))
              : parseFloat(total),
          0
        )
      );
    }
  }, [valuesChanged]);

  return (
    <Layout>
      <GlobalStyle />

      <Container>
        <Grid container xs={12} style={{ marginBottom: '15px' }}>
          <Grid container item xs={6} justify="flex-start" align="center">
            <Typography
              className="dFlex alignCenter"
              style={{ fontFamily: 'Lato', fontSize: '16px' }}
            >
              <b>Projeção de Vendas</b>
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="flex-end" align="center">
            <Typography
              className="dFlex alignCenter"
              style={{ fontFamily: 'Lato', fontSize: '12px' }}
            >
              <b>{`Total Meta Loja ${projectionDate}:`} &nbsp;</b>
              <span>{`${totalGoal.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}`}</span>
            </Typography>
          </Grid>
        </Grid>

        {form}
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  projectionInfo: state.projection
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSalesProjection);
