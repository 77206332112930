import React from 'react';

import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';


import AnalyzeIcon from 'images/menu-items/desktop/analyzeLightBlueNoBgOutline.svg';
import CatalogIcon from 'images/menu-items/desktop/catalogLightBlueNoBgOutline.svg';
import CopilotIcon from 'images/menu-items/desktop/copilotLightBlueNoBgOutline.svg';
import CountIcon from 'images/menu-items/desktop/countLightBlueNoBgOutline.svg';
import MenuIcon from 'images/menu-items/desktop/menuLightBlueNoBgOutline.svg';
import ParametersIcon from 'images/menu-items/desktop/parametersLightBlueNoBgOutline.svg';
import ProductionIcon from 'images/menu-items/desktop/productionLightBlueNoBgOutline.svg';
import ReceivementIcon from 'images/menu-items/desktop/receivementLightBlueNoBgOutline.svg';
import RegisterOrderIcon from 'images/menu-items/desktop/registerOrderLightBlueNoBgOutline.svg';
import SalesProjectionIcon from 'images/menu-items/desktop/salesProjectionLightBlueNoBgOutline.svg';
import TransferIcon from 'images/menu-items/desktop/transferLightBlueNoBgOutline.svg';
import UsersIcon from 'images/menu-items/desktop/usersLightBlueNoBgOutline.svg';
import MiseEnPlaceIcon from 'images/menu-items/miseEnPlace.svg';

import './styles.scss';
import { clearFilter as clearFilterCard } from 'store/reducers/cardsFilterSlice';
import { clearFilter as clearFilterCount } from 'store/reducers/countFilterSlice';
import { clearFilter as clearFilterProduction } from 'store/reducers/productionsFilterSlice';
import { clearFilter as clearFilterProduct } from 'store/reducers/productsFilterSlice';

function HoverItems({
  restaurantId = 0,
  currentRole = {},
  setOpenHover = () => {},
  reference = null,
  confirmModal,
  setConfirmModal,
  storedQuotationProductsSelected,
  isQuotationPage
}) {
  const dispatch = useDispatch();
  const showJustLabel = currentRole?.subs?.length === 1;
  const openConfirmationDialog =
    storedQuotationProductsSelected?.length && isQuotationPage;

  const clearLocalStorageFilters = () => {
    localStorage.removeItem('cardId');
    localStorage.removeItem('productId');
    dispatch(clearFilterCard());
    dispatch(clearFilterCount());
    dispatch(clearFilterProduction());
    dispatch(clearFilterProduct());
  };

  const handleRedirect = (pathName) => {
    setConfirmModal({
      ...confirmModal,
      name: 'quotation',
      open: true,
      redirectPath: pathName,
      confirmText: 'Sim',
      cancelText: 'Não',
      content: (
        <Typography className="bold">
          Você perderá todos os itens selecionados, deseja continuar?
        </Typography>
      )
    });
  };

  const renderIcon = (item) => {
    let currentIcon = CatalogIcon

    if (item === 'menu') {
      currentIcon = MenuIcon
    }

    if (item === 'salesProjections') {
      currentIcon = SalesProjectionIcon
    }

    if (item === 'receipts') {
      currentIcon = ReceivementIcon
    }

    if (item === 'progDiaryCounts' || item === 'progDiary') {
      currentIcon = AnalyzeIcon
    }

    if (item === 'counts') {
      currentIcon = CountIcon
    }

    if (item === 'transfers') {
      currentIcon = TransferIcon
    }

    if (item === 'newOrder') {
      currentIcon = RegisterOrderIcon
    }

    if (item === 'copilot') {
      currentIcon = CopilotIcon
    }

    if (item === 'miseEnPlace') {
      currentIcon = MiseEnPlaceIcon
    }

    if (item === 'productions') {
      currentIcon = ProductionIcon
    }

    if (item === 'parameters') {
      currentIcon = ParametersIcon
    }

    if (item === 'users') {
      currentIcon = UsersIcon
    }

    return (
      <img
        className="mr8"
        style={{ width: '16px', height: '16px' }}
        src={currentIcon}
        alt={currentIcon}
      />
    )
  }

  return (
    <div
      className={`onHoverSideBar ${currentRole?.name}`}
      onMouseLeave={() => setOpenHover(false)}
      ref={reference}
      id="hoverItemSideBar"
    >
      <div className="onHoverSideBarHeader">
        {currentRole.subs?.length === 1 ? (
          <Link
            className="hoverItemTitle"
            onClick={(e) => {
              if (!e.ctrlKey) {
                clearLocalStorageFilters();
              }
            }}
            to={`${currentRole.url !== '' ? `/${currentRole.url}` : ''}/${restaurantId}`}
          >
            {currentRole?.label}
          </Link>
        ) : (
          <Typography className="hoverItemTitle">
            {currentRole?.label}
          </Typography>
        )}
      </div>

      {!showJustLabel && currentRole.subs && (
        currentRole?.subs?.map((subtitle) => (
          <Link
            className={`hoverItemDefault ${subtitle?.showIcon ? 'lightBlue' : 'topDivider'}`}
            onClick={(e) => {
              if (!e.ctrlKey) {
                clearLocalStorageFilters();
              }

              if (openConfirmationDialog) {
                handleRedirect(`/${subtitle.url}/${restaurantId}`);
              }
            }}
            to={!openConfirmationDialog && `/${subtitle.url}/${restaurantId}`}
          >
            <div className="dFlex alignCenter noBg w100 flexRow justifyStart">
              {subtitle?.showIcon && renderIcon(subtitle?.name)}
              
              <span>
                {subtitle?.label}
              </span>
            </div>
          </Link>
        ))
      )}
    </div>
  );
}

export default HoverItems;
